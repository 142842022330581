import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core"
import { EntityView } from "@puntaje/shared/core"

/*#################################

Tabla con botones de acción en la columna derecha

#################################*/

@Component({
    templateUrl: "table_with_actions.component.html",
    selector: "table-with-actions",
    styleUrls: ["table_with_actions.component.scss"]
})
export class TableWithActionsComponent implements OnChanges, AfterViewInit {
    constructor(public cdr: ChangeDetectorRef) {}

    @Input() contentArray: any[]
    @Input() disableTrackBy: boolean = false
    @Input() getObjects: any
    @Input() showParams: any
    @Input() tableButtonsArray: any
    @Input() enableStickyHeaders: boolean = false

    entityView = EntityView
    headerParams: any
    showSmallTable: boolean
    showFloating: boolean = false

    @ViewChild("scroll")
    scroll: ElementRef

    @ViewChildren("header", { read: ElementRef })
    headers: QueryList<ElementRef>
    headerElements: ElementRef[]
    headerOffsetSize: any[]

    @ViewChildren("rowContent", { read: ElementRef })
    rowContents: QueryList<ElementRef>

    rowContentElements: ElementRef[]
    rowContentOffsetSize: any[]

    ngOnChanges(changes: SimpleChanges) {
        this.headerParams = this.getHeaderParams(this.showParams)
        this.showFloating = false
        this.cdr.detectChanges()
    }

    ngAfterViewInit() {
        this.getWidths()
        this.headers.changes.subscribe({
            next: this.getWidths
        })
        this.rowContents.changes.subscribe({
            next: this.getWidths
        })
    }

    getWidths = () => {
        this.cdr.detectChanges()
        if (this.enableStickyHeaders) {
            setTimeout(() => {
                this.headerElements = this.headers.map(header => header.nativeElement)
                this.rowContentElements = this.rowContents.map(row => row.nativeElement)
                this.cdr.detectChanges()

                this.headerOffsetSize = this.headers.map(header => ({
                    offsetWidth: header.nativeElement.offsetWidth,
                    offsetHeight: header.nativeElement.offsetHeight
                }))
                this.rowContentOffsetSize = this.rowContents.map(row => ({
                    offsetWidth: row.nativeElement.offsetWidth,
                    offsetHeight: row.nativeElement.offsetHeight
                }))
                this.showFloating = true
            })
        }
    }

    getHeaderParams(params: any) {
        let headerParams = {}
        const keys = Object.keys(params)

        keys.forEach(key => {
            if (params[key].type == EntityView) {
                const headerParamsModel = this.getHeaderParams(params[key].class.viewParams)
                headerParams = Object.assign({}, headerParams, headerParamsModel)
            } else {
                headerParams[key] = params[key]
            }
        })

        return headerParams
    }

    onResize(event) {
        this.showSmallTable = event.target.innerWidth <= 650
        this.cdr.detectChanges()
    }

    id = (index, content) => {
        if (this.disableTrackBy) {
            return content
        }

        return content && !this.disableTrackBy ? content.id : undefined
    }

    onScroll(event) {
        if (this.scroll) {
            this.scroll.nativeElement.scrollLeft = event.target.scrollLeft
        }
    }

    visibleKeys(object: any) {
        return Object.keys(object).filter(key => object[key].tableVisible)
    }
}
