<ll-titulo>Editar Evaluación Recomendada #{{ evaluacionSugeridaId }}</ll-titulo>
<ll-toolnavbar>
    <evaluacion-recomendadas-toolnavbar
        [displayButtons]="'all'"
        [evaluacionSugeridaId]="evaluacionSugeridaId"
    ></evaluacion-recomendadas-toolnavbar>
</ll-toolnavbar>

<evaluacion-recomendada-edit
    [evaluacionSugeridaId]="evaluacionSugeridaId"
    [showEvaluacionMultiple]="showEvaluacionMultiple"
></evaluacion-recomendada-edit>
