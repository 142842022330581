import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezona_pagina.component.html"
})
export class BannerPaginaComponent implements OnInit, OnDestroy {
    bannerPaginaId: number
    sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.artezona_paginas.show")
        this.sub = this.route.params.subscribe(params => {
            this.bannerPaginaId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
