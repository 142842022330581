import { Component, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Asociacion, Asociaciones, AsociacionForm } from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "asociaciones-new",
    templateUrl: "asociaciones.form.component.html"
})
export class AsociacionesNewComponent implements OnInit {
    asociacion: Asociacion = new Asociacion()
    params = AsociacionForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar asociacion"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private asociacionesService: Asociaciones, private router: Router) {}

    ngOnInit() {
        this.form = AsociacionForm.getForm(this.asociacion)
        this.loadingLayout.ready()
    }

    save() {
        AsociacionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asociacionesService.save(this.asociacion).then(response => {
                this.router.navigate(["asociaciones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asociacion = new Asociacion()
            AsociacionForm.markFormControlsAsPristine(this.form)
            AsociacionForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
