import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PlanPersonalContenido, PlanPersonalContenidos } from "@puntaje/puntaje/api-services"
import { PlanPersonalContenidoForm } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"

@Component({
    selector: "plan-personal-contenido-edit",
    templateUrl: "../plan-personal-contenido-new/plan-personal-contenido-new.component.html"
})
export class PlanPersonalContenidoEditComponent implements OnInit {
    @Input() contenidoId

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() planificacionId

    planPersonalContenido = new PlanPersonalContenido()
    oPlanPersonalContenido = new PlanPersonalContenido()
    params = PlanPersonalContenidoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Editar Contenido"
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected planPersonalCotenidosService: PlanPersonalContenidos,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = PlanPersonalContenidoForm.getForm(this.planPersonalContenido)
        this.setData()
        this.loadingLayout.ready()
    }

    setData() {
        this.planPersonalCotenidosService.find(this.contenidoId).then((response: PlanPersonalContenido) => {
            this.planPersonalContenido = response
            this.oPlanPersonalContenido = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        PlanPersonalContenidoForm.markFormControlsAsTouched(this.form)

        if (this.form.valid) {
            this.planPersonalCotenidosService
                .update(this.planPersonalContenido.id, this.planPersonalContenido)
                .then(response => {
                    this.router.navigate(["/planificaciones", this.planificacionId])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.planPersonalContenido = this.oPlanPersonalContenido.clone()
            PlanPersonalContenidoForm.markFormControlsAsPristine(this.form)
            PlanPersonalContenidoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
