<div class="filtro clearfix">
    <loading-layout #loadingLayout>
        <div class="clearfix periodo">
            <filtro-periodo (select)="onSelectPeriodo($event)"></filtro-periodo>
        </div>

        <h4>
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            Filtrar datos
        </h4>
        <generador-instrumento-select
            *ngIf="asignatura"
            [hidden]="!asignatura.generador_instrumentos || asignatura.generador_instrumentos.length == 1"
            [generadorInstrumentos]="asignatura.generador_instrumentos"
            [(generadorInstrumento)]="generadorInstrumento"
        ></generador-instrumento-select>
        <autocomplete
            class="input-filter"
            (filterChange)="searchEstablecimientos($event)"
            [(ngModel)]="establecimiento"
            (ngModelChange)="lugar = undefined"
            [options]="establecimientos"
            placeholder="Seleccione establecimiento"
            [showTextFun]="'toStringIdPais'"
        ></autocomplete>
        <select class="form-control" [(ngModel)]="lugar" (ngModelChange)="establecimiento = undefined">
            <option selected [ngValue]="undefined">Seleccione {{ config.plataforma.lugarTipo }}</option>
            <option *ngFor="let lugar of lugares" [ngValue]="lugar">{{ lugar.nombre }}</option>
        </select>

        <cui-button type="button" class="btn_style pull-right" [disabled]="loadingUsuarios" (click)="filtrar()">
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            Filtrar resultados
        </cui-button>

        <loader *ngIf="loadingUsuarios" class="pull-right loading-usuarios" [loaderType]="'default-xsmall'"></loader>
    </loading-layout>
</div>
