import { Component, ViewChild, OnInit } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Banners,
    BannerZona,
    BannerZonas,
    BannerPagina,
    BannerPaginas,
    BannerInstancia,
    BannerInstancias
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "artezonas-activas",
    templateUrl: "banners-activos.component.html",
    styleUrls: ["banners-activos.component.scss"]
})
export class BannersActivosComponent implements OnInit {
    bannerZonas: BannerZona[] = []
    bannerPaginas: BannerPagina[] = []
    bannerInstancias: BannerInstancia[] = []
    orderedInstances = []
    form: UntypedFormGroup = new UntypedFormGroup({})
    ready: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    toggleWait: boolean = false
    hideOutOfDate: boolean = true

    constructor(
        private router: Router,
        private bannersService: Banners,
        private bannerZonasService: BannerZonas,
        private bannerPaginasService: BannerPaginas,
        private bannerInstanciasService: BannerInstancias
    ) {}

    ngOnInit() {
        this.bannerZonasService.where().then((response: BannerZona[]) => {
            this.bannerZonas = response
            this.bannerPaginasService.where().then((response: BannerPagina[]) => {
                this.bannerPaginas = response
                if (this.loadingLayout) this.loadingLayout.ready()
            })
        })
    }
}
