import {
    SaveType,
    TextView,
    LinkView,
    DateView,
    SemaphoreView,
    EntityView,
    MultipleLinkView
} from "@puntaje/shared/core"
import { Establecimientos } from "./establecimientos.service"
import { Lugares } from "./lugares.service"
import { Asociaciones } from "./asociaciones.service"
import { Asociacion } from "./asociaciones.model"

export class EstablecimientoChileView {
    public static get viewParams() {
        let self = this
        return {
            rbd: { label: "RBD", type: TextView, tableVisible: true, sorterEnabled: true }
        }
    }
}
