<usuarios-buscador></usuarios-buscador>
<div class="overflowable">
    <loading-layout #loadingLayout>
        <table-with-actions
            [contentArray]="usuarios"
            [showParams]="params"
            [tableButtonsArray]="tableButtonsArray"
        ></table-with-actions>
    </loading-layout>
</div>
<paginator [getList]="showAll.bind(this)"></paginator>
