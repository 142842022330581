import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from "@angular/core"
import { EvaluacionSugeridas, EvaluacionSugerida, EvaluacionSugeridaForm } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"

@Component({
    selector: "evaluacion-recomendada-edit",
    templateUrl: "./evaluacion-recomendada-edit.component.html",
    styleUrls: ["./evaluacion-recomendada-edit.component.scss"]
})
export class EvaluacionRecomendadaEditComponent implements OnInit {
    @Input() evaluacionSugeridaId: number

    oEvaluacionSugerida: EvaluacionSugerida = new EvaluacionSugerida()
    evaluacionSugerida: EvaluacionSugerida = new EvaluacionSugerida()
    params = EvaluacionSugeridaForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    @Input() showEvaluacionMultiple: boolean = false

    constructor(
        protected evaluacionSugeridasService: EvaluacionSugeridas,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = EvaluacionSugeridaForm.getForm(this.evaluacionSugerida)
        this.evaluacionSugeridasService.find(this.evaluacionSugeridaId).then((response: EvaluacionSugerida) => {
            this.evaluacionSugerida = response
            this.oEvaluacionSugerida = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        EvaluacionSugeridaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.evaluacionSugeridasService
                .update(this.evaluacionSugerida.id, this.evaluacionSugerida)
                .then(response => {
                    this.router.navigate(["evaluacion_sugeridas"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.evaluacionSugerida = this.oEvaluacionSugerida.clone()
            EvaluacionSugeridaForm.markFormControlsAsPristine(this.form)
            EvaluacionSugeridaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
