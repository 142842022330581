import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./typeform-widgets-new.component.html",
    styleUrls: ["./typeform-widgets-new.component.scss"]
})
export class TypeformWidgetsNewComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.typeform_widget.new")
    }
}
