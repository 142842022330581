<loading-layout #loadingLayout>
    <div class="panel panel-default">
        <div class="panel-heading {{ claseAsignatura }}">{{ asignatura.asignatura }}</div>
        <div class="panel-body">
            <div>
                <div *ngIf="errors && errors.length > 0" class="alert alert-danger alert-dismissable fade in">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>
                        <li *ngFor="let error of errors">{{ error }}</li>
                    </strong>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-12" *ngIf="currentInstrumentoMensual === undefined">
                    <b>{{ tipoInstrumento | transformInstrumento: "singular" | capitalize }}:</b>
                    sin asignar.
                </div>
                <div class="col-md-12" *ngIf="currentInstrumentoMensual">
                    <b>{{ tipoInstrumento | transformInstrumento: "singular" | capitalize }} actual:</b>
                    <li>
                        <b>Nombre:</b>
                        {{ currentInstrumentoMensual.nombre }}
                    </li>
                    <li>
                        <b>Id:</b>
                        <a [routerLink]="['/instrumentos', currentInstrumentoMensual.instrumento_id]">
                            {{ currentInstrumentoMensual.instrumento_id }}
                        </a>
                    </li>
                </div>
            </div>
            <div class="disponibles col-md-3" *ngIf="hayInstrumentos()">
                <label for="disponibles">{{ tipoInstrumento | transformInstrumento: "plural" | capitalize }}</label>
                <select class="form-control" id="disponibles" [(ngModel)]="instrumento">
                    <option [ngValue]="undefined">-- Seleccione Instrumento Predefinido --</option>
                    <option *ngFor="let instrumento of instrumentosPredefinidos" [ngValue]="instrumento">
                        {{ instrumento.toString() }}
                    </option>
                </select>
            </div>
            <div class="no-disponibles col-md-3" *ngIf="!hayInstrumentos()">
                No existen {{ tipoInstrumento | transformInstrumento: "plural" }} predefinidos para esta asignatura.
            </div>
            <div class="col-md-3" *ngIf="hayInstrumentos()">
                <div>
                    <label for="nombreEnsayo">Nombre {{ tipoInstrumento | transformInstrumento: "singular" }}</label>
                    <input class="form-control" type="text" id="nombreEnsayo" [(ngModel)]="nombreInstrumento" />
                </div>
            </div>
            <div class="col-md-3" *ngIf="hayInstrumentos()">
                <button class="btn btn-info" (click)="asignarInstrumento()">
                    Asignar {{ tipoInstrumento | transformInstrumento: "singular" | capitalize }}
                </button>
            </div>
        </div>
    </div>
</loading-layout>
