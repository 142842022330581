import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { BannerZona, BannerZonas, BannerZonaForm } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerZonas-edit",
    templateUrl: "banner_zonas.form.component.html"
})
export class BannerZonasEditComponent implements OnInit {
    @Input() bannerZonaId: number
    @Input() listRoute: string

    oBannerZona: BannerZona = new BannerZona()
    bannerZona: BannerZona = new BannerZona()
    params = BannerZonaForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private bannersService: BannerZonas, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.form = BannerZonaForm.getForm(this.bannerZona)
        this.bannersService.find(this.bannerZonaId).then((response: BannerZona) => {
            this.bannerZona = response
            this.oBannerZona = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        BannerZonaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.bannersService.update(this.bannerZona.id, this.bannerZona).then(response => {
                this.router.navigate([this.listRoute])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.bannerZona = this.oBannerZona.clone()
            BannerZonaForm.markFormControlsAsPristine(this.form)
            BannerZonaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 0)
    }
}
