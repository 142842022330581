import { Component, Directive, ViewChild, AfterContentInit, Input, TemplateRef, ChangeDetectorRef } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { queueScheduler, Subscription } from "rxjs"
import { observeOn } from "rxjs/operators"
import { LoggedLayoutSidebarService } from "./logged-layout-sidebar.service"

/*#################################

Template simple para páginas una vez que usuario se haya logeado. Incluye el menú al costado izquierdo y el navbar en la parte
superior.
Implementa una sección de contenido, un título, un subtítulo y un set de tabs (no son requeridos, pero título puede incluir
un valor por defecto si no está definido).

logged-layout-toolnavbar
logged-layout-titulo
logged-layout-subtitulo
logged-layout-contenido
logged-layout-tabs

#################################*/

@Component({
    templateUrl: "cui-logged-layout-sidebar.component.html",
    selector: "cui-logged-layout-sidebar",
    styleUrls: ["cui-logged-layout-sidebar.component.scss"]
})
export class CUILoggedLayoutSidebarComponent {
    @ViewChild("loggedLayoutContenido", { static: true }) loggedLayoutContenido
    showTabs: boolean = false
    showContenido: boolean = false

    @Input() hideTitleOnPrint: boolean = false

    sub: Subscription
    subTemplate: Subscription

    templateObj: { [name: string]: TemplateRef<any> } = {}

    constructor(
        protected authService: AuthService,
        protected loggedLayoutSidebarService: LoggedLayoutSidebarService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.loggedLayoutSidebarService.resetSlots()
        this.loggedLayoutSidebarService.setOptions({
            hideTitleOnPrint: this.hideTitleOnPrint
        })

        this.sub = this.loggedLayoutSidebarService.options$.subscribe(options => {
            this.hideTitleOnPrint = options.hideTitleOnPrint
        })

        this.subTemplate = this.loggedLayoutSidebarService.templates$
            .pipe(observeOn(queueScheduler))
            .subscribe(templateObj => {
                this.templateObj = templateObj

                this.checkSlots()
                this.cdr.detectChanges()
            })
    }

    checkSlots() {
        this.showTabs = !!this.templateObj?.["tabs"]
        this.showContenido = this.loggedLayoutContenido.nativeElement.children.length > 0
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subTemplate.unsubscribe()
    }
}
