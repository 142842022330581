import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "home.component.html"
})
export class HomeComponent implements OnInit {
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;

    constructor(protected titleService: TitleService, protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.landing.home")
    }
}
