<ll-titulo>Editar reporte #{{ establecimientoReporteId }}</ll-titulo>
<ll-toolnavbar>
    <establecimiento-reporte-toolnavbar
        [displayButtons]="'all'"
        [establecimientoId]="establecimientoId"
        [establecimientoReporteId]="establecimientoReporteId"
    ></establecimiento-reporte-toolnavbar>
</ll-toolnavbar>

<establecimiento-reportes-edit
    [establecimientoId]="establecimientoId"
    [establecimientoReporteId]="establecimientoReporteId"
></establecimiento-reportes-edit>
