import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { Establecimiento, Establecimientos, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { AsociacionesBuscadorService } from "./asociaciones_buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
declare const config: AppConfig

@Component({
    selector: "asociaciones-buscador",
    templateUrl: "asociaciones_buscador.component.html"
})
export class AsociacionesBuscadorComponent implements OnInit {
    asociacion: any = { like: {}, asociacion_monitor: {} }
    sub: Subscription
    subQueryParams: Subscription
    open: boolean = false

    filteredUsuarios: Usuario[] = []

    @Input() mios: boolean

    constructor(
        protected asociacionesBuscadorService: AsociacionesBuscadorService,
        protected usuariosService: Usuarios,
        protected route: ActivatedRoute,
        protected router: Router,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.sub = this.asociacionesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
        this.subQueryParams = this.route.queryParams.subscribe(params => {
            this.loadSearchParamsFromUrl(params)
        })
        this.search()
    }

    search() {
        this.addSearchParamsToUrl()
        this.asociacionesBuscadorService.search(this.asociacion)
    }

    /**
    * Carga los query params en los inputs/select del form del buscador
    * @param params query params
    */

    loadSearchParamsFromUrl(params) {
        this.asociacion.id = params["id"]
        this.asociacion.like.asociacion = params["asociacion"]
        this.asociacion.asociacion_monitor.monitor_id = params["monitor_id"]
        this.cdr.detectChanges()
    }

    addSearchParamsToUrl() {
        let params = {
            id: this.asociacion.id,
            asociacion: this.asociacion.like.asociacion,
            monitor_id: this.asociacion.asociacion_monitor.monitor_id
        }

        if (this.mios) {
            params["mios"] = 1
        }

        this.router.navigate([], {
            queryParams: params,
        })
    }

    deleteIfUndefined(obj, key) {
        if (obj[key] == null || obj[key] == undefined || obj[key] == "") {
            delete obj[key]
        }
    }

    toArray(obj, key) {
        if (!obj[key]) {
            delete obj[key]
        } else {
            obj[key] = obj[key].split(/\s*,\s*/)
        }
    }

    getUsuarios(usuarioFilter: string) {
        this.usuariosService
            .where({
                per: 100,
                raw: 1,
                usuario: { nombre_completo: usuarioFilter },
                include: "[usuario_chile,usuario_colombia]"
            })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
                if (this.filteredUsuarios.length < 1) {
                    if (config.plataforma.pais == "chile") {
                        this.getUsuariosByRut(usuarioFilter)
                    } else if (config.plataforma.pais == "colombia") {
                        this.getUsuariosByIdentificador(usuarioFilter)
                    }
                }
            })
    }

    getUsuariosByRut(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_chile: { rut: usuarioFilter }, include: "usuario_chile" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    getUsuariosByIdentificador(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_colombia: { ti_o_cc: usuarioFilter }, include: "usuario_colombia" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    onSelectUsuario(usuario: Usuario) {
        if (usuario) {
            this.asociacion.asociacion_monitor.monitor_id = usuario.id
        } else {
            delete this.asociacion.asociacion_monitor.monitor_id
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQueryParams.unsubscribe()
    }
}
