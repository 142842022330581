import { Component, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Establecimiento,
    Establecimientos,
    EstablecimientoForm,
    EstablecimientoChile,
    EstablecimientoColombia
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimientos-new",
    templateUrl: "establecimientos.form.component.html"
})
export class EstablecimientosNewComponent implements OnInit {
    establecimiento: Establecimiento = new Establecimiento()
    params = EstablecimientoForm.formParams
    form: UntypedFormGroup
    pais: string
    logged_layout_title = "Agregar establecimiento"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected establecimientosService: Establecimientos, protected router: Router) {}

    ngOnInit() {
        this.pais = config.plataforma.pais
        this.establecimiento["establecimiento_" + this.pais] = new Establecimiento["establecimiento_" + this.pais]()
        this.form = EstablecimientoForm.getForm(this.establecimiento)
        this.loadingLayout.ready()
    }

    save() {
        EstablecimientoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.establecimientosService.save(this.establecimiento).then(response => {
                this.router.navigate(["establecimientos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimiento = new Establecimiento()
            EstablecimientoForm.markFormControlsAsPristine(this.form)
            EstablecimientoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
