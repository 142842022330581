<generic-modal [id]="'recordatorioId'" [modalLarge]="true" (closeCrossCallback)="closeCross()">
    <modal-titulo>Agregar recordatorio</modal-titulo>
    <modal-contenido>
        <div>
            <p>Ingrese un texto</p>
            <textarea class="form-control" [(ngModel)]="texto" name="texto" id="texto"></textarea>
            <p>Ingrese fecha y hora</p>
            <datepicker-form [(ngModel)]="fecha_envio" name="fecha_envio" id="fecha_envio"></datepicker-form>
        </div>
        <cui-button class="btn_style" (click)="guardar()">Guardar</cui-button>
        <cui-button class="btn_style" data-dismiss="modal" (click)="close()">Cancelar</cui-button>
    </modal-contenido>
</generic-modal>
