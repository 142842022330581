import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { PlanEstudio, PlanEstudios, PlanEstudioView } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "plan_estudio",
    templateUrl: "plan_estudio.component.html"
})
export class PlanEstudioComponent implements OnInit {
    @Input() planEstudioId: number

    planEstudio: PlanEstudio
    params = PlanEstudioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private planEstudiosService: PlanEstudios) {}

    ngOnInit() {
        this.planEstudiosService.find(this.planEstudioId, { with_instancias: true }).then((response: PlanEstudio) => {
            this.planEstudio = response
            this.loadingLayout.ready()
        })
    }
}
