<ll-titulo class="print-hide"></ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs print-hide" #tabList>
        <li role="presentation" [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }">
            <a data-toggle="tab" href="#parcial" (click)="setCurrentTab('parcial')">Resumen de uso</a>
        </li>
        <li role="presentation" [ngClass]="{ in: currentTab == 'anual', active: currentTab == 'anual' }">
            <a data-toggle="tab" href="#anual" (click)="setCurrentTab('anual')">Informe de uso</a>
        </li>
        <li role="presentation" [ngClass]="{ in: currentTab == 'informe', active: currentTab == 'informe' }">
            <a data-toggle="tab" href="#informe" (click)="setCurrentTab('informe')">Informe anual</a>
        </li>
    </ul>
</ll-tabs>

<div class="tab-content">
    <div
        id="parcial"
        class="tab-pane fade print-hide"
        [ngClass]="{ in: currentTab == 'parcial', active: currentTab == 'parcial' }"
    >
        <resumen-utp></resumen-utp>
    </div>
    <div id="anual" class="tab-pane fade" [ngClass]="{ in: currentTab == 'anual', active: currentTab == 'anual' }">
        <resumen-utp-anual></resumen-utp-anual>
    </div>
    <div
        id="informe"
        class="tab-pane fade"
        [ngClass]="{ in: currentTab == 'informe', active: currentTab == 'informe' }"
    >
        <informe-anual [enableEstablecimientoAutocomplete]="true"></informe-anual>
    </div>
</div>
