import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Injector } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    CuentasForm,
    UsuarioChile,
    UsuarioColombia,
    RolUsuario,
    PremiumUsuario,
    PremiumUsuarios,
    CuentasCbForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "cuentas-edit",
    templateUrl: "cuentas_admin.form.component.html"
})
export class CuentasEditComponent implements OnInit {
    @Input() usuarioId: number
    @Input() sinRut: boolean = false
    oUsuario: Usuario = new Usuario()
    usuario: Usuario = new Usuario()
    params = CuentasForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    isNew: boolean = false
    isPlus: boolean = false

    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario

    constructor(
        protected usuariosService: Usuarios,
        protected premiumUsuariosService: PremiumUsuarios,
        protected injector: Injector,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.usuario.rol_usuarios = []
        this.usuario.premium_usuarios = []
        let today = new Date().toISOString().slice(0, 10)
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        if (this.sinRut) {
            this.params = CuentasCbForm.formParams
            this.form = CuentasCbForm.getForm(this.usuario, null, this.injector)
        } else {
            this.form = CuentasForm.getForm(this.usuario, null, this.injector)
        }

        this.usuariosService
            .find(this.usuarioId, { include: "[usuario_" + this.pais + ",roles]" })
            .then((response: Usuario) => {
                this.usuario = response
                this.premiumUsuariosService
                    .where({
                        premium_usuario: {
                            usuario_id: this.usuarioId,
                            activo: 1,
                            gte: { fecha_termino: today },
                            lte: { fecha_inicio: today }
                        }
                    })
                    .then((response: PremiumUsuario[]) => {
                        let pu = response
                        this.usuario["premium_usuarios"] = pu
                    })
                if (!this.usuario["usuario_" + this.pais]) {
                    this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
                }

                this.isPlus = this.usuario.isPlus()

                this.oUsuario = this.usuario.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    save() {
        this.setmarkFormControlsAsTouched()
        if (this.form.valid) {
            this.usuariosService.update(this.usuario.id, this.usuario).then(response => {
                this.router.navigate(["cuentas"])
            })
        }
    }

    addRol() {
        this.usuario.rol_usuarios.push(new RolUsuario())
    }

    setFormParentArray(form) {
        form.setParent(this.form.controls["rol_usuarios"])
    }

    clear() {
        setTimeout(() => {
            this.usuario = this.oUsuario.clone()
            this.setmarkFormControlsAsTouched()

            this.cdr.detectChanges()
        }, 150)
    }

    setmarkFormControlsAsTouched() {
        if (this.sinRut) {
            CuentasCbForm.markFormControlsAsTouched(this.form)
            CuentasCbForm.markFormControlsAsDirty(this.form)
        } else {
            CuentasForm.markFormControlsAsTouched(this.form)
            CuentasForm.markFormControlsAsDirty(this.form)
        }
    }

    onPlusChange(isPlus: boolean) {
        this.isPlus = isPlus

        if (isPlus) {
            const today = new Date()
            const endOfYear = new Date(today.getFullYear(), 11, 31)
            this.usuario.fecha_inicio_plus = today.toISOString().slice(0, 10)
            this.usuario.fecha_termino_plus = endOfYear.toISOString().slice(0, 10)
        } else {
            this.usuario.fecha_inicio_plus = null
            this.usuario.fecha_termino_plus = null
        }

        this.cdr.detectChanges()
    }
}
