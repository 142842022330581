import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    AfterViewInit,
    OnChanges
} from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"

import {
    Establecimiento,
    GrupoUsuario,
    Usuario,
    Evaluacion,
    Estadisticas,
    Lugar,
    Usuarios,
    Establecimientos,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    EstablecimientoUsuarios,
    GrupoUsuarioUsuario,
    EstablecimientoUsuario
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasDeclaradosComponent } from "./filtro_estadisticas_declarados.component"
import { Asignaturas, Asignatura, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    BaseChart,
    PieChart,
    ColumnChart,
    StackedBarChart,
    BarChart,
    GenericModalComponent
} from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-declarados",
    templateUrl: "estadisticas_declarados.component.html",
    styleUrls: ["estadisticas_declarados.component.scss"]
})
export class EstadisticasDeclaradosComponent implements OnInit, OnChanges {
    @ViewChild(FiltroEstadisticasDeclaradosComponent)
    filtroEstadisticas: FiltroEstadisticasDeclaradosComponent
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    asignatura: Asignatura
    @Input() asignaturaId: number
    @Input() establecimientoId: number
    generadorInstrumento: GeneradorInstrumento
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("innerLayout") innerLayout: LoadingLayoutComponent

    showEstadisticasCursos: boolean = false
    showEstadisticasCurso: boolean = false
    showEstadisticasEnsayo: boolean = false

    ready: boolean = false

    establecimiento: Establecimiento
    lugar: Lugar
    usuarios: Usuario[]

    grupoUsuario: GrupoUsuario
    usuario: Usuario
    evaluacion: Evaluacion
    previousEvaluacion: Evaluacion

    estadisticas: any
    estadisticaUsuarios: any
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()
    @Input() evaluacionTipo: string

    openModal: EventEmitter<boolean> = new EventEmitter<boolean>()
    establecimientoFake: Establecimiento
    grupoUsuarioFake: GrupoUsuario
    jefeUtpFake: Usuario

    filteredUsuarios: Usuario[]

    creating: boolean = false

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected estadisticasService: Estadisticas,
        protected usuariosService: Usuarios,
        protected establecimientosService: Establecimientos,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected establecimientoUsuariosService: EstablecimientoUsuarios
    ) {}

    ngOnInit() {}

    setData() {
        this.loadingLayout.standby()
        this.asignaturasService.find(this.asignaturaId, { simple: 1 }).then((asignatura: Asignatura) => {
            this.asignatura = asignatura
            this.estadisticas = null
            this.onReadyAsignatura.emit(this.asignatura)
            this.loadingLayout.ready()
        })
    }

    onFilter(filtros) {
        this.innerLayout.standby()
        this.ready = false

        this.establecimiento = filtros.establecimiento
        this.lugar = filtros.lugar
        this.usuarios = filtros.usuarios

        this.estadisticas = null

        this.generadorInstrumento = filtros.generadorInstrumento

        this.evaluacionTipo = this.generadorInstrumento.tipo_instrumento.tipo_instrumento

        let params = {
            collection: "EstadisticaUsuario",
            estadistica: {
                oficial: 1,
                evaluacion_tipo: this.generadorInstrumento.tipo_instrumento.tipo_instrumento,
                generador_instrumento_id: this.generadorInstrumento.id,
                usuario_id: this.usuarios.map(u => u.id)
            }
        }

        this.estadisticasService.wherePost(params).then((estadisticas: any) => {
            this.estadisticas = estadisticas
            this.innerLayout.ready()
        })
    }

    print() {
        window.print()
    }

    onReady(asignatura) {
        this.establecimientoFake = new Establecimiento()
        this.grupoUsuarioFake = new GrupoUsuario()

        this.ready = true
        this.asignatura = asignatura
        this.onReadyAsignatura.emit(this.asignatura)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"]) {
            this.setData()
        }
    }

    getUsuarios(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario: { nombre_completo: usuarioFilter } })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    createEstablecimientoFake() {
        if (!this.establecimientoFake.establecimiento || !this.grupoUsuarioFake.nombre || !this.jefeUtpFake) return

        this.creating = true
        this.establecimientosService.save(this.establecimientoFake).then((establecimiento: Establecimiento) => {
            this.grupoUsuarioFake.establecimiento_id = establecimiento.id
            this.grupoUsuarioFake.privado = true

            let now = new Date()
            this.grupoUsuarioFake.fecha_inicial = new Date(now.getFullYear(), 0, 1)
            this.grupoUsuarioFake.fecha_final = new Date(now.getFullYear(), 11, 31, 23, 59, 59)

            this.grupoUsuariosService.save(this.grupoUsuarioFake).then((grupoUsuario: GrupoUsuario) => {
                let grupoUsuarioUsuariosParams = {
                    grupo_usuario_usuarios: this.usuarios.map(u => {
                        return {
                            grupo_usuario_id: grupoUsuario.id,
                            usuario_id: u.id,
                            propietario: 0
                        }
                    })
                }

                this.grupoUsuarioUsuariosService
                    .saveMany(grupoUsuarioUsuariosParams)
                    .then((grupoUsuarioUsuarios: GrupoUsuarioUsuario[]) => {
                        let establecimientoUsuario = new EstablecimientoUsuario()
                        establecimientoUsuario.establecimiento_id = establecimiento.id
                        establecimientoUsuario.usuario_id = this.jefeUtpFake.id

                        this.establecimientoUsuariosService
                            .save(establecimientoUsuario)
                            .then((eu: EstablecimientoUsuario) => {
                                this.creating = false
                                this.genericModal.close()
                            })
                    })
            })
        })
    }
}
