import { BaseForm, Validations, GroupValidator } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class UsuariosCompletarRegistroSocialColombiaForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.requiredOne(["ti", "cc"])]
    }

    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            telefono: {
                label: "Teléfono",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateTelefonoColombia]
            },
            cc: {
                label: "Número de Identificación Personal",
                type: "text",
                validations: [Validators.required, Validations.validateTIoCC],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueCCNotSelf }],
                global: true,
                visible: true
            }
        }
    }
}
