import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import {
    EstablecimientoContacto,
    EstablecimientoContactos,
    EstablecimientoContactoView
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { EstablecimientoContactosBuscadorService } from "./establecimiento_contactos_buscador.service"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento-contactos",
    templateUrl: "establecimiento_contactos.component.html"
})
export class EstablecimientoContactosComponent implements OnInit {
    @Input() establecimientoId: number
    establecimientoContactos: EstablecimientoContacto[]
    params = EstablecimientoContactoView.viewParams
    tableButtonsArray: any

    sub: Subscription
    searchParams: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected establecimientoContactosService: EstablecimientoContactos,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected establecimientoContactosBuscadorService: EstablecimientoContactosBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.sub = this.establecimientoContactosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number = 1, count: number = 20, order: number = null, key: string = null) => {
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc"
        }

        if (order) {
            params.order = ""
            if (order == 1) params.order = "asc"
            if (order == -1) params.order = "desc"

            if (key == "fecha") {
                params.sort_by = "created_at"
            }
        }

        params = {
            ...params,
            ...this.searchParams,
            establecimiento_contacto: {
                establecimiento_id: this.establecimientoId,
                ...this.searchParams.establecimiento_contacto
            }
        }

        params = Object.assign(params, this.searchParams)
        return this.establecimientoContactosService
            .where(params)
            .then((response: EstablecimientoContacto[], total: number) => {
                this.establecimientoContactos = response
                this.loadingLayout.ready()

                return total
            })
    }

    view(establecimientoContacto: EstablecimientoContacto) {
        this.router.navigate([
            "establecimientos/" + this.establecimientoId + "/establecimiento_contactos/" + establecimientoContacto.id
        ])
    }

    edit(establecimientoContacto: EstablecimientoContacto) {
        this.router.navigate([
            "establecimientos/" +
                this.establecimientoId +
                "/establecimiento_contactos/" +
                establecimientoContacto.id +
                "/edit"
        ])
    }

    delete(id: number) {
        this.establecimientoContactosService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showModalDelete(establecimientoContacto: EstablecimientoContacto) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este establecimiento?")
        this.simpleModalService.setModalCallback(() => this.delete(establecimientoContacto.id))
    }
}
