import { TextView, LinkView, DateView, ThumbnailView } from "@puntaje/shared/core"
import { BannerPaginas } from "./banner_paginas.service"

export class BannerPaginaView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: BannerPaginas, key: "id" } },
            pagina: { label: "Pagina", type: TextView, tableVisible: true }
        }
    }
}
