import { TextView, LinkView, DateView } from "@puntaje/shared/core"
import { PlanEstudioAplicados } from "./plan_estudio_aplicados.service"

export class PlanEstudioAplicadoView {
    public static get viewParams() {
        return {
            id: {
                label: "Id",
                type: LinkView,
                tableVisible: true,
                options: { class: PlanEstudioAplicados, key: "id" }
            },
            plan_estudio_nombre: { label: "Nombre Plan Estudio", type: TextView, tableVisible: true },
            plan_estudio_aplicado: { label: "Nombre Aplicado", type: TextView, tableVisible: true },
            asignatura_nombre: { label: "Asignatura", type: TextView, tableVisible: true },
            estado: { label: "Estado", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
