<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <form novalidate [formGroup]="form">
                <ng-container *ngFor="let key of params | keys">
                    <form-input
                        *ngIf="key != 'libro_url_profesor' && key != 'libro_url_alumno'"
                        [form]="form"
                        [params]="params[key]"
                        [(value)]="planificacion[key]"
                        [key]="key"
                    ></form-input>
                </ng-container>
                <label>Select Plan Personal Tipo</label>
                <select
                    class="form-control"
                    [(ngModel)]="planificacion.plan_personal_tipo_id"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="checkPlanPersonalTipo()"
                >
                    <option [ngValue]="undefined">--- Select Plan Personal ---</option>
                    <option *ngFor="let ppt of planPersonalTipos" [ngValue]="ppt.id">{{ ppt.tipo }}</option>
                </select>
                <form-input
                    *ngIf="planPersonalTipo?.tipo == 'plan de clase'"
                    [form]="form"
                    [params]="params['libro_url_profesor']"
                    [(value)]="planificacion.libro_url_profesor"
                    [key]="'libro_url_profesor'"
                ></form-input>
                <form-input
                    *ngIf="planPersonalTipo?.tipo == 'plan de clase'"
                    [form]="form"
                    [params]="params['libro_url_alumno']"
                    [(value)]="planificacion.libro_url_alumno"
                    [key]="'libro_url_alumno'"
                ></form-input>
                <label>Tipo Instrumento</label>
                <select
                    class="form-control"
                    [(ngModel)]="tipoInstrumento"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="getGeneradorInstrumento()"
                >
                    <option [ngValue]="undefined">-- Seleccione tipo Instrumento--</option>
                    <option *ngFor="let tp of tipoInstrumentos" [ngValue]="tp">{{ tp.tipo_instrumento }}</option>
                </select>
                <label>Asignatura</label>
                <select
                    [(ngModel)]="asignatura"
                    class="form-control"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="getGeneradorInstrumento()"
                >
                    <option [ngValue]="undefined">-- Seleccione Asignatura --</option>
                    <option *ngFor="let option of asignaturas" [ngValue]="option">{{ option.toString() }}</option>
                </select>
                <label>Generador de Instrumentos</label>
                <select
                    [(ngModel)]="planificacion.generador_instrumento_id"
                    class="form-control"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="getGeneradorInstrumento()"
                >
                    <option [ngValue]="undefined">-- Generador de Instrumentos --</option>
                    <option *ngFor="let option of generadorInstrumentos" [ngValue]="option.id">
                        {{ option.generador_instrumento }}
                    </option>
                </select>
                <button (click)="save()" type="button" class="btn btn-default">
                    {{ save_button_text | easyplaceholder: "Guardar" }}
                </button>
                <button (click)="clear()" type="button" class="btn btn-default">
                    {{ clear_button_text | easyplaceholder: "Borrar" }}
                </button>
            </form>
        </div>
    </div>
</loading-layout>
