<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="planificacion-data container-fluid">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view [params]="params[key]" [value]="planificacion" [key]="key"></model-simple-view>
            </ng-container>
        </div>
        <br />
        <a
            class="btn btn-sm btn-default pull-right margin-right"
            [routerLink]="['/planificaciones', planificacionId, 'sesiones', 'new']"
        >
            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            Nueva Sesion
        </a>
        <br />
        <div class="sessiones">
            <div *ngFor="let clase of planPersonalSesiones; let i = index" transition-group-item class="clase-profesor">
                <planificacion-sesion
                    [planificacionId]="planificacionId"
                    [enableToggleActiva]="true"
                    [provieneDeCompartida]="false"
                    [clase]="clase"
                    [index]="i"
                    [enableReporte]="enableReporte"
                    (deleteClase)="showModalDelete($event)"
                ></planificacion-sesion>
            </div>
        </div>
    </div>
</loading-layout>
