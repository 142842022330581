import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { TypeformWidget, TypeformWidgetes } from "@puntaje/puntaje/api-services"

@Component({
    template: ""
})
export class TypeformWidgetsDeleteComponent implements OnInit, OnDestroy {
    private sub: Subscription

    constructor(protected typeformWidgetsService: TypeformWidgetes, protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.typeformWidgetsService.remove(+params["id"]).then(response => {
                this.router.navigate(["typeform_widgets"])
            })
        })
    }
    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
