import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { TipoContacto } from "./tipo_contactos.model"
import { TipoContactos } from "./tipo_contactos.service"

export class EstablecimientoReporteForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            fecha: { label: "Fecha", type: "datepicker", visible: true },
            comentarios: {
                label: "Comentarios",
                type: "textarea",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            tipo_contacto_id: {
                label: "Tipo Reporte",
                type: "select",
                visible: true,
                options: { class: TipoContactos }
            },
            resuelto: { label: "Resuelto", type: "checkbox", visible: true }
        }
    }
}
