import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { Establecimiento, Establecimientos, GrupoUsuario, GrupoUsuarios } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

@Component({
    selector: "filtro-estadisticas-evaluacion-recomendada",
    templateUrl: "./filtro-estadisticas-evaluacion-recomendada.component.html",
    styleUrls: ["./filtro-estadisticas-evaluacion-recomendada.component.scss"]
})
export class FiltroEstadisticasEvaluacionRecomendadaComponent implements OnInit {
    @Output() filter = new EventEmitter<{ grupoUsuario?: GrupoUsuario; establecimiento?: Establecimiento }>()

    pais: string = this.config.plataforma.pais
    identificadorEstablecimiento: string = this.config.plataforma.identificadorEstablecimiento[0]
    grupoUsuarioAlias = this.config.plataforma.grupoUsuarioAlias

    establecimientos: Establecimiento[] = []
    establecimiento: Establecimiento

    grupoUsuario: GrupoUsuario
    fetching = false

    constructor(
        protected config: AppConfig,
        protected establecimientosService: Establecimientos,
        protected grupoUsuariosService: GrupoUsuarios
    ) {}

    ngOnInit(): void {}

    onEstablecimientoAutocompleteChange(establecimientoStr: string) {
        this.establecimiento = undefined
        this.grupoUsuario = undefined

        let params: any = {
            page: 1,
            per: 100,
            raw: 1,
            render_options: {
                include: {
                    [`establecimiento_${this.pais}`]: null
                }
            }
        }

        const paramsEstablecimientos = {
            ...params,
            establecimiento: {
                establecimiento: establecimientoStr
            }
        }

        const paramsEstablecimientosPais = {
            ...params,
            [`establecimiento_${this.pais}`]: {
                [this.identificadorEstablecimiento]: establecimientoStr
            }
        }

        const establecimientosPromise = this.establecimientosService.where(paramsEstablecimientos)
        const establecimientosByIdPaisPromise = this.establecimientosService.where(paramsEstablecimientosPais)

        Promise.all([establecimientosPromise, establecimientosByIdPaisPromise]).then(
            ([establecimientos, establecimientosByIdPais]) => {
                this.establecimientos = establecimientosByIdPais.concat(establecimientos)
            }
        )
    }

    async onEstablecimientoSelected(establecimiento: Establecimiento) {
        this.fetching = true
        if (establecimiento) {
            establecimiento.grupo_usuarios = await this.grupoUsuariosService.where({
                grupo_usuario: {
                    establecimiento_id: establecimiento.id
                },
                with_alumnos: 1,
                pais: this.pais
            })

            establecimiento.grupo_usuarios = establecimiento.grupo_usuarios.sort((gu1, gu2) => gu2.id - gu1.id)
            establecimiento.grupo_usuarios.forEach(gu => {
                gu.alumnos.forEach(usuario => {
                    usuario.grupo_usuarios = usuario.grupo_usuarios || []
                    usuario.grupo_usuarios.push(gu)
                })

                gu.usuarios = gu.alumnos
            })
        }

        this.establecimiento = establecimiento
        this.grupoUsuario = undefined
        this.fetching = false
    }

    onFilter() {
        this.filter.emit({
            grupoUsuario: this.grupoUsuario,
            establecimiento: this.establecimiento
        })
    }
}
