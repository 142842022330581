import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    EstablecimientoReporte,
    EstablecimientoReportes,
    EstablecimientoReporteForm
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "establecimiento-reportes-new",
    templateUrl: "establecimiento_reportes.form.component.html"
})
export class EstablecimientoReportesNewComponent implements OnInit {
    @Input() establecimientoId: number

    establecimientoReporte: EstablecimientoReporte = new EstablecimientoReporte()
    params = EstablecimientoReporteForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar establecimiento"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected establecimientoReportesService: EstablecimientoReportes, protected router: Router) {}

    ngOnInit() {
        this.establecimientoReporte.establecimiento_id = this.establecimientoId
        this.form = EstablecimientoReporteForm.getForm(this.establecimientoReporte)
        this.loadingLayout.ready()
    }

    save() {
        EstablecimientoReporteForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.establecimientoReportesService.save(this.establecimientoReporte).then(response => {
                this.router.navigate(["establecimientos/" + this.establecimientoId + "/establecimiento_reportes"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimientoReporte = new EstablecimientoReporte()
            this.establecimientoReporte.establecimiento_id = this.establecimientoId
            EstablecimientoReporteForm.markFormControlsAsPristine(this.form)
            EstablecimientoReporteForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
