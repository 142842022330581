import { BaseForm, Validations } from "@puntaje/shared/core"

export class UsuariosCompletarRegistroSocialCbChileForm extends BaseForm {
    companyName: string = ""

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            rut: {
                label: "RUT",
                type: "text"
            },
            telefono: {
                label: "Teléfono",
                type: "text",
                visible: true,
                validations: [Validations.validateTelefonoChile]
            }
        }
    }
}
