import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"

import { Asignatura, Asignaturas, GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import {
    Establecimiento,
    Establecimientos,
    Evaluaciones,
    Evaluacion,
    Usuario,
    Usuarios,
    GrupoUsuario,
    Lugar,
    Lugares
} from "@puntaje/puntaje/api-services"

import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "filtro-estadisticas-declarados",
    templateUrl: "filtro_estadisticas_declarados.component.html",
    styleUrls: ["filtro_estadisticas_declarados.component.scss"]
})
export class FiltroEstadisticasDeclaradosComponent implements OnInit {
    config: typeof config = config
    loadingUsuarios: boolean = false

    @Input() establecimientoId: number
    @Output() filter: EventEmitter<any> = new EventEmitter<any>()

    generadorInstrumento: GeneradorInstrumento
    establecimiento: Establecimiento
    lugar: Lugar

    @Input() asignaturaId: number
    asignatura: Asignatura

    establecimientos: Establecimiento[]
    lugares: Lugar[]
    nombreGrupo: string = "Curso"

    @Input() evaluacionTipo: string

    fechaInicial: Date
    fechaFinal: Date

    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    constructor(
        protected establecimientosService: Establecimientos,
        protected evaluacionesService: Evaluaciones,
        protected usuariosService: Usuarios,
        protected asignaturasService: Asignaturas,
        protected lugaresService: Lugares,
        protected cdr: ChangeDetectorRef
    ) {
        this.nombreGrupo = config.plataforma.grupoUsuarioAlias ? config.plataforma.grupoUsuarioAlias : "Curso"
    }

    ngOnInit() {
        this.lugaresService.where({ lugar_tipo: config.plataforma.lugarTipo }).then((lugares: Lugar[]) => {
            this.lugares = lugares

            this.asignaturasService
                .find(this.asignaturaId, { with_generador_instrumentos: 1, tipo_instrumento: this.evaluacionTipo })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura

                    if (this.establecimientoId) {
                        this.establecimientosService
                            .find(this.establecimientoId)
                            .then((establecimiento: Establecimiento) => {
                                this.establecimiento = establecimiento
                                this.loadingLayout.ready()
                            })
                    } else {
                        this.loadingLayout.ready()
                    }
                })
        })
    }

    searchEstablecimientos(establecimiento) {
        let params: any = {
            id_pais_establecimiento: establecimiento,
            pais: config.plataforma.pais,
            include: `[establecimiento_${config.plataforma.pais}]`
        }

        this.establecimientosService.where(params).then((establecimientos: Establecimiento[]) => {
            this.establecimientos = establecimientos
        })
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicial = fechaInicial
        this.fechaFinal = fechaFinal
    }

    filtrar() {
        this.loadingUsuarios = true

        let params: any = { usuario: {}, only_id: 1 }
        if (this.establecimiento) params.usuario.establecimiento_id = this.establecimiento.id
        if (this.lugar) params.usuario.lugar_id = this.lugar.id

        this.usuariosService.where(params).then((usuarios: Usuario[]) => {
            this.loadingUsuarios = false
            this.filter.emit({
                usuarios: usuarios,
                generadorInstrumento: this.generadorInstrumento,
                establecimiento: this.establecimiento,
                lugar: this.lugar
            })
        })
    }
}
