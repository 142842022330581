import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Asociacion, Asociaciones, AsociacionForm } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asociaciones.edit.component.html"
})
export class AsociacionesEditComponent implements OnInit {
    asociacionId: number
    loggedLayoutTitle = "Editar asociacion"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asociaciones.asociaciones.edit")
        this.route.params.subscribe(params => {
            this.asociacionId = +params["id"]
        })
    }
}
