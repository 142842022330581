import { Component, ViewEncapsulation, Directive, ContentChildren } from "@angular/core"

@Component({
    selector: "cui-data-list-item",
    template: `
        <ng-content select="cui-data-list-item-icon"></ng-content>
        <ng-content select="cui-data-list-item-title"></ng-content>
        <ng-content select="cui-data-list-item-info"></ng-content>
        <ng-content select="cui-data-list-item-actions"></ng-content>
    `,
    styleUrls: ["cui-data-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIDataListItemComponent {
    constructor() {}
}

@Directive({ selector: "cui-data-list-header" })
export class CUIDataListHeader {}

@Directive({ selector: "cui-data-list-item-icon" })
export class CUIDataListItemIcon {}

@Directive({ selector: "cui-data-list-item-title" })
export class CUIDataListItemTitle {}

@Directive({ selector: "cui-data-list-item-info" })
export class CUIDataListItemInfo {}

@Directive({ selector: "cui-data-list-item-actions" })
export class CUIDataListItemActions {}

@Component({
    selector: "cui-data-list",
    templateUrl: "cui-data-list.component.html",
    styleUrls: ["cui-data-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIDataListComponent {
    @ContentChildren(CUIDataListItemComponent) rows: CUIDataListItemComponent[] = []
    constructor() {}
}
