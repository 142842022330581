<loading-layout #loadingLayout>
    <div *ngIf="errors && errors.length > 0">
        <div class="alert alert-danger fade in alert-dismissable">
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            <ul>
                <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
        </div>
    </div>
    <div>
        <cui-section-headline>Id del Lote</cui-section-headline>
        <div class="info-container clearfix">
            <div class="col-md-4">
                <div class="form-group">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Id del Lote"
                        [(ngModel)]="lote_id"
                        (ngModelChange)="checkLote($event)"
                    />
                </div>
                <loading-layout #loadingLayoutLote>
                    <div *ngIf="lote || searching">
                        <p>
                            <b>Lote ID:</b>
                            {{ lote?.id }}
                        </p>
                        <p>
                            <b>Nombre Lote:</b>
                            {{ lote?.lote || "Sin nombre" }}
                        </p>
                        <p>
                            <b>Asignatura:</b>
                            {{ lote?.asignatura?.asignatura }}
                        </p>
                        <p>
                            <b>N° Preguntas:</b>
                            {{ lote?.cantidad_preguntas }}
                        </p>

                        <div class="form-group">
                            <label>Tiempo (minutos)</label>
                            <input type="number" class="form-control" placeholder="Tiempo" [(ngModel)]="tiempo" />
                        </div>

                        <alert-preguntas-exclusivas
                            [preguntasNotPlatform]="preguntasNotPlatform"
                        ></alert-preguntas-exclusivas>
                    </div>
                </loading-layout>
                <div *ngIf="!lote">
                    <p>Ingrese un lote id.</p>
                </div>
            </div>
            <div class="col-md-2">
                <button class="btn btn-primary" (click)="getLote(lote_id)">Buscar Lote</button>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <select
                        id="curriculum"
                        [(ngModel)]="generador_selected"
                        (ngModelChange)="updateValues($event)"
                        class="form-control"
                    >
                        <option [ngValue]="null" [disabled]="true" [selected]="true">
                            -- Seleccione Tipo de Generador --
                        </option>
                        <option *ngFor="let generador of generadores" [ngValue]="generador">
                            {{ generador.generador_instrumento }}
                        </option>
                    </select>
                </div>
                <div *ngIf="generador_selected">
                    <p>
                        <b>Asignatura:</b>
                        {{ generador_selected?.asignatura.asignatura }}
                    </p>
                    <p>
                        <b>Tipo:</b>
                        {{ generador_selected?.tipo_instrumento.tipo_instrumento }}
                    </p>
                    <p>
                        <b>N° Preguntas por defecto:</b>
                        {{ generador_selected.numero_preguntas }}
                    </p>
                    <p>
                        <b>Tiempo por defecto:</b>
                        {{ generador_selected.tiempo }} minutos
                    </p>
                </div>
                <div *ngIf="!generador_selected">
                    <p>Seleccione un tipo de generador.</p>
                </div>
            </div>
        </div>
        <div *ngIf="lote && generador_selected && !errorPreguntasNotPlatform">
            <button class="btn btn-primary" (click)="guardar()">Generar</button>
        </div>
    </div>
</loading-layout>
