import { BaseForm } from "@puntaje/shared/core"
import { Asociaciones } from "./asociaciones.service"

export class AsociacionEstablecimientosForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asociacion_id: { label: "Asociación", type: "select", visible: true, options: { class: Asociaciones } },
            establecimiento_id: { label: "Establecimiento", type: "hidden", visible: false },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
