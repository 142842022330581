import { Component, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    InstrumentoPredefinido,
    InstrumentoPredefinidos,
    InstrumentoPredefinidoForm
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "instrumento-predefinido-new",
    templateUrl: "instrumento_predefinidos.form.component.html"
})
export class InstrumentoPredefinidosNewComponent implements OnInit {
    instrumentoPredefinido: InstrumentoPredefinido = new InstrumentoPredefinido()
    params = InstrumentoPredefinidoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar instrumento predefinido"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected instrumentoPredefinidosService: InstrumentoPredefinidos, protected router: Router) {}

    ngOnInit() {
        this.form = InstrumentoPredefinidoForm.getForm(this.instrumentoPredefinido)
        this.loadingLayout.ready()
    }

    save() {
        InstrumentoPredefinidoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (config.instrumentosPredefinidos.zonas.length == 1) {
                this.instrumentoPredefinido.tag = config.instrumentosPredefinidos.zonas[0]
            }
            this.instrumentoPredefinidosService.save(this.instrumentoPredefinido).then(response => {
                this.router.navigate(["instrumento_predefinidos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.instrumentoPredefinido = new InstrumentoPredefinido()
            InstrumentoPredefinidoForm.markFormControlsAsPristine(this.form)
            InstrumentoPredefinidoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
