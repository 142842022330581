import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Usuario, Usuarios, UsuarioView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { TabService, AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "usuario_perfil_admin.component.html",
    styleUrls: ["usuario_perfil_admin.component.scss"]
})
export class UsuarioPerfilAdminComponent implements OnInit, OnDestroy {
    usuario: Usuario
    private sub: Subscription
    params = UsuarioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    openingTab: string = "perfil"
    tabSubscription: Subscription
    pais: string
    idPais: any
    myUsuarioId: number
    isPrivate: boolean = true

    constructor(
        protected titleService: TitleService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected sanitizer: DomSanitizer,
        protected tabService: TabService,
        protected authService: AuthService
    ) {
        this.myUsuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.usuario_perfil_admin")
        this.pais = config.plataforma.pais
        this.idPais = config.plataforma.identificadorUsuario
        this.sub = this.route.params.subscribe(params => {
            this.usuariosService.find(+params["id"], { include: "usuario_" + this.pais }).then((usuario: Usuario) => {
                this.usuario = usuario
                this.isPrivate = !(this.usuario.id == this.myUsuarioId)
                this.loadingLayout.ready()
            })
        })
        this.tabSubscription = this.tabService.tabSubject.subscribe(tabState => {
            if (tabState["UsuarioComponent"]) {
                this.openingTab = tabState["UsuarioComponent"]
            }
        })
        this.tabService.tabSubject.next(this.tabService.tabState)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.tabSubscription.unsubscribe()
    }

    setTabClasses(tab: string) {
        let classes = {
            active: this.openingTab == tab,
            in: this.openingTab == tab
        }
        return classes
    }
}
