import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_estudio_sesion.component.html"
})
export class PlanEstudioSesionComponent implements OnInit, OnDestroy {
    sub: any
    plan_estudio_id: number
    sesion_id: number
    sesiones_route: string
    enableDudas: boolean = true
    planesEstudioRoute: string

    subData: Subscription

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_estudios.plan_estudio_sesion")
        this.subData = this.route.data.subscribe(data => {
            this.enableDudas = data.enableDudas ?? this.enableDudas
            this.planesEstudioRoute = data.planesEstudioRoute ?? this.planesEstudioRoute
        })

        this.sub = this.route.params.subscribe(params => {
            this.plan_estudio_id = +params["id"]
            this.sesion_id = +params["sesion_id"]
            this.sesiones_route = this.planesEstudioRoute + "/" + this.plan_estudio_id + "/sesiones"
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
