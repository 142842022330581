import { DateView, LinkView, TextView } from "@puntaje/shared/core"
import { Asignaturas } from "./asignaturas.service"
import { PlanEstudios } from "./plan_estudios.service"

export class PlanEstudioView {
    public static get viewParams(): any {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: PlanEstudios, key: "id" } },
            plan_estudio: { label: "Nombre", type: TextView, tableVisible: true },
            descripcion: { label: "Descripción", type: TextView, tableVisible: true },
            nombre_asignatura: {
                label: "Asignatura",
                type: LinkView,
                tableVisible: true,
                options: { class: Asignaturas, key: "asignatura_id" }
            },
            estado: { label: "Estado", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
