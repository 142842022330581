import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneradorInstrumento, GeneradorInstrumentos } from '@puntaje/nebulosa/api-services';
import { Evaluacion, GeneradorInstrumentoDiagnosticoEvaluacion, GeneradorInstrumentoDiagnosticoEvaluaciones, Evaluaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "generador-diagnostico-evaluacion-edit",
    templateUrl: "./generador-diagnostico-evaluacion.form.component.html"
})
export class GeneradorDiagnosticoEvaluacionEditComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    @Input() generadorInstrumentoId: number
    @Input() _evaluacionId: number

    evaluacionId: number
    generadorInstrumento: GeneradorInstrumento
    generadorInstrumentoDiagnosticoEvaluacion: GeneradorInstrumentoDiagnosticoEvaluacion

    msg: string
    valid: boolean = false
    saving: boolean = false

    constructor(
        protected generadorInstrumentoService: GeneradorInstrumentos,
        protected evaluacionesService: Evaluaciones,
        protected generadorInstrumentoDiagnosticoEvaluaciones: GeneradorInstrumentoDiagnosticoEvaluaciones,
        protected router: Router
    ) {}

    ngOnInit() {
        this.generadorInstrumentoDiagnosticoEvaluaciones
            .where({
                generador_instrumento_diagnostico_evaluacion: {
                    generador_instrumento_id: this.generadorInstrumentoId,
                    evaluacion_id: this._evaluacionId
                }
            })
            .then((response: GeneradorInstrumentoDiagnosticoEvaluacion[]) => {
                this.generadorInstrumentoDiagnosticoEvaluacion = response[0]
                this.evaluacionId = this._evaluacionId
                this.loadingLayout.ready()
            })

    }

    async save() {
        this.loadingLayout.standby()
        this.msg = ""
        this.valid = false

        if (!this.evaluacionId) {
            this.msg = "Debe seleccionar una evaluación"
        } else {
            this.msg = "La evaluación seleccionada no existe"
            const params = { raw: 1, include: "[instrumento,evaluacion_usuarios]" }
            const evaluacion = await this.evaluacionesService.find(this.evaluacionId, params).catch(() => console.log("Error al buscar la evaluación"))

            if (evaluacion) {
                if (evaluacion.instrumento.generador_instrumento_id != this.generadorInstrumentoId) {
                    this.msg = "La evaluación seleccionada no pertenece a este generador"
                } else {
                    this.msg = "La evaluación seleccionada no está compartida a todos los estudiantes"
                    const compartida = evaluacion.evaluacion_usuarios.every(eu => {
                        if (
                            eu.receptor_id == null &&
                            (eu.receptor_type == "Usuario" || eu.receptor_type == "GrupoUsuario")
                        ) {
                            return true
                        }
                        return false
                    })

                    if (compartida) {
                        // Guardar
                        this.msg = "Error al actualizar la evaluación"
                        const newGeneradorInstrumentoDE = this.generadorInstrumentoDiagnosticoEvaluacion.clone()
                        newGeneradorInstrumentoDE.evaluacion_id = this.evaluacionId
                        const generadorInstrumentoDiagnosticoEvaluacion =
                            await this.generadorInstrumentoDiagnosticoEvaluaciones.update(
                                this.generadorInstrumentoDiagnosticoEvaluacion.id,
                                newGeneradorInstrumentoDE
                            )

                        if (generadorInstrumentoDiagnosticoEvaluacion) {
                            this.valid = true
                            this.msg = "Evaluación actualizada correctamente"
                        }
                    }
                }
            }
        }
        this.loadingLayout.ready()
    }

    back() {
        this.router.navigate(["generador_diagnostico"])
    }
}
