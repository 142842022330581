import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { LoggedLayoutService } from "@puntaje/shared/cui"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "estadisticas_declarados.component.html"
})
export class EstadisticasDeclaradosComponent implements OnInit, OnDestroy {
    establecimientoId: number
    sub: Subscription
    asignatura: Asignatura
    asignaturaId: number
    tipoInstrumento: string = config.plataforma.evaluacionDefault

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.estadiosticas_declarados")
        this.sub = this.route.queryParams.subscribe(params => {
            this.asignaturaId = +params["asignatura_id"]
            this.tipoInstrumento = params["tipo_instrumento"] || this.tipoInstrumento
            this.establecimientoId = +params["establecimiento_id"]
        })

        this.loggedLayoutService.setOptions({ hideTitleOnPrint: true })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onReadyAsignaturaDropdown(asignaturaId) {
        this.router.navigate(["/estadisticas"], { queryParams: { asignatura_id: asignaturaId } })
    }
}
