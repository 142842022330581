<div class="print-show-block print-info print-page-break-after" [hidden]="true">
    <img class="portada-logo" src="{{ logosrc }}" alt="logo portada" />
    <h2 *ngIf="establecimiento">{{ establecimiento.establecimiento }}</h2>
    <h2 *ngIf="lugar">{{ lugar.nombre }}</h2>

    <p class="subtitle">
        Informe de
        <span *ngIf="establecimiento">Establecimiento</span>
        <span *ngIf="lugar">{{ lugarTipo }}</span>
    </p>

    <p class="dotted-line clearfix" *ngIf="asignatura">
        <span class="fake-label">Asignatura:</span>
        <span class="value">{{ asignatura.asignatura }}</span>
    </p>
    <div class="row printInfo-tables">
        <div class="col-md-4 col-sm-6 left-col">
            <table class="table minitable-printInfo">
                <tbody>
                    <tr>
                        <!-- <td rowspan="2" class="category"><fa name="user" class="icono"></fa><br>Estudiantes</td> -->
                        <td class="fake-th fix-padding">Nº Estudiantes</td>
                        <td class="fake-th fix-padding">Nº Evaluaciones Realizadas</td>
                    </tr>
                    <tr>
                        <td class="fix-padding">{{ numeroEstudiantes }}</td>
                        <td class="fix-padding">{{ numeroEvaluacionesInstancias }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8 col-sm-6 right-col">
            <table class="table minitable-printInfo">
                <tbody>
                    <tr>
                        <td rowspan="2" class="category">
                            <ogr-icon name="prueba" class="icono"></ogr-icon>
                            <br />
                            Resultados
                        </td>
                        <td class="fake-th">Promedio</td>
                        <td class="fake-th">Mínimo</td>
                        <td class="fake-th">Máximo</td>
                    </tr>
                    <tr>
                        <td>{{ promedio | roundfloat }}</td>
                        <td>{{ minimo }}</td>
                        <td>{{ maximo }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
