<div class="contenedor">
    <div class="caja" id="section1">
        <div class="caja-inner-margin">
            <div class="row">
                <div class="col-md-8 col-md-offset-2">
                    <div class="titulo">
                        <img alt="prelog/logo" src="{{ config.app.assets.logoLargeLight }}" class="landing-logo" />
                    </div>

                    <div class="login-container">
                        <login-ua [enableContactUs]="false" [enableRegister]="false"></login-ua>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
