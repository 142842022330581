<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <form novalidate [formGroup]="form">
                <form-input
                    *ngIf="!isNew"
                    [form]="form"
                    [params]="params['id']"
                    [(value)]="usuario['id']"
                    [key]="'id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuario['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['usuario_' + pais]"
                    [(value)]="usuario['usuario_' + pais]"
                    [key]="'usuario_' + pais"
                ></form-input>
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            [ngModel]="isPlus"
                            (ngModelChange)="onPlusChange($event)"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        PN+
                    </label>
                </div>
                <ng-container *ngIf="usuario.fecha_inicio_plus || usuario.fecha_termino_plus">
                    <form-input
                        [form]="form"
                        [params]="params['fecha_inicio_plus']"
                        [(value)]="usuario['fecha_inicio_plus']"
                        [key]="'fecha_inicio_plus'"
                    ></form-input>
                    <form-input
                        [form]="form"
                        [params]="params['fecha_termino_plus']"
                        [(value)]="usuario['fecha_termino_plus']"
                        [key]="'fecha_termino_plus'"
                    ></form-input>
                </ng-container>
                <form-input
                    [form]="form"
                    [params]="params['premium_usuarios']"
                    [(value)]="usuario['premium_usuarios']"
                    [key]="'premium_usuarios'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['roles']"
                    [(value)]="usuario['roles']"
                    [key]="'roles'"
                ></form-input>
                <!-- <button (click)="addRol()" type="button" class="btn btn-default">Agregar Rol
				</button> -->
                <button (click)="save()" type="button" class="btn btn-default">
                    {{ save_button_text | easyplaceholder: "Guardar" }}
                </button>
                <button (click)="clear()" type="button" class="btn btn-default">
                    {{ clear_button_text | easyplaceholder: "Borrar" }}
                </button>
            </form>
        </div>
    </div>
</loading-layout>
