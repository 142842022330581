import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PlanPersonalSesion, PlanPersonalSesiones, PlanPersonal } from "@puntaje/puntaje/api-services"
import { PlanPersonalSesionForm } from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { UntypedFormGroup } from "@angular/forms"

@Component({
    selector: "sesiones-edit",
    templateUrl: "../sesiones-form/sesiones-form.component.html"
})
export class SesionesEditComponent implements OnInit {
    @Input() sesionId: number
    @Input() id: number
    planPersonalSesion: PlanPersonalSesion = new PlanPersonalSesion()
    oPlanPersonalSesion: PlanPersonalSesion = new PlanPersonalSesion()
    params = PlanPersonalSesionForm.formParams
    form: UntypedFormGroup
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected planPersonalSesionesService: PlanPersonalSesiones,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = PlanPersonalSesionForm.getForm(this.planPersonalSesion)
        this.setData()
        this.loadingLayout.ready()
    }

    setData() {
        this.planPersonalSesionesService.find(this.sesionId).then((response: PlanPersonalSesion) => {
            this.planPersonalSesion = response
            this.oPlanPersonalSesion = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        PlanPersonalSesionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planPersonalSesionesService
                .update(this.planPersonalSesion.id, this.planPersonalSesion)
                .then(response => {
                    this.router.navigate(["/planificaciones", this.id])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.planPersonalSesion = this.oPlanPersonalSesion
            PlanPersonalSesionForm.markFormControlsAsPristine(this.form)
            PlanPersonalSesionForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
