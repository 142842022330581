import { TextView, LinkView, DateView, EntitiesView, EntityView, BooleanView, AppConfig } from "@puntaje/shared/core"
import { Usuarios } from "./usuarios.service"
import { GrupoUsuario } from "./grupo_usuarios.model"
import { Establecimientos } from "./establecimientos.service"
import { Amigo } from "./amigos.model"

declare const config: AppConfig

export class UsuarioAdminView {
    public static get viewParams() {
        let params: any = {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Usuarios, key: "id" } },
            nombre: { label: "Nombre", type: TextView, tableVisible: true },
            apellido_paterno: { label: "Apellido paterno", type: TextView, tableVisible: true },
            apellido_materno: { label: "Apellido materno", type: TextView, tableVisible: true },
            //real_activo: { label: "Activo", type: BooleanView, tableVisible: true },no mostraba si usuario realmente estaba activo o no
            activo: { label: "Activo", type: BooleanView, tableVisible: true },
            nomina: { label: "Datos Completados", type: BooleanView, tableVisible: true }, //hace referencia al campo nomina
            is_pro: { label: "Pro", type: BooleanView, tableVisible: true },
            plus: { label: "Plus", type: BooleanView, tableVisible: true },
            email: { label: "Email", type: TextView, tableVisible: true }
        }

        let classes = {
            chile: { label: "Rut" },
            colombia: { label: "Ti/CC" },
            mexico: { label: "CURP" },
            brasil: { label: "CPF" }
        }

        params["usuario_" + config.plataforma.pais] = {
            type: TextView,
            label: classes[config.plataforma.pais].label,
            tableVisible: true
        }

        return params
    }
}
