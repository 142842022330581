<div class="seccion" (window:resize)="onResize($event)">
    <loading-layout #loadingLayout [opaque]="true">
        <cui-section-headline>1. Seleccione los detalles del nuevo instrumento</cui-section-headline>
        <div class="" #externalFrameReference>
            <!--     Generar Instrumento      -->
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="options-container">
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Seleccione un tipo de instrumento
                        </h4>
                        <select
                            class="form-control"
                            [(ngModel)]="tipoEvaluacion"
                            (change)="changeTipoEvaluacion()"
                            name="tipo-evaluacion"
                        >
                            <option *ngFor="let tipo of evaluacionTipos" [ngValue]="tipo">
                                {{ tipo | capitalize }}
                            </option>
                        </select>
                    </div>
                    <div class="options-container">
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Cantidad de preguntas y tiempo
                        </h4>
                        <numero-preguntas-tiempo
                            [generadorInstrumento]="generadorInstrumento"
                            [(numeroPreguntas)]="numeroPreguntas"
                            [(tiempo)]="tiempo"
                            [tipoEvaluacion]="tipoEvaluacion"
                        ></numero-preguntas-tiempo>
                    </div>
                    <div class="options-container" [hidden]="!asignaturas">
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Escoja Asignatura
                        </h4>
                        <select
                            [compareWith]="compareAsignatura"
                            class="form-control"
                            [(ngModel)]="asignatura"
                            (change)="changeAsignatura()"
                        >
                            <option [ngValue]="undefined">Seleccione asignatura</option>
                            <option *ngFor="let asig of asignaturas" [ngValue]="asig">
                                {{ asig.asignatura }}
                            </option>
                        </select>
                    </div>
                    <div
                        class="options-container"
                        *ngIf="generadorInstrumentos"
                        [hidden]="!generadorInstrumentos || generadorInstrumentos.length == 1"
                    >
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Escoja Tipo
                        </h4>
                        <generador-instrumento-select
                            [generadorInstrumentos]="generadorInstrumentos"
                            [(generadorInstrumento)]="generadorInstrumento"
                            (generadorInstrumentoChange)="clasificacionesIds = null"
                        ></generador-instrumento-select>
                    </div>
                    <div class="options-container">
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Instrumentos ignorados (ID separado por coma)
                        </h4>
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="1,2,3"
                                [(ngModel)]="instrumentosIgnorados"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio">
                            <label>
                                <input type="radio" [value]="true" [(ngModel)]="preguntasValidadas" name="validadas" />
                                Solo preguntas validadas
                            </label>
                        </div>

                        <div class="radio">
                            <label>
                                <input type="radio" [value]="false" [(ngModel)]="preguntasValidadas" name="validadas" />
                                Preguntas validadas y no validadas
                            </label>
                        </div>
                    </div>
                    <br />
                </div>
                <div class="col-md-6 col-sm-12">
                    <div
                        id="contenidos-container"
                        class="contenidos-container clear-both"
                        [class.contenidos-container-show]="show_contenidos"
                    >
                        <h4 class="h4_sub_title_1">
                            <fa name="arrow-right" class="highlight-chevron"></fa>
                            &nbsp;Personalizar
                        </h4>
                        <p class="tutorial">
                            Selecciona las categorías para personalizar los contenidos del instrumento de evaluación.
                        </p>
                        <div class="generar-col2 contenidos-ensayo">
                            <configurar-clasificaciones
                                *ngIf="generadorInstrumento"
                                [enableCantidadPreguntas]="true"
                                [tipoInstrumento]="tipoEvaluacion"
                                [generadorInstrumentoId]="generadorInstrumento.id"
                                [asignaturaId]="asignaturaId"
                                [validadas]="true"
                                [(clasificacionesIds)]="clasificacionesIds"
                            ></configurar-clasificaciones>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <h4 class="h4_sub_title_1">
                <fa name="arrow-right" class="highlight-chevron"></fa>
                &nbsp;Generar instrumento de evaluación
            </h4>
            <cui-button
                type="button"
                class="btn_style btn-new"
                (click)="generarInstrumento()"
                [disabled]="!clasificacionesIds"
            >
                <ogr-icon [name]="'prueba'"></ogr-icon>
                &nbsp;Generar instrumento de evaluación
            </cui-button>
        </div>
    </loading-layout>
</div>

<div *ngIf="instrumento">
    <cui-section-headline id="instrumentoSeleccionado">
        2. Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }} a partir del Instrumento seleccionado de la
        sección "evaluar"
    </cui-section-headline>
    <p class="tutorial">
        Para terminar de generar {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}, elija la opción
        de Compartir en el menú.
    </p>
    <div class="row">
        <div class="col-md-4 inverted-col">
            <opciones-instrumento
                [instrumento]="instrumento"
                [tipoId]="1"
                [tipoEvaluacion]="tipoEvaluacion"
                [scrollAndFollow]="true"
                [responsiveBreak]="991"
                [topPadding]="20"
                [bottomPadding]="20"
                [followTopReference]="followReference"
                [followBottomReference]="followReference"
                [enableAdminOptions]="true"
            ></opciones-instrumento>
        </div>
        <div class="col-md-8" #followReference>
            <h3 class="titulo-instrumento">
                <div class="titulo-instrumento-wrap">
                    <span class="titulo-instrumento-icon">
                        <ogr-icon [name]="'prueba'"></ogr-icon>
                    </span>
                    <span class="titulo-instrumento-text">
                        Instrumento #{{ instrumento.id }}
                        <span *ngIf="instrumentoAsignatura">- {{ instrumentoAsignatura.asignatura }}</span>
                    </span>
                </div>
            </h3>
            <instrumento
                [instrumento]="instrumento"
                (ready)="onInstrumentoReady()"
                (readyAsignatura)="onAsignaturaInstrumentoReady($event)"
                [enableReporte]="enableReporte"
            ></instrumento>
        </div>
    </div>
</div>

<ng-container *ngIf="instrumentos">
    <ul class="nav nav-tabs">
        <li *ngFor="let i of instrumentos" role="presentation" [ngClass]="{ active: selectedInstrumento == i }">
            <a data-toggle="tab" (click)="selectedInstrumento = i" href="#instrumento_{{ i.id }}">
                {{ i.generador_instrumento.generador_instrumento }}
            </a>
        </li>
    </ul>

    <div class="tab-content-instrumento" *ngFor="let i of instrumentos">
        <div id="instrumento_{{ i.id }}">
            <ng-container *ngIf="i == selectedInstrumento">
                <cui-section-headline id="instrumentoSeleccionado">
                    2. Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }} a partir del Instrumento
                    seleccionado
                </cui-section-headline>
                <p class="tutorial">
                    Para terminar de generar {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}, elija
                    la opción de Compartir en el menú.
                </p>
                <div class="row">
                    <div class="col-md-4 inverted-col">
                        <opciones-instrumento
                            [instrumento]="i"
                            [tipoId]="1"
                            [tipoEvaluacion]="tipoEvaluacion"
                            [scrollAndFollow]="true"
                            [responsiveBreak]="991"
                            [topPadding]="20"
                            [bottomPadding]="20"
                            [followTopReference]="followReference"
                            [followBottomReference]="followReference"
                            [enableAdminOptions]="true"
                        ></opciones-instrumento>
                    </div>
                    <div class="col-md-8" #followReference>
                        <h3 class="titulo-instrumento">
                            <div class="titulo-instrumento-wrap">
                                <span class="titulo-instrumento-icon">
                                    <ogr-icon [name]="'prueba'"></ogr-icon>
                                </span>
                                <span class="titulo-instrumento-text">
                                    Instrumento #{{ i.id }}
                                    <span *ngIf="instrumentoAsignatura">- {{ instrumentoAsignatura.asignatura }}</span>
                                </span>
                            </div>
                        </h3>
                        <instrumento
                            [instrumento]="i"
                            (ready)="onInstrumentoReady()"
                            (readyAsignatura)="onAsignaturaInstrumentoReady($event)"
                            [enableReporte]="enableReporte"
                        ></instrumento>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
