import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import {
    PlanEstudio,
    PlanEstudios,
    PlanEstudioLote,
    PlanEstudioLotes,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    PlanEstudioView
} from "@puntaje/nebulosa/api-services"
import {
    PlanEstudioAplicado,
    PlanEstudioEvaluacion,
    PlanEstudioEvaluaciones,
    PlanEstudioAplicados,
    PlanEstudioAplicadoView
} from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "plan_estudios",
    templateUrl: "plan_estudios.component.html"
})
export class PlanEstudiosComponent implements OnInit {
    @Input() listRoute: string
    params = PlanEstudioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayout2", { static: true }) loadingLayout2: LoadingLayoutComponent

    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    generadorInstrumentos: GeneradorInstrumento[]

    tableButtons: any

    showConfigurar: boolean = false
    planEstudios: PlanEstudio[]
    planEstudioLotes: PlanEstudioLote[]
    planEstudioAplicados: PlanEstudioAplicado[]
    planEstudioAplicado: PlanEstudioAplicado

    disablePlanEstudioAplicados: boolean = false

    constructor(
        protected planEstudiosService: PlanEstudios,
        protected planLotesService: PlanEstudioLotes,
        protected planAplicadosService: PlanEstudioAplicados,
        protected planEvaluacionesService: PlanEstudioEvaluaciones,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtons = (pe: PlanEstudio) => {
            return {
                configure: { function: this.configure.bind(this), text: "Configurar", glyphicon: "glyphicon-pencil" }
            }
        }

        //this.showAll();
    }

    showAll(page: number = 1, count: number = 20) {
        return this.planEstudiosService.where({ page: page, per: count }).then((pes: PlanEstudio[], total: number) => {
            this.planEstudios = pes
            this.loadingLayout.ready()
            this.loadingLayout2.ready()

            return total
        })
    }

    view(planEstudio: PlanEstudioAplicado) {
        this.router.navigate([this.listRoute + "/" + planEstudio.id])
    }

    configure(planEstudio: PlanEstudio) {
        this.loadingLayout2.standby()

        this.planEstudioAplicado = new PlanEstudioAplicado()
        Object.assign(this.planEstudioAplicado, {
            plan_estudio_id: planEstudio.id,
            asignatura_id: planEstudio.asignatura_id,
            estado: 1,
            plan_estudio_aplicado: planEstudio.plan_estudio
        })

        this.generadorInstrumentosService
            .where({
                asignatura_plataforma: { plataforma_id: config.plataforma.id, asignatura_id: planEstudio.asignatura_id }
            })
            .then((gis: GeneradorInstrumento[]) => {
                this.generadorInstrumentos = gis

                this.planLotesService
                    .where({ plan_estudio_lote: { plan_estudio_id: planEstudio.id } })
                    .then((pels: PlanEstudioLote[]) => {
                        this.planEstudioLotes = pels
                        this.planEstudioLotes.forEach(pel => {
                            pel.generador_instrumento_id = this.generadorInstrumentos[0].id
                        })

                        this.loadingLayout2.ready()
                    })
            })
    }

    activate() {
        let planEstudioLotes = this.planEstudioLotes.map(pel => {
            let newPel = new PlanEstudioLote()
            Object.assign(newPel, {
                lote_id: pel.lote_id,
                id: pel.id,
                nombre: pel.nombre,
                plan_estudio_sesion_id: pel.plan_estudio_sesion_id,
                orden: pel.orden,
                generador_instrumento_id: pel.generador_instrumento_id
            })

            return newPel
        })
        ;(this.planEstudioAplicado as any).plan_estudio_lotes = planEstudioLotes

        this.planAplicadosService.save(this.planEstudioAplicado).then((pe: PlanEstudioAplicado) => {
            //location.reload();
            this.planEstudioAplicado = undefined
            this.disablePlanEstudioAplicados = true
            setTimeout(() => {
                this.disablePlanEstudioAplicados = false
            }, 1)
        })

        /*this.planLotesService.where({plan_estudio_lote: {plan_estudio_id: planEstudio.plan_estudio_id}}).then((response: PlanEstudioLote[], total: number) => {
            var promises = []
            let peLotes = response.map((plLote: PlanEstudioLote) => {
                plLote.plan_estudio = planEstudio.plan_estudio;
                promises.push(this.planEvaluacionesService.get_evaluacion_id(plLote).getPromise());
                return plLote;
            });

            Promise.all(promises).then((ids: number[]) => {
                var peEvaluaciones = [];
                for (var i = 0, len = peLotes.length; i < len; i++) {
                    var ple = new PlanEstudioEvaluacion();
                    //ple.plan_estudio_aplicado = planEstudio;
                    ple.plan_estudio_id = planEstudio.plan_estudio_id;
                    ple.plan_estudio_sesion_id = peLotes[i].plan_estudio_sesion_id;
                    ple.plan_estudio_lote_id = peLotes[i].id;
                    ple.plan_estudio_aplicado_id = planEstudio.id;
                    ple.orden = peLotes[i].orden;
                    ple.evaluacion_id = +ids[i]["_body"];
                    peEvaluaciones.push(ple);
                }
                planEstudio.plan_estudio_evaluaciones = peEvaluaciones;
                this.planAplicadosService.save(planEstudio).then((response2) => {
                    //this.router.navigate([this.listRoute]);
                    location.reload();
                });
            });
        });*/
    }
}
