import {
    BannersComponent,
    BannerPaginaComponent,
    BannerPaginasComponent,
    BannerPaginasEditComponent,
    BannerPaginasNewComponent,
    BannerZonaComponent,
    BannerZonasComponent,
    BannerZonasEditComponent,
    BannerZonasNewComponent,
    BannerInstanciasComponent,
    BannerComponent,
    BannersActivosComponent,
    BannersEditComponent,
    BannersNewComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const bannersRoutes: Routes = [
    {
        path: "banners",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannersComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners-activos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannersActivosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/instancias/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/paginas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerPaginasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/paginas/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerPaginasNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/paginas/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: BannerPaginaComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }
        ]
    },
    {
        path: "banners/paginas/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerPaginasEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/zonas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerZonasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/zonas/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerZonasNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/zonas/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: BannerZonaComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }
        ]
    },
    {
        path: "banners/zonas/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerZonasEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannersNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "banners/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannerComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }]
    },
    {
        path: "banners/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BannersEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const bannersRouting = RouterModule.forChild(bannersRoutes)
