import { Component, Input, Directive } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"

@Component({
    selector: "toolnavbar",
    templateUrl: "toolnavbar.html"
})
export class ToolNavbar {
    private sub: Subscription

    @Input() displayButtonsInput: string = ""

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayNavbar: boolean = true
    displayBtnSearch: boolean = true

    urlNew: string = ""
    urlAll: string = ""
    urlEdit: string = ""

    @Input() resourceName: string
    @Input() resourceId: number
    @Input() queryParams: any = {}

    constructor(protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.urlNew = `/${this.resourceName}/new`
        this.urlAll = `/${this.resourceName}`
        this.urlEdit = `/${this.resourceName}/${this.resourceId}/edit`

        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtonsInput != null) {
            this.displayBtnAll = this.displayButtonsInput.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtonsInput.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtonsInput.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtonsInput.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit && !this.displayBtnSearch) {
            this.displayNavbar = false
        }
    }

    new() {
        if (this.urlNew != "") {
            this.router.navigate([this.urlNew], { queryParams: this.queryParams })
        }
    }

    all() {
        if (this.urlAll != "") {
            this.router.navigate([this.urlAll], { queryParams: this.queryParams })
        }
    }

    edit() {
        if (this.urlEdit != "") {
            this.router.navigate([this.urlEdit], { queryParams: this.queryParams })
        }
    }
}
