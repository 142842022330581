import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { AsociacionContacto, AsociacionContactos, AsociacionContactoView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asociacion-contacto",
    templateUrl: "asociacion_contacto.component.html"
})
export class AsociacionContactoComponent implements OnInit {
    @Input() asociacionContactoId: number
    @Output() asociacionContactoChange = new EventEmitter<any>()

    asociacionContacto: AsociacionContacto
    params = AsociacionContactoView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected asociacionContactosService: AsociacionContactos,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.asociacionContactosService
            .find(this.asociacionContactoId)
            .then((asociacionContacto: AsociacionContacto) => {
                this.asociacionContactoChange.emit(asociacionContacto)
                this.asociacionContacto = asociacionContacto

                this.loadingLayout.ready()
            })
    }
}
