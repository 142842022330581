import { BaseForm, Validations } from "@puntaje/shared/core"
import { Banners } from "./banners.service"
import { BannerTipo } from "./banner_tipos.model"
import { BannerTipos } from "./banner_tipos.service"

export class IframeBannersForm extends BaseForm {
    public static get formParams(): any {
        return {
            iframe_url: { label: "Iframe URL", type: "text", visible: true }
        }
    }
}
