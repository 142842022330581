import { BaseForm, Validations } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class NominaForm extends BaseForm {
    public static get formParams(): any {
        return {
            file: { label: "Archivo", type: "file", visible: true, validations: [Validations.validateNomina] }
        }
    }
}
