<div class="row push-bottom print-hide" *ngIf="evaluacion">
    <div class="col-md-3">
        <filtro-estadisticas-evaluacion-recomendada
            (filter)="onFilter($event)"
        ></filtro-estadisticas-evaluacion-recomendada>
    </div>
</div>

<loading-layout>
    <ng-container *ngIf="!loadingLayout.loading">
        <estadisticas-evaluacion-portada
            [evaluacionTipo]="evaluacionTipo.evaluacion_tipo"
            [evaluacion]="evaluacion"
            [establecimiento]="establecimiento"
            [asignatura]="asignatura"
            [grupoUsuario]="grupoUsuario"
            [evaluacionInstancias]="evaluacionInstancias"
            [estadisticas]="estadisticas"
        ></estadisticas-evaluacion-portada>

        <div class="clearfix">
            <cui-button class="btn_style pull-right print-hide" (click)="print()">
                <fa name="print"></fa>
                &nbsp;{{ "estadisticas.profe.imprimir" | t }}
            </cui-button>
        </div>
        <datos-generales *ngIf="evaluacion" [instrumento]="evaluacion.instrumento"></datos-generales>

        <tabla-resumen-evaluacion-por-alumno
            class="print-page-break-after"
            *ngIf="evaluacionInstancias"
            [evaluacionInstancias]="evaluacionInstancias"
            [estadisticas]="!establecimiento && !grupoUsuario ? estadisticas : null"
            [usuarios]="usuarios"
            [evaluacion]="evaluacion"
            [evaluacionTiempos]="evaluacionTiempos"
            [actualizarFiltro]="false"
            [disableActions]="true"
            [hideEstadisticas]="!!establecimiento || !!grupoUsuario"
            [enableColegio]="!establecimiento && !grupoUsuario"
        ></tabla-resumen-evaluacion-por-alumno>

        <grafico-distribucion-tramo-puntaje
            *ngIf="showGraphs"
            class="print-page-break-after"
            [asignatura]="asignatura"
            [data]="distribucion_ensayos_tramo_puntaje"
            [tipoEvaluacion]="evaluacionTipo.evaluacion_tipo"
            [evaluacion]="evaluacion"
            [evaluacionInstancias]="evaluacionInstancias"
            [usuarios]="usuarios"
        ></grafico-distribucion-tramo-puntaje>

        <tabla-resultados-por-pregunta
            class="print-page-break-after"
            *ngIf="evaluacionInstancias"
            [evaluacionInstancias]="evaluacionInstancias"
            [evaluacion]="evaluacion"
            (dataReady)="onResultadosPorPreguntaReady($event)"
        ></tabla-resultados-por-pregunta>

        <ng-container *ngIf="showGraphs">
            <ng-container *ngFor="let cte of clasificacionTiposEstadisticas">
                <ng-template [ngIf]="cte.compuesto">
                    <grafico-desempeno-clasificacion-compuesto
                        class="print-page-break-after"
                        [evaluacionTipo]="evaluacionTipo.evaluacion_tipo"
                        [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                        [asignatura]="asignatura"
                        [clasificaciones]="this['desempeno_' + cte.nombre]"
                        [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas']"
                        [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas']"
                        [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas']"
                        [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                    ></grafico-desempeno-clasificacion-compuesto>
                </ng-template>
                <ng-template [ngIf]="!cte.compuesto">
                    <grafico-desempeno-clasificacion
                        class="print-page-break-after"
                        [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                        [asignatura]="asignatura"
                        [clasificaciones]="this['desempeno_' + cte.nombre]"
                        [data]="this['desempeno_' + cte.nombre + '_correctas']"
                        [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                    ></grafico-desempeno-clasificacion>
                </ng-template>
                <tabla-desempeno-por-usuario-clasificacion
                    [usuarios]="usuarios"
                    [asignaturaId]="asignatura.id"
                    [estadisticaUsuarios]="estadisticaUsuarios"
                    [clasificacionTipo]="clasificacionTipoByNombre[cte.nombre][0]"
                    [clasificacionTipoReferenced]="clasificacionReferenced[cte.nombre]"
                ></tabla-desempeno-por-usuario-clasificacion>
            </ng-container>
            <listado-preguntas-mas-incorrectas
                *ngIf="dataRespuestas"
                [dataFull]="dataRespuestas"
            ></listado-preguntas-mas-incorrectas>
        </ng-container>
    </ng-container>
</loading-layout>
