import { Component, OnInit, Input, ViewChild } from "@angular/core"
import {
    EvaluacionUsuario,
    EvaluacionUsuarios,
    Usuarios,
    Usuario,
    EvaluacionInstancias
} from "@puntaje/puntaje/api-services"
import { SessionService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "resultados-ensayos",
    templateUrl: "./resultados-ensayos.component.html",
    styleUrls: ["./resultados-ensayos.component.scss"]
})
export class ResultadosEnsayosComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() evaluacionId
    @Input() perfil = "alumno"
    evaluacionUsuarios
    usuarios: Usuario[]
    lista: any[] = [
        {
            nombre: "Nombre Apellido",
            fecha: new Date(),
            actividad_id: 1
        },
        {
            nombre: "Nombre Apellido2",
            fecha: null,
            actividad_id: 2
        }
    ]
    selected: any
    total: number

    constructor(
        protected evaluacionUsuarioService: EvaluacionUsuarios,
        protected sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected evaluacionInstanciasService: EvaluacionInstancias
    ) {}

    ngOnInit() {
        this.getData()
    }

    async getData(page: number = 1, per: number = 6) {
        let params = {
            evaluacion_usuario: {
                receptor_type: "GrupoUsuario",
                evaluacion_id: this.evaluacionId
            }
        }
        this.evaluacionUsuarios = await this.evaluacionUsuarioService.where(params)

        const grupoUsuarioIds = [] // creamos un arreglo vacío para guardar el total
        let evaluacionUsuariosGrupoUsuarioIds = this.evaluacionUsuarios.map(eu => eu.receptor_id) // obtenemos un arreglo de  ids grupo usuarios
        let usuariosActivos = this.sessionService.getGrupoUsuariosActivos() //obtenemos los grupoUsuario del localStorage

        evaluacionUsuariosGrupoUsuarioIds.forEach(id => {
            if (usuariosActivos.find(ua => ua == id)) grupoUsuarioIds.push(id)
        })

        const usuarioParams = {
            page,
            per: per,
            grupo_usuario_usuario: {
                grupo_usuario_id: grupoUsuarioIds,
                propietario: 0
            }
        }

        const [usuarios, total] = await this.usuariosService.where(usuarioParams).then((u, t) => {
            return [u, t]
        })

        this.usuarios = usuarios
        this.total = total
        const usuarioIds = this.usuarios.map(u => u.id)

        const evaluacionInstanciaParams = {
            evaluacion_instancia: {
                evaluacion_id: this.evaluacionId,
                usuario_id: usuarioIds
            }
        }
        const evaluacionInstancias = await this.evaluacionInstanciasService.where(evaluacionInstanciaParams)
        const evaluacionInstanciaByUsuarioId = evaluacionInstancias.reduce((acc, ei) => {
            acc[ei.usuario_id] = ei

            return acc
        }, {})

        this.lista = this.usuarios.map(usuario => {
            return {
                usuario_id: usuario.id,
                nombre: usuario.nombreCompleto(),
                evaluacionInstancia: evaluacionInstanciaByUsuarioId[usuario.id]
            }
        })

        this.selected = this.lista[0]
        this.loadingLayout.ready()
        return this.total
    }

    onSelectItem(selected: any) {
        this.selected = selected
    }
}
