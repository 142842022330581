<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="push-bot">
            <model-simple-view
                *ngFor="let key of params | keys"
                [params]="params[key]"
                [value]="establecimientoReporte"
                [key]="key"
            ></model-simple-view>
        </div>
    </div>
</loading-layout>
