import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "ensayos_mes.component.html",
    styleUrls: ["ensayos_mes.component.scss"]
})
export class EnsayosMesComponent implements OnInit, OnDestroy {
    config: typeof config
    month: number
    sub: Subscription
    year: number = new Date().getFullYear()
    months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ]

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {
        this.config = config
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.ensayos_mes")
        this.sub = this.route.params.subscribe(params => {
            this.month = parseInt(params["month"])
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
