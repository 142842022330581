import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import {
    EstablecimientoReporte,
    EstablecimientoReportes,
    EstablecimientoReporteView
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento-reporte",
    templateUrl: "establecimiento_reporte.component.html"
})
export class EstablecimientoReporteComponent implements OnInit {
    @Input() establecimientoReporteId: number
    @Output() establecimientoReporteChange = new EventEmitter<any>()

    establecimientoReporte: EstablecimientoReporte
    params = EstablecimientoReporteView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected establecimientoReportesService: EstablecimientoReportes,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.establecimientoReportesService
            .find(this.establecimientoReporteId)
            .then((establecimientoReporte: EstablecimientoReporte) => {
                this.establecimientoReporteChange.emit(establecimientoReporte)
                this.establecimientoReporte = establecimientoReporte

                this.loadingLayout.ready()
            })
    }
}
