<loading-layout #loadingLayout>
    <table-with-actions
        [contentArray]="canalesStreaming"
        [showParams]="params"
        [tableButtonsArray]="tableButtonsArray"
    ></table-with-actions>
    <paginator [getList]="this.showAll.bind(this)"></paginator>
    <generic-modal [buttonClicked]="openModal" [id]="'modal-material'">
        <modal-titulo class="modal-titulo">
            Asociar Material al streaming {{ selectedMaterialStream?.alias }}
        </modal-titulo>
        <modal-contenido class="modal-contenido">
            <form class="form-horizontal">
                <div class="form-group">
                    <label for="material_id" class="col-sm-3 control-label">ID Material</label>
                    <div class="col-sm-9">
                        <input
                            type="text"
                            class="form-control"
                            name="material_id"
                            (keyup)="materialPreviewId = null"
                            [(ngModel)]="materialId"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-3 col-sm-9">
                        <button (click)="asociarMaterial()" class="btn btn-primary">Asociar</button>
                        <button
                            style="margin-left: 20px"
                            (click)="materialPreviewId = materialId"
                            class="btn btn-primary"
                        >
                            Previsualizar
                        </button>
                    </div>
                </div>
            </form>
            <material-preview [id]="materialPreviewId" *ngIf="materialPreviewId"></material-preview>
        </modal-contenido>
        <modal-botones>
            <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal">Cerrar</cui-button>
        </modal-botones>
    </generic-modal>
</loading-layout>
