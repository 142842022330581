import { Component, OnInit, Input } from "@angular/core"
import {
    /*Establecimiento,*/ Establecimientos,
    EstablecimientoReporte,
    EstablecimientoReportes
} from "@puntaje/puntaje/api-services"
import { EstablecimientoReportesBuscadorService } from "./establecimiento_reportes_buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento-reportes-buscador",
    templateUrl: "establecimiento_reportes_buscador.component.html"
})
export class EstablecimientoReportesBuscadorComponent implements OnInit {
    sub: Subscription
    open: boolean = false

    @Input() establecimientoId: number
    establecimientoReporte: any = { like: {}, gte: {}, lte: {} }

    tTipos: any[] = [
        { value: 1, TipoType: "Llamada" },
        { value: 2, TipoType: "Email" },
        { value: 3, TipoType: "Zopim" }
    ]
    tResuelto: any[] = [
        { value: 0, ResueltoType: "No Resuelto" },
        { value: 1, ResueltoType: "Resuelto" }
    ]

    constructor(protected establecimientoReportesBuscadorService: EstablecimientoReportesBuscadorService) {}

    ngOnInit() {
        this.sub = this.establecimientoReportesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.establecimientoReportesBuscadorService.search(this.establecimientoReporte, this.establecimientoId)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
