import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"
import { PlanPersonalesBuscadorService } from "@puntaje/puntaje/new-modules/shared"

@Component({
    templateUrl: "plan_personales.toolnavbar.component.html",
    selector: "plan-personales-toolnavbar"
})
export class PlanPersonalesToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() planPersonalId: number
    @Input() displayButtons: string
    @Input() urlAll: string = "/plan_personales"
    @Input() model: string = "planes personales"
    @Input() planPersonalTipo: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected planPersonalesBuscadorService: PlanPersonalesBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlNew = this.urlAll + "/new"
        if (this.planPersonalId != null) {
            this.urlEdit = this.urlAll + "/" + this.planPersonalId + "/edit"
        }

        if (this.planPersonalTipo) {
            this.queryParams["plan_personal_tipo"] = this.planPersonalTipo
        }

        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.planPersonalId != null) {
            this.urlEdit = this.urlAll + "/" + this.planPersonalId + "/edit"
        }
        super.edit()
    }

    search() {
        this.planPersonalesBuscadorService.openSearch.next()
    }
}
