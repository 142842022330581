import { Component, OnInit, Input } from "@angular/core"
import { PlanPersonal, PlanPersonales } from "@puntaje/puntaje/api-services"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-sesiones-new",
    templateUrl: "./sesiones-new.component.html",
    styleUrls: ["./sesiones-new.component.scss"]
})
export class SesionesNewComponent implements OnInit {
    planificacion: PlanPersonal

    constructor(
        protected titleService: TitleService,
        protected planPersonalesService: PlanPersonales,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.sesiones.new")
        this.route.params.subscribe(params => {
            this.planPersonalesService
                .find(+params["id"], { include: "plan_personal_ciclos" })
                .then(planificacion => (this.planificacion = planificacion))
        })
    }
}
