import {
    SaveType,
    TextView,
    LinkView,
    DateView,
    SemaphoreView,
    EntityView,
    BooleanView,
    MultipleLinkView,
    ArrayView,
    AppConfig
} from "@puntaje/shared/core"
import { Establecimientos } from "./establecimientos.service"
import { Lugares } from "./lugares.service"
import { Asociaciones } from "./asociaciones.service"
import { Asociacion } from "./asociaciones.model"
import { EstablecimientoChileView } from "./establecimiento_chile.view"
import { EstablecimientoColombiaView } from "./establecimiento_colombia.view"

import { Usuarios } from "./usuarios.service"
import { EstablecimientoMexicoView } from "./establecimiento_mexico.view"
import { EstablecimientoBrasilView } from "./establecimiento_brasil.view"

declare const config: AppConfig

const elemColorsFun: any = (fechaInicial = null, fechaFinal = null) => {
    let coef = 1.0
    if (fechaInicial && fechaFinal) {
        let days = Math.round((fechaFinal.getTime() - fechaInicial.getTime()) / (1000 * 60 * 60 * 24))

        coef = days / 30
    }

    return {
        cantidad_contactos_efectivos: {
            green: { min: ~~(1 * coef), max: Infinity },
            yellow: { min: 0.1, max: 0.9 },
            red: { min: 0, max: Math.max(0, ~~(1 * coef) - 1) }
        },
        numero_evaluaciones_docentes: {
            green: { min: ~~(6 * coef), max: Infinity },
            yellow: { min: ~~(4 * coef), max: ~~(5 * coef) - 1 },
            red: { min: 0, max: Math.max(0, ~~(3 * coef) - 1) }
        },
        numero_evaluaciones_respondidas_promedio: {
            green: { min: ~~(15 * coef), max: Infinity },
            yellow: { min: ~~(5 * coef), max: ~~(15 * coef) - 1 },
            red: { min: 0, max: ~~(5 * coef) - 1 }
        }
    }
}

export class EstablecimientoView {
    @SaveType(() => Asociacion) public asociaciones: Asociacion[]

    public static fechaInicial = null
    public static fechaFinal = null

    public static get viewParams() {
        if (!this.fechaInicial && !this.fechaFinal) {
            let now = new Date()
            let year = now.getFullYear()
            let month = now.getMonth()
            this.fechaInicial = new Date(month < 2 ? year - 1 : year, 2, 1, 0, 0, 0, 0)
            this.fechaFinal = now
        }

        let elemColors = elemColorsFun(this.fechaInicial, this.fechaFinal)

        let self = this
        let classes = {
            chile: EstablecimientoChileView,
            colombia: EstablecimientoColombiaView,
            mexico: EstablecimientoMexicoView,
            brasil: EstablecimientoBrasilView
        }

        let params = {
            id: {
                label: "ID Estab.",
                type: LinkView,
                sorterEnabled: true,
                tableVisible: true,
                options: { class: Establecimientos, key: "id" }
            },
            asociaciones: {
                label: "ID Asoc.",
                type: MultipleLinkView,
                sorterEnabled: true,
                tableVisible: true,
                options: { class: Asociaciones, key: "asociaciones", modelKey: "id" }
            },
            ["establecimiento_" + config.plataforma.pais]: {
                type: EntityView,
                tableVisible: true,
                class: classes[config.plataforma.pais]
            },
            establecimiento: { label: "Nombre", type: TextView, sorterEnabled: true, tableVisible: true },
            monitores: {
                label: "Monitores",
                type: MultipleLinkView,
                sorterEnabled: true,
                tableVisible: true,
                options: { class: Usuarios, key: "monitores" }
            },
            activo: {
                label: "Activo",
                type: BooleanView,
                tableVisible: true,
                options: { labelTrue: "Activo", labelFalse: "Inactivo" }
            },
            lugar: {
                label: "Comuna",
                type: TextView,
                sorterEnabled: true,
                tableVisible: true,
                options: { class: Lugares, key: "nombre" }
            },
            numero_alumnos_año: {
                label: "Nº estudiantes (anual)",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            },
            numero_docentes_año: {
                label: "Nº docentes (anual)",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            },
            numero_personas: {
                label: "Nº usuarios declarados (acumulado)",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            },
            encargado_nombre: {
                label: "Nombre Encargado",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            },
            colegio_telefono: {
                label: "Teléfono",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            },
            encargado_email: {
                label: "Correo",
                type: TextView,
                sorterEnabled: false,
                tableVisible: true
            }
        }

        this.fechaInicial = this.fechaFinal = null

        return params
    }
}
