import { TextView, LinkView, DateView, ThumbnailView } from "@puntaje/shared/core"
import { BannerZonas } from "./banner_zonas.service"

export class BannerZonaView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: BannerZonas, key: "id" } },
            zona: { label: "Zona", type: TextView, tableVisible: true },
            ancho: { label: "Ancho (px)", type: TextView, tableVisible: true },
            alto: { label: "Alto (px)", type: TextView, tableVisible: true }
        }
    }
}
