<ll-titulo>Contacto #{{ asociacionContactoId }}</ll-titulo>
<ll-toolnavbar>
    <asociacion-contacto-toolnavbar
        [displayButtons]="'edit,all'"
        [asociacionContactoId]="asociacionContactoId"
        [asociacionId]="asociacionId"
    ></asociacion-contacto-toolnavbar>
</ll-toolnavbar>

<asociacion-contacto [asociacionContactoId]="asociacionContactoId"></asociacion-contacto>
