<nav class="navbar navbar-default" *ngIf="displayNavbar">
    <button *ngIf="displayBtnAll" (click)="all()" class="btn btn-sm btn-default pull-left">
        <span class="glyphicon glyphicon-list" aria-hidden="true"></span>
        Establecimientos
    </button>

    <button *ngIf="displayBtnSearch" (click)="search()" class="btn btn-sm btn-default pull-left">
        <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
        Buscar
    </button>

    <button *ngIf="displayBtnNew" (click)="new()" class="btn btn-sm btn-default pull-right">
        <span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span>
        Agregar Establecimiento
    </button>

    <button *ngIf="displayBtnEdit" (click)="edit()" class="btn btn-sm btn-default pull-right margin-right">
        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
        Editar Establecimiento
    </button>
</nav>
