import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "arrayview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>

        <ng-template #viewValue>
            <span *ngFor="let item of value[key]">
                {{ item.toString() }}
                <br />
            </span>
        </ng-template>
    `
})
export class ArrayView extends View {}
