import { Component, OnInit } from "@angular/core"
import { Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { PlanPersonalesBuscadorService } from "@puntaje/puntaje/new-modules/shared"
import { Subscription } from "rxjs"

@Component({
    selector: "plan-personales-buscador",
    templateUrl: "plan-personales-buscador.component.html"
})
export class PlanPersonalesBuscadorComponent implements OnInit {
    planPersonal: any = {}
    generadorInstrumento: any = {}

    sub: Subscription

    open: boolean = false

    filteredUsuarios: Usuario[] = []

    constructor(
        protected planPersonalesBuscadorService: PlanPersonalesBuscadorService,
        protected usuariosService: Usuarios
    ) {}

    ngOnInit() {
        this.sub = this.planPersonalesBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.planPersonalesBuscadorService.search(this.planPersonal, this.generadorInstrumento)
    }

    download() {
        this.planPersonalesBuscadorService.search(this.planPersonal, this.generadorInstrumento, "planificaciones.csv")
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
