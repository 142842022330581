import { BaseForm } from "@puntaje/shared/core"
// import { Usuarios } from './usuarios.service';

export class PremiumUsuarioForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            usuario_id: { type: "hidden", visible: true },
            fecha_inicio: { label: "Inicio Premium", type: "date", visible: true },
            fecha_termino: { label: "Termino Premium", type: "date", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
