import { Component, Input, OnInit, ViewChild } from "@angular/core"

import {
    Asignatura,
    Asignaturas,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    TipoInstrumentos,
    TipoInstrumento
} from "@puntaje/nebulosa/api-services"
import {
    InstrumentoPredefinido,
    InstrumentoPredefinidos,
    Evaluacion,
    Evaluaciones,
    EvaluacionUsuario,
    InstrumentoMensual,
    InstrumentoMensuales
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { FlashMessageService } from "@puntaje/shared/core"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import moment from "moment-timezone"
import { TransformInstrumentoPipe } from "@puntaje/nebulosa/api-services"
import { State, selectAsignaturasById } from "@puntaje/puntaje/store"
import { select, Store } from "@ngrx/store"
import { filter, first } from "rxjs/operators"

@Component({
    selector: "instrumento-mensual-asignatura",
    templateUrl: "instrumento_mensual_asignatura.component.html",
    styleUrls: ["instrumento_mensual_asignatura.component.scss"]
})
export class InstrumentoMensualAsignaturaComponent implements OnInit {
    config: typeof config
    claseAsignatura: string

    @Input() asignatura: Asignatura
    @Input() tipoInstrumento: string
    @Input() month: number
    @Input() year: number

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    instrumentosPredefinidos: InstrumentoPredefinido[]
    instrumentosMensuales: InstrumentoMensual[]
    instrumento: InstrumentoPredefinido
    nombreInstrumento: string
    tipoInstrumentoId: number
    currentInstrumentoMensual: InstrumentoMensual
    errors: string[]

    constructor(
        private instrumentosPredefinidosService: InstrumentoPredefinidos,
        private generadoresService: GeneradorInstrumentos,
        private instrumentoMensualService: InstrumentoMensuales,
        private evaluacionesService: Evaluaciones,
        private tipoInstrumentoService: TipoInstrumentos,
        private flashMessageService: FlashMessageService,
        private transformInstrumentoPipe: TransformInstrumentoPipe,
        private store: Store<State>
    ) {
        this.config = config
    }

    async ngOnInit() {
        const asignaturasById = await this.store
            .pipe(
                select(selectAsignaturasById),
                filter(x => !!x),
                first()
            )
            .toPromise()

        this.claseAsignatura = asignaturasById[this.asignatura.id].confByEvaluacionTipo[this.tipoInstrumento].clase
        const predefinidosQuery = {
            instrumento_predefinido: {
                asignatura_id: this.asignatura.id
            },
            evaluacion_tipo: {
                evaluacion_tipo: this.tipoInstrumento
            }
        }

        const mensualesQuery = {
            instrumento_mensual: {
                month: this.month,
                year: this.year,
                asignatura_id: this.asignatura.id
            },
            sort_by: "created_at",
            order: "desc"
        }
        this.tipoInstrumentoService
            .where({ tipo_instrumento: { tipo_instrumento: this.tipoInstrumento } })
            .then((tipo: TipoInstrumento[]) => {
                mensualesQuery.instrumento_mensual["tipo_instrumento_id"] = tipo[0].id
                this.tipoInstrumentoId = tipo[0].id
                this.instrumentosPredefinidosService
                    .where(predefinidosQuery)
                    .then((instrumentosPredefinidos: InstrumentoPredefinido[]) => {
                        this.instrumentosPredefinidos = instrumentosPredefinidos
                        this.instrumentoMensualService
                            .where(mensualesQuery)
                            .then((instrumentosMensuales: InstrumentoMensual[]) => {
                                this.instrumentosMensuales = instrumentosMensuales
                                if (instrumentosMensuales.length > 0) {
                                    this.currentInstrumentoMensual = instrumentosMensuales[0]
                                }
                                this.loadingLayout.ready()
                            })
                    })
            })
    }

    hayInstrumentos() {
        return this.instrumentosPredefinidos && this.instrumentosPredefinidos.length > 0
    }

    asignarInstrumento() {
        if (!this.loadingLayout.loading && this.canAsignar()) {
            this.loadingLayout.standby()
            const instrumentoMensual = new InstrumentoMensual()
            instrumentoMensual.instrumento_id = this.instrumento.instrumento_id
            instrumentoMensual.nombre = this.nombreInstrumento
            instrumentoMensual.month = this.month
            instrumentoMensual.year = this.year
            instrumentoMensual.tipo_instrumento_id = this.tipoInstrumentoId
            instrumentoMensual.asignatura_id = this.asignatura.id

            const date = moment([this.year, this.month, 1]).tz(moment.tz.guess())
            const inicio = date.startOf("month").format("YYYY-MM-DD HH:mm")
            const final = date.endOf("month").format("YYYY-MM-DD HH:mm")

            const evaluacion = new Evaluacion()
            evaluacion.evaluacion = this.nombreInstrumento
            evaluacion.fecha_inicio = inicio
            evaluacion.fecha_termino = final
            evaluacion.fecha_mostrar_respuestas = final
            evaluacion.instrumento_id = this.instrumento.instrumento_id

            const evaluacionUsuario = new EvaluacionUsuario()
            evaluacionUsuario.receptor_type = "Usuario"
            evaluacionUsuario.receptor_id = null
            evaluacion.evaluacion_usuarios = [evaluacionUsuario]

            this.generadoresService
                .find(this.instrumento.instrumento.generador_instrumento_id, { with_tipo_instrumento: 1 })
                .then((generador: GeneradorInstrumento) => {
                    ;(evaluacion as any).evaluacion_tipo = generador.tipo_instrumento.tipo_instrumento
                    instrumentoMensual.tipo_instrumento_id = generador.tipo_instrumento_id
                    this.evaluacionesService.save(evaluacion).then((ev: Evaluacion) => {
                        instrumentoMensual.evaluacion_usuario_id = ev.evaluacion_usuarios[0].id
                        this.instrumentoMensualService
                            .save(instrumentoMensual)
                            .then((instrumento: InstrumentoMensual) => {
                                this.currentInstrumentoMensual = instrumento
                                this.loadingLayout.ready()
                            })
                    })
                })
        }
    }

    canAsignar() {
        let ret = true
        this.errors = []
        if (this.nombreInstrumento === undefined || this.nombreInstrumento === null || this.nombreInstrumento === "") {
            this.errors.push("Debe ingresar un nombre válido. ")
            ret = false
        }
        if (this.instrumento === undefined || this.instrumento === null) {
            this.errors.push(
                "Debe seleccionar un " + this.transformInstrumentoPipe.transform(this.tipoInstrumento, "singular")
            )
            ret = false
        }
        if (
            this.instrumento &&
            this.currentInstrumentoMensual &&
            this.currentInstrumentoMensual.instrumento_id === this.instrumento.instrumento_id
        ) {
            this.errors.push(
                "El " + this.transformInstrumentoPipe.transform(this.tipoInstrumento, "singular") + " ya esta asignado"
            )
            ret = false
        }
        return ret
    }
}
