import { BaseForm, Validations } from "@puntaje/shared/core"

export class EstablecimientosColombiaForm extends BaseForm {
    companyName: string = ""

    public static get formParams(): any {
        return {
            establecimiento_id: { type: "hidden", visible: true },
            icfes_id: { label: "Icfes ID", type: "text", visible: true }
        }
    }
}
