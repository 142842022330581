import { StreamingAdminComponent, MaterialStreamingComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const streamingRoutes: Routes = [
    {
        path: "streaming",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: StreamingAdminComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    { path: "material_streaming", component: MaterialStreamingComponent, canActivate: [TokenGuard, AuthGuard] }
]

export const streamingRouting = RouterModule.forChild(streamingRoutes)
