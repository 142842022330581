import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from "@angular/core"
import {
    PlanPersonal,
    PlanPersonales,
    PlanPersonalPredefinidos,
    PlanPersonalPredefinido
} from "@puntaje/puntaje/api-services"
import { PlanPersonalView } from "@puntaje/puntaje/api-services"
import { PaginatorComponent, AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { PlanPersonalesBuscadorService } from "@puntaje/puntaje/new-modules/shared"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "lista-planificaciones",
    templateUrl: "./lista-planificaciones.component.html",
    styleUrls: ["./lista-planificaciones.component.scss"]
})
export class ListaPlanificacionesComponent implements OnInit, OnDestroy {
    @Input() mios: boolean
    @Input() planPersonalTipo: string | string[]
    planificaciones: PlanPersonal[]
    params = PlanPersonalView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent)
    paginator: PaginatorComponent
    planClaseId: number
    sub: Subscription
    searchParams: any
    @Input() enableOrderAsc?: boolean
    @Input() notProfesor?: boolean = config.planificaciones?.hidePlanificacionFromProfesorInAdmin || false

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected router: Router,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService,
        protected planPersonalPredefinidosService: PlanPersonalPredefinidos,
        protected planPersonalesBuscadorService: PlanPersonalesBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: {
                function: this.view.bind(this),
                text: "Ver",
                callModal: false,
                glyphicon: "glyphicon-search"
            },
            edit: {
                function: this.edit.bind(this),
                text: "Editar",
                glyphicon: "glyphicon-pencil"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            activate: {
                function: this.activar.bind(this),
                text: "Activar",
                glyphicon: "glyphicon-pencil",
                showIf: planPersonal => !planPersonal.plan_personal_predefinido
            },
            deactivate: {
                function: this.desactivar.bind(this),
                text: "Desactivar",
                glyphicon: "glyphicon-pencil",
                showIf: planPersonal => planPersonal.plan_personal_predefinido
            },
            vistaPreviaProfesor: {
                function: this.showVistaPrevia.bind(this),
                text: "Vista Previa Profesor",
                glyphicon: "glyphicon-pencil",
                showIf: planPersonal => planPersonal.plan_personal_tipo.tipo == "plan de clase"
            },
            vistaPreviaAlumno: {
                function: this.showVistaPreviaAlumno.bind(this),
                text: "Vista Previa Alumno",
                glyphicon: "glyphicon-pencil",
                showIf: planPersonal => planPersonal.plan_personal_tipo.tipo == "plan de clase"
            }
        }

        this.sub = this.planPersonalesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        let params = {
            page,
            per: count,
            include: "[plan_personal_tipo,generador_instrumento,plan_personal_predefinido]",
            plan_personal_tipo: {
                tipo: this.planPersonalTipo || [
                    "planificacion",
                    "plan de clase",
                    "plan de clase alumno",
                    "plan de clase profesor"
                ]
            }
        }
        if (this.enableOrderAsc) {
            params["order"] = "asc"
        }
        if (this.notProfesor) {
            params["not_profesor"] = true
        }
        params = Object.assign({}, params, this.searchParams)

        return this.planPersonalesService.where(params).then((response, total) => {
            this.planificaciones = response
            this.loadingLayout.ready()

            return total
        })
    }

    nuevo() {
        this.router.navigate(["planificaciones/new"])
    }

    view(planPersonal: PlanPersonal) {
        this.router.navigate(["planificaciones/" + planPersonal.id])
    }

    edit(planPersonal: PlanPersonal) {
        this.router.navigate(["planificaciones/" + planPersonal.id + "/edit"])
    }

    delete(id: number) {
        this.loadingLayout.standby()
        this.planPersonalesService.remove(id).then(_ => {
            this.paginator.reload()
        })
    }

    activar(planPersonal: PlanPersonal) {
        this.loadingLayout.standby()
        let nuevo = new PlanPersonalPredefinido()
        nuevo.plan_personal_id = planPersonal.id
        this.planPersonalPredefinidosService.save(nuevo).then(_ => {
            this.paginator.reload()
        })
    }

    desactivar(planPersonal: PlanPersonal) {
        this.loadingLayout.standby()
        this.planPersonalPredefinidosService.remove(planPersonal.plan_personal_predefinido.id).then(_ => {
            this.paginator.reload()
        })
    }

    showModalDelete(planPersonal: PlanPersonal) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta planificación?")
        this.simpleModalService.setModalCallback(() => this.delete(planPersonal.id))
    }

    showVistaPrevia(planPersonal: PlanPersonal) {
        this.planClaseId = planPersonal.id
        this.router.navigate(["vista_previa/" + planPersonal.id])
    }

    showVistaPreviaAlumno(planPersonal: PlanPersonal) {
        this.planClaseId = planPersonal.id
        this.router.navigate(["vista_previa_alumno/" + planPersonal.id])
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
