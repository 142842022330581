import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Asignatura } from "@puntaje/nebulosa/api-services"
import {
    EvaluacionInstancia,
    Evaluacion,
    GrupoUsuario,
    Establecimiento,
    Lugar,
    Usuario
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "estadisticas-usuario-portada",
    templateUrl: "./estadisticas_usuario_portada.component.html",
    styleUrls: ["./estadisticas_portada.component.scss"]
})
export class EstadisticasUsuarioPortadaComponent implements OnInit {
    @Input() evaluacionTipo: string
    @Input() establecimiento: Establecimiento
    @Input() usuarios: Usuario[]
    @Input() lugar: Lugar
    @Input() asignatura: Asignatura

    @Input() estadisticas: any
    numeroEvaluacionesInstancias: number
    numeroEstudiantes: number
    promedio: number
    minimo: number
    maximo: number

    lugarTipo: string = config.plataforma.lugarTipo
    logosrc: string = ""

    constructor() {}

    ngOnInit() {
        this.logosrc = config.app.assets.logoMedium

        this.numeroEstudiantes = this.usuarios.length
        this.numeroEvaluacionesInstancias = this.estadisticas.reduce((acc, e) => {
            return acc + e.numero_evaluaciones
        }, 0)

        let sumaEvaluaciones = this.estadisticas.reduce((acc, e) => {
            return acc + e.promedio * e.numero_evaluaciones
        }, 0)
        this.promedio =
            sumaEvaluaciones / (this.numeroEvaluacionesInstancias + (this.numeroEvaluacionesInstancias == 0 ? 1 : 0))
        this.maximo = this.estadisticas.reduce((acc, e) => {
            if (e.maximo > acc) return e.maximo
            else return acc
        }, 0)
        this.minimo = this.estadisticas.reduce((acc, e) => {
            if (e.minimo < acc) return e.minimo
            else return acc
        }, this.maximo)
    }
}
