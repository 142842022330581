import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import {
    AsignaturaEvaluacionTipo,
    AsignaturaEvaluacionTipoEstablecimiento,
    AsignaturaEvaluacionTipoEstablecimientos,
    AsignaturaEvaluacionTipos,
    AsignaturaEvaluacionTiposView
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "lista-asignatura-evaluacion-tipos",
    templateUrl: "./lista-asignatura-evaluacion-tipos.component.html",
    styleUrls: ["./lista-asignatura-evaluacion-tipos.component.scss"]
})
export class ListaAsignaturaEvaluacionTiposComponent implements OnInit {
    @Input() disableConfigure: boolean = false
    @Input() disableDelete: boolean = false
    @Input() disablePredefinido: boolean = false
    tableButtonsArray: any
    asignaturas: { [id: number]: Asignatura } = {}
    asignaturaEvaluacionTipos: AsignaturaEvaluacionTipo[]
    asignaturaEvaluacionTipoEstablecimientos: AsignaturaEvaluacionTipoEstablecimiento[]
    params = AsignaturaEvaluacionTiposView.viewParams
    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent)
    paginator: PaginatorComponent
    asignaturasPromise: Promise<void>

    @Input() establecimientoId: number

    constructor(
        protected asignaturaEvaluacionTiposServices: AsignaturaEvaluacionTipos,
        protected asignaturaEvaluacionTipoEstablecimientosServices: AsignaturaEvaluacionTipoEstablecimientos,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected asignaturasService: Asignaturas
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            configurar: {
                function: this.configurar.bind(this),
                text: "Configurar",
                callModal: false,
                glyphicon: "glyphicon-search",
                showIf: _ => !this.disableConfigure
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash",
                showIf: _ => !this.disableDelete
            },
            marcarPredefinido: {
                function: this.makePredefinido.bind(this),
                text: "Marcar Predefinido",
                glyphicon: "glyphicon-tag",
                showIf: asignaturaEvaluacionTipos => !asignaturaEvaluacionTipos.predefinido && !this.disablePredefinido
            },
            desmarcarPredefinido: {
                function: this.makePredefinido.bind(this),
                text: "Desmarcar Predefinido",
                glyphicon: "glyphicon-tag",
                showIf: asignaturaEvaluacionTipos => asignaturaEvaluacionTipos.predefinido && !this.disablePredefinido
            },
            activate: {
                function: this.activar.bind(this),
                text: "Activar",
                glyphicon: "glyphicon-pencil",
                showIf: asignaturaEvaluacionTipo =>
                    this.establecimientoId && !asignaturaEvaluacionTipo.asignatura_evaluacion_tipo_establecimiento
            },
            deactivate: {
                function: this.desactivar.bind(this),
                text: "Desactivar",
                glyphicon: "glyphicon-pencil",
                showIf: asignaturaEvaluacionTipo =>
                    this.establecimientoId && asignaturaEvaluacionTipo.asignatura_evaluacion_tipo_establecimiento
            }
        }

        this.asignaturasPromise = this.asignaturasService.where().then((response: Asignatura[]) => {
            response.forEach((a, i) => {
                this.asignaturas[a.id] = a
            })
        })
    }

    activar(asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) {
        this.loadingLayout.standby()
        let nuevo = new AsignaturaEvaluacionTipoEstablecimiento()
        nuevo.asignatura_evaluacion_tipo_id = asignaturaEvaluacionTipo.id
        nuevo.establecimiento_id = this.establecimientoId
        this.asignaturaEvaluacionTipoEstablecimientosServices.save(nuevo).then(_ => {
            this.paginator.reload()
        })
    }

    desactivar(asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) {
        this.loadingLayout.standby()
        this.asignaturaEvaluacionTipoEstablecimientosServices
            .remove(asignaturaEvaluacionTipo.asignatura_evaluacion_tipo_establecimiento.id)
            .then(_ => {
                this.paginator.reload()
            })
    }

    configurar(asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) {
        this.router.navigate(["asignatura_evaluacion_tipos" + "/" + asignaturaEvaluacionTipo.id + "/edit"])
    }

    showAll = (page: number = 1, count: number = 20) => {
        let params = {
            page,
            per: count,
            render_options: {
                methods: ["nombre_evaluacion_tipo"]
            }
        }

        if (this.establecimientoId !== undefined && this.establecimientoId !== null) {
            let parametros = {
                asignatura_evaluacion_tipo_establecimiento: {
                    establecimiento_id: this.establecimientoId
                }
            }
            this.asignaturaEvaluacionTipoEstablecimientosServices.where(parametros).then(response => {
                this.asignaturaEvaluacionTipoEstablecimientos = response
            })
        }

        return this.asignaturaEvaluacionTiposServices.where(params).then(async (response, total) => {
            this.asignaturaEvaluacionTipos = response
            await this.asignaturasPromise

            this.asignaturaEvaluacionTipos = this.asignaturaEvaluacionTipos.map(asignaturaEvaluacionTipo => {
                asignaturaEvaluacionTipo.asignatura = this.asignaturas[asignaturaEvaluacionTipo.asignatura_id]
                if (
                    this.establecimientoId !== undefined &&
                    this.establecimientoId !== null &&
                    this.asignaturaEvaluacionTipoEstablecimientos !== undefined
                ) {
                    asignaturaEvaluacionTipo.asignatura_evaluacion_tipo_establecimiento =
                        this.asignaturaEvaluacionTipoEstablecimientos.find(
                            asignaturaEvaluacionTipoEstablecimiento =>
                                asignaturaEvaluacionTipoEstablecimiento.asignatura_evaluacion_tipo_id ==
                                asignaturaEvaluacionTipo.id
                        )
                }

                return asignaturaEvaluacionTipo
            })

            this.loadingLayout.ready()

            return total
        })
    }

    delete(id: number) {
        this.asignaturaEvaluacionTiposServices.remove(id).then(response => {
            location.reload()
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    showModalDelete(asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta asignatura por evaluación?")
        this.simpleModalService.setModalCallback(() => this.delete(asignaturaEvaluacionTipo.id))
    }

    makePredefinido(asignaturaEvaluacionTipo: AsignaturaEvaluacionTipo) {
        this.loadingLayout.standby()

        asignaturaEvaluacionTipo.predefinido = !asignaturaEvaluacionTipo.predefinido

        return this.asignaturaEvaluacionTiposServices
            .update(asignaturaEvaluacionTipo.id, asignaturaEvaluacionTipo)
            .then(_ => {
                this.paginator.reload()
            })
    }
}
