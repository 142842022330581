import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./crear-evaluacion-recomendada.component.html",
    styleUrls: ["./crear-evaluacion-recomendada.component.scss"]
})
export class CrearEvaluacionRecomendadaComponent implements OnInit {
    subData: Subscription

    showEvaluacionMultiple = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.evaluaciones_recomendadas.new")
        this.subData = this.route.data.subscribe(data => {
            this.showEvaluacionMultiple = data.showEvaluacionMultiple ?? this.showEvaluacionMultiple
        })
    }
}
