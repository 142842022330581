import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class PlanPersonalContenidoForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            plan_personal_sesion_id: {
                label: "plan_personal_sesion_id",
                type: "hidden",
                visible: true,
                validations: [Validators.required]
            },
            tipo: {
                label: "Tipo",
                type: "select",
                visible: true,
                options: [
                    {
                        id: "Evaluacion",
                        toString: function () {
                            return "Evaluacion"
                        }
                    },
                    {
                        id: "Material",
                        toString: function () {
                            return "Material"
                        }
                    }
                ],
                validations: [Validators.required]
            },
            tipo_id: {
                label: "ID material o ID evaluación",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            orden: { label: "orden", type: "number", visible: true, validations: [Validators.required] }
        }
    }
}
