<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="push-bot">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view
                    *ngIf="!params[key].showHidden"
                    [params]="params[key]"
                    [value]="noticia"
                    [key]="key"
                ></model-simple-view>
            </ng-container>
        </div>
    </div>
</loading-layout>
