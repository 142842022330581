import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { BannerZona, BannerZonas, BannerZonaView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerZona",
    templateUrl: "banner_zona.component.html"
})
export class BannerZonaComponent implements OnInit {
    @Input() bannerZonaId: number

    bannerZona: BannerZona
    params = BannerZonaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private bannerZonasService: BannerZonas) {}

    ngOnInit() {
        this.bannerZonasService.find(this.bannerZonaId).then((response: BannerZona) => {
            this.bannerZona = response
            this.loadingLayout.ready()
        })
    }
}
