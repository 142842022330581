import { Component, Injector, Input } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "multiple-link-view",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <ng-container *ngFor="let link of links">
                <a [routerLink]="link.url">{{ link.text }}</a>
                <br />
            </ng-container>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class MultipleLinkView extends View {
    links: any[] = []

    constructor(private injector: Injector) {
        super()
    }

    ngOnInit() {
        if (this.options !== undefined && this.options.class !== undefined) {
            let array = this.value[this.options.key]
            if (array !== undefined && array.length > 0) {
                let urlLink, text, service
                array.forEach(model => {
                    service = this.injector.get(this.options.class)
                    urlLink = "/" + service.getTableName() + "/" + model["id"]

                    if (this.options.modelKey !== undefined && this.value[this.key]) {
                        let modelKey = this.options.modelKey
                        text = model[modelKey]
                    } else if (model[service.getSingularTableName()]) {
                        text = model[service.getSingularTableName()]
                    } else {
                        text = model.toString()
                    }
                    this.links.push({ url: urlLink, text: text })
                })
            }
        }
    }
}
