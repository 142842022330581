import { ChangeDetectorRef, Component, Injector, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { CuentasNewForm, RolUsuario, Usuario, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "cuentas-new",
    templateUrl: "cuentas_admin.form.component.html"
})
export class CuentasNewComponent implements OnInit {
    usuario: Usuario = new Usuario()
    params = CuentasNewForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar usuario"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    isNew: boolean = true

    isPlus: boolean = false

    constructor(
        protected usuariosService: Usuarios,
        protected injector: Injector,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.usuario.rol_usuarios = []
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        this.form = CuentasNewForm.getForm(this.usuario, null, this.injector)
        this.loadingLayout.ready()
    }

    save() {
        CuentasNewForm.markFormControlsAsTouched(this.form)

        let pass: string
        if (this.usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()]) {
            pass = this.usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()].substring(0, 6)
        } else {
            pass = this.usuario["usuario_" + this.pais][this.idPais[1].toLowerCase()].substring(0, 6)
        }

        this.usuario.password = pass
        this.usuario.password_confirmation = pass
        this.usuario.nomina = true
        this.usuario.manual_reset_password = true

        if (this.form.valid) {
            this.usuariosService.save(this.usuario).then(response => {
                this.router.navigate(["cuentas"])
            })
        }
    }

    addRol() {
        this.usuario.rol_usuarios.push(new RolUsuario())
    }

    clear() {
        setTimeout(() => {
            this.usuario = new Usuario()
            CuentasNewForm.markFormControlsAsPristine(this.form)
            CuentasNewForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    onPlusChange(isPlus: boolean) {
        this.isPlus = isPlus

        if (isPlus) {
            const today = new Date()
            const endOfYear = new Date(today.getFullYear(), 11, 31)
            this.usuario.fecha_inicio_plus = today.toISOString().slice(0, 10)
            this.usuario.fecha_termino_plus = endOfYear.toISOString().slice(0, 10)
        } else {
            this.usuario.fecha_inicio_plus = null
            this.usuario.fecha_termino_plus = null
        }

        this.cdr.detectChanges()
    }
}
