import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { Banner, Banners, BannerView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "banner",
    templateUrl: "banner.component.html"
})
export class BannerComponent implements OnInit {
    @Input() bannerId: number

    banner: Banner
    params = BannerView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private bannersService: Banners) {}

    ngOnInit() {
        this.bannersService.find(this.bannerId).then((response: Banner) => {
            this.banner = response
            this.loadingLayout.ready()
        })
    }
}
