<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input
                type="text"
                [(ngModel)]="searchParams.instrumento_predefinido.id"
                (ngModelChange)="deleteIfUndefined(searchParams.instrumento_predefinido, 'id')"
                name="id"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label>Generador instrumento</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.instrumento_predefinido.generador_instrumento_id"
                (ngModelChange)="deleteIfUndefined(searchParams.instrumento_predefinido, 'generador_instrumento_id')"
                name="generador_instrumento"
            >
                <option [ngValue]="undefined">Todos</option>
                <option
                    *ngFor="let generador_instrumento of generador_instrumentos | keyvalue"
                    [ngValue]="generador_instrumento.value['id']"
                >
                    {{ generador_instrumento.value["generador_instrumento"] }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label>Asignatura</label>
            <select
                class="form-control"
                [(ngModel)]="searchParams.instrumento_predefinido.asignatura_id"
                (ngModelChange)="deleteIfUndefined(searchParams.instrumento_predefinido, 'asignatura_id')"
                name="asignatura"
            >
                <option [ngValue]="undefined">Todas</option>
                <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura.id">
                    {{ asignatura }}
                </option>
            </select>
        </div>

        <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
    </div>
</form>
