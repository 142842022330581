import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { SessionService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "establecimientos.component.html"
})
export class EstablecimientosComponent implements OnInit {
    displayButtons: string
    lugarTipo: string
    subData: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.establecimientos.establecimientos.list")
        this.subData = this.route.data.subscribe(data => {
            this.lugarTipo = data.lugarTipo ?? this.lugarTipo
        })

        if (
            this.sessionService.getRoles().includes("Administrador") ||
            this.sessionService.getRoles().includes("SuperAdministrador")
        )
            this.displayButtons = "new,search"
        else this.displayButtons = "search"
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
