import { TextView, LinkView, DateView, MultipleArtezonaView } from "@puntaje/shared/core"
import { Banners } from "./banners.service"
import { BannerTipos } from "./banner_tipos.service"

export class BannerView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Banners, key: "id" } },
            nombre: { label: "Nombre", type: LinkView, tableVisible: true, options: { class: Banners, key: "id" } },
            banner_tipo: {
                label: "Tipo de Banner",
                type: TextView,
                tableVisible: true
            },
            image_banners: {
                label: "Imágenes",
                type: MultipleArtezonaView,
                tableVisible: true,
                options: { imageKey: "image_banners", iframeKey: "iframe_banners" }
            },
            fecha_inicial: { label: "Fecha Inicial", type: DateView, tableVisible: true },
            fecha_final: { label: "Fecha Final", type: DateView, tableVisible: true },
            clicks: { label: "Clicks", type: TextView, tableVisible: true },
            impresiones: { label: "Impresiones", type: TextView, tableVisible: true },
            max_clicks: { label: "Max Clicks", type: TextView, tableVisible: true },
            max_impresiones: { label: "Max Impresiones", type: TextView, tableVisible: true }
        }
    }
}
