import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { GrupoUsuario, PlanPersonalSesion, PlanPersonalSesiones, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-clase-sesion-avance-por-alumno-admin",
    templateUrl: "./plan-clase-sesion-avance-por-alumno-admin.component.html",
    styleUrls: ["./plan-clase-sesion-avance-por-alumno-admin.component.scss"]
})
export class PlanClaseSesionAvancePorAlumnoAdminComponent {
    avancePorAlumno = []
    totalAlumnos: number

    private _planPersonalSesionId: number
    private _conjunto: any

    @Input()
    get planPersonalSesionId(): number {
        return this._planPersonalSesionId
    }
    set planPersonalSesionId(planPersonalSesionId: number) {
        this._planPersonalSesionId = planPersonalSesionId
    }

    @Input()
    get conjunto() {
        return this._conjunto
    }
    set conjunto(conjunto: any) {
        this._conjunto = conjunto
        this.totalAlumnos = Object.keys(conjunto).length
    }

    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(protected planPersonalSesionesService: PlanPersonalSesiones, protected usuariosService: Usuarios) {}

    getAvancePorAlumnos(page: number = 1, per: number = 10) {
        if (this._conjunto && this._planPersonalSesionId && this.totalAlumnos) {
            return this.planPersonalSesionesService
                .avancePorAlumnoConjunto(this._planPersonalSesionId, this._conjunto, page, per)
                .then((avance: any) => {
                    this.avancePorAlumno = avance
                    return this.totalAlumnos
                })
        } else return new Promise<number>((resolve, reject) => resolve(this.totalAlumnos || 0))
    }
}
