import { Component, Input, HostBinding } from "@angular/core"

@Component({
    selector: "cui-layout-with-sidemenu,[cui-layout-with-sidemenu]",
    template: `
        <div class="header"><ng-content select="[cui-layout-slot=header]"></ng-content></div>
        <div class="body" select="[cui-layout-slot=body]"></div>
        <div class="sidemenu" select="[cui-layout-slot=sidemenu]"></div>
        <div class="footer" select="[cui-layout-slot=footer]"></div>
    `,
    styleUrls: ["cui-layout-with-sidemenu.component.scss"]
})
export class CUILayoutWithSidemenuComponent {
    constructor() {}
}
