import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { PlanEstudioAplicado, PlanEstudioAplicados, PlanEstudioAplicadoView } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-estudio-aplicados",
    templateUrl: "./plan-estudio-aplicados.component.html",
    styleUrls: ["./plan-estudio-aplicados.component.scss"]
})
export class PlanEstudioAplicadosComponent implements OnInit {
    @Input() listRoute: string
    params = PlanEstudioAplicadoView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    tableButtons: any

    showConfigurar: boolean = false
    planEstudioAplicados: PlanEstudioAplicado[]

    constructor(
        protected planEstudioAplicadosService: PlanEstudioAplicados,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtons = (pe: PlanEstudioAplicado) => {
            let disable = {
                function: this.toggleEstado.bind(this),
                text: "Deshabilitar",
                callModal: false,
                glyphicon: "glyphicon-ban-circle"
            }
            let enable = {
                function: this.toggleEstado.bind(this),
                text: "Habilitar",
                callModal: false,
                glyphicon: "glyphicon-check"
            }

            let toggleEstado = pe.estado == 1 ? disable : enable

            // return {
            //     view:          {function: this.view.bind(this), text: 'Ver', callModal: false, glyphicon:"glyphicon-search"},
            //     delete:        {function: this.showModalDelete.bind(this), text: 'Borrar', callModal: true, glyphicon:"glyphicon-trash"},
            //     toggleEstado:  toggleEstado
            // };

            return {
                view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
                toggleEstado: toggleEstado
            }
        }
        //this.showAll();
    }

    showAll(page: number = 1, count: number = 20) {
        return this.planEstudioAplicadosService
            .where({ page: page, per: count })
            .then((pes: PlanEstudioAplicado[], total: number) => {
                this.planEstudioAplicados = pes
                this.loadingLayout.ready()
                return total
            })
    }

    view(planEstudio: PlanEstudioAplicado) {
        this.router.navigate([this.listRoute + "/" + planEstudio.id])
    }

    toggleEstado(planEstudioAplicado: PlanEstudioAplicado) {
        let peaObj = new PlanEstudioAplicado(true)
        peaObj.estado = planEstudioAplicado.estado == 1 ? 0 : 1

        this.planEstudioAplicadosService.update(planEstudioAplicado.id, peaObj).then((pea: PlanEstudioAplicado) => {
            planEstudioAplicado.estado = pea.estado
        })
    }

    delete(id: number) {
        this.planEstudioAplicadosService.remove(id).then(response => {
            location.reload()
            //this.router.navigate([this.listRoute]);
            //this.paginator.changePage(this.paginator.currentPage);
        })
    }

    showModalDelete(planEstudio: PlanEstudioAplicado) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que deshabilitar eliminar este plan?")
        this.simpleModalService.setModalCallback(() => this.delete(planEstudio.id))
    }
}
