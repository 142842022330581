import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class PlanPersonalSesionForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            plan_personal_sesion: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            fecha_inicial: {
                label: "Fecha Inicial",
                type: "datepicker",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            fecha_final: { label: "Fecha Final", type: "datepicker", visible: true, validations: [Validators.required] }
        }
    }
}
