import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"

import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"

import { PlanEstudiosComponent } from "./plan_estudios.component"
import { PlanEstudioComponent } from "./plan_estudio.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanesEstudiosComponent } from "./planes_estudios.component"
import { PlanEstudioSesionesComponent } from "./plan_estudio_sesiones.component"
import { PlanEstudioSesionComponent } from "./plan_estudio_sesion.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        PlanEstudiosModule,
        ModelViewModule,
        CUILayoutsModule,
        BannersModule,
        CommonsLayoutsModule,
        SharedModule
    ],
    declarations: [
        PlanEstudiosComponent,
        PlanEstudioComponent,
        PlanesEstudiosComponent,
        PlanEstudioSesionesComponent,
        PlanEstudioSesionComponent
    ],
    exports: [
        PlanEstudiosComponent,
        PlanEstudioComponent,
        PlanesEstudiosComponent,
        PlanEstudioSesionesComponent,
        PlanEstudioSesionComponent
    ]
})
export class PuntajePlanEstudiosModule {}
