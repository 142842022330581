<div class="content-principal">
  <div class="descripcion">
    <ng-container
      *ngIf="config.plataforma.name == 'Aprendolibre' && config.app.name == 'profesores'; else elseDescripcion">
      <label>{{ planPersonalSesion?.plan_personal_sesion }}</label>
    </ng-container>
    <ng-template #elseDescripcion>
      <label>{{ nombreSesion | capitalize }} {{ index + 1 }}</label>
      <p>{{ planPersonalSesion?.plan_personal_sesion }}</p>
    </ng-template>
  </div>
  <div *ngIf="planPersonalSesion?.fecha_inicial" class="visible-desde">
    <label>{{ 'estudiar.plan_de_clases.visible_desde' | t }}</label>
    <p>{{ planPersonalSesion?.fecha_inicial | date: "dd-MM-yyyy h:mm a" }}</p>
  </div>

  <ng-container *ngIf="avanceConjunto">
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <div class="content-label">
          <label class="label-barra">{{ 'estudiar.plan_de_clases.avance' | t }}:</label>
          <label class="label-barra">
            {{ avanceConjunto.avance + "/" + avanceConjunto.total_alumnos }} {{
            'estudiar.plan_de_clases.estudiantes' | t }}
          </label>
        </div>
        <div class="barra-wrap">
          <div class="barra-avance" [class.fullwidth]="startAnimValues">
            <div class="avance" [style.width]="
                                    (avanceConjunto.avance * 100) / avanceConjunto.total_alumnos + '%'
                                "></div>
          </div>
        </div>
        <div class="porcentaje-centro">
          <p>
            {{
            avanceConjunto.total_alumnos === 0
            ? 0
            : ((avanceConjunto.avance * 100) / avanceConjunto.total_alumnos
            | roundfloat)
            }}%
          </p>
        </div>
      </div>

      <div class="col-xs-12 col-md-4">
        <div class="content-label">
          <label class="label-barra">{{ 'estudiar.plan_de_clases.promedio_logro' | t }}:</label>
          <label class="label-barra">{{ avanceConjunto.porcentaje_logro_promedio }}%</label>
        </div>
        <div class="barra-wrap">
          <div class="barra-avance" [class.fullwidth]="startAnimValues">
            <div class="avance" [style.width]="avanceConjunto.porcentaje_logro_promedio + '%'"></div>
          </div>
        </div>
        <div class="porcentaje-centro">
          <p>{{ avanceConjunto.porcentaje_logro_promedio | roundfloat }}%</p>
        </div>
      </div>

      <div class="col-xs-12 col-md-4">
        <div class="content-label">
          <label class="label-barra">{{ 'estudiar.plan_de_clases.promedio_tiempo' | t }}:</label>
        </div>
        <div class="content-label">
          <label class="tiempo-promedio">{{ avanceConjunto.tiempo_promedio }}</label>
        </div>
      </div>
    </div>
    <div>
      <cui-button buttonType="primary" (click)="showAvancePorAlumno = !showAvancePorAlumno">
        {{ showAvancePorAlumno ? "Ocultar avance por alumno" : "Mostar avance por alumno" }}
      </cui-button>
    </div>
    <br />
    <cui-tabs *ngIf="showAvancePorAlumno" justifyContent="flex-start">
      <cui-tab title="Resumen" (select)="selectTab('resumen')">
        <plan-clase-sesion-avance-por-alumno-admin 
          [planPersonalSesionId]="planPersonalSesion.id"
          [conjunto]="conjunto"></plan-clase-sesion-avance-por-alumno-admin>
      </cui-tab>
      <cui-tab title="Detalle por material/evaluación" (select)="selectTab('detalle')">
        <plan-clase-sesion-detalle-por-contenido-admin 
          [planPersonalSesionId]="planPersonalSesion.id"
          [conjunto]="conjunto"
          [nombre]="nombre"></plan-clase-sesion-detalle-por-contenido-admin>
      </cui-tab>
    </cui-tabs>
  </ng-container>
  <ng-container *ngIf="!avanceConjunto">{{ 'estudiar.plan_de_clases.not_comp' | t }}</ng-container>
</div>