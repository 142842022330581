<form class="row" *ngIf="open">
    <div class="col-md-4">
        <div class="form-group">
            <input
                type="text"
                [(ngModel)]="establecimientoReporte.id"
                name="id"
                class="form-control"
                placeholder="ID"
            />
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <select
                class="form-control"
                [(ngModel)]="establecimientoReporte.tipo_contacto_id"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [ngValue]="undefined">Tipo reporte</option>
                <option *ngFor="let tipo of tTipos" [ngValue]="tipo.value">{{ tipo.TipoType }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <select
                class="form-control"
                [(ngModel)]="establecimientoReporte.resuelto"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [ngValue]="undefined">Estado</option>
                <option *ngFor="let reporte of tResuelto" [ngValue]="reporte.value">{{ reporte.ResueltoType }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label>Fecha entre</label>
            <datepicker-form
                [(ngModel)]="establecimientoReporte.gte.fecha"
                [ngModelOptions]="{ standalone: true }"
            ></datepicker-form>
        </div>
        <cui-button-link [isBlock]="true" class="btn_style" (click)="search()">
            <fa name="search"></fa>
            &nbsp;Buscar
        </cui-button-link>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label>Fecha hasta</label>
            <datepicker-form
                [(ngModel)]="establecimientoReporte.lte.fecha"
                [ngModelOptions]="{ standalone: true }"
            ></datepicker-form>
        </div>
    </div>
</form>
