import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TypeformWidget, TypeformWidgetes, TypeformWidgetView } from "@puntaje/puntaje/api-services"
import { PaginatorComponent } from "@puntaje/shared/core"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Subscription } from "rxjs"
import { TypeformWidgetsBuscadorService } from "./typeform-widgets-buscador/typeform-widgets-buscador.service"

@Component({
    selector: "typeform-widgets",
    templateUrl: "typeform-widgets.component.html"
})
export class TypeformWidgetsComponent implements OnInit, OnDestroy {
    typeformWidgets: TypeformWidget[]
    params = TypeformWidgetView.viewParams
    tableButtonsArray: any
    subSearch: Subscription
    searchParams = { typeform_widget: {} }
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected typeformWidgetsService: TypeformWidgetes,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected typeformWidgetsBuscadorService: TypeformWidgetsBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        const queryParams = this.router?.routerState?.root?.queryParams
        queryParams &&
            queryParams.subscribe(params => {
                let page = +params["page"]
                let count = +params["count"]
                if (isNaN(page)) {
                    page = 1
                }
                if (isNaN(count)) {
                    count = 20
                }
                this.showAll(page, count)
            })

        this.subSearch = this.typeformWidgetsBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        let params: any = {
            page: page,
            per: count,
            raw: 1,
            sort_by: "id",
            order: "desc"
        }

        params = {
            ...params,
            ...this.searchParams,
            typeform_widget: {
                ...this.searchParams.typeform_widget
            },
            render_options: {
                include: {
                    typeform_widget_tipo: null,
                    typeform_widget_tipo_usuario: null,
                    lugar: null,
                    establecimiento: null
                }
            }
        }

        return this.typeformWidgetsService.where(params).then((response: TypeformWidget[], total: number) => {
            this.typeformWidgets = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(typeformWidget: TypeformWidget) {
        this.router.navigate(["typeform_widgets/" + typeformWidget.id])
    }

    edit(typeformWidget: TypeformWidget) {
        this.router.navigate(["typeform_widgets/" + typeformWidget.id + "/edit"])
    }

    delete(id: number) {
        this.router.navigate(["typeform_widgets/" + id + "/delete"])
    }

    showModalDelete(typeformWidget: TypeformWidget) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este Typeform widget?")
        this.simpleModalService.setModalCallback(() => this.delete(typeformWidget.id))
    }

    ngOnDestroy() {
        this.subSearch.unsubscribe()
    }
}
