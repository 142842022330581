import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    InstrumentoPredefinido,
    InstrumentoPredefinidos,
    InstrumentoPredefinidoForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "instrumento-predefinido-edit",
    templateUrl: "instrumento_predefinidos.form.component.html"
})
export class InstrumentoPredefinidosEditComponent implements OnInit {
    @Input() instrumentoPredefinidoId: number

    oInstrumentoPredefinido: InstrumentoPredefinido = new InstrumentoPredefinido()
    instrumentoPredefinido: InstrumentoPredefinido = new InstrumentoPredefinido()
    params = InstrumentoPredefinidoForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected instrumentoPredefinidosService: InstrumentoPredefinidos,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = InstrumentoPredefinidoForm.getForm(this.instrumentoPredefinido)
        this.instrumentoPredefinidosService
            .find(this.instrumentoPredefinidoId)
            .then((response: InstrumentoPredefinido) => {
                this.instrumentoPredefinido = response
                this.oInstrumentoPredefinido = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    save() {
        InstrumentoPredefinidoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (config.instrumentosPredefinidos.zonas.length == 1) {
                this.instrumentoPredefinido.tag = config.instrumentosPredefinidos.zonas[0]
            }
            this.instrumentoPredefinidosService
                .update(this.instrumentoPredefinido.id, this.instrumentoPredefinido)
                .then(response => {
                    this.router.navigate(["instrumento_predefinidos"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.instrumentoPredefinido = this.oInstrumentoPredefinido.clone()
            InstrumentoPredefinidoForm.markFormControlsAsPristine(this.form)
            InstrumentoPredefinidoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
