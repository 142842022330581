import { SaveType, TextView, LinkView, DateView, BooleanView, HtmlView } from "@puntaje/shared/core"
import { Streamings } from "./streamings.service"

export class StreamingView {
    public static get viewParams() {
        let self = this
        return {
            id: { label: "Id", type: LinkView, tableVisible: false, options: { class: Streamings, key: "id" } },
            channel_alias: { label: "Canal", type: TextView, tableVisible: true },
            streaming_enabled: { label: "Streaming Habilitado", type: BooleanView, tableVisible: true },
            material_id: { label: "ID Material Asociado", type: TextView, tableVisible: true }
        }
    }
}
