<div class="cui-accordion {{ size }}" tabindex="0" role="button" aria-expanded="false" [toggleExpandable]="target">
    <p class="cui-accordion-left">
        <ng-container *ngIf="iconPosition == 'left'">
            <ng-container *ngTemplateOutlet="icon"></ng-container>
        </ng-container>
    </p>
    <p class="cui-accordion-title">
        <ng-content select="cui-accordion-title"></ng-content>
    </p>
    <p class="cui-accordion-right">
        <ng-container *ngIf="iconPosition == 'right'">
            <ng-container *ngTemplateOutlet="icon"></ng-container>
        </ng-container>
    </p>
</div>
<div [expandableContent] class="cui-accordion-content" [id]="id" #target [expandOnCreation]="startOpen">
    <div class="{{ boxStyle }}" *ngIf="content">
        <ng-content select="cui-accordion-content"></ng-content>
    </div>
</div>
<ng-template #icon>
    <span class="tog-icon open-icon" [attr.aria-labelledby]="id">
        <ng-content select="cui-accordion-icon-open"></ng-content>
        <fa name="plus" *ngIf="!customOpenIcon && !rotatingIcon"></fa>
    </span>
    <span class="tog-icon close-icon" [attr.aria-labelledby]="id">
        <ng-content select="cui-accordion-icon-close"></ng-content>
        <fa name="minus" *ngIf="!customCloseIcon && !rotatingIcon"></fa>
    </span>
    <span class="tog-icon rotating-icon" [attr.aria-labelledby]="id">
        <ng-content select="cui-accordion-icon-rotating"></ng-content>
        <fa name="chevron-right" *ngIf="!customRotatingIcon && rotatingIcon"></fa>
    </span>
</ng-template>
