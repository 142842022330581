import { Component } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "entityview",
    template: `
        <ng-template #viewLabel></ng-template>
        <ng-template #viewValue>
            <model-simple-view
                *ngFor="let relatedKey of params | keys"
                [key]="relatedKey"
                [params]="params[relatedKey]"
                [value]="value[key]"
            ></model-simple-view>
        </ng-template>
    `
})
export class EntityView extends View {}
