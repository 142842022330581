import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Asignaturas,
    AsignaturaWithConfig,
    Clasificaciones,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { Evaluaciones, GrupoUsuarios, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { isThisQuarter } from "date-fns"

declare let $

@Component({
    selector: "generar-instrumento-admin",
    templateUrl: "generar_instrumento_admin.component.html",
    styleUrls: ["generar_instrumento_admin.component.scss"]
})
export class GenerarInstrumentoAdminComponent implements OnInit, OnChanges, AfterViewChecked {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() enableBuscador: boolean = false
    @Input() enableReporte: boolean = true
    asignaturas: Asignatura[]
    asignatura: Asignatura
    asignaturaId: number
    instrumentoAsignatura: Asignatura
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()

    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento
    numeroPreguntas: number
    tiempo: number
    clasificacionesIds: number[]
    show_contenidos: boolean

    instrumento: Instrumento
    instrumentos: Instrumento[]
    selectedInstrumento: Instrumento

    instrumentoId: number
    buscarInstrumento: boolean = false
    buscado: boolean = false
    instrumentoBuscado: Instrumento
    instrumentosBuscados: Instrumento[]

    nombreEvaluacion: string = ""
    fechaInicio: string = ""
    fechaTermino: string = ""
    fechaMostrarRespuestas: string = ""

    @Input() tipoEvaluacion: string
    tipoEvaluacionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    sub: Subscription
    asignaturasByEvaluacionTipo$: Observable<{ [evaluacionTipo: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    config = config

    preguntasValidadas = false
    instrumentosIgnorados = ""
    evaluacionTipos =
        config.plataforma.evaluacionTiposMenu?.length > 0
            ? config.plataforma.evaluacionTiposMenu
            : config.plataforma.evaluacionTipos.filter(et => et.indexOf("diagnostico") == -1)

    @ViewChild("externalFrameReference", { read: ElementRef, static: true }) externalFrame: ElementRef
    enableScrollAndFollow: boolean = true

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected store: Store<State>,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //this.loadingLayout.ready();
        this.sub = combineLatest([
            this.tipoEvaluacionSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ]).subscribe(
            ([tipoEvaluacion, asignaturasByEvaluacionTipo]: [
                string,
                { [evaluacionTipo: string]: AsignaturaWithConfig[] }
            ]) => {
                this.asignaturas = asignaturasByEvaluacionTipo[tipoEvaluacion]
                this.asignaturaId = this.asignaturas[0].id
                this.loadData()
            }
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["tipoEvaluacion"]) {
            this.tipoEvaluacionSubject.next(this.tipoEvaluacion)
        }
    }

    changeTipoEvaluacion() {
        if (this.tipoEvaluacion) {
            this.tipoEvaluacionSubject.next(this.tipoEvaluacion)
        }
    }

    ngAfterViewChecked() {
        this.enableScrollAndFollow = window.innerWidth > 991
    }

    loadData() {
        this.loadingLayout.standby()
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, {
                    tipo_instrumento: this.tipoEvaluacion,
                    generador_instrumento: { grupal: [0, 1] }
                })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyAsignatura.emit(this.asignatura)
                    this.generadorInstrumentos = asignatura.generador_instrumentos
                    this.loadingLayout.ready()
                })
        }
    }

    generarInstrumento() {
        this.loadingLayout.standby()
        const instrumento = new Instrumento() as any
        instrumento.tiempo = this.tiempo
        instrumento.numero_preguntas = this.numeroPreguntas
        instrumento.asignatura_id = this.asignaturaId
        instrumento.generador_instrumento_id = this.generadorInstrumento.id
        instrumento.clasificaciones_id = this.clasificacionesIds
        instrumento.instrumento_ignorado_ids = this.instrumentosIgnorados.split(",")
        instrumento.validado = this.preguntasValidadas
        this.instrumentosService.save(instrumento).then((i: Instrumento | Instrumento[]) => {
            if (Array.isArray(i)) {
                this.instrumentosBuscados = i
                this.cargarInstrumentos()
            } else {
                this.instrumentoBuscado = i
                this.cargarInstrumento()
            }
        })
    }

    cargarInstrumento() {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null
        this.instrumentosService
            .find(this.instrumentoBuscado.id, { with_generador_instrumento: 1 })
            .then((i: Instrumento) => {
                this.instrumento = i
            })
    }

    cargarInstrumentos() {
        this.loadingLayout.standby()
        this.instrumento = null
        this.instrumentos = null
        this.instrumentoAsignatura = null

        let instrumentoIds = this.instrumentosBuscados.map(i => i.id)
        this.instrumentosService
            .where({ with_instrumento_preguntas: 1, instrumento: { id: instrumentoIds } })
            .then((i: Instrumento[]) => {
                this.instrumentos = i
                this.selectedInstrumento = i[0]
            })
    }

    onExternalLoadingInstrumento() {
        this.loadingLayout.standby()
    }

    onInstrumentoReady() {
        this.loadingLayout.ready()
        this.scrollToInstrumento()
    }

    onAsignaturaInstrumentoReady(instrumentoAsignatura) {
        this.instrumentoAsignatura = instrumentoAsignatura
    }

    onResize(event) {
        this.enableScrollAndFollow = event.target.innerWidth > 991
    }

    scrollToInstrumento() {
        let target = document.getElementById("instrumentoSeleccionado")
        $("html, body").animate(
            {
                scrollTop: target.offsetTop + 120
            },
            1000,
            "easeInOutExpo"
        )
    }

    changeAsignatura() {
        this.asignaturaId = this.asignatura.id
        this.loadData()
    }

    compareAsignatura(asig1: Asignatura, asig2: Asignatura) {
        return asig1 && asig2 && asig1.id === asig2.id
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
