<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div class="options clearfix">
            <button class="btn btn-default btn-sm" (click)="logInAsProfesor()">
                <fa name="user-o"></fa>
                Impersonar Profesor
            </button>
            <button class="btn btn-default btn-sm" (click)="logInAsAlumno()">
                <fa name="user-o"></fa>
                Impersonar Alumno
            </button>
            <a class="btn btn-default btn-sm" routerLink="/cuentas/{{ usuario.id }}/edit">
                <fa name="pencil"></fa>
                Editar Usuario
            </a>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="avatar-container">
                    <img *ngIf="usuario.avatar" alt="avatar" class="avatar" [src]="usuario.avatar | trustedurl" />
                    <img
                        *ngIf="!usuario.avatar"
                        alt="no avatar"
                        class="avatar"
                        src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                    />
                </div>
                <div class="nombre-container">
                    <label>{{ params.nombre.label }}:</label>
                    <p>{{ usuario?.nombre }}</p>
                </div>
                <div class="apellido-container">
                    <label>{{ params.apellido_paterno.label }}:</label>
                    <p>{{ usuario?.apellido_paterno }}</p>
                </div>
                <div class="apellido-container">
                    <label>{{ params.apellido_materno.label }}:</label>
                    <p>{{ usuario?.apellido_materno }}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="email-container" *ngIf="!isPrivate">
                    <label>{{ params.email.label }}:</label>
                    <p>{{ usuario?.email }}</p>
                </div>
                <div class="fecha-nacimiento-container" *ngIf="!isPrivate">
                    <label>{{ params.fecha_nacimiento.label }}:</label>
                    <p>{{ usuario?.fecha_nacimiento | prettydate | easyplaceholder: "No definida" }}</p>
                </div>
                <div *ngIf="enableShowCountryData" class="pais-container">
                    <div *ngFor="let idP of idPais">
                        <label>{{ nombreIdPais[idP] }}:</label>
                        <p>
                            {{
                                usuario["usuario_" + pais] && usuario["usuario_" + pais][idP.toLowerCase()]
                                    | easyplaceholder: "-"
                            }}
                        </p>
                    </div>
                </div>
                <div class="establecimiento-container">
                    <label *ngIf="usuario?.establecimiento">Establecimiento al que declara pertenecer:</label>
                    <p>{{ usuario?.establecimiento }}</p>
                </div>
                <div class="plus-container">
                    <label>PN+:</label>
                    <p>{{ usuario?.isPlus() ? "Activo" : "No activo" }}</p>
                </div>
            </div>
        </div>
        <div class="grupos">
            <div class="grupo-container">
                <cui-section-headline>Grupos asociados:</cui-section-headline>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Establecimiento</th>
                            <th>Periodo</th>
                            <th>Grupo/Curso/Nivel</th>
                            <th>Rol</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let g of usuario.grupo_usuario_usuarios">
                            <td>
                                <a
                                    *ngIf="g.grupo_usuario.establecimiento"
                                    [routerLink]="'/establecimientos/' + g.grupo_usuario.establecimiento.id"
                                >
                                    <ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
                                    {{ g.grupo_usuario.establecimiento | easyplaceholder: "-" }}
                                </a>
                                <span *ngIf="!g.grupo_usuario.establecimiento">
                                    <ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
                                    {{ g.grupo_usuario.establecimiento | easyplaceholder: "-" }}
                                </span>
                            </td>
                            <td>
                                {{ g.grupo_usuario.fecha_inicial | date: "yyyy" }} -
                                {{ g.grupo_usuario.fecha_final | date: "yyyy" }}
                            </td>
                            <td>{{ g.grupo_usuario.nombre }}</td>
                            <td>
                                <span *ngIf="g.propietario">
                                    <ogr-icon name="docente" class="icono highlight"></ogr-icon>
                                    Docente
                                </span>
                                <span *ngIf="!g.propietario">
                                    <fa name="user-o" class="icono"></fa>
                                    Estudiante
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</loading-layout>
