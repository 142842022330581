<form class="row" *ngIf="open">
    <div class="col-md-4">
        <div class="form-group">
            <input type="text" [(ngModel)]="asociacionContacto.id" name="id" class="form-control" placeholder="ID" />
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <select
                class="form-control"
                [(ngModel)]="asociacionContacto.efectivo"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [ngValue]="undefined">Contacto efectivo</option>
                <option *ngFor="let contacto of tContactos" [ngValue]="contacto.value">
                    {{ contacto.ContactoType }}
                </option>
            </select>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <select
                class="form-control"
                [(ngModel)]="asociacionContacto.tipo_contacto_id"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [ngValue]="undefined">Tipo contacto</option>
                <option *ngFor="let tipo of tTipos" [ngValue]="tipo.value">{{ tipo.TipoType }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label>Fecha entre</label>
            <datepicker-form
                [(ngModel)]="asociacionContacto.gte.fecha"
                [ngModelOptions]="{ standalone: true }"
            ></datepicker-form>
        </div>
        <cui-button-link [isBlock]="true" class="btn_style" (click)="search()">
            <fa name="search"></fa>
            &nbsp;Buscar
        </cui-button-link>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label>Fecha hasta</label>
            <datepicker-form
                [(ngModel)]="asociacionContacto.lte.fecha"
                [ngModelOptions]="{ standalone: true }"
            ></datepicker-form>
        </div>
    </div>
    &nbsp;
    <div class="col-md-4">
        <div class="form-group">
            <select
                class="form-control"
                [(ngModel)]="asociacionContacto.resuelto"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [ngValue]="undefined">Estado</option>
                <option *ngFor="let contacto of tResuelto" [ngValue]="contacto.value">
                    {{ contacto.ResueltoType }}
                </option>
            </select>
        </div>
    </div>
</form>
