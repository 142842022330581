import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Usuario, Usuarios, UsuarioAdminView, PremiumUsuarios, PremiumUsuario } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { UsuariosBuscadorService } from "./usuarios_buscador.service"

@Component({
    selector: "cuentas_admin, cuentas-admin",
    templateUrl: "cuentas_admin.component.html",
    styleUrls: ["cuentas_admin.component.scss"]
})
export class CuentasAdminComponent implements OnInit, OnDestroy {
    @Input() establecimientoId: number

    usuarios: Usuario[]
    params = UsuarioAdminView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @Input() showButtonDelete: boolean = false

    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join("/")
    usuarioCreado: boolean = false

    usuario: Usuario
    usuarioLoaded: boolean = false

    usuarioInGrupo: boolean = false

    idValue: string
    nombre: string
    apellidoPaterno: string
    apellidoMaterno: string
    email: string

    sub: Subscription
    searchParams: any = {}

    constructor(
        protected usuariosService: Usuarios,
        protected premiumUsuariosService: PremiumUsuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected simpleModalService: SimpleModalService,
        protected usuariosBuscadorService: UsuariosBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            show: { function: this.show.bind(this), text: "Ver", glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            reset: {
                function: this.resetPassword.bind(this),
                text: "Reiniciar Contraseña",
                glyphicon: "glyphicon-repeat"
            },
            activate: {
                function: this.showModalActivate.bind(this),
                text: "Activar",
                callModal: true,
                glyphicon: "glyphicon-repeat"
            },
            deactivate: {
                function: this.showModalDeactivate.bind(this),
                text: "Desactivar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            deactivate_pro: {
                function: this.showModalRevocarPro.bind(this),
                text: "Quitar Pro",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            logInAsAlumno: {
                function: this.logInAsAlumno.bind(this),
                text: "Impersonar Alumno",
                callModal: false,
                glyphicon: "glyphicon-user"
            },
            logInAsProfesor: {
                function: this.logInAsProfesor.bind(this),
                text: "Impersonar Profesor",
                callModal: false,
                glyphicon: "glyphicon-user"
            },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Eliminar",
                callModal: true,
                glyphicon: "glyphicon-trash",
                showIf: () => this.showButtonDelete
            }
        }

        this.sub = this.usuariosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            setTimeout(() => {
                this.paginator.reload()
            }, 10)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        this.loadingLayout.standby()
        let params = {
            page: page,
            per: count
            // check_activo: 1
        }

        params = {
            ...params,
            ...this.searchParams,
            usuario: {
                ...this.searchParams.usuario
            }
        }

        Object.keys(params["usuario"]).forEach(k => {
            if (params["usuario"][k] === "" || params["usuario"][k] === undefined) delete params["usuario"][k]
        })
        Object.keys(params["usuario"].like).forEach(k => {
            if (params["usuario"].like[k] === "" || params["usuario"].like[k] === undefined)
                delete params["usuario"].like[k]
        })

        return this.usuariosService.where(params).then((response: Usuario[], total: number) => {
            this.usuarios = response
            this.usuarios.forEach((usuario: Usuario) => {
                let idUsuario = usuario.id
                let today = new Date().toISOString().slice(0, 10)
                this.premiumUsuariosService
                    .where({
                        premium_usuario: {
                            usuario_id: idUsuario,
                            activo: 1,
                            gte: { fecha_termino: today },
                            lte: { fecha_inicio: today }
                        }
                    })
                    .then((premiumUsuarios: PremiumUsuario[]) => {
                        usuario.is_pro = premiumUsuarios.length > 0
                    })
            })
            setTimeout(() => {
                this.loadingLayout.ready()
            }, 700)

            return total
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    activarUsuario(usuario) {
        this.usuariosService.activar(usuario.id).then(usuario => {
            alert("Se ha activado al usuario")
            this.paginator.reload()
        })
    }

    desactivarUsuario(usuario) {
        this.usuariosService.desactivar(usuario.id).then(usuario => {
            alert("Se ha desactivado al usuario")
            this.paginator.reload()
        })
    }

    showModalActivate(usuario: Usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea activar este usuario?")
        this.simpleModalService.setModalCallback(() => this.activarUsuario(usuario))
    }

    showModalDeactivate(usuario: Usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea desactivar este usuario?")
        this.simpleModalService.setModalCallback(() => this.desactivarUsuario(usuario))
    }

    showModalRevocarPro(usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea revocar el servicio pro?")
        this.simpleModalService.setModalCallback(() => this.revocarPro(usuario))
    }

    revocarPro(usuario) {
        let idUsuario = usuario.id
        let today = new Date().toISOString().slice(0, 10)
        this.premiumUsuariosService
            .where({
                premium_usuario: {
                    usuario_id: idUsuario,
                    activo: 1,
                    gte: { fecha_termino: today },
                    lte: { fecha_inicio: today }
                }
            })
            .then((response: PremiumUsuario[]) => {
                let pu = response
                if (pu.length > 0) {
                    let pu_id = pu[0].id
                    pu[0].activo = false
                    pu[0].fecha_termino = new Date()
                    this.premiumUsuariosService.update(pu_id, pu[0]).then(u => {
                        alert("Se ha revocado la licencia pro.")
                        this.paginator.reload()
                    })
                } else {
                    alert("El usuario no posee cuenta pro.")
                }
            })
    }

    show(usuario: Usuario) {
        this.router.navigate(["/usuarios/" + usuario.id])
    }

    edit(usuario: Usuario) {
        this.router.navigate(["cuentas/" + usuario.id + "/edit"])
    }

    resetPassword(u) {
        let usuario = new Usuario()
        delete usuario.usuario_chile
        delete usuario.usuario_colombia
        delete usuario.establecimiento
        delete usuario.grupo_usuarios
        // delete usuario.rol_usuarios
        // delete usuario.roles
        // delete usuario["roles_attributes"]
        let pass = u["usuario_" + this.pais][this.idPais[0].toLowerCase()]
            ? u["usuario_" + this.pais][this.idPais[0].toLowerCase()].substring(0, 6)
            : u["usuario_" + this.pais][this.idPais[1].toLowerCase()].substring(0, 6)

        usuario.password = pass
        usuario.password_confirmation = pass
        usuario.id = u.id
        usuario.manual_reset_password = true
        usuario["reiniciar_password"] = true

        this.usuariosService.update(u.id, usuario).then(u => {
            alert("La password ha sido restablecida exitosamente a los primeros 6 dígitos del " + this.nombreId)
        })
    }

    validateIdValueFun(idValue) {
        switch (this.pais) {
            case "chile":
                return { valid: this.validaRut(idValue), msg: "El rut ingresado no es válido." }
            case "colombia":
                return {
                    valid: this.idValue.length > 7 || /^[0-9a-zA-Z]+$/.test(idValue),
                    msg: "El número de identificación no es válido."
                }
            default:
                return { valid: false, msg: "País de la plataforma no disponible" }
        }
    }

    validateIdValue() {
        return this.validateIdValueFun(this.idValue)
    }

    validateIdValueUsuario(usuario) {
        let idValue = usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()]

        return this.validateIdValueFun(idValue)
    }

    validaRut(rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
        var tmp = rutCompleto.split("-")
        var digv = tmp[1]
        var rut = tmp[0]
        if (digv == "K") digv = "k"
        return this.dv(rut) == digv
    }

    dv(T) {
        var M = 0,
            S = 1
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
        return S ? S - 1 : "k"
    }

    logInAsAlumno(usuario: Usuario) {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(usuario.id, { application: "estudiante" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }

    logInAsProfesor(usuario: Usuario) {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(usuario.id, { application: "docente" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }

    showModalDelete(usuario: Usuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este usuario?")
        this.simpleModalService.setModalCallback(() => this.eliminarUsuario(usuario))
    }

    eliminarUsuario(usuario: Usuario) {
        if (!usuario.activo && !usuario["usuario_" + config.plataforma.pais]) {
            this.usuariosService.remove(usuario.id).then(response => {
                this.paginator.reload()
            })
        } else {
            alert("El usuario esta activo o tiene un identificador asociado")
        }
    }
}
