<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="row print-hide">
            <div class="col-lg-4 col-md-5">
                <filtro-estadisticas-declarados
                    (filter)="onFilter($event)"
                    [establecimientoId]="establecimientoId"
                    [asignaturaId]="asignaturaId"
                    [evaluacionTipo]="evaluacionTipo"
                ></filtro-estadisticas-declarados>
            </div>
            <div class="col-lg-6 col-md-7">
                <!-- <informacion-general-grupos *ngIf="showEstadisticasCursos && estadisticas" [establecimiento]="establecimiento" [estadisticas]="estadisticas" ></informacion-general-grupos> -->
            </div>
        </div>
        <div class="clearfix">
            <loading-layout #innerLayout [loading]="false">
                <ng-container *ngIf="ready">
                    <cui-button class="btn_style pull-right print-hide" *ngIf="ready" (click)="print()">
                        <fa name="print"></fa>
                        &nbsp;Imprimir
                    </cui-button>
                    <cui-button-link class="btn_style add-fake-button print-hide" (click)="openModal.emit(true)">
                        Agregar Establecimiento Ficticio
                    </cui-button-link>
                    <generic-modal [id]="'agregar-establecimiento-fake'" [buttonClicked]="openModal">
                        <modal-titulo>Agregar establecimiento ficticio</modal-titulo>
                        <modal-contenido>
                            <div>{{ estNgModel.touched }}</div>
                            <div>{{ grupoNgModel.touched }}</div>
                            <div>{{ jefeNgModel.touched }}</div>
                            <div
                                *ngIf="
                                    estNgModel.touched &&
                                    grupoNgModel.touched &&
                                    jefeNgModel.touched &&
                                    (!establecimientoFake.establecimiento || !grupoUsuarioFake.nombre || !jefeUtpFake)
                                "
                            >
                                Todos los campos son requeridos
                            </div>
                            <div class="form-group">
                                <label>Nombre Establecimiento</label>
                                <input
                                    #estNgModel="ngModel"
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="establecimientoFake.establecimiento"
                                />
                            </div>

                            <div class="form-group">
                                <label>Nombre Curso</label>
                                <input
                                    #grupoNgModel="ngModel"
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="grupoUsuarioFake.nombre"
                                />
                            </div>
                            <div class="form-group">
                                <label>Jefe UTP</label>
                                <autocomplete
                                    #jefeNgModel="ngModel"
                                    class="ac-jefe-utp"
                                    [(ngModel)]="jefeUtpFake"
                                    [options]="filteredUsuarios"
                                    (filterChange)="getUsuarios($event)"
                                ></autocomplete>
                            </div>
                        </modal-contenido>
                        <modal-botones>
                            <loader *ngIf="creating" class="creating" [loaderType]="'default-xsmall'"></loader>

                            <cui-button-link class="btn_style" (click)="createEstablecimientoFake()">
                                Agregar
                            </cui-button-link>
                            <cui-button-link class="btn_style" data-dismiss="modal">Cancelar</cui-button-link>
                        </modal-botones>
                    </generic-modal>
                </ng-container>
            </loading-layout>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <estadisticas-usuarios
                    *ngIf="estadisticas"
                    [usuarios]="usuarios"
                    [establecimiento]="establecimiento"
                    [lugar]="lugar"
                    [asignaturaId]="asignaturaId"
                    [estadisticas]="estadisticas"
                    [evaluacionTipo]="evaluacionTipo"
                    (onReady)="onReady($event)"
                ></estadisticas-usuarios>
            </div>
        </div>
    </div>
</loading-layout>
