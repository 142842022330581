import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { PlanPersonalSesion, PlanPersonalSesiones, PlanPersonal } from "@puntaje/puntaje/api-services"
import { PlanPersonalSesionForm } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"

@Component({
    selector: "sesiones-new",
    templateUrl: "../sesiones-form/sesiones-form.component.html",
    styleUrls: ["./sesiones-new.component.scss"]
})
export class SesionesNewComponent implements OnInit {
    @Input() planificacion: PlanPersonal
    planPersonalSesion = new PlanPersonalSesion()
    params = PlanPersonalSesionForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar Sesión a Planificacion"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected planPersonalSesionesService: PlanPersonalSesiones, protected router: Router) {}

    ngOnInit() {
        this.planPersonalSesion.plan_personal_ciclo_id = this.planificacion.plan_personal_ciclos[0].id
        this.form = PlanPersonalSesionForm.getForm(this.planPersonalSesion)
        this.loadingLayout.ready()
        this.planPersonalSesion.clasificaciones = ""
    }

    save() {
        PlanPersonalSesionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planPersonalSesionesService.save(this.planPersonalSesion).then(response => {
                this.router.navigate(["/planificaciones", this.planificacion.id])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.planPersonalSesion = new PlanPersonalSesion()
            PlanPersonalSesionForm.markFormControlsAsPristine(this.form)
            PlanPersonalSesionForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
