import { Component, ViewChild, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "streaming-admin.component.html"
})
export class StreamingAdminComponent {
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}
}
