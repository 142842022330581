<ll-titulo>Evaluación: {{ evaluacion?.evaluacion }}</ll-titulo>

<datos-generales-evaluacion
    *ngIf="evaluacion"
    [evaluacion]="evaluacion"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
></datos-generales-evaluacion>

<datos-generales
    *ngIf="instrumento"
    [instrumento]="instrumento"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
></datos-generales>
<div class="row top-margin">
    <div class="col-md-4 inverted-col padding-bottom">
        <opciones-instrumento
            *ngIf="instrumento"
            [evaluacionId]="evaluacion.id"
            [instrumento]="instrumento"
            [tipoId]="1"
            [scrollAndFollow]="true"
            [responsiveBreak]="991"
            [topPadding]="20"
            [bottomPadding]="20"
            [followTopReference]="followReference"
            [followBottomReference]="followReference"
            [enableAdminOptions]="true"
        ></opciones-instrumento>
    </div>
    <div class="col-md-8" #followReference>
        <cui-section-headline>Vista previa</cui-section-headline>
        <instrumento [instrumento]="instrumento"></instrumento>
    </div>
</div>
