<div class="filtro">
  <div class="form-group row">
    <div class="col-md-4 col-xs-6">
      <select class="form-control" (change)="selectEvaluacionTipo($event)">
        <option [ngValue]="null">Seleccione Tipo de evaluación</option>
        <option *ngFor="let tipo of evaluacionTipos" [ngValue]="tipo">
          {{ tipo }}
        </option>
      </select>
    </div>
    <div class="col-md-4 col-xs-6">
      <select class="form-control"  (change)="selectAsignatura($event)">
        <option selected [ngValue]="null">Seleccione Asignatura</option>
        <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura">
          {{ asignatura.asignatura }}
        </option>
      </select>
    </div>
    <div class="col-md-4 col-xs-6">
      <select class="form-control" [(ngModel)]="planId" (change)="onFiltro()">
        <option [ngValue]="null">Seleccione Plan</option>
        <option *ngFor="let plan of planes" [ngValue]="plan.id">{{ plan.plan_personal }}</option>
      </select>
    </div>
  </div>
</div>