import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { BannerPagina, BannerPaginas, BannerPaginaForm } from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerPaginas-new",
    templateUrl: "banner_paginas.form.component.html"
})
export class BannerPaginasNewComponent implements OnInit {
    @Input() listRoute: string
    bannerPagina: BannerPagina = new BannerPagina()
    params = BannerPaginaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar banner_pagina"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private bannerPaginasService: BannerPaginas, private router: Router) {}

    ngOnInit() {
        this.form = BannerPaginaForm.getForm(this.bannerPagina)
        this.loadingLayout.ready()
    }

    save() {
        BannerPaginaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.bannerPaginasService.save(this.bannerPagina).then(response => {
                this.router.navigate([this.listRoute])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.bannerPagina = new BannerPagina()
            BannerPaginaForm.markFormControlsAsPristine(this.form)
            BannerPaginaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
