<loading-layout #loadingLayout>
    <establecimientos-buscador (onReady)="onBuscadorReady()"></establecimientos-buscador>
    <br />
    <div class="overflowable">
        <loading-layout #tableLoading>
            <table-with-actions
                *ngIf="establecimientos"
                [enableStickyHeaders]="true"
                [getObjects]="showAll"
                [contentArray]="establecimientos"
                [showParams]="params"
                [tableButtonsArray]="tableButtonsArray"
                [disableTrackBy]="true"
            ></table-with-actions>
        </loading-layout>
    </div>
    <paginator [getList]="showAll"></paginator>

    <div>
        (*) En el intervalo actual (por defecto Marzo del presente año a hoy):
        <br />
        NCE: Numero Contactos Efectivos
        <br />
        IGD: Instrumentos Generados por Docentes (puede considerar instrumentos no compartidos como instrumentos de
        evaluaciones compartidas)
        <br />
        CEID: Cantidad Evaluaciones Impresas por Docentes (que descargaron/imprimieron una evaluación)
        <br />
        ECD: Evaluaciones Compartidas por Docente
        <br />
        CECPE: Cantidad Evaluaciones Contestadas en Promedio por Estudiante
        <br />
        ERvsT: Cantidad de evaluaciones respondidas vs cantidad de evaluaciones que alumnos pueden responder
        <br />
    </div>
</loading-layout>
