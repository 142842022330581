import {
    EstablecimientoContactoComponent,
    EstablecimientoContactosEditComponent,
    EstablecimientoContactosNewComponent,
    EstablecimientoContactosComponent,
    EstablecimientoReporteComponent,
    EstablecimientoReportesEditComponent,
    EstablecimientoReportesNewComponent,
    EstablecimientoReportesComponent,
    EstablecimientoComponent,
    EstablecimientosEditComponent,
    EstablecimientosNewComponent,
    EstablecimientosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const establecimientosRoutes: Routes = [
    {
        path: "establecimientos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientosNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_contactos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoContactosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_contactos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoContactosNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_contactos/:establecimientoContactoId/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoContactosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_contactos/:establecimientoContactoId",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoContactoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_reportes",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoReportesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_reportes/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoReportesNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_reportes/:establecimientoReporteId/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoReportesEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "establecimientos/:id/establecimiento_reportes/:establecimientoReporteId",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstablecimientoReporteComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const establecimientosRouting = RouterModule.forChild(establecimientosRoutes)
