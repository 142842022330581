import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { TypeformWidget, TypeformWidgetes, TypeformWidgetView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "typeform-widget",
    templateUrl: "typeform-widget.component.html"
})
export class TypeformWidgetComponent implements OnInit, OnDestroy {
    typeformWidget: TypeformWidget
    params = TypeformWidgetView.viewParams
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected typeformWidgetsService: TypeformWidgetes,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.typeformWidgetsService.find(+params["id"]).then((response: TypeformWidget) => {
                this.typeformWidget = response
                this.loadingLayout.ready()
            })
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
