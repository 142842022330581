import { TextView, LinkView, DateView, BooleanView } from "@puntaje/shared/core"
import { Evaluaciones } from "./evaluaciones.service"

export class EvaluacionView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Evaluaciones, key: "id" } },
            evaluacion: { label: "Evaluación", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
