import { TextView, LinkView, DateView } from "@puntaje/shared/core"
import { Lugares } from "@puntaje/carreras/api-services"
import { Establecimientos } from "./establecimientos.service"
import { TypeformWidgetes } from "./typeform_widgetes.service"
import { TypeformWidgetTipos } from "./typeform_widget_tipos.service"
import { TypeformWidgetTipoUsuarios } from "./typeform_widget_tipo_usuarios.service"

export class TypeformWidgetView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: TypeformWidgetes, key: "id" } },
            nombre_referencia: {label: "Nombre Referencia", type: TextView, tableVisible: true },
            codigo: {label: "Código", type: TextView, tableVisible: true },
            fecha_inicio: {label: "Fecha Inicio", type: DateView, tableVisible: true },
            fecha_termino: {label: "Fecha Término", type: DateView, tableVisible: true },
            typeform_widget_tipo: {
                label: "Tipo",
                type: LinkView,
                tableVisible: true,
                options: { class: TypeformWidgetTipos, key: "typeform_widget_tipo_id", modelKey: "tipo" }
            },
            typeform_widget_tipo_usuario: {
                label: "Tipo Usuario",
                type: LinkView,
                tableVisible: true,
                options: { class: TypeformWidgetTipoUsuarios, key: "typeform_widget_tipo_usuario_id", modelKey: "tipo_usuario" }
            },
            lugar: {
                label: "Lugar",
                type: LinkView,
                tableVisible: true,
                options: { class: Lugares, key: "lugar_id", modelKey: "nombre" }
            },
            establecimiento: {
                label: "Establecimiento",
                type: LinkView,
                tableVisible: true,
                options: { class: Establecimientos, key: "establecimiento_id", modelKey: "establecimiento" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}