<div class="filter-container clearfix">
    <autocomplete
        ngModel
        [ngModelOptions]="{ standalone: true }"
        placeholder="Establecimientos"
        showTextFun="toStringWithIdPais"
        [options]="establecimientos"
        (filterChange)="onEstablecimientoAutocompleteChange($event)"
        (ngModelChange)="onEstablecimientoSelected($event)"
        [autoSelect]="false"
        class="push-bottom"
        name="establecimiento"
    ></autocomplete>
    <select
        class="form-control push-bottom"
        name="grupo_usuario"
        [(ngModel)]="grupoUsuario"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="!establecimiento"
    >
        <option selected [ngValue]="undefined">Seleccione {{ grupoUsuarioAlias }}</option>
        <ng-container *ngIf="establecimiento">
            <option *ngFor="let grupoUsuario of establecimiento.grupo_usuarios" [ngValue]="grupoUsuario">
                {{ grupoUsuario.nombre }}
            </option>
        </ng-container>
    </select>
    <cui-button [disabled]="fetching" (click)="onFilter()" class="pull-right">
        <fa name="search"></fa>
        &nbsp;Filtrar
    </cui-button>
</div>
