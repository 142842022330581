import { Component, OnInit, Input } from "@angular/core"
import {
    /*Establecimiento,*/ Establecimientos,
    EstablecimientoContacto,
    EstablecimientoContactos,
    TipoContactos
} from "@puntaje/puntaje/api-services"
import { EstablecimientoContactosBuscadorService } from "./establecimiento_contactos_buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento-contactos-buscador",
    templateUrl: "establecimiento_contactos_buscador.component.html"
})
export class EstablecimientoContactosBuscadorComponent implements OnInit {
    // establecimiento: any = {};
    // establecimientoPais: any = {};
    // establecimientoContactoId: any = {};

    // idEstablecimientoNombre: string = config.plataforma.identificadorEstablecimiento[0];
    // idEstablecimientoKey: string = config.plataforma.identificadorEstablecimiento[0].replace(" ", "_").toLowerCase();

    sub: Subscription
    open: boolean = false

    @Input() establecimientoId: number
    establecimientoContacto: any = { like: {}, gte: {}, lte: {} }
    tipoContactoId: number = undefined

    // searchParams: any = {establecimiento_contacto: {}};
    tContactos: any[] = [
        { value: 0, ContactoType: "No Efectivo" },
        { value: 1, ContactoType: "Efectivo" }
    ]
    tTipos: any[] = []
    tResuelto: any[] = [
        { value: 0, ResueltoType: "No Resuelto" },
        { value: 1, ResueltoType: "Resuelto" }
    ]

    constructor(
        protected establecimientoContactosBuscadorService: EstablecimientoContactosBuscadorService,
        protected tipoContactosService: TipoContactos
    ) {}

    async ngOnInit() {
        this.tTipos = await this.tipoContactosService.where({})

        this.sub = this.establecimientoContactosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.establecimientoContactosBuscadorService.search(
            this.establecimientoContacto,
            this.establecimientoId,
            this.tipoContactoId
        )
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
