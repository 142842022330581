import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    OnChanges,
    ChangeDetectorRef
} from "@angular/core"
import { Router } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { Establecimiento, Establecimientos, EstablecimientoView } from "@puntaje/puntaje/api-services"
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter, first } from "rxjs/operators"
import { EstablecimientosBuscadorService } from "../establecimientos_buscador.service"

@Component({
    selector: "establecimientos",
    templateUrl: "establecimientos.component.html",
    styleUrls: ["establecimientos.component.scss"]
})
export class EstablecimientosComponent implements OnInit, OnDestroy {
    establecimientos: Establecimiento[]
    params = EstablecimientoView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("tableLoading", { static: true }) tableLoading: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @Input() mios: boolean

    sub: Subscription
    searchParams: any = {}

    idEstablecimientoKey: string = config.plataforma.identificadorEstablecimiento[0]
        .trim()
        .replace(" ", "_")
        .toLowerCase()

    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    buscadorReady = false

    @Input() lugarDefault?: string
    @Input() lugarTipo?: string

    constructor(
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected simpleModalService: SimpleModalService,
        protected establecimientosBuscadorService: EstablecimientosBuscadorService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            stats: {
                function: this.stats.bind(this),
                text: "Estadisticas alumnos declarados",
                glyphicon: "glyphicon-stats"
            },
            // delete: {function: this.showModalDelete.bind(this), text: 'Borrar', callModal: true, glyphicon:"glyphicon-trash"},
            deactivate: {
                function: this.showModalDeactivate.bind(this),
                text: "Desactivar/Activar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            },
            contacts: {
                function: this.contacts.bind(this),
                text: "Contactos",
                callModal: false,
                glyphicon: "glyphicon-search"
            }
        }
        this.sub = this.establecimientosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    onBuscadorReady() {
        this.buscadorReady = true
        this.loadingLayout.ready()
    }

    showAll = (page: number = 1, count: number = 20, order: number = null, key: string = null) => {
        if (!this.buscadorReady)
            return new Promise<number>(resolve => {
                resolve(0)
            })
        this.tableLoading.standby()
        let params: any = {
            page: page,
            per: count,
            raw: 1,
            include: "[monitores,asociaciones,establecimiento_" + config.plataforma.pais + ",lugar]",
            methods: "[numero_docentes_año,numero_alumnos_año,numero_personas]",
            sort_by: "establecimiento_" + config.plataforma.pais + "." + this.idEstablecimientoKey,
            order: "asc"
        }

        if (this.lugarDefault && this.lugarDefault.trim()) {
            params.lugar = this.lugarDefault.trim()
            params.lugar_tipo_lugar = this.lugarTipo
        }

        if (order) {
            params.order = ""
            if (order == 1) params.order = "asc"
            if (order == -1) params.order = "desc"

            if (key == "rbd" || key == "icfes_id") {
                params.sort_by = "establecimiento_" + config.plataforma.pais + "." + key
            } else if (key == "lugar") {
                params.sort_by = "lugares.nombre"
            } else if (key == "asociaciones") {
                params.sort_by = "asociaciones.asociacion"
            } else {
                params.sort_by = "establecimientos." + key
            }
        }

        params = {
            ...params,
            ...this.searchParams,
            establecimiento: {
                ...params.establecimiento,
                ...this.searchParams.establecimiento
            }
        }

        return this.establecimientosService.where(params).then((establecimientos: Establecimiento[], total: number) => {
            this.establecimientos = establecimientos
            if (this.searchParams.fecha_inicial_uso && this.searchParams.fecha_final_uso) {
                EstablecimientoView.fechaInicial = this.searchParams.fecha_inicial_uso
                EstablecimientoView.fechaFinal = this.searchParams.fecha_final_uso
                this.params = EstablecimientoView.viewParams
            }
            this.tableLoading.ready()

            return total
        })
    }

    view(establecimiento: Establecimiento) {
        this.router.navigate(["establecimientos/" + establecimiento.id])
    }

    contacts(establecimiento: Establecimiento) {
        this.router.navigate(["establecimientos/" + establecimiento.id + "/establecimiento_contactos"])
    }

    reports(establecimiento: Establecimiento) {
        this.router.navigate(["establecimientos/" + establecimiento.id + "/establecimiento_reportes"])
    }

    edit(establecimiento: Establecimiento) {
        this.router.navigate(["establecimientos/" + establecimiento.id + "/edit"])
    }

    async stats(establecimiento: Establecimiento) {
        let listaAsignaturas = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()

        this.router.navigate(["estadisticas/declarados"], {
            queryParams: {
                tipo_instrumento: config.plataforma.evaluacionDefault,
                asignatura_id: listaAsignaturas[config.plataforma.evaluacionDefault][0].id,
                establecimiento_id: establecimiento.id
            }
        })
    }

    delete(id: number) {
        this.establecimientosService.remove(+this.params["id"]).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    toggleActivo(establecimiento: Establecimiento) {
        let e = establecimiento
        e.activo = !e.activo
        this.establecimientosService.update(e.id, e).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    showModalDelete(establecimiento: Establecimiento) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este establecimiento?")
        this.simpleModalService.setModalCallback(() => this.delete(establecimiento.id))
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showModalDeactivate(establecimiento: Establecimiento) {
        this.simpleModalService.setModalHeader("Confirmación")
        if (establecimiento.activo) {
            this.simpleModalService.setModalStringContent("¿Está seguro que desea desactivar este establecimiento?")
        } else {
            this.simpleModalService.setModalStringContent("¿Está seguro que desea activar este establecimiento?")
        }
        this.simpleModalService.setModalCallback(() => this.toggleActivo(establecimiento))
    }
}
