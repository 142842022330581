import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { UtilModule } from "@puntaje/shared/core"
import { AsociacionesModule } from "@puntaje/puntaje/new-modules/asociaciones"

import { AsociacionComponent } from "./asociacion.component"
import { AsociacionesComponent } from "./asociaciones.component"
import { AsociacionesNewComponent } from "./asociaciones.new.component"
import { AsociacionesEditComponent } from "./asociaciones.edit.component"
import { AsociacionContactosComponent } from "./asociacion_contactos.component"
import { AsociacionContactosEditComponent } from "./asociacion_contactos_edit.component"
import { AsociacionContactosNewComponent } from "./asociacion_contactos_new.component"
import { AsociacionContactoComponent } from "./asociacion_contacto.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, UtilModule, AsociacionesModule, CUILayoutsModule],
    declarations: [
        AsociacionComponent,
        AsociacionesComponent,
        AsociacionesNewComponent,
        AsociacionesEditComponent,
        AsociacionContactosComponent,
        AsociacionContactosEditComponent,
        AsociacionContactosNewComponent,
        AsociacionContactoComponent
    ],
    exports: [
        AsociacionComponent,
        AsociacionesComponent,
        AsociacionesNewComponent,
        AsociacionesEditComponent,
        AsociacionContactosComponent,
        AsociacionContactosEditComponent,
        AsociacionContactosNewComponent,
        AsociacionContactoComponent
    ]
})
export class PuntajeAsociacionesModule {}
