import { TextView, LinkView, DateView } from "@puntaje/shared/core"
import { Asociaciones } from "./asociaciones.service"

export class AsociacionView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Asociaciones, key: "id" } },
            asociacion: { label: "Asociación", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
