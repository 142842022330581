<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <form novalidate [formGroup]="form">
                <form-input
                    *ngFor="let key of params | keys"
                    [form]="form"
                    [params]="params[key]"
                    [(value)]="typeformWidget[key]"
                    [key]="key"
                ></form-input>
                <div class="form-group">
                    <label for="establecimiento_id">Establecimiento</label>
                    <autocomplete
                        [(ngModel)]="establecimiento"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Establecimientos"
                        showTextFun="toStringWithIdPais"
                        [options]="establecimientos"
                        (filterChange)="onEstablecimientoAutocompleteChange($event)"
                        (ngModelChange)="onEstablecimientoSelected($event)"
                        [autoSelect]="false"
                        class="push-bottom"
                        name="establecimiento"
                    ></autocomplete>
                </div>
                <button (click)="save()" type="button" class="btn btn-default">
                    {{ save_button_text | easyplaceholder: "Guardar" }}
                </button>
                <button (click)="clear()" type="button" class="btn btn-default">
                    {{ clear_button_text | easyplaceholder: "Borrar" }}
                </button>
            </form>
        </div>
    </div>
</loading-layout>
