<div>
    <div class="wrap">
        <div
            *ngIf="enableStickyHeaders && showFloating && !showSmallTable"
            class="acciones"
            [ngStyle]="{
                width: headerOffsetSize[headerOffsetSize.length - 1].offsetWidth + 'px'
            }"
        >
            <div class="heading">Acciones</div>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr class="table-headers">
                        <th>Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        *ngFor="let content of contentArray; let i = index"
                        [ngStyle]="{
                            height: rowContentOffsetSize[i].offsetHeight + 'px'
                        }"
                    >
                        <td>
                            <table-with-actions-buttons
                                [tableButtonsArray]="tableButtonsArray"
                                [functionParameter]="content"
                            ></table-with-actions-buttons>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="enableStickyHeaders && showFloating && !showSmallTable">
            <div class="headers">
                <div #scroll class="scroll">
                    <ng-template ngFor let-key let-i="index" [ngForOf]="visibleKeys(headerParams)">
                        <div class="column">
                            <div
                                class="heading heading-data"
                                [ngStyle]="{ width: headerOffsetSize[i].offsetWidth + 'px' }"
                            >
                                <ng-container
                                    *ngIf="getObjects && headerParams[key].sorterEnabled; else noFloatingSorter"
                                >
                                    <sorter [getObjects]="getObjects" [key]="key" [(objects)]="contentArray">
                                        {{ headerParams[key].label }}
                                    </sorter>
                                </ng-container>

                                <ng-template #noFloatingSorter>
                                    {{ headerParams[key].label }}
                                </ng-template>
                            </div>
                        </div>
                    </ng-template>

                    <div class="column last-column">
                        <div
                            class="heading"
                            [ngStyle]="{ width: headerOffsetSize[headerOffsetSize.length - 1].offsetWidth + 'px' }"
                        >
                            Acciones
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="table-data" (scroll)="onScroll($event)">
            <table
                class="table table-striped table-bordered"
                [class.smallTable]="showSmallTable"
                (window:resize)="onResize($event)"
            >
                <tbody>
                    <tr
                        *ngIf="headerParams"
                        sorterGroup
                        class="showBig"
                        [ngClass]="{ 'table-headers': enableStickyHeaders }"
                    >
                        <ng-template ngFor let-key [ngForOf]="visibleKeys(headerParams)">
                            <th #header class="tbheader">
                                <ng-container *ngIf="getObjects && headerParams[key].sorterEnabled; else noSorter">
                                    <sorter [getObjects]="getObjects" [key]="key" [(objects)]="contentArray">
                                        {{ headerParams[key].label }}
                                    </sorter>
                                </ng-container>
                                <ng-template #noSorter>
                                    {{ headerParams[key].label }}
                                </ng-template>
                            </th>
                        </ng-template>
                        <th #header class="tbheader-acciones" [ngClass]="{ 'last-column': enableStickyHeaders }">
                            Acciones
                        </th>
                    </tr>
                    <tr
                        #rowContent
                        *ngFor="let content of contentArray; let i = index; trackBy: id"
                        class="showBig tbrow"
                    >
                        <ng-template ngFor let-key [ngForOf]="visibleKeys(showParams)">
                            <ng-container [ngSwitch]="showParams[key].type">
                                <ng-container *ngSwitchCase="entityView">
                                    <ng-container *ngFor="let modelKey of showParams[key].class.viewParams | keys">
                                        <td *ngIf="showParams[key].class.viewParams[modelKey].tableVisible">
                                            <model-table-cell
                                                [params]="showParams[key].class.viewParams[modelKey]"
                                                [value]="content[key]"
                                                [key]="modelKey"
                                            ></model-table-cell>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <td *ngSwitchDefault>
                                    <model-table-cell
                                        [params]="showParams[key]"
                                        [value]="content"
                                        [key]="key"
                                        [ngClass]="{ 'pre-line-white-space': showParams[key].pre_line_white_space }"
                                    ></model-table-cell>
                                </td>
                            </ng-container>
                        </ng-template>
                        <td [ngClass]="{ 'last-column': enableStickyHeaders }">
                            <table-with-actions-buttons
                                [tableButtonsArray]="tableButtonsArray"
                                [functionParameter]="content"
                            ></table-with-actions-buttons>
                        </td>
                    </tr>
                    <tr *ngFor="let content of contentArray; let i = index; trackBy: id" class="showSmall">
                        <td>
                            <ng-template ngFor let-key [ngForOf]="visibleKeys(showParams)">
                                <ng-container [ngSwitch]="showParams[key].type">
                                    <ng-container *ngSwitchCase="entityView">
                                        <ng-container *ngFor="let modelKey of showParams[key].class.viewParams | keys">
                                            <tr *ngIf="showParams[key].class.viewParams[modelKey].tableVisible">
                                                <td>{{ showParams[key].class.viewParams[modelKey].label }}</td>
                                                <td>
                                                    <model-table-cell
                                                        [params]="showParams[key].class.viewParams[modelKey]"
                                                        [value]="content[key]"
                                                        [key]="modelKey"
                                                    ></model-table-cell>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <tr *ngSwitchDefault>
                                        <td *ngIf="showParams[key].tableVisible">{{ showParams[key].label }}</td>
                                        <td *ngIf="showParams[key].tableVisible">
                                            <model-table-cell
                                                [params]="showParams[key]"
                                                [value]="content"
                                                [key]="key"
                                            ></model-table-cell>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
