import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core"
import { Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GeneradorInstrumento, GeneradorInstrumentos, Lote, Lotes, Pregunta } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "instrumento-lote",
    templateUrl: "instrumento_desde_lote.component.html",
    styleUrls: ["instrumento_desde_lote.component.scss"]
})
export class InstrumentoDesdeLoteComponent implements OnInit {
    config: typeof config
    lote_id: number
    lote: Lote
    tipo_instrumento: string = ""
    instrumento: Instrumento
    plataforma_id: number
    generadores: GeneradorInstrumento[]
    generador_id: number
    asignatura_id: number
    generador_selected: GeneradorInstrumento = null
    searching: boolean = false
    errors: string[] = []
    tiempo: number
    preguntasNotPlatform: Pregunta[]
    errorPreguntasNotPlatform: boolean = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutLote", { static: true }) loadingLayoutLote: LoadingLayoutComponent

    constructor(
        protected instrumentosService: Instrumentos,
        protected generadorService: GeneradorInstrumentos,
        protected lotesService: Lotes
    ) {
        this.config = config
    }

    @Output() instrumentoChange: EventEmitter<Instrumento> = new EventEmitter<Instrumento>()

    ngOnInit() {
        this.plataforma_id = config.plataforma.id
        this.generadorService
            .where({
                tipo_instrumento: { tipo_instrumento: this.config.plataforma.evaluacionTipos },
                asignatura_plataforma: { plataforma_id: this.plataforma_id },
                with_asignatura: true,
                with_tipo_instrumento: true
            })
            .then((generadores: GeneradorInstrumento[]) => {
                this.generadores = generadores
                this.loadingLayout.ready()
                this.loadingLayoutLote.ready()
            })
    }

    guardar() {
        this.errors = []

        if (this.generador_selected && this.lote) {
            if (this.lote.asignatura["id"] !== this.generador_selected["asignatura"]["id"]) {
                this.errors.push("La asignatura del lote debe ser la misma que la asignatura del generador")
            } else {
                let query_obj = {
                    lote_id: this.lote_id,
                    generador_instrumento_id: this.generador_id,
                    asignatura_id: this.asignatura_id,
                    tiempo: this.tiempo
                }
                this.instrumentosService.desdeLote(query_obj).then((response: Instrumento) => {
                    this.instrumentoChange.emit(response)
                })
            }
        }
    }

    updateValues(generador: GeneradorInstrumento) {
        this.generador_selected = generador
        this.generador_id = generador.id
        this.asignatura_id = generador["asignatura"].id
    }

    getLote(loteId: number) {
        if (loteId) {
            this.searching = true
            this.loadingLayoutLote.loading = true

            let paramsLotes = {
                with_info: true,
                include: "preguntas:[plataformas]"
            }
            this.lotesService.find(loteId, paramsLotes).then((response: Lote) => {
                this.lote = response
                this.searching = false
                this.tiempo = this.lote.cantidad_preguntas * 2
                this.preguntasNotPlatform = this.lote.preguntas.filter(p => !p.isFromPlatform())
                this.errorPreguntasNotPlatform = this.preguntasNotPlatform && this.preguntasNotPlatform.length > 0
                this.loadingLayoutLote.ready()
            })
        }
    }

    checkLote(loteId) {
        if (!loteId) {
            this.lote = null
        }
    }
}
