<loading-layout #loadingLayout>
    <form novalidate [formGroup]="form">
        <div *ngIf="ready">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let zona of bannerZonas">{{ zona.zona }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pagina of bannerPaginas">
                        <td>{{ pagina.pagina }}</td>
                        <td *ngFor="let zona of bannerZonas">
                            <input
                                type="checkbox"
                                (click)="toggle(pagina.id, zona.id)"
                                [checked]="orderedInstances[pagina.id][zona.id][0]?.activo"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <button (click)="save()" type="button" class="btn btn-default" [disabled]="toggleWait">Guardar</button>
            <loading-layout
                #loadingLayoutWait
                [loaderType]="'default-small'"
                *ngIf="toggleWait"
                class="loader-wait"
            ></loading-layout>
        </div>
    </form>
</loading-layout>
