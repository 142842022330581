import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { ClasificacionesCheckboxesComponent } from "../clasificaciones_checkboxes.component"
import { Pregunta } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "alert-preguntas-exclusivas",
    templateUrl: "alert-preguntas-exclusivas.component.html"
})
export class AlertPreguntasExclusivasComponent {
    @Input() preguntasNotPlatform: Pregunta[]
}
