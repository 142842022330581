import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "planificacion",
    templateUrl: "./planificacion.component.html",
    styleUrls: ["./planificacion.component.scss"]
})
export class PlanificacionComponent implements OnInit {
    planificacionId: number
    protected sub: Subscription
    subData: Subscription

    enableReporte = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.planificaciones.show")
        this.sub = this.route.params.subscribe(params => {
            this.planificacionId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.enableReporte = data.enableReporte ?? this.enableReporte
        })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
