import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"

export class AsociacionForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            asociacion: {
                label: "Asociación",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
