<div class="tabla-container clearfix">
  <!-- Tabla nueva -->
  <div class="scroll-wrap">
    <table class="table tabla-resumen" *ngIf="avancePorAlumno.length > 0">
      <thead>
        <tr>
          <th class="">Estudiante</th>
          <th class="">Avance</th>
          <th class="">Logro Promedio</th>
          <th class="">Tiempo Promedio</th>
          <th class="">Fecha Finalización</th>
        </tr>
      </thead>
      <tbody *ngFor="let avance of avancePorAlumno">
        <tr>
          <td class="usuario">
            <p>
              <fa name="user" class="icono"></fa>
              {{ avance.nombre_alumno }}
            </p>
            <p>
              <fa name="id-card-o" class="icono"></fa>
              {{ avance.identificador_alumno }}
            </p>
          </td>
          <td class="avance">
            <div class="content-avance">
              <div class="content-label-barra">
                <label class="label-barra">{{ avance.porcentaje_avance }}%</label>
              </div>
              <div class="barra-wrap">
                <div class="barra-avance" [class.fullwidth]="true">
                  <div class="avance" [style.width]="avance.porcentaje_avance + '%'"></div>
                </div>
              </div>
            </div>
          </td>
          <td class="notaPromedio">
            <div class="content-label">{{ avance.porcentaje_logro_promedio }}%</div>
          </td>
          <td class="tiempoPromedio">
            <div class="content-label">{{ avance.tiempo_promedio | easyplaceholder: "-" }}</div>
          </td>
          <td class="fecha">
            <div class="content-label">{{ avance.fecha_finalizacion | date | easyplaceholder: "-" }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<paginator *ngIf="totalAlumnos" [per]="10" [getList]="this.getAvancePorAlumnos.bind(this)"></paginator>