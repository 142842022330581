import { BaseForm, Validations } from "@puntaje/shared/core"
import { Banners } from "./banners.service"
import { BannerTipo } from "./banner_tipos.model"
import { BannerTipos } from "./banner_tipos.service"
import { ImageBannersForm } from "./image_banners.form"
import { IframeBannersForm } from "./iframe_banners.form"
export class BannerForm extends BaseForm {
    public static form: any = {
        id: { label: "Id", type: "hidden", visible: false },
        nombre: { label: "Nombre", type: "text", visible: true },
        fecha_inicial: { label: "Fecha Inicio", type: "datepicker", visible: true },
        fecha_final: { label: "Fecha Fin", type: "datepicker", visible: true },
        banner_tipo_id: { label: "BannerTipo", type: "select", visible: true, options: { class: BannerTipos } },
        max_clicks: { label: "Max Clicks", type: "number", visible: true },
        max_impresiones: { label: "Max Impresiones", type: "number", visible: true },
        created_at: { label: "Fecha de creación", type: "hidden", visible: true },
        updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
    }

    public static formImageBanner: any = {
        id: { label: "Id", type: "hidden", visible: false },
        nombre: { label: "Nombre", type: "text", visible: true },
        fecha_inicial: { label: "Fecha Inicio", type: "datepicker", visible: true },
        fecha_final: { label: "Fecha Fin", type: "datepicker", visible: true },
        // banner_tipo_id: {label: "BannerTipo", type: "select", visible: true, options: {class: BannerTipos}},
        max_clicks: { label: "Max Clicks", type: "number", visible: true },
        max_impresiones: { label: "Max Impresiones", type: "number", visible: true },
        image_banners: { type: "models", class: ImageBannersForm, hidden: true },
        created_at: { label: "Fecha de creación", type: "hidden", visible: true },
        updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
    }

    public static formIframeBanner: any = {
        id: { label: "Id", type: "hidden", visible: false },
        nombre: { label: "Nombre", type: "text", visible: true },
        fecha_inicial: { label: "Fecha Inicio", type: "datepicker", visible: true },
        fecha_final: { label: "Fecha Fin", type: "datepicker", visible: true },
        // banner_tipo_id: {label: "BannerTipo", type: "select", visible: true, options: {class: BannerTipos}},
        max_clicks: { label: "Max Clicks", type: "number", visible: true },
        max_impresiones: { label: "Max Impresiones", type: "number", visible: true },
        iframe_banners: { type: "models", class: IframeBannersForm, hidden: true },
        created_at: { label: "Fecha de creación", type: "hidden", visible: true },
        updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
    }

    public static getFormByTipo(bannerName?: string) {
        if (bannerName == "Imagen con link") {
            return this.formImageBanner
        }
        if (bannerName == "Iframe") {
            return this.formIframeBanner
        }
        return this.form
    }

    public static get formParams() {
        return this.form
    }
}
