import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./estadisticas-evaluacion-recomendada.component.html",
    styleUrls: ["./estadisticas-evaluacion-recomendada.component.scss"]
})
export class EstadisticasEvaluacionRecomendadaComponent implements OnInit {
    sub: Subscription
    evaluacionSugeridaId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionSugeridaId = +params["id"]
        })
    }
}
