import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { PlanEstudio, PlanEstudios } from "@puntaje/nebulosa/api-services"
import { ToolNavbar } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_estudios.toolnavbar.component.html",
    selector: "plan_estudio-toolnavbar"
})
export class PlanEstudiosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() plan_estudio: PlanEstudio
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(router: Router, route: ActivatedRoute) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/plan_estudios"
        this.urlNew = "/plan_estudios/new"
        if (this.plan_estudio != null) {
            this.urlEdit = "/plan_estudios/" + this.plan_estudio.id + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.plan_estudio != null) {
            this.urlEdit = "/plan_estudios/" + this.plan_estudio.id + "/edit"
        }
        super.edit()
    }
}
