import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Establecimiento } from "@puntaje/puntaje/api-services"
import { ToolNavbar } from "@puntaje/shared/core"
import { EstablecimientoContactosBuscadorService } from "./establecimiento_contactos_buscador.service"

@Component({
    templateUrl: "establecimiento_contactos.toolnavbar.component.html",
    selector: "establecimiento-contacto-toolnavbar"
})
export class EstablecimientoContactosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() establecimientoId: number
    @Input() establecimientoContactoId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected establecimientoContactosBuscadorService: EstablecimientoContactosBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/establecimientos/" + this.establecimientoId + "/establecimiento_contactos"
        this.urlNew = "/establecimientos/" + this.establecimientoId + "/establecimiento_contactos/new"
        if (this.establecimientoContactoId != null) {
            this.urlEdit =
                "/establecimientos/" +
                this.establecimientoId +
                "/establecimiento_contactos/" +
                this.establecimientoContactoId +
                "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.establecimientoContactoId != null) {
            this.urlEdit =
                "/establecimientos/" +
                this.establecimientoId +
                "/establecimiento_contactos/" +
                this.establecimientoContactoId +
                "/edit"
        }
        super.edit()
    }

    search() {
        this.establecimientoContactosBuscadorService.openSearch.next()
    }
}
