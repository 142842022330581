import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { PaginatorComponent } from "@puntaje/shared/core"
import { PlanPersonalSesiones } from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-clase-sesion-detalle-por-contenido-admin",
    templateUrl: "./plan-clase-sesion-detalle-por-contenido-admin.component.html",
    styleUrls: ["./plan-clase-sesion-detalle-por-contenido-admin.component.scss"]
})
export class PlanClaseSesionDetallePorContenidoAdminComponent implements OnInit {
    @Input() planPersonalSesionId: number
    @Input() nombre: string
    detallePorContenidoAlumnos: any
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    private _conjunto: any
    totalAlumnos: number
    @Input()
    get conjunto() {
        return this._conjunto
    }
    set conjunto(conjunto: any) {
        this._conjunto = conjunto
        this.totalAlumnos = Object.keys(conjunto).length
    }

    constructor(protected planPersonalSesionesService: PlanPersonalSesiones) {}

    ngOnInit() {
        this.getData()
    }

    getData = async (page: number = 1, count: number = 10) => {
        const params = {
            page,
            per: count,
            nombre: this.nombre,
            conjunto: this._conjunto
        }
        this.detallePorContenidoAlumnos = await this.planPersonalSesionesService.detalleContenidoSesionConjunto(
            this.planPersonalSesionId,
            params
        )
        return this.totalAlumnos
    }
}
