import { Component } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    template: `


        <ll-titulo>Demo</ll-titulo>


        <demo></demo>


    `
})
export class DemoComponent {
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;

    constructor(protected titleService: TitleService) {}
}
