import { Component } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "planificaciones-estadisticas",
    templateUrl: "./planificaciones-estadisticas.component.html",
    styleUrls: ["./planificaciones-estadisticas.component.scss"]
})
export class PlanificacionesEstadisticasComponent {
    planId: number

    onSelect(planPersonalId) {
        this.planId = planPersonalId
    }
}
