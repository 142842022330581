import { Component, OnInit, Input, Optional, ViewChild, ChangeDetectorRef, EventEmitter } from "@angular/core"
import { ActivatedRoute, Params } from "@angular/router"
import { Material, Materiales, Asignaturas, Asignatura, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { MaterialRelacion, MaterialRelaciones } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    templateUrl: "streaming_videos_list_admin.component.html",
    selector: "streaming-videos-list-admin",
    styleUrls: ["streaming_videos_list_admin.component.scss"]
})
export class StreamingVideosListAdminComponent implements OnInit {
    asignaturaId: number
    tipoMaterial: number
    plataformaId: number
    tipoAsignatura: string
    @Input() tipoStreaming: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    materiales: Material[]
    materialesRelacionByMaterialId: any
    openModal: EventEmitter<any> = new EventEmitter<any>()
    showMaterialPreview: boolean = false
    showEvaluacionPreview: boolean = false
    materialRelacionSelected: MaterialRelacion

    constructor(
        private route: ActivatedRoute,
        protected materialesService: Materiales,
        public cdr: ChangeDetectorRef,
        protected materialRelacionesService: MaterialRelaciones,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.plataformaId = config.plataforma.id
        this.tipoMaterial = TipoMaterial.STREAMING
        //this.setData(1,12);
    }

    setData(page: number, per: number) {
        if (this.loadingLayout) this.loadingLayout.standby()
        let query: any = {
            material: {
                asignatura_id: this.asignaturaId,
                visible: 1,
                material_tipo_id: 3
            },
            plataforma_id: config.plataforma.id,
            page: page,
            per: per,
            sort_by: "created_at",
            order: "desc"
        }
        return this.materialesService.where(query).then((response: Material[], total: number) => {
            this.materiales = response
            if (this.loadingLayout) this.loadingLayout.ready()
            this.getAdditionalData()
            return total
        })
    }

    updateAsignatura(event) {
        this.asignaturaId = event
        if (this.paginator && this.asignaturaId) this.paginator.reload()
    }

    getAdditionalData() {
        let materialesIds = this.materiales.map(m => m.id)
        this.materialRelacionesService
            .where({ material_relacion: { material_id: materialesIds } })
            .then((response: MaterialRelacion[]) => {
                this.materialesRelacionByMaterialId = (response as any).groupBy(mr => mr.material_id)
            })
    }

    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[2].length == 11 ? match[2] : false
    }

    previewMaterialRelacionado(mr: MaterialRelacion) {
        this.showMaterialPreview = false
        this.showEvaluacionPreview = false
        this.materialRelacionSelected = new MaterialRelacion()
        this.materialRelacionSelected.id = mr.id
        this.materialRelacionSelected.material_id = mr.material_id
        this.materialRelacionSelected.relacion_type = mr.relacion_type
        this.materialRelacionSelected.relacion_id = mr.relacion_id
        this.openModal.emit()
    }

    loadPreview() {
        this.showMaterialPreview = false
        this.showEvaluacionPreview = false
        setTimeout(() => {
            if (this.materialRelacionSelected.relacion_type == "guia") {
                this.showMaterialPreview = true
            }
            if (this.materialRelacionSelected.relacion_type == "evaluacion") {
                this.showEvaluacionPreview = true
            }
        }, 100)
    }

    showAsociarOptions(material: Material) {
        this.showMaterialPreview = false
        this.showEvaluacionPreview = false
        this.materialRelacionSelected = new MaterialRelacion()
        this.materialRelacionSelected.material_id = material.id
        this.materialRelacionSelected.relacion_type = "guia"
        //this.showMaterialPreview = false;
        this.openModal.emit()
    }

    confirmAsociar() {
        if (
            this.materialRelacionSelected.material_id &&
            this.materialRelacionSelected.relacion_id &&
            this.materialRelacionSelected.relacion_type
        ) {
            if (this.materialRelacionSelected.id) {
                this.materialRelacionesService
                    .update(this.materialRelacionSelected.id, this.materialRelacionSelected)
                    .then((materialRelacion: MaterialRelacion) => {
                        this.paginator.reload()
                    })
            } else {
                this.materialRelacionesService
                    .save(this.materialRelacionSelected)
                    .then((materialRelacion: MaterialRelacion) => {
                        this.paginator.reload()
                    })
            }
        }
    }

    confirmDelete(mr: MaterialRelacion) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea desasociar este recurso?")
        this.simpleModalService.setModalCallback(() => this.deleteMaterialRelacion(mr.id))
        this.simpleModalService.showSimpleModal()
    }

    deleteMaterialRelacion(id: number) {
        this.materialRelacionesService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }
}
