<div class="content-container">
    <div class="sidemenu-col">
        <ng-content select="cui-layout-sidebar"></ng-content>
    </div>
    <div class="custom-row">
        <div class="content-col">
            <ng-content select="cui-layout-header"></ng-content>
            <div class="container-fluid bottom-padding">
                <cui-titlebar [class.print-hide]="hideTitleOnPrint">
                    <cui-titlebar-titulo>
                        <l-slot name="titulo"></l-slot>
                    </cui-titlebar-titulo>
                    <cui-titlebar-subtitulo>
                        <l-slot name="subtitulo"></l-slot>
                    </cui-titlebar-subtitulo>
                </cui-titlebar>
                <div [class.logged-layout-tabs]="showTabs" #loggedLayoutTabs>
                    <l-slot name="tabs"></l-slot>
                </div>

                <div [class.logged-layout-contenido]="showContenido" #loggedLayoutContenido>
                    <l-slot name="toolnavbar"></l-slot>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <div class="push-footer"></div>
</div>
<ng-content select="cui-layout-footer"></ng-content>
