import { Component, Input, OnInit } from "@angular/core"

import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "instrumentos-mensual",
    templateUrl: "instrumentos_mensual.component.html",
    styleUrls: ["instrumentos_mensual.component.scss"]
})
export class InstrumentosMensualComponent implements OnInit {
    @Input() month: number
    @Input() year: number
    @Input() tipoInstrumento: string

    asignaturas: Asignatura[]

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(private store: Store<State>) {}

    ngOnInit() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturas = asignaturasByEvaluacionTipo[this.tipoInstrumento]
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}
