import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { StreamingModule as StreamingMod } from "@puntaje/puntaje/new-modules/streaming"

import { UtilModule } from "@puntaje/shared/core"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { StreamingComponent } from "./streaming.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { StreamingAlumnosComponent } from "./streaming-alumnos.component"
import { StreamingAdminComponent } from "./streaming-admin.component"
import { MaterialStreamingComponent } from "./material_streaming.component"

@NgModule({
    imports: [CommonModule, StreamingMod, BannersModule, UtilModule, SharedModule, CUILayoutsModule],
    exports: [StreamingComponent, StreamingAlumnosComponent, StreamingAdminComponent, MaterialStreamingComponent],
    declarations: [StreamingComponent, StreamingAlumnosComponent, StreamingAdminComponent, MaterialStreamingComponent]
})
export class PuntajeStreamingModule {}
