import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Noticia } from "@puntaje/puntaje/api-services"
import { ToolNavbar } from "@puntaje/shared/core"

@Component({
    templateUrl: "noticias.toolnavbar.component.html",
    selector: "noticia-toolnavbar"
})
export class NoticiasToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() noticiaId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true

    constructor(router: Router, route: ActivatedRoute) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/noticias"
        this.urlNew = "/noticias/new"
        if (this.noticiaId != null) {
            this.urlEdit = "/noticias/" + this.noticiaId + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.noticiaId != null) {
            this.urlEdit = "/noticias/" + this.noticiaId + "/edit"
        }
        super.edit()
    }
}
