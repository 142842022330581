<loading-layout #loadingLayout>
    <establecimiento-contactos-buscador [establecimientoId]="establecimientoId"></establecimiento-contactos-buscador>
    <table-with-actions
        [getObjects]="showAll"
        [contentArray]="establecimientoContactos"
        [showParams]="params"
        [tableButtonsArray]="tableButtonsArray"
    ></table-with-actions>
    <paginator [getList]="showAll"></paginator>
</loading-layout>
