<div class="filtro">
    <div class="form-group row">
        <div class="col-md-12 col-xs-6">
            <label>Usuario a ingresado desde:</label>
            <input type="date" class="form-control" [(ngModel)]="startDate" (change)="loadData()" />
        </div>
        <div class="col-md-12 col-xs-6">
            <label>Usuario a ingresado hasta:</label>
            <input type="date" class="form-control" [(ngModel)]="endDate" (change)="loadData()" />
        </div>
        <hr />
        <div class="col-md-12 col-xs-6">
            <label>Establecimiento:</label>
            <autocomplete
                class="autocomplete"
                [(ngModel)]="establecimiento"
                [options]="establecimientos"
                (filterChange)="getEstablecimientos($event)"
                (controlValue)="getGrupoUsuarios($event)"
                showTextFun="toStringWithIdPais"
            ></autocomplete>
        </div>
        <hr />
        <div class="col-md-12 col-xs-6">
            <label>Curso:</label>
            <select class="form-control" [(ngModel)]="grupoUsuario" (change)="loadData()">
                <option selected [ngValue]="null">Seleccione curso</option>
                <option *ngFor="let gu of grupoUsuarios" [ngValue]="gu">
                    {{ gu.nombre }}
                </option>
            </select>
        </div>
    </div>
</div>
