<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <model-simple-view
            *ngFor="let key of params | keys"
            [params]="params[key]"
            [value]="bannerZona"
            [key]="key"
        ></model-simple-view>
    </div>
</loading-layout>
