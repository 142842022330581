import { Component, OnInit, Input } from "@angular/core"
import { AsignaturaEvaluacionTipoEstablecimientos, AsignaturaEvaluacionTipos } from "@puntaje/puntaje/api-services"

@Component({
    selector: "asignaturas-establecimiento",
    templateUrl: "./asignaturas-establecimiento.component.html",
    styleUrls: ["./asignaturas-establecimiento.component.scss"]
})
export class AsignaturasEstablecimientoComponent implements OnInit {
    @Input() establecimientoId: number

    asignaturasByEvaluacionTipo: any = {}

    constructor(
        private asignaturaEvaluacionTipoEstablecimientosService: AsignaturaEvaluacionTipoEstablecimientos,
        private asignaturaEvaluacionTipos: AsignaturaEvaluacionTipos
    ) {}

    ngOnInit() {
        const params = {
            asignatura_evaluacion_tipo_establecimiento: {
                establecimiento_id: this.establecimientoId
            },
            render_options: {
                include: { asignatura_evaluacion_tipo: { include: "evaluacion_tipo" } }
            }
        }

        this.asignaturaEvaluacionTipoEstablecimientosService
            .where(params)
            .then(asignaturaEvaluacionTipoEstablecimientos => {
                this.asignaturasByEvaluacionTipo = asignaturaEvaluacionTipoEstablecimientos.reduce(
                    (acc, asignaturaEvaluacionTipoEstablecimiento) => {
                        acc[
                            asignaturaEvaluacionTipoEstablecimiento.asignatura_evaluacion_tipo.evaluacion_tipo.evaluacion_tipo
                        ] =
                            acc[
                                asignaturaEvaluacionTipoEstablecimiento.asignatura_evaluacion_tipo.evaluacion_tipo
                                    .evaluacion_tipo
                            ] || []
                        acc[
                            asignaturaEvaluacionTipoEstablecimiento.asignatura_evaluacion_tipo.evaluacion_tipo
                                .evaluacion_tipo
                        ].push(asignaturaEvaluacionTipoEstablecimiento.asignatura_evaluacion_tipo.asignatura_plataforma)

                        return acc
                    },
                    {}
                )
            })
    }
}
