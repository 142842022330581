import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    PlanPersonal,
    PlanPersonales,
    PlanPersonalForm,
    PlanPersonalCiclo,
    PlanPersonalTipos,
    PlanPersonalTipo
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    ClasificacionTipo,
    Asignatura,
    Asignaturas,
    TipoInstrumento,
    TipoInstrumentos,
    GeneradorInstrumentos,
    GeneradorInstrumento
} from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "planificaciones-new",
    templateUrl: "./planificaciones-new.component.html",
    styleUrls: ["./planificaciones-new.component.scss"]
})
export class PlanificacionesNewComponent implements OnInit {
    planificacion: PlanPersonal = new PlanPersonal()
    params = PlanPersonalForm.formParams
    form: UntypedFormGroup
    pais: string
    logged_layout_title = "Agregar planificación"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    tipoInstrumentos: TipoInstrumento[]
    asignaturas: Asignatura[]
    asignatura: Asignatura
    tipoInstrumento: TipoInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento
    planPersonalCiclos: PlanPersonalCiclo[] = []
    planPersonalCiclo: PlanPersonalCiclo = new PlanPersonalCiclo()
    planPersonalTipo: PlanPersonalTipo
    planPersonalTipos: PlanPersonalTipo[]

    config: typeof config = config

    constructor(
        protected planificacionesService: PlanPersonales,
        protected router: Router,
        private asignaturasService: Asignaturas,
        protected authService: AuthService,
        private tipoInstrumnetosService: TipoInstrumentos,
        private generadorInatrumentoService: GeneradorInstrumentos,
        protected planPersonalTiposService: PlanPersonalTipos,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.setData()
        this.form = PlanPersonalForm.getForm(this.planificacion)
        this.getdataPrincipal()
        this.loadingLayout.ready()
    }

    checkPlanPersonalTipo() {
        this.planPersonalTipo = this.planPersonalTipos.find(ppt => ppt.id == this.planificacion.plan_personal_tipo_id)
        if (this.planPersonalTipo.tipo !== "plan de clase") {
            this.planificacion.libro_url_profesor = undefined
            this.planificacion.libro_url_alumno = undefined
        }
        this.cdr.detectChanges()
    }

    setData() {
        this.planificacion.usuario_id = this.authService.getUserData().id
        this.planPersonalCiclo["orden"] = 0

        this.planPersonalCiclos.push(this.planPersonalCiclo)
        this.planificacion.plan_personal_ciclos = this.planPersonalCiclos
    }

    save() {
        PlanPersonalForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planificacionesService.save(this.planificacion).then(response => {
                this.router.navigate(["planificaciones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.planificacion = new PlanPersonal()
            PlanPersonalForm.markFormControlsAsPristine(this.form)
            PlanPersonalForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    async getdataPrincipal() {
        this.asignaturas = (await this.asignaturasService.where({
            plataforma: this.config.plataforma.name
        })) as Asignatura[]
        this.tipoInstrumentos = (await this.tipoInstrumnetosService.where()) as TipoInstrumento[]
        this.tipoInstrumento = this.tipoInstrumentos.find(
            tp => tp.tipo_instrumento == config.plataforma.evaluacionDefault
        )
        this.planPersonalTipos = await this.planPersonalTiposService.where()

        this.loadingLayout.ready()
    }

    async getGeneradorInstrumento() {
        if (this.asignatura && this.tipoInstrumentos) {
            let params = {
                asignatura_plataforma: {
                    asignatura_id: this.asignatura.id,
                    plataforma_id: this.config.plataforma.id
                },
                tipo_instrumento: {
                    id: this.tipoInstrumento.id
                },
                visible: true
            }
            this.generadorInstrumentos = (await this.generadorInatrumentoService.where(
                params
            )) as GeneradorInstrumento[]
        }
    }
}
