import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"

import { CuentasComponent } from "./cuentas.component"
import { CuentasEditComponent } from "./cuentas_edit.component"
import { CuentasNewComponent } from "./cuentas_new.component"
import { CuentasDeshabilitadasComponent } from "./cuentas_deshabilitadas.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [CommonModule, FormsModule, CommonsLayoutsModule, GrupoUsuariosModule, UsuariosModule, CUILayoutsModule],
    declarations: [CuentasComponent, CuentasEditComponent, CuentasNewComponent, CuentasDeshabilitadasComponent],
    exports: [CuentasComponent, CuentasEditComponent, CuentasNewComponent, CuentasDeshabilitadasComponent]
})
export class PuntajeCuentasModule {}
