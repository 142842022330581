import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { AsociacionContacto, AsociacionContactos, AsociacionContactoForm } from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "asociacion-contactos-new",
    templateUrl: "asociacion_contactos.form.component.html"
})
export class AsociacionContactosNewComponent implements OnInit {
    @Input() asociacionId: number

    asociacionContacto: AsociacionContacto = new AsociacionContacto()
    params = AsociacionContactoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar asociacion"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected asociacionContactosService: AsociacionContactos, protected router: Router) {}

    ngOnInit() {
        this.asociacionContacto.asociacion_id = this.asociacionId
        this.form = AsociacionContactoForm.getForm(this.asociacionContacto)
        this.loadingLayout.ready()
    }

    save() {
        AsociacionContactoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            ;(this.asociacionContacto as any).tipo_contacto_ids = this.asociacionContacto.tipo_contactos.map(
                tc => tc.id
            )

            this.asociacionContactosService.save(this.asociacionContacto).then(response => {
                this.router.navigate(["asociaciones/" + this.asociacionId + "/asociacion_contactos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asociacionContacto = new AsociacionContacto()
            this.asociacionContacto.asociacion_id = this.asociacionId
            AsociacionContactoForm.markFormControlsAsPristine(this.form)
            AsociacionContactoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
