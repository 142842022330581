import { Component, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezona_zonas.new.component.html"
})
export class BannerZonasNewComponent {
    loggedLayoutTitle = "Agregar zona"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}
}
