import { BaseForm } from "@puntaje/shared/core"

export class EvaluacionSugeridaForm extends BaseForm {
    private static convertToObject(array: Array<String>) {
        var pairs = []
        array.forEach((v, i) => {
            pairs.push({
                id: v,
                toString() {
                    return v
                }
            })
        })
        return pairs
    }

    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            evaluacion_id: { label: "Evaluacion Id", type: "number", visible: true },
            evaluacion_multiple_id: { label: "Evaluacion multiple Id", type: "number", visible: true },
            nombre: { label: "Nombre", type: "text", visible: true }
        }
    }
}
