<table
    class="table table-striped table-bordered"
    (window:resize)="onResize($event)"
    [class.smallTable]="showSmallTable"
>
    <tbody>
        <tr class="showBig">
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <th *ngIf="show_params[key].tableVisible">{{ show_params[key].label }}</th>
            </ng-template>
            <th>Acciones</th>
        </tr>
        <tr *ngFor="let content of contentArray; let i = index; trackBy: id" class="showBig">
            <ng-template ngFor let-key [ngForOf]="show_params | keys">
                <td *ngIf="show_params[key].tableVisible">
                    <model-table-cell [params]="show_params[key]" [value]="content" [key]="key"></model-table-cell>
                </td>
            </ng-template>
            <td>
                <table-with-actions-buttons
                    [tableButtonsArray]="tableButtonsArray(content)"
                    [functionParameter]="content"
                ></table-with-actions-buttons>
            </td>
        </tr>

        <tr *ngFor="let content of contentArray; let i = index; trackBy: id" class="showSmall">
            <td>
                <ng-template ngFor let-key [ngForOf]="show_params | keys">
                    <tr>
                        <td *ngIf="show_params[key].tableVisible">{{ show_params[key].label }}</td>
                        <td *ngIf="show_params[key].tableVisible">
                            <model-table-cell
                                [params]="show_params[key]"
                                [value]="content"
                                [key]="key"
                            ></model-table-cell>
                        </td>
                    </tr>
                </ng-template>
            </td>
        </tr>
    </tbody>
</table>
