import { Subscription } from "rxjs"

import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

import { Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_instrumento_validado.component.html",
    styleUrls: ["generar_instrumento_validado.component.scss"]
})
export class GenerarInstrumentoValidadoComponent implements OnInit {
    //El único lugar donde si o si estaban todas bien
    tipoInstrumentos: string[] = config.plataforma.evaluacionTipos
    tipoInstrumento: string = config.plataforma.evaluacionDefault

    constructor(
        protected titleService: TitleService,
        private router: Router,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos
    ) {}

    async ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.generar_instrumento_validado")
    }
}
