import { Component, Input, HostBinding, Directive, ContentChild } from "@angular/core"

@Directive({ selector: "cui-accordion-content" })
export class CUIAccordionContent {}

@Directive({ selector: "cui-accordion-title" })
export class CUIAccordionTitle {}

@Directive({ selector: "cui-accordion-info" })
export class CUIAccordionInfo {}

@Directive({ selector: "cui-accordion-icon-open" })
export class CUIAccordionIconOpen {}

@Directive({ selector: "cui-accordion-icon-close" })
export class CUIAccordionIconClose {}

@Directive({ selector: "cui-accordion-icon-rotating" })
export class CUIAccordionIconRotating {}

@Component({
    selector: "cui-accordion",
    templateUrl: "cui-accordion.component.html",
    styleUrls: ["cui-accordion.component.scss"]
})
export class CUIAccordionComponent {
    @Input("boxStyle") boxStyle: "boxed" | "tree" | "default" = "default"
    @Input("iconPosition") iconPosition: "left" | "none" | "right" = "right"
    @Input("size") size: "small" | "large" | "medium" = "medium"
    @Input("id") id: string
    @Input() rotatingIcon: boolean = false
    @Input() startOpen: boolean = false
    @ContentChild(CUIAccordionIconOpen) customOpenIcon: CUIAccordionIconOpen
    @ContentChild(CUIAccordionIconOpen) customCloseIcon: CUIAccordionIconClose
    @ContentChild(CUIAccordionIconRotating) customRotatingIcon: CUIAccordionIconRotating
    @ContentChild(CUIAccordionContent) content: CUIAccordionContent
    constructor() {}
}
