import { TextView, DateView, BooleanView } from "@puntaje/shared/core"
import { AsignaturaEvaluacionTipos } from "./asignatura_evaluacion_tipos.service"

export class AsignaturaEvaluacionTiposView {
    public static get viewParams() {
        return {
            id: {
                label: "Id",
                type: TextView,
                tableVisible: true,
                options: { class: AsignaturaEvaluacionTipos, key: "id" }
            },
            abreviacion: {
                label: "Abreviación",
                type: TextView,
                tableVisible: true
            },
            nombre_evaluacion_tipo: {
                label: "Evaluación",
                type: TextView,
                tableVisible: true
            },
            asignatura: { label: "Asignatura", type: TextView, tableVisible: true },
            predefinido: { label: "Predefinido", type: BooleanView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
