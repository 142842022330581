<menu-asignaturas-horizontal
    [asignaturaId]="asignaturaId"
    [enableResponsive]="true"
    [tipoAsignaturas]="tipoAsignatura"
    (asignaturaChanged)="updateAsignatura($event)"
></menu-asignaturas-horizontal>
<loading-layout #loadingLayout>
    <ng-container *ngIf="materiales && materiales.length != 0">
        <table class="table tabla-materiales">
            <thead>
                <tr>
                    <th colspan="2">Video</th>
                    <th>Fecha de subida</th>
                    <th>Material asociado</th>
                    <th class="acciones">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of materiales">
                    <td class="thumb">
                        <span>
                            <img [src]="m.thumbnail? m.thumbnail : 'https://img.youtube.com/vi/' + getYoutubeVideoId(m.url) + '/mqdefault.jpg'" />
                        </span>
                    </td>
                    <td>{{ m.material }}</td>
                    <td>{{ m.created_at | prettydate }}</td>
                    <td>
                        <ng-container *ngIf="materialesRelacionByMaterialId && materialesRelacionByMaterialId[m.id]">
                            <p class="mr-container" *ngFor="let mr of materialesRelacionByMaterialId[m.id]">
                                <a (click)="previewMaterialRelacionado(mr)">
                                    <span *ngIf="mr.relacion_type == 'guia'">
                                        <ogr-icon class="icono guia" name="guia"></ogr-icon>
                                        Guía: #{{ mr.relacion_id }}
                                    </span>
                                    <span *ngIf="mr.relacion_type == 'evaluacion'">
                                        <ogr-icon class="icono evaluacion" name="editar-prueba"></ogr-icon>
                                        Evaluación: #{{ mr.relacion_id }}
                                    </span>
                                </a>
                                <button type="button" class="btn-clear" (click)="confirmDelete(mr)">
                                    <fa name="times"></fa>
                                </button>
                            </p>
                        </ng-container>
                    </td>
                    <td class="acciones">
                        <a (click)="showAsociarOptions(m)">Asociar material</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <p *ngIf="materiales && materiales.length == 0" class="not-found">No se encontraron videos para esta asignatura.</p>
</loading-layout>
<paginator
    *ngIf="asignaturaId"
    [getList]="setData.bind(this)"
    [class.hidePaginator]="!materiales || (materiales && materiales.length == 0)"
    [per]="12"
></paginator>

<generic-modal [buttonClicked]="openModal" [id]="'modal-material-relacion'">
    <modal-titulo class="modal-titulo">Material o evaluación asociado</modal-titulo>
    <modal-contenido class="modal-contenido">
        <div class="form-material-relacionado" *ngIf="materialRelacionSelected">
            <select [(ngModel)]="materialRelacionSelected.relacion_type" class="form-control tipo-select">
                <option [ngValue]="'guia'">Guía</option>
                <option [ngValue]="'evaluacion'">Evaluación</option>
            </select>
            <input
                class="form-control input-relacion-id"
                placeholder="ID"
                type="text"
                [(ngModel)]="materialRelacionSelected.relacion_id"
            />
            <cui-button class="btn_style" (click)="loadPreview()">
                <fa name="search"></fa>
                Previsualizar
            </cui-button>
        </div>
        <br />
        <material-preview [id]="materialRelacionSelected.relacion_id" *ngIf="showMaterialPreview"></material-preview>
        <p *ngIf="showEvaluacionPreview">Previsualización de evaluaciones aún no disponible.</p>
    </modal-contenido>
    <modal-botones>
        <cui-button type="button" class="btn_style" data-dismiss="modal" (click)="confirmAsociar()">
            Asociar y guardar
        </cui-button>
        <cui-button buttonType="cancel" type="button" class="btn_style_2" data-dismiss="modal">Cerrar</cui-button>
    </modal-botones>
</generic-modal>
