import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config = config

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {}

    ngOnInit() {
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }
}
