<ll-titulo>{{ nombreEvaluacionTipoPlural | capitalize }} breves</ll-titulo>
<p class="text-justify">
    En esta sección encontrarás mini {{ nombreEvaluacionTipoPlural }} de 20 preguntas cada uno, los cuáles son de
    carácter rotativo, es decir, están disponibles durante un mes antes de ser renovados.
</p>
<p class="text-justify">
    Una vez renovados no tendrás la oportunidad de hacer el {{ nombreEvaluacionTipoSingular }} del mes anterior, por lo
    que te recomendamos estar al día con tu ejercitación.
</p>
<p class="text-justify">
    En un mes puedes hacer el mismo {{ nombreEvaluacionTipoSingular }} cuantas veces quieras, pero debes tener presente
    que el puntaje que será válido para las estadísticas será solamente el primero que hagas de cada mes. ¡Te deseamos
    el mayor de los éxitos!
</p>
<ensayos-breves [tracking]="true"></ensayos-breves>
