import { EntityView, LinkView, TextView } from "@puntaje/shared/core"
import { InstrumentoAsSugeridaView } from "./instrumento_as_sugerida.view"
import { Evaluaciones } from "./evaluaciones.service"

export class EvaluacionSugeridaView {
    public static get viewParams() {
        return {
            id: {
                label: "Id",
                type: TextView,
                tableVisible: true
            },
            nombre: {
                label: "Nombre",
                type: TextView,
                tableVisible: true
            },
            asignatura_id: {
                label: "Asignatura Id",
                type: TextView,
                tableVisible: true
            },
            evaluacion_id: {
                label: "Evaluacion ID",
                type: LinkView,
                tableVisible: true,
                options: {
                    class: Evaluaciones,
                    key: "evaluacion_id"
                }
            },
            instrumento: {
                type: EntityView,
                tableVisible: true,
                class: InstrumentoAsSugeridaView
            }
        }
    }
}
