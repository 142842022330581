<loading-layout>
    <datos-generales-plan-clase *ngIf="planPersonal" [planPersonal]="planPersonal"></datos-generales-plan-clase>
    <!------------------------------------------------->
    <br />
    <div class="row">
        <div class="col-md-6">
            <filtro-plan-clase-admin-usuarios *ngIf="planPersonal" 
            [planId]="planPersonal.id"
            (conjuntoWithAvances)="setConjuntoAndAvanceSesiones($event)"
            >
        </filtro-plan-clase-admin-usuarios>
        </div>
    </div>
    <br />

    <ng-container *ngIf="avanceSesiones && (planPersonal || (planPersonal && vistaPrevia))">
        <!--Plan semanal -->
        <div *ngFor="let planPersonalSesion of planPersonalSesiones; let i = index">
            <plan-clase-semanas-admin
                [planPersonal]="planPersonal"
                [planPersonalSesion]="planPersonalSesion"
                [index]="i"
                [clasificacionActividadByMaterialId]="clasificacionActividadByMaterialId"
                [clasificacionContextoByMaterialId]="clasificacionContextoByMaterialId"
                [nombre]="nombreGrupo"
                [conjunto]="conjunto"
                [avanceConjunto]="avanceSesiones[planPersonalSesion.id]"
                (avanceChange)="avanceChanged($event)"
                [vistaPrevia]="vistaPrevia"
                [actividad]="actividad"
            ></plan-clase-semanas-admin>
        </div>
    </ng-container>
</loading-layout>
