import { PuntajeEstablecimientosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { AdminLayoutsModule } from "../layouts/index"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"

import { establecimientosRouting } from "./admin_establecimientos.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        establecimientosRouting,
        AdminLayoutsModule,
        CommonsLayoutsModule,
        EstablecimientosModule,
        PuntajeEstablecimientosModule
    ],
    declarations: [],
    exports: []
})
export class AdminEstablecimientosModule {
    static forRoot(): ModuleWithProviders<AdminEstablecimientosModule> {
        return {
            ngModule: AdminEstablecimientosModule,
            providers: []
        }
    }
}
