import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core"
import { Asignaturas, Asignatura, ClasificacionTipo, ClasificacionTipos } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { GrupoUsuario, Lugar, Establecimiento, Usuario } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "estadisticas-usuarios",
    templateUrl: "estadisticas_usuarios.component.html",
    styleUrls: ["estadisticas.component.scss"]
})
export class EstadisticasUsuariosComponent implements OnInit, OnChanges {
    @Input() asignaturaId: number
    @Input() estadisticas: any
    @Input() usuarios: Usuario[]
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    asignatura: Asignatura
    @Input() evaluacionTipo: string
    @Input() lugar: Lugar
    @Input() establecimiento: Establecimiento

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    enableGraphs: boolean = false

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any

    distribucion_ensayos_tramo_puntaje: number[] = []
    desempeno_materia_cursos: string[] = []
    desempeno_materia_omitidas: number[] = []
    desempeno_materia_correctas: number[] = []
    desempeno_materia_incorrectas: number[] = []
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number
    desempeno_subejes: string[] = []
    desempeno_subejes_omitidas: number[] = []
    desempeno_subejes_correctas: number[] = []
    desempeno_subejes_incorrectas: number[] = []

    constructor(protected asignaturasService: Asignaturas, protected clasificacionTiposService: ClasificacionTipos) {}

    ngOnInit() {
        this.loadingLayout.standby()
    }

    setData() {
        this.loadingLayout.standby()

        this.clasificacionTiposEstadisticas = config.evaluaciones[this.evaluacionTipo].clasificacionTiposEstadisticas

        let cTipos = this.clasificacionTiposEstadisticas.filter(ct => ct.profesores).map(ct => ct.nombre)

        this.clasificacionTiposService
            .where({ clasificacion_tipo: { clasificacion_tipo: cTipos } })
            .then((cts: ClasificacionTipo[]) => {
                this.clasificacionTipos = cts

                this.asignaturasService.find(this.asignaturaId).then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.setValues()
                    this.enableGraphs = true
                    this.loadingLayout.ready()
                    this.onReady.emit(this.asignatura)
                })
            })
    }

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((acc, e) => {
            Object.keys(e.distribucion_calificaciones).forEach(key => {
                const calificacion = +key.replace(",", ".")

                acc[calificacion] = acc[calificacion] || 0
                acc[calificacion] += e.distribucion_calificaciones[key]
            })

            return acc
        }, {})

        let estadistica_clasificaciones = {}
        this.estadisticas.forEach(e => {
            e.estadistica_clasificaciones.forEach(ec => {
                if (!estadistica_clasificaciones[ec.clasificacion_id]) {
                    estadistica_clasificaciones[ec.clasificacion_id] = ec
                } else {
                    estadistica_clasificaciones[ec.clasificacion_id].correctas += ec.correctas
                    estadistica_clasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas
                    estadistica_clasificaciones[ec.clasificacion_id].omitidas += ec.omitidas
                }
            })
        })
        estadistica_clasificaciones = (Object as any).values(estadistica_clasificaciones)

        this.clasificacionTipos.forEach(ct => {
            let ecs = (estadistica_clasificaciones as any[]).filter(
                ec => ec.clasificacion.clasificacion_tipo_id == ct.id
            )

            let nombre = (ct as any).clasificacion_tipo

            this["desempeno_" + nombre] = ecs.map(ec => ec.clasificacion.clasificacion)
            this["desempeno_" + nombre + "_omitidas"] = ecs.map(ec => ec.omitidas)
            this["desempeno_" + nombre + "_incorrectas"] = ecs.map(ec => ec.incorrectas)
            this["desempeno_" + nombre + "_correctas"] = ecs.map(ec => ec.correctas)
            this["desempeno_" + nombre + "_total"] = ecs.map(ec => ec.omitidas + ec.incorrectas + ec.correctas)
        })

        let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0)
        this.desempeno_promedio_omitidas = ~~(
            this.estadisticas.reduce((x, e) => x + e.omitidas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_incorrectas = ~~(
            this.estadisticas.reduce((x, e) => x + e.incorrectas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_correctas = ~~(
            this.estadisticas.reduce((x, e) => x + e.correctas, 0) / numero_evaluaciones
        )

        /*let estadistica_clasificacion_cursos = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 1);
        this.desempeno_materia_cursos = estadistica_clasificacion_cursos.map((ec) => ec.clasificacion.clasificacion);
        this.desempeno_materia_omitidas = estadistica_clasificacion_cursos.map((ec) => ec.omitidas);
        this.desempeno_materia_incorrectas = estadistica_clasificacion_cursos.map((ec) => ec.incorrectas);
        this.desempeno_materia_correctas = estadistica_clasificacion_cursos.map((ec) => ec.correctas);
        let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0);
        this.desempeno_promedio_omitidas = ~~(this.estadisticas.reduce((x, e) => x + e.omitidas, 0)/numero_evaluaciones);
        this.desempeno_promedio_incorrectas = ~~(this.estadisticas.reduce((x, e) => x + e.incorrectas, 0)/numero_evaluaciones);
        this.desempeno_promedio_correctas = ~~(this.estadisticas.reduce((x, e) => x + e.correctas, 0)/numero_evaluaciones);
        let estadistica_clasificacion_subejes = estadistica_clasificaciones.filter((ec) => ec.clasificacion.clasificacion_tipo_id == 7 && ec.clasificacion.clasificacion_padre_id != null);
        this.desempeno_subejes = estadistica_clasificacion_subejes.map((ec) => ec.clasificacion.clasificacion);
        this.desempeno_subejes_omitidas = estadistica_clasificacion_subejes.map((ec) => ec.omitidas);
        this.desempeno_subejes_incorrectas = estadistica_clasificacion_subejes.map((ec) => ec.incorrectas);
        this.desempeno_subejes_correctas = estadistica_clasificacion_subejes.map((ec) => ec.correctas);*/
    }

    //TODO: deshabilitar esto cuando lo demás funcione
    setTestValues() {
        this.distribucion_ensayos_tramo_puntaje = [96, 81, 91, 155, 100, 0, 0]
        this.desempeno_materia_omitidas = [5, 3, 4, 7, 2]
        this.desempeno_materia_incorrectas = [2, 2, 3, 2, 1]
        this.desempeno_materia_correctas = [3, 4, 4, 2, 5]
        this.desempeno_promedio_omitidas = 25
        this.desempeno_promedio_correctas = 50
        this.desempeno_promedio_incorrectas = 25
        this.desempeno_subejes = ["Números Reales", "Números Complejos", "Potencias", "Raíces", "Logaritmos"]
        this.desempeno_subejes_omitidas = [849, 69, 266, 168, 255]
        this.desempeno_subejes_incorrectas = [984, 159, 407, 288, 397]
        this.desempeno_subejes_correctas = [1094, 148, 650, 461, 317]
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"]) {
            this.setData()
        }
    }
}
