<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="planPersonal.id" name="id" class="form-control" />
        </div>

        <div class="form-group">
            <label>Nombre</label>
            <input type="text" [(ngModel)]="planPersonal.plan_personal" name="planPersonal" class="form-control" />
        </div>

        <div class="form-group">
            <label>Generador Instrumento</label>
            <input
                type="text"
                [(ngModel)]="generadorInstrumento.generador_instrumento"
                name="generadorInstrumento"
                class="form-control"
            />
        </div>

        <div class="form-group">
            <label>Activo/No Activo</label>
            <select class="form-control" [(ngModel)]="planPersonal.activo" [ngModelOptions]="{ standalone: true }">
                <option [ngValue]="undefined">Todos</option>
                <option [ngValue]="true">Activo</option>
                <option [ngValue]="false">No Activo</option>
            </select>
        </div>

        <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
        <cui-button-link class="btn_style pull-right" (click)="download()">Exportar</cui-button-link>
        <br />
    </div>
</form>
