import { BaseForm } from "@puntaje/shared/core"
import { BannerPaginas } from "./banner_paginas.service"

export class BannerPaginaForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            pagina: { label: "Nombre", type: "text", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
