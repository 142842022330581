import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { InstrumentoPredefinido, InstrumentoPredefinidos, Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { InstrumentoPredefinidosBuscadorService } from "./instrumento-predefinidos-buscador.service"
import { Subscription, Observable } from "rxjs"
import { filter } from "rxjs/operators"
import {
    Asignatura,
    AsignaturaWithConfig,
    GeneradorInstrumento,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { select, Store } from "@ngrx/store"
import { selectAsignaturasById, selectAsignaturasList, State } from "@puntaje/puntaje/store"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { IMyDpOptions } from "mydatepicker"
import { SessionService } from "@puntaje/shared/core"

@Component({
    selector: "instrumento-predefinidos-buscador",
    templateUrl: "./instrumento-predefinidos-buscador.component.html",
    styleUrls: ["./instrumento-predefinidos-buscador.component.scss"]
})
export class InstrumentoPredefinidosBuscadorComponent implements OnInit {
    asignaturas: Asignatura[] = []
    generador_instrumentos: GeneradorInstrumento[] = []
    searchParams: any = { instrumento_predefinido: { like: {} } }

    asignaturas$ = this.store.pipe(
        select(selectAsignaturasList),
        filter(x => !!x)
    )

    // sirve para poder poner campos por defecto que se llenen de forma asincrona
    @Output() onReady = new EventEmitter()

    generador_instrumento_index_arr: number[]

    sub: Subscription
    sub2: Subscription

    open: boolean = false

    constructor(
        protected instrumentoPredefinidosService: InstrumentoPredefinidos,
        protected instrumentoPredefinidosBuscadorService: InstrumentoPredefinidosBuscadorService,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected store: Store<State>,
        protected usuariosService: Usuarios,
        protected sessionService: SessionService
    ) {}

    ngOnInit() {
        this.sub = this.instrumentoPredefinidosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
        //por defecto que aparezca abierto
        this.instrumentoPredefinidosBuscadorService.openSearch.next(true)

        this.sub2 = this.asignaturas$.subscribe(asignaturasById => {
            this.asignaturas = asignaturasById
        })

        this.generadorInstrumentosService
            .where({
                asignatura_plataforma: { plataforma_id: config.plataforma.id },
                visible: true
            })
            .then((response: GeneradorInstrumento[]) => {
                this.generador_instrumentos = response.sort((a, b) =>
                    a.generador_instrumento.localeCompare(b.generador_instrumento)
                )
            })

        this.onReady.emit()
        this.search()
    }

    search() {
        this.instrumentoPredefinidosBuscadorService.search(this.searchParams)
    }

    deleteIfUndefined(obj, key) {
        if (obj[key] == null || obj[key] == undefined || obj[key] == "") {
            delete obj[key]
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
