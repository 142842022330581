import { EvaluacionSugeridaView } from "@puntaje/puntaje/api-services"
import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { EvaluacionSugerida, EvaluacionSugeridas, InstrumentoPredefinido } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "evaluaciones-recomendadas-list",
    templateUrl: "./evaluaciones-recomendadas-list.component.html",
    styleUrls: ["./evaluaciones-recomendadas-list.component.scss"]
})
export class EvaluacionesRecomendadasListComponent implements OnInit {
    evaluacionesSugeridas: EvaluacionSugerida[] = []
    params = EvaluacionSugeridaView.viewParams

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    @Input() showEvaluacionMultiple: boolean = false

    constructor(
        protected evaluacionSugeridasService: EvaluacionSugeridas,
        protected simpleModalService: SimpleModalService,
        protected router: Router
    ) {}

    tableButtonsArray = {
        edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
        delete: {
            function: this.showModalDelete.bind(this),
            text: "Borrar",
            callModal: true,
            glyphicon: "glyphicon-trash"
        },
        estadisticas: {
            function: this.verEstadisticas.bind(this),
            text: "Estadisticas",
            glyphicon: "glyphicon-stats"
        }
    }

    showModalDelete(evaluacionSugerida: EvaluacionSugerida) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta evaluacion recomendada?")
        this.simpleModalService.setModalCallback(() => this.delete(evaluacionSugerida.id))
    }

    delete(id: number) {
        this.evaluacionSugeridasService.remove(id).then(response => {
            this.paginator.changePage(1)
        })
    }

    edit(evaluacionSugerida: EvaluacionSugerida) {
        this.router.navigate(["evaluacion_sugeridas", evaluacionSugerida.id, "edit"])
    }

    verEstadisticas(evaluacionSugerida: EvaluacionSugerida) {
        this.router.navigate(["evaluacion_sugeridas", evaluacionSugerida.id, "estadisticas"])
    }

    ngOnInit() {}

    getEvaluacionesSugeridas = (page: number = 1, per: number = 1) => {
        this.loadingLayout.standby()
        const params = {
            per: per,
            page: page,
            with_evaluacion_multiple: this.showEvaluacionMultiple
        }

        return this.evaluacionSugeridasService
            .where(params)
            .then((evaluacionSugeridas: EvaluacionSugerida[], total: number) => {
                this.evaluacionesSugeridas = evaluacionSugeridas
                this.evaluacionesSugeridas.forEach((es: EvaluacionSugerida) => {
                    es["instrumento"] = es.evaluacion_multiple
                        ? es.evaluacion_multiple.evaluacion_multiple_evaluaciones[0].evaluacion?.instrumento
                        : es.evaluacion?.instrumento
                })
                this.loadingLayout.ready()
                return total
            })
    }
}
