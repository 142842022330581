import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from "@angular/core"
import { PlanPersonalSesion, PlanPersonalContenidos } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { EvaluacionPreviewModalComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { MaterialPreviewModalComponent } from "@puntaje/puntaje/new-modules/materiales"
import { Router } from "@angular/router"

interface EditingSesionFields {
    fecha: boolean
    nom: boolean
    dateRangeError: boolean
}

@Component({
    selector: "planificacion-sesion",
    templateUrl: "./planificacion-sesion.component.html",
    styleUrls: ["./planificacion-sesion.component.scss"]
})
export class PlanificacionSesionComponent implements OnInit {
    hasEvaluaciones: boolean = false
    evaluacionesId: any = {}
    materialOpen = false
    modalEvaluacionId: number

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(EvaluacionPreviewModalComponent)
    evaluacionPreviewModalComponent: EvaluacionPreviewModalComponent
    @ViewChild(MaterialPreviewModalComponent)
    materialPreviewModalComponent: MaterialPreviewModalComponent

    @Input() clase: PlanPersonalSesion
    @Input() index: number
    @Input() activa: boolean
    @Input() provieneDeCompartida: boolean
    @Input() editingSesionFields: EditingSesionFields
    @Input() enableToggleActiva: boolean = false
    @Input() enableEstado: boolean = false
    @Input() enableGoToEstadisticas: boolean = false
    @Input() planificacionId: number
    @Input() enableReporte: boolean = true
    indexContenido: number

    fechasChange = new EventEmitter<any>()

    @Output() deleteClase = new EventEmitter<{ clase: PlanPersonalSesion; index: number }>()

    constructor(
        protected cdr: ChangeDetectorRef,
        protected simpleModalService: SimpleModalService,
        protected planPersonalCotenidosService: PlanPersonalContenidos,
        protected router: Router
    ) {}

    ngOnInit() {
        this.clase.plan_personal_contenidos = this.clase.plan_personal_contenidos.sort((a, b) => {
            return a.orden - b.orden
        })

        this.editingSesionFields = { fecha: false, nom: false, dateRangeError: false }
        this.evaluacionesId = { contenido: {} }

        this.clase.plan_personal_contenidos.forEach((contenido, index) => {
            if (contenido.evaluacion) {
                this.hasEvaluaciones = true
                this.evaluacionesId.contenido[index] = contenido.tipo_id // contenido.evaluacion.id
            }
        })
        this.loadingLayout.ready()
    }

    openEvalPreview(evaluacionId: number) {
        this.modalEvaluacionId = evaluacionId
        this.cdr.detectChanges()
        this.evaluacionPreviewModalComponent.open()
    }

    closeEvalPreview() {
        this.modalEvaluacionId = undefined
    }

    openMaterialPreview(material) {
        this.materialOpen = true
        this.cdr.detectChanges()
        this.materialPreviewModalComponent.open(material)
    }

    closeMaterialPreview() {
        this.materialOpen = false
        this.modalEvaluacionId = undefined
    }

    showModalDelete(contenido, i) {
        this.indexContenido = i
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este contenido?")
        this.simpleModalService.setModalCallback(() => this.delete(contenido.id))
        this.simpleModalService.showSimpleModal()
    }

    delete(id: number) {
        this.planPersonalCotenidosService.remove(id).then(response => {
            this.loadingLayout.standby()
            this.clase.plan_personal_contenidos.splice(this.indexContenido, 1)
            this.loadingLayout.ready()
        })
    }

    emitClase() {
        this.deleteClase.emit({ clase: this.clase, index: this.index })
    }

    ordenar(data) {
        data.sort((a, b) => {
            return a.orden - b.orden
        })
    }

    checkDateRange(obj) {}

    saveEdit(obj) {}

    cancelEdit(obj) {}
}
