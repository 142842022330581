import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { EstadisticasPlanificacionesComponent } from "./estadisticas-planificaciones/estadisticas-planificaciones.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { EstadisticasClaseComponent } from "./estadisticas-clase/estadisticas-clase.component"
import { PlanificacionCompartidaComponent } from "./planificacion-compartida/planificacion-compartida.component"
import { PlanificacionPredefinidaComponent } from "./planificacion-predefinida/planificacion-predefinida.component"
import { ProfesoresPlanificacionesPredefinidasComponent } from "./profesores-planificaciones-predefinidas/profesores-planificaciones-predefinidas.component"
import { ProfesoresRevisarPlanificacionesComponent } from "./profesores-revisar-planificaciones/profesores-revisar-planificaciones.component"
import { PlanificacionComponent } from "./planificacion/planificacion.component"
import { PlanificacionesEditComponent } from "./planificaciones-edit/planificaciones-edit.component"
import { SesionesNewComponent } from "./sesiones-new/sesiones-new.component"
import { SesionesEditComponent } from "./sesiones-edit/sesiones-edit.component"
import { PlanPersonalContenidoNewComponent } from "./plan-personal-contenido-new/plan-personal-contenido-new.component"
import { PlanPersonalContenidoEditComponent } from "./plan-personal-contenido-edit/plan-personal-contenido-edit.component"
import { PlanificacionesNewComponent } from "./planificaciones-new/planificaciones-new.component"
import { ListaPlanificacionesComponent } from "./lista-planificaciones/lista-planificaciones.component"
import { PlanificacionesEstadisticasComponent } from "./planificaciones-estadisticas/planificaciones-estadisticas.component"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"

@NgModule({
    declarations: [
        EstadisticasPlanificacionesComponent,
        EstadisticasClaseComponent,
        PlanificacionCompartidaComponent,
        PlanificacionPredefinidaComponent,
        ProfesoresPlanificacionesPredefinidasComponent,
        ProfesoresRevisarPlanificacionesComponent,
        ListaPlanificacionesComponent,
        PlanificacionesNewComponent,
        PlanificacionComponent,
        PlanificacionesEditComponent,
        SesionesNewComponent,
        SesionesEditComponent,
        PlanPersonalContenidoNewComponent,
        PlanPersonalContenidoEditComponent,
        PlanificacionesEstadisticasComponent
    ],
    exports: [
        EstadisticasPlanificacionesComponent,
        EstadisticasClaseComponent,
        PlanificacionCompartidaComponent,
        PlanificacionPredefinidaComponent,
        ProfesoresPlanificacionesPredefinidasComponent,
        ProfesoresRevisarPlanificacionesComponent,
        ListaPlanificacionesComponent,
        PlanificacionesNewComponent,
        PlanificacionComponent,
        PlanificacionesEditComponent,
        SesionesNewComponent,
        SesionesEditComponent,
        PlanPersonalContenidoNewComponent,
        PlanPersonalContenidoEditComponent,
        PlanificacionesEstadisticasComponent
    ],
    imports: [
        CommonModule,
        Angular2FontawesomeModule,
        PlanificacionesModule,
        PlanPersonalesModule,
        SharedModule,
        CUILayoutsModule,
        PlanClasesModule
    ]
})
export class PuntajePlanificacionesModule {}
