<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="typeformWidget.id" name="id" class="form-control" />
        </div>

        <div class="form-group">
            <label>Nombre</label>
            <input type="text" [(ngModel)]="typeformWidget.nombre_referencia" name="nombre" class="form-control" />
        </div>

        <div class="form-group">
            <label>Código</label>
            <input type="text" [(ngModel)]="typeformWidget.codigo" name="codigo" class="form-control" />
        </div>

        <div class="form-group">
            <label>Tipo de Usuario</label>
            <select class="form-control" [(ngModel)]="typeformWidget.typeform_widget_tipo_usuario_id"
                name="tipo_usuario">
                <option selected [ngValue]="undefined">Seleccione Tipo de Usuario</option>
                <option *ngFor="let tipoUsuario of tipoUsuarios" [ngValue]="tipoUsuario.id">{{ tipoUsuario.tipo_usuario
                    }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label>Lugar</label>
            <select class="form-control" [(ngModel)]="typeformWidget.lugar_id" name="lugar">
                <option selected [ngValue]="undefined">Seleccione {{ config.plataforma.lugarTipo }}</option>
                <option *ngFor="let lugar of lugares" [ngValue]="lugar.id">{{ lugar.nombre }}</option>
            </select>
        </div>


        <div class="form-group">
            <label>{{ idEstablecimientoNombre }}</label>
            <input type="text" [(ngModel)]="establecimientoPais[idEstablecimientoKey]" name="rbd"
                class="form-control" />
        </div>

        <cui-button-link id="btnSearch" class="btn_style" (click)="search()">Buscar</cui-button-link>
    </div>
</form>
