<ll-titulo>Contacto #{{ establecimientoContactoId }}</ll-titulo>
<ll-toolnavbar>
    <establecimiento-contacto-toolnavbar
        [displayButtons]="'edit,all'"
        [establecimientoContactoId]="establecimientoContactoId"
        [establecimientoId]="establecimientoId"
    ></establecimiento-contacto-toolnavbar>
</ll-toolnavbar>

<establecimiento-contacto [establecimientoContactoId]="establecimientoContactoId"></establecimiento-contacto>
