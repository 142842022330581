import { PuntajeStreamingModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { UtilModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { streamingRouting } from "./admin_streaming.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        AdminLayoutsModule,
        StreamingModule,
        streamingRouting,
        PuntajeStreamingModule
    ],
    declarations: [],
    exports: []
})
export class AdminStreamingModule {}
