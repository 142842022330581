import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Establecimiento,
    Establecimientos,
    EstablecimientoForm,
    EstablecimientoChile,
    EstablecimientoColombia
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimientos-edit",
    templateUrl: "establecimientos.form.component.html"
})
export class EstablecimientosEditComponent implements OnInit {
    @Input() establecimientoId: number

    oEstablecimiento: Establecimiento = new Establecimiento()
    establecimiento: Establecimiento = new Establecimiento()
    params = EstablecimientoForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    pais: string = config.plataforma.pais

    constructor(
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.establecimiento["establecimiento_" + this.pais] = new Establecimiento["establecimiento_" + this.pais]()
        this.form = EstablecimientoForm.getForm(this.establecimiento)
        this.establecimientosService
            .find(this.establecimientoId, { include: "[establecimiento_" + this.pais + ",lugar]" })
            .then((response: Establecimiento) => {
                this.establecimiento = response
                if (!this.establecimiento["establecimiento_" + this.pais]) {
                    this.establecimiento["establecimiento_" + this.pais] = new Establecimiento[
                        "establecimiento_" + this.pais
                    ]()
                }
                this.oEstablecimiento = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    save() {
        EstablecimientoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.establecimientosService.update(this.establecimiento.id, this.establecimiento).then(response => {
                this.router.navigate(["establecimientos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimiento = this.oEstablecimiento.clone()
            EstablecimientoForm.markFormControlsAsPristine(this.form)
            EstablecimientoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
