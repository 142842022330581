import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { Subject } from "rxjs"
import { UntypedFormGroup } from "@angular/forms"

import { S3Service, CableService, AuthService } from "@puntaje/shared/core"
import { Nomina, NominaForm, Nominas } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "nomina-alumnos",
    templateUrl: "nomina_alumnos.component.html",
    styleUrls: ["nomina_alumnos.component.scss"]
})
export class NominaAlumnosComponent implements OnInit {
    nomina: Nomina = new Nomina()
    saving: boolean = false
    response: any
    filas: number
    grupos: any
    errores: Array<string>
    form: UntypedFormGroup
    xhr_upload: Subject<XMLHttpRequest> = new Subject<XMLHttpRequest>()
    params = NominaForm.formParams
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    uploadingNominaSubscription: any
    progress: number = 0
    totalProgress: number = 0
    progressWidth: string = "0%"
    uploadTimeout: number = 900000

    constructor(
        private nominasService: Nominas,
        public s3Service: S3Service,
        private router: Router,
        protected cableService: CableService,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        let usuarioId = this.authService.getUserData().id

        this.uploadingNominaSubscription = this.cableService.subscribe(
            "UploadingNominaChannel",
            { usuario_id: usuarioId },
            {
                connected: function () {
                    console.log("uploadingNominaSubscription connected")
                },
                disconnected: function () {
                    console.log("uploadingNominaSubscription disconnected")
                },
                rejected: function () {
                    console.log("uploadingNominaSubscription rejected")
                },
                received: data => {
                    this.progress = data.personas_procesadas
                    this.totalProgress = data.total_personas

                    if (this.progress == this.totalProgress) {
                        this.progress = 0
                        this.totalProgress = 0
                    }

                    this.updateProgressWidth()
                }
            }
        )

        this.form = NominaForm.getForm(this.nomina)
        this.loadingLayout.ready()
    }

    save() {
        this.saving = true
        NominaForm.markFormControlsAsTouched(this.form)

        if (this.form.valid) {
            this.nominasService.cargarNomina("alumnos").uploadFile(
                "file",
                this.nomina.file,
                this.xhr_upload,
                response => {
                    this.response = JSON.parse(response)
                    this.updateValues()
                    //this.router.navigate(['nominas/alumnos']);
                    this.saving = false
                },
                this.uploadTimeout
            )
        } else {
            this.saving = false
        }
    }

    updateValues() {
        if (this.response) {
            this.filas = this.response["success"]
            this.grupos = this.response["grupos"]
            this.errores = this.response["errores"]
        }
    }

    clear() {
        setTimeout(() => {
            this.nomina = new Nomina()
            NominaForm.markFormControlsAsPristine(this.form)
            NominaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    getProgressPercent() {
        if (this.totalProgress == 0) {
            return 0
        }

        return (this.progress / this.totalProgress) * 100
    }

    getProgressWidth() {
        return this.getProgressPercent() + "%"
    }

    updateProgressWidth() {
        this.progressWidth = this.getProgressWidth()
    }

    downloadSample() {
        this.nominasService.plantillaNomina("alumnos").then((response: any) => {
            const plantillaExcelDecoded = atob(response.plantilla)
            let binaryData = new Uint8Array(plantillaExcelDecoded.length)
            binaryData = binaryData.map((d, i) => plantillaExcelDecoded.charCodeAt(i))

            const plantillaBlob = new Blob([binaryData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })

            const a = document.createElement("a")
            a.download = "plantilla_nomina_alumnos.xlsx"
            a.href = window.URL.createObjectURL(plantillaBlob)
            a.click()
        })
    }
}
