import { Component, Input, OnInit, SimpleChanges } from "@angular/core"

/*#################################

Botones de acción en la columna derecha de una tabla

#################################*/

@Component({
    templateUrl: "table_with_actions_buttons.component.html",
    selector: "table-with-actions-buttons",
    styleUrls: ["table_with_actions_buttons.component.scss"]
})
export class TableWithActionsButtonsComponent {
    @Input() functionParameter: any
    @Input() tableButtonsArray: any[]

    show = {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["functionParameter"] && changes["functionParameter"].currentValue) {
            Object.keys(this.tableButtonsArray).forEach(key => {
                this.show[key] = this.tableButtonsArray[key].hasOwnProperty("showIf")
                    ? !!this.tableButtonsArray[key].showIf(this.functionParameter)
                    : true
            })
        }
    }
}
