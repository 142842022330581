import { Component, ViewChild, OnInit, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezona_paginas.edit.component.html"
})
export class BannerPaginasEditComponent implements OnInit {
    bannerPaginaId: number
    loggedLayoutTitle = "Editar Pagina"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.artezona_paginas.edit")
        this.route.params.subscribe(params => {
            this.bannerPaginaId = +params["id"]
        })
    }
}
