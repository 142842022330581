import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { BannerPagina, BannerPaginas, BannerPaginaView, Banner } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerPaginas",
    templateUrl: "banner_paginas.component.html"
})
export class BannerPaginasComponent implements OnInit {
    @Input() listRoute: string
    bannerPaginas: BannerPagina[]
    params = BannerPaginaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(
        protected bannerPaginasService: BannerPaginas,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.showAll()
    }

    showAll(page: number = 1, count: number = 20) {
        return this.bannerPaginasService
            .where({ page: page, per: count })
            .then((response: BannerPagina[], total: number) => {
                this.bannerPaginas = response
                this.loadingLayout.ready()
                return total
            })
    }

    view(banner: Banner) {
        this.router.navigate([this.listRoute + "/" + banner.id])
    }

    edit(banner: Banner) {
        this.router.navigate([this.listRoute + "/" + banner.id + "/edit"])
    }

    delete(id: number) {
        this.bannerPaginasService.remove(id).then(response => {
            this.router.navigate([this.listRoute])
            //this.paginator.changePage(this.paginator.currentPage);
        })
    }

    showModalDelete(bannerPagina: BannerPagina) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta pagina?")
        this.simpleModalService.setModalCallback(() => this.delete(bannerPagina.id))
    }
}
