import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { BannerPagina, BannerPaginas, BannerPaginaView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerPagina",
    templateUrl: "banner_pagina.component.html"
})
export class BannerPaginaComponent implements OnInit {
    @Input() bannerPaginaId: number

    bannerPagina: BannerPagina
    params = BannerPaginaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(private bannerPaginasService: BannerPaginas) {}

    ngOnInit() {
        this.bannerPaginasService.find(this.bannerPaginaId).then((response: BannerPagina) => {
            this.bannerPagina = response
            this.loadingLayout.ready()
        })
    }
}
