import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Asociacion } from "@puntaje/puntaje/api-services"
import { ToolNavbar } from "@puntaje/shared/core"
import { AsociacionContactosBuscadorService } from "./asociacion_contactos_buscador.service"

@Component({
    templateUrl: "asociacion_contactos.toolnavbar.component.html",
    selector: "asociacion-contacto-toolnavbar"
})
export class AsociacionContactosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() asociacionId: number
    @Input() asociacionContactoId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected asociacionContactosBuscadorService: AsociacionContactosBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/asociaciones/" + this.asociacionId + "/asociacion_contactos"
        this.urlNew = "/asociaciones/" + this.asociacionId + "/asociacion_contactos/new"
        if (this.asociacionContactoId != null) {
            this.urlEdit =
                "/asociaciones/" + this.asociacionId + "/asociacion_contactos/" + this.asociacionContactoId + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.asociacionContactoId != null) {
            this.urlEdit =
                "/asociaciones/" + this.asociacionId + "/asociacion_contactos/" + this.asociacionContactoId + "/edit"
        }
        super.edit()
    }

    search() {
        this.asociacionContactosBuscadorService.openSearch.next()
    }
}
