<ll-titulo>Reporte #{{ establecimientoReporteId }}</ll-titulo>
<ll-toolnavbar>
    <establecimiento-reporte-toolnavbar
        [displayButtons]="'edit,all'"
        [establecimientoReporteId]="establecimientoReporteId"
        [establecimientoId]="establecimientoId"
    ></establecimiento-reporte-toolnavbar>
</ll-toolnavbar>

<establecimiento-reporte [establecimientoReporteId]="establecimientoReporteId"></establecimiento-reporte>
