import { Subscription } from "rxjs"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Instrumento, Evaluacion } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluaciones_masivas.component.html"
})
export class EvaluacionesMasivasComponent implements OnInit {
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.evaluaciones_masivas")
    }

    ngOnDestroy() {}
}
