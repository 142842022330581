import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { BannerPagina, BannerPaginas, BannerPaginaForm } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerPaginas-edit",
    templateUrl: "banner_paginas.form.component.html"
})
export class BannerPaginasEditComponent implements OnInit {
    @Input() bannerPaginaId: number
    @Input() listRoute: string

    oBannerPagina: BannerPagina = new BannerPagina()
    bannerPagina: BannerPagina = new BannerPagina()
    params = BannerPaginaForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private bannerPaginasService: BannerPaginas, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.form = BannerPaginaForm.getForm(this.bannerPagina)
        this.bannerPaginasService.find(this.bannerPaginaId).then((response: BannerPagina) => {
            this.bannerPagina = response
            this.oBannerPagina = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        BannerPaginaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.bannerPaginasService.update(this.bannerPagina.id, this.bannerPagina).then(response => {
                this.router.navigate([this.listRoute])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.bannerPagina = this.oBannerPagina.clone()
            BannerPaginaForm.markFormControlsAsPristine(this.form)
            BannerPaginaForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 0)
    }
}
