import { PuntajeArtezonasModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"

import { bannersRouting } from "./admin_artezonas.routing"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/index"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdminLayoutsModule,
        UtilModule,
        BannersModule,
        ModelViewModule,
        bannersRouting,
        PuntajeArtezonasModule
    ],
    declarations: [],
    exports: []
})
export class AdminBannersModule {}
