import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, UntypedFormGroup, FormArray } from "@angular/forms"
import { Router } from "@angular/router"
import { S3, S3Service } from "@puntaje/shared/core"
import {
    Banner,
    Banners,
    BannerForm,
    BannerTipo,
    BannerTipos,
    IframeBannersForm,
    ImageBannersForm,
    ImageBanner,
    ImageBanners
} from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "banners-new",
    templateUrl: "banners.form.component.html",
    styleUrls: ["banners.form.component.scss"]
})
export class BannersNewComponent implements OnInit {
    bannerTipos: BannerTipo[]
    bannerTipoSelected: BannerTipo
    banner: Banner
    form: UntypedFormGroup
    params: any
    paramsImages: any
    paramsIframes: any

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    @Input() listRoute: string
    loadingOpaque: boolean = false

    constructor(
        private bannersService: Banners,
        private s3Service: S3Service,
        private router: Router,
        protected bannerTiposService: BannerTipos,
        protected cdr: ChangeDetectorRef,
        protected imageBannersService: ImageBanners
    ) {}

    ngOnInit() {
        this.bannerTiposService.where().then((bannerTipos: BannerTipo[]) => {
            this.bannerTipos = bannerTipos
            this.paramsIframes = IframeBannersForm.formParams
            this.paramsImages = ImageBannersForm.formParams
            this.loadingLayout.ready()
        })
    }

    getBannerForm(bannerTipo: BannerTipo) {
        this.bannerTipoSelected = bannerTipo
        let tipo: string = bannerTipo.nombre
        this.banner = new Banner(tipo)
        this.form = BannerForm.getForm(this.banner, BannerForm.getFormByTipo(tipo))
        this.params = BannerForm.getFormByTipo(tipo)
        this.banner.banner_tipo_id = bannerTipo.id
    }

    save() {
        BannerForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (this.banner.image_banners && this.banner.image_banners.length > 0) {
                this.loadingOpaque = true
                if (this.loadingLayout) this.loadingLayout.standby()
                let promises = this.banner.image_banners
                    .filter(im => im.file)
                    .map(im => {
                        return new Promise((resolve, reject) => {
                            this.s3Service.where(im.getS3Params()).then((policies: S3[]) => {
                                let policy = policies as any as S3
                                im.image_url = policy["key"]
                                this.s3Service.uploadToS3(policy, im.file, this.imageBannersService.tableName, resolve)
                            })
                        })
                    })
                Promise.all(promises).then(res => {
                    this.doSave()
                })
            } else {
                this.doSave()
            }
        }
    }

    doSave = () => {
        this.bannersService.save(this.banner).then((banner: Banner) => {
            if (this.loadingLayout) this.loadingLayout.ready()
            this.loadingOpaque = false
            this.router.navigate([this.listRoute])
        })
    }

    clear() {
        setTimeout(() => {
            this.getBannerForm(this.bannerTipoSelected)
            BannerForm.markFormControlsAsPristine(this.form)
            BannerForm.markFormControlsAsUntouched(this.form)
            this.banner.banner_tipo_id = this.bannerTipoSelected.id
        }, 150)
    }

    addImage() {
        this.banner.addImageBanner()
    }

    removeImage(index: number) {
        this.banner.image_banners.splice(index, 1)
    }
}
