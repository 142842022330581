import { TextView, LinkView, DateView } from "@puntaje/shared/core"
import { GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Instrumentos } from "./instrumentos.service"
import { InstrumentoPredefinidos } from "./instrumento_predefinidos.service"

export class InstrumentoPredefinidoView {
    public static get viewParams() {
        return {
            id: {
                label: "Id",
                type: LinkView,
                tableVisible: true,
                options: { class: InstrumentoPredefinidos, key: "id" }
            },
            instrumento_id: {
                label: "Instrumento ID",
                type: LinkView,
                tableVisible: true,
                options: { class: Instrumentos, key: "instrumento_id" }
            },
            instrumento_multiple_id: {
                label: "Instrumento Multiple ID",
                type: TextView,
                tableVisible: true
            },
            asignatura: { label: "Asignatura", type: TextView, tableVisible: true },
            nombre: { label: "Nombre", type: TextView, tableVisible: true },
            tag: { label: "Tag", type: TextView, tableVisible: true },
            generador_instrumento: {
                label: "Instrumento Generador",
                type: TextView,
                tableVisible: true,
                options: { class: GeneradorInstrumentos, key: "generador_instrumento" }
            },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
