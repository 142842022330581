<cui-tabs justifyContent="flex-start" [usingDisplayNone]="false">
    <cui-tab title="TextsModule" ariaLabelledBy="texts">
        <h2>Títulos</h2>
        <cui-main-headline>Título principal</cui-main-headline>
        <cui-section-headline>Título secundario</cui-section-headline>
        <cui-title-headline>Título de sección</cui-title-headline>
        <br />
    </cui-tab>
    <cui-tab title="InputsModule" ariaLabelledBy="inputs">
        <h2>Botones</h2>
        <h3>Por tamaño</h3>
        <cui-button-bar>
            <cui-button size="xsmall">XSmall</cui-button>
            <cui-button size="small">Small</cui-button>
            <cui-button>Medium</cui-button>
            <cui-button size="large">Large</cui-button>
            <cui-button size="xlarge">XLarge</cui-button>
        </cui-button-bar>
        <br />

        <cui-button-bar>
            <cui-button-link size="xsmall">XSmall</cui-button-link>
            <cui-button-link size="small">Small</cui-button-link>
            <cui-button-link>Medium</cui-button-link>
            <cui-button-link size="large">Large</cui-button-link>
            <cui-button-link size="xlarge">XLarge</cui-button-link>
        </cui-button-bar>
        <br />
        <h3>Por tipo</h3>

        <cui-button-bar>
            <cui-button buttonType="primary">primary</cui-button>
            <cui-button buttonType="secondary">secondary</cui-button>
            <cui-button buttonType="cancel">cancel</cui-button>
            <cui-button buttonType="confirm">confirm</cui-button>
            <cui-button buttonType="danger">danger</cui-button>
        </cui-button-bar>
        <br />
    </cui-tab>
    <cui-tab title="ContainersModule" ariaLabelledBy="containers">
        <h2>Containers</h2>
        <cui-button-bar>
            <cui-button class="left-side">
                <ogr-icon name="prueba" class="left-icon"></ogr-icon>
                Izquierda1
            </cui-button>
            <cui-button class="left-side">
                <ogr-icon name="prueba" class="left-icon"></ogr-icon>
                Izquierda2
            </cui-button>
            <cui-button class="right-side">
                <ogr-icon name="prueba" class="right-icon"></ogr-icon>
                Derecha1
            </cui-button>
            <cui-button class="right-side">
                <ogr-icon name="prueba" class="right-icon"></ogr-icon>
                Derecha2
            </cui-button>
        </cui-button-bar>
        <br />
        <cui-button-bar>
            <a class="left-side">Izquierda1</a>
            <a class="left-side">Izquierda2</a>
            <a class="right-side">Derecha1</a>
            <a class="right-side">Derecha2</a>
        </cui-button-bar>
        <br />

        <cui-accordion boxStyle="boxed">
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de este acordion</cui-accordion-content>
        </cui-accordion>

        <cui-accordion>
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de otro acordion</cui-accordion-content>
        </cui-accordion>

        <cui-accordion iconPosition="left">
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de otro acordion</cui-accordion-content>
        </cui-accordion>

        <cui-accordion iconPosition="left" [rotatingIcon]="true">
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de otro acordion</cui-accordion-content>
        </cui-accordion>

        <cui-accordion [rotatingIcon]="true">
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de otro acordion</cui-accordion-content>
        </cui-accordion>

        <cui-accordion>
            <cui-accordion-icon-open><fa name="plus-circle"></fa></cui-accordion-icon-open>
            <cui-accordion-icon-close><fa name="minus-circle"></fa></cui-accordion-icon-close>
            <cui-accordion-title>Acordion</cui-accordion-title>
            <cui-accordion-content>Contenido de este</cui-accordion-content>
        </cui-accordion>
        <br />

        <cui-info-card>
            <cui-info-card-title>Título</cui-info-card-title>
            <ogr-icon cui-info-card-icon name="hacer-prueba"></ogr-icon>
            <cui-info-card-left-content>
                <cui-info-card-data-pair>
                    <label cui-info-card-data-pair-label>Label</label>
                    <p cui-info-card-data-pair-value>Value</p>
                </cui-info-card-data-pair>
                <cui-info-card-data-pair>
                    <label cui-info-card-data-pair-label>Label</label>
                    <p cui-info-card-data-pair-value>Value</p>
                </cui-info-card-data-pair>
                <cui-info-card-data-pair>
                    <label cui-info-card-data-pair-label>Label</label>
                    <p cui-info-card-data-pair-value>Value</p>
                </cui-info-card-data-pair>
            </cui-info-card-left-content>
            <cui-info-card-right-content>
                <cui-info-card-data-pair>
                    <label cui-info-card-data-pair-label>Label</label>
                    <p cui-info-card-data-pair-value>Value</p>
                </cui-info-card-data-pair>
                <div cui-info-card-area>Asdf</div>
            </cui-info-card-right-content>
        </cui-info-card>
        <br />
    </cui-tab>
    <cui-tab title="LayoutsModule" ariaLabelledBy="layouts">
        <h2>Layouts</h2>
        <h3>CUI Basic Layout</h3>
        <cui-basic-layout class="demo"></cui-basic-layout>
        <br />
        <h3>CUI Layout with sidemenu</h3>
        <cui-layout-with-sidemenu class="demo"></cui-layout-with-sidemenu>
        <br />
        <h3>CUI Tow columns layout</h3>
        <cui-two-columns-layout class="demo"></cui-two-columns-layout>
        <br />
    </cui-tab>
    <cui-tab title="TablesModule" ariaLabelledBy="tables">
        <h2>Tablas y listas</h2>
        <cui-data-list>
            <cui-data-list-header>Ejemplo</cui-data-list-header>
            <cui-data-list-item>
                <cui-data-list-item-title>Ejemplo 1</cui-data-list-item-title>
                <cui-data-list-item-actions><cui-button size="small">Acción</cui-button></cui-data-list-item-actions>
            </cui-data-list-item>
            <cui-data-list-item>
                <cui-data-list-item-title>Ejemplo 2</cui-data-list-item-title>
            </cui-data-list-item>
            <cui-data-list-item>
                <cui-data-list-item-title>Ejemplo 3</cui-data-list-item-title>
            </cui-data-list-item>
        </cui-data-list>

        <cui-table [withPaginator]="false">
            <tr cui-table-header-row>
                <th>Asdf</th>
                <th>Asdf2</th>
                <th>Asdf3</th>
            </tr>
            <tr cui-table-data-row>
                <td>Asdfff</td>
                <td>Asdfff</td>
                <td>Asdfff</td>
            </tr>
            <tr cui-table-data-row>
                <td>Asdfff 2</td>
                <td>Asdfff 2</td>
                <td>Asdfff 2</td>
            </tr>
            <tr cui-table-data-row>
                <td>Asdfff3</td>
                <td>Asdfff3</td>
                <td>Asdfff3</td>
            </tr>
        </cui-table>
    </cui-tab>
</cui-tabs>
