import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { Asociacion, Asociaciones, AsociacionForm } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "asociaciones-edit",
    templateUrl: "asociaciones.form.component.html"
})
export class AsociacionesEditComponent implements OnInit {
    @Input() asociacionId: number

    oAsociacion: Asociacion = new Asociacion()
    asociacion: Asociacion = new Asociacion()
    params = AsociacionForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private asociacionesService: Asociaciones, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.form = AsociacionForm.getForm(this.asociacion)
        this.asociacionesService.find(this.asociacionId).then((response: Asociacion) => {
            this.asociacion = response
            this.oAsociacion = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        AsociacionForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.asociacionesService.update(this.asociacion.id, this.asociacion).then(response => {
                this.router.navigate(["asociaciones"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.asociacion = this.oAsociacion.clone()
            AsociacionForm.markFormControlsAsPristine(this.form)
            AsociacionForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 0)
    }
}
