<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="establecimiento-data container-fluid print-hide">
            <ng-container *ngFor="let key of params | keys">
                <model-simple-view
                    *ngIf="!params[key]['showHidden']"
                    [params]="params[key]"
                    [value]="establecimiento"
                    [key]="key"
                ></model-simple-view>
            </ng-container>
        </div>
        <asignaturas-establecimiento
            class="print-hide"
            [establecimientoId]="establecimientoId"
        ></asignaturas-establecimiento>
        <tabset *ngIf="hasRol.Administrador || hasRol.Monitor || hasRol.SuperAdministrador">
            <tab heading="Administrar usuarios">
                <div class="row">
                    <div class="col-md-6 bottom-pad">
                        <cui-section-headline id="administradores-list-title">
                            Administradores del establecimiento
                        </cui-section-headline>
                        <ul class="administradores-list">
                            <li *ngFor="let usuario of establecimiento?.usuarios">
                                <a [routerLink]="'/usuarios/' + usuario.id" class="nombre">
                                    <fa name="user-o" class="left-icon"></fa>
                                    {{ usuario.nombreCompleto() }}
                                </a>
                                <div class="opciones">
                                    <cui-button-link (click)="deleteUsuario(usuario)" class="btn_style btn-xs">
                                        <fa name="times" class="left-icon"></fa>
                                        Desasociar
                                    </cui-button-link>
                                    <div class="opciones-r">
                                        <button class="btn btn-default btn-xs" (click)="logInAsProfesor(usuario.id)">
                                            <fa name="user-o" class="left-icon"></fa>
                                            Impersonar Profesor
                                        </button>
                                        <button class="btn btn-default btn-xs" (click)="logInAsAlumno(usuario.id)">
                                            <fa name="user-o" class="left-icon"></fa>
                                            Impersonar Alumno
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <cui-button-link (click)="toggleUsuarios()" class="btn_style">
                            <fa name="user-plus" class="left-icon"></fa>
                            Asociar usuario
                        </cui-button-link>
                        <div *ngIf="showAssociateUsuario" class="asociar-container">
                            <autocomplete
                                [(ngModel)]="usuario"
                                [options]="filteredUsuarios"
                                (filterChange)="getUsuarios($event)"
                                placeholder="Escriba y seleccione de la lista"
                                showTextFun="toStringDatos"
                                class="autocomplete"
                            ></autocomplete>
                            <cui-button-link (click)="associateUsuario()" class="btn_style">
                                <fa name="plus" class="left-icon"></fa>
                                Asociar
                            </cui-button-link>
                        </div>
                    </div>
                    <div class="col-md-6 bottom-pad">
                        <cui-section-headline id="monitores-list-title">
                            Monitores del establecimiento
                        </cui-section-headline>
                        <ul class="monitores-list">
                            <li *ngFor="let usuario of establecimiento?.monitores">
                                <a [routerLink]="'/usuarios/' + usuario.id" class="nombre">
                                    <fa name="user-o" class="left-icon"></fa>
                                    {{ usuario.nombreCompleto() }}
                                </a>
                                <div class="opciones">
                                    <cui-button-link (click)="deleteMonitor(usuario)" class="btn_style btn-xs">
                                        <fa name="times" class="left-icon"></fa>
                                        Desasociar
                                    </cui-button-link>
                                    <div class="opciones-r">
                                        <button class="btn btn-default btn-xs" (click)="logInAsProfesor(usuario.id)">
                                            <fa name="user-o" class="left-icon"></fa>
                                            Impersonar Profesor
                                        </button>
                                        <button class="btn btn-default btn-xs" (click)="logInAsAlumno(usuario.id)">
                                            <fa name="user-o" class="left-icon"></fa>
                                            Impersonar Alumno
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <cui-button-link (click)="toggleMonitores()" class="btn_style">
                            <fa name="user-plus"></fa>
                            Asociar usuario
                        </cui-button-link>
                        <div *ngIf="showAssociateMonitor" class="asociar-container">
                            <autocomplete
                                [(ngModel)]="usuario"
                                [options]="filteredUsuarios"
                                (filterChange)="getUsuarios($event)"
                                placeholder="Escriba y seleccione de la lista"
                                showTextFun="toStringDatos"
                                class="autocomplete"
                            ></autocomplete>
                            <cui-button-link (click)="associateMonitor()" class="btn_style">
                                <fa name="plus" class="left-icon"></fa>
                                Asociar
                            </cui-button-link>
                        </div>
                    </div>
                </div>
                <br />
                <grupo-usuarios-asociados
                    [establecimientoId]="establecimientoId"
                    [evaluacionTipo]="config.plataforma.evaluacionDefault"
                ></grupo-usuarios-asociados>
            </tab>
            <tab heading="Resumen de uso">
                <tabset>
                    <tab heading="Resumen de uso">
                        <resumen-utp></resumen-utp>
                    </tab>
                    <tab heading="Informe de uso">
                        <resumen-utp-anual></resumen-utp-anual>
                    </tab>
                    <tab heading="Informe anual">
                        <informe-anual [mostrarTablaDesempeno]="mostrarTablaDesempenoInforme"></informe-anual>
                    </tab>
                </tabset>
            </tab>
            <tab heading="Activar/Desactivar Asignaturas">
                <lista-asignatura-evaluacion-tipos
                    [disableConfigure]="true"
                    [disableDelete]="true"
                    [disablePredefinido]="true"
                    [establecimientoId]="establecimientoId"
                ></lista-asignatura-evaluacion-tipos>
            </tab>
        </tabset>
    </div>
</loading-layout>
