import { Component, Input, HostBinding } from "@angular/core"

@Component({
    selector: "cui-two-columns-layout,[cui-two-columns-layout]",
    template: `
        <div class="header"><ng-content select="[cui-layout-slot=header]"></ng-content></div>
        <div class="column1" select="[cui-layout-slot=column1]"></div>
        <div class="column2" select="[cui-layout-slot=column2]"></div>
        <div class="footer" select="[cui-layout-slot=footer]"></div>
    `,
    styleUrls: ["cui-two-columns-layout.component.scss"]
})
export class CUITwoColumnsLayoutComponent {
    constructor() {}
}
