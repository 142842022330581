import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { NominasModule } from "@puntaje/puntaje/new-modules/carga-nominas"

import { NominaAlumnosComponent } from "./nomina_alumnos.component"
import { NominaProfesoresComponent } from "./nomina_profesores.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NominasModule, CUILayoutsModule],
    declarations: [NominaAlumnosComponent, NominaProfesoresComponent],
    exports: [NominaAlumnosComponent, NominaProfesoresComponent]
})
export class PuntajeNominasModule {}
