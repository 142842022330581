<ll-titulo>Estadísticas</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>

<menu-asignaturas-horizontal
    class="print-hide"
    [asignaturaId]="asignaturaId"
    [tipoAsignaturas]="tipoInstrumento"
    (asignaturaChanged)="asignaturaId = $event"
></menu-asignaturas-horizontal>
<estadisticas-declarados
    [asignaturaId]="asignaturaId"
    [establecimientoId]="establecimientoId"
    (onReadyAsignatura)="onReady($event)"
    [evaluacionTipo]="tipoInstrumento"
></estadisticas-declarados>
