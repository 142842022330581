import { AppConfig, BaseForm, Validations } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { PremiumUsuarioForm } from "./premium_usuarios.form"
import { Roles } from "./roles.service"

import { CuentasChileForm } from "./cuentas_chile.form"
import { CuentasColombiaForm } from "./cuentas_colombia.form"
import { CuentasMexicoForm } from "./cuentas_mexico.form"
import { CuentasBrasilForm } from "./cuentas_brasil.form"

declare const config: AppConfig

export class CuentasForm extends BaseForm {
    public static get formParams() {
        let classes = {
            chile: CuentasChileForm,
            colombia: CuentasColombiaForm,
            mexico: CuentasMexicoForm,
            brasil: CuentasBrasilForm
        }

        let params: any = {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                validations: []
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            ["usuario_" + config.plataforma.pais]: { type: "model", class: classes[config.plataforma.pais] },
            premium_usuarios: { label: "Test", type: "models", class: PremiumUsuarioForm, visible: true },
            roles: { label: "Roles", type: "checkbox", multiple: true, options: { class: Roles } },
            fecha_inicio_plus: { label: "Fecha inicio PN+", type: "date", visible: true },
            fecha_termino_plus: { label: "Fecha término PN+", type: "date", visible: true }
        }

        return params
    }
}
