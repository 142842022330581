import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { EvaluacionSugerida, EvaluacionSugeridaForm, EvaluacionSugeridas } from "@puntaje/puntaje/api-services"

@Component({
    selector: "evaluacion-recomendada-new",
    templateUrl: "./evaluacion-recomendada.new.component.html",
    styleUrls: ["./evaluacion-recomendada.new.component.scss"]
})
export class EvaluacionRecomendadaNewComponent implements OnInit {
    evaluacionSugerida: EvaluacionSugerida = new EvaluacionSugerida()
    params = EvaluacionSugeridaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar evaluación recomendada"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    @Input() showEvaluacionMultiple: boolean = false

    constructor(protected evaluacionSugeridasService: EvaluacionSugeridas, protected router: Router) {}

    ngOnInit() {
        this.form = EvaluacionSugeridaForm.getForm(this.evaluacionSugerida)
        this.loadingLayout.ready()
    }

    save() {
        EvaluacionSugeridaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.evaluacionSugeridasService.save(this.evaluacionSugerida).then(response => {
                this.router.navigate(["evaluacion_sugeridas"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.evaluacionSugerida = new EvaluacionSugerida()
            EvaluacionSugeridaForm.markFormControlsAsPristine(this.form)
            EvaluacionSugeridaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
