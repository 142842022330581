import { AppConfig, BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { AsociacionEstablecimientosForm } from "./asociacion_establecimientos.form"

import { EstablecimientosChileForm } from "./establecimientos_chile.form"
import { EstablecimientosColombiaForm } from "./establecimientos_colombia.form"
import { EstablecimientosMexicoForm } from "./establecimiento_mexico.form"
import { EstablecimientosBrasilForm } from "./establecimientos_brasil.form"

declare const config: AppConfig

export class EstablecimientoForm extends BaseForm {
    public static get formParams() {
        let classes = {
            chile: EstablecimientosChileForm,
            colombia: EstablecimientosColombiaForm,
            mexico: EstablecimientosMexicoForm,
            brasil: EstablecimientosBrasilForm
        }

        let params: any = {
            id: { label: "Id", type: "hidden", visible: false },
            establecimiento: {
                label: "Establecimiento",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            ["establecimiento_" + config.plataforma.pais]: { type: "model", class: classes[config.plataforma.pais] },
            asociacion_establecimientos: { type: "models", class: AsociacionEstablecimientosForm, visible: true },
            lugar_id: {
                label: "Lugar",
                type: "select",
                visible: true,
                options: {
                    class: Lugares,
                    params: { lugar_tipo: config.plataforma.lugarTipo, sort_by: "nombre", order: "asc" }
                },
                validations: [Validators.required]
            },
            latitud: { label: "Latitud", type: "number", visible: true },
            longitud: { label: "Longitud", type: "number", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            encargado_nombre: { label: "Nombre Encargado", type: "text", visible: true },
            encargado_telefono: { label: "Teléfono Encargado", type: "text", visible: true },
            encargado_email: { label: "Email Encargado", type: "text", visible: true },
            encargado_ultima_fecha_capacitacion: {
                label: "Ultima fecha de capacitación",
                type: "datepicker",
                visible: true
            },
            director_nombre: { label: "Nombre Director", type: "text", visible: true },
            director_telefono: { label: "Teléfono Director", type: "text", visible: true },
            director_email: { label: "Email Director", type: "text", visible: true },
            colegio_direccion: { label: "Dirección del colegio", type: "text", visible: true },
            colegio_telefono: { label: "Teléfono del colegio", type: "text", visible: true },
            valor_plan: { label: "Valor Plan", type: "text", visible: true },
            comentarios: { label: "Comentarios", type: "textarea", visible: true }
        }

        return params
    }
}
