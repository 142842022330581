import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { Instrumento, Instrumentos, InstrumentoView } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "mis-instrumentos",
    templateUrl: "mis_instrumentos.component.html"
})
export class MisInstrumentosComponent implements OnInit {
    @Input() listRoute: string
    instrumentos: Instrumento[]
    params = InstrumentoView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected instrumentosService: Instrumentos,
        protected router: Router,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = (instrumento: Instrumento) => {
            return {
                view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
                delete: {
                    function: this.showModalDelete.bind(this),
                    text: "Borrar",
                    callModal: true,
                    glyphicon: "glyphicon-trash"
                }
            }
        }
        this.showAll()
    }

    showAll(page: number = 1, count: number = 20) {
        return this.instrumentosService
            .where({ page: page, per: count, usuario_id: this.authService.getUserData().id })
            .then((response: Instrumento[], total: number) => {
                this.instrumentos = response
                this.loadingLayout.ready()
                return total
            })
    }

    view(instrumento: Instrumento) {
        this.router.navigate([this.listRoute + "/" + instrumento.id])
    }

    delete(id: number) {
        this.instrumentosService.remove(id).then(response => {
            location.reload()
            //this.router.navigate([this.listRoute]);
            //this.paginator.changePage(this.paginator.currentPage);
        })
    }

    showModalDelete(instrumento: Instrumento) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que deshabilitar eliminar este instrumento?")
        this.simpleModalService.setModalCallback(() => this.delete(instrumento.id))
    }
}
