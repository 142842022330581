import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    PlanPersonal,
    PlanPersonalForm,
    PlanPersonalCiclo,
    PlanPersonales,
    PlanPersonalTipos,
    PlanPersonalTipo
} from "@puntaje/puntaje/api-services"
import {
    TipoInstrumento,
    Asignatura,
    GeneradorInstrumento,
    Asignaturas,
    TipoInstrumentos,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "planificaciones-edit",
    templateUrl: "../planificaciones-new/planificaciones-new.component.html",
    styleUrls: ["./planificaciones-edit.component.scss"]
})
export class PlanificacionesEditComponent implements OnInit {
    @Input() planificacionId: number
    oPlanificacion: PlanPersonal = new PlanPersonal()
    planificacion: PlanPersonal = new PlanPersonal()
    params = PlanPersonalForm.formParams
    form: UntypedFormGroup
    pais: string
    logged_layout_title = "Editar planificación"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    tipoInstrumentos: TipoInstrumento[]
    asignaturas: Asignatura[]
    asignatura: Asignatura
    tipoInstrumento: TipoInstrumento
    generadorInstrumentos: GeneradorInstrumento[]
    generadorInstrumento: GeneradorInstrumento
    planPersonalCiclos: PlanPersonalCiclo[] = []
    planPersonalCiclo: PlanPersonalCiclo = new PlanPersonalCiclo()
    planPersonalTipo: PlanPersonalTipo
    planPersonalTipos: PlanPersonalTipo[]

    config: typeof config = config

    constructor(
        protected planificacionesService: PlanPersonales,
        protected router: Router,
        private asignaturasService: Asignaturas,
        protected authService: AuthService,
        private tipoInstrumnetosService: TipoInstrumentos,
        private generadorInatrumentoService: GeneradorInstrumentos,
        protected cdr: ChangeDetectorRef,
        protected planPersonalTiposService: PlanPersonalTipos
    ) {}

    ngOnInit() {
        this.form = PlanPersonalForm.getForm(this.planificacion)
        Promise.all([this.setData(), this.getdataPrincipal()]).then(async () => {
            if (this.planificacion.generador_instrumento_id) {
                const generadorInstrumento = await this.generadorInatrumentoService.find(
                    this.planificacion.generador_instrumento_id,
                    { include: "[asignatura,tipo_instrumento]" }
                )
                if (!generadorInstrumento) return

                this.asignatura = this.asignaturas.find(a => a.id == generadorInstrumento.asignatura?.id)
                this.tipoInstrumento = this.tipoInstrumentos.find(
                    ti => ti.id == generadorInstrumento.tipo_instrumento.id
                )

                this.getGeneradorInstrumento()
            }
        })
    }

    save() {
        PlanPersonalForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.planificacionesService.update(this.planificacion.id, this.planificacion).then(response => {
                this.router.navigate(["planificaciones"])
            })
        }
    }

    checkPlanPersonalTipo() {
        this.planPersonalTipo = this.planPersonalTipos.find(ppt => ppt.id == this.planificacion.plan_personal_tipo_id)
        if (this.planPersonalTipo.tipo !== "plan de clase") {
            this.planificacion.libro_url_profesor = undefined
            this.planificacion.libro_url_alumno = undefined
        }
        this.cdr.detectChanges()
    }

    async setData() {
        return this.planificacionesService.find(this.planificacionId).then((response: PlanPersonal) => {
            this.planificacion = response
            this.oPlanificacion = response.clone()
            this.cdr.detectChanges()
        })
    }

    async getdataPrincipal() {
        this.asignaturas = (await this.asignaturasService.where({
            plataforma: this.config.plataforma.name
        })) as Asignatura[]
        this.tipoInstrumentos = (await this.tipoInstrumnetosService.where()) as TipoInstrumento[]
        this.planPersonalTipos = await this.planPersonalTiposService.where().then(data => {
            this.planPersonalTipo = data.find(ppt => ppt.id == this.planificacion.plan_personal_tipo_id)
            return data
        })
        this.loadingLayout.ready()
    }

    clear() {
        setTimeout(() => {
            this.planificacion = this.oPlanificacion.clone()
            PlanPersonalForm.markFormControlsAsPristine(this.form)
            PlanPersonalForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    async getGeneradorInstrumento() {
        if (this.asignatura && this.tipoInstrumentos) {
            let params = {
                asignatura_plataforma: {
                    asignatura_id: this.asignatura.id,
                    plataforma_id: this.config.plataforma.id
                },
                tipo_instrumento: {
                    id: this.tipoInstrumento.id
                },
                visible: true
            }
            this.generadorInstrumentos = (await this.generadorInatrumentoService.where(
                params
            )) as GeneradorInstrumento[]
        }
    }
}
