import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asociacion.component.html"
})
export class AsociacionComponent implements OnInit {
    asociacionId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asociaciones.asociaciones.show")
        this.route.params.subscribe(params => {
            this.asociacionId = +params["id"]
        })
    }
}
