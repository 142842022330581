import { Component, HostBinding, OnInit, OnDestroy } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

/*#################################

Menú a la izquierda del sitio. En este momento es el menú de puntaje.

#################################*/

@Component({
    templateUrl: "sidemenu.component.html",
    selector: "sidemenu",
    styleUrls: ["sidemenu.component.scss"]
})
export class SidemenuComponent extends MenuComponent implements OnInit, OnDestroy {
    config: typeof config = config
    asignaturasByEvaluacionTipo: any
    asignaturaId: number
    constructor(store: Store<State>) {
        super(store)
    }

    @HostBinding("class.show-sidemenu") showSidemenu: boolean = false

    toggleSidemenu() {
        this.showSidemenu = !this.showSidemenu
    }

    closeSidemenu() {
        this.showSidemenu = false
    }

    async setAsignaturas() {
        this.asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()
    }

    ngOnInit() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturasByEvaluacionTipo = asignaturasByEvaluacionTipo
            this.asignaturaId = this.asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault][0].id
        })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }
}
