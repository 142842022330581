import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Asignaturas } from "@puntaje/nebulosa/api-services"

@Injectable({ providedIn: "root" })
export class LoadAsignaturaGuard implements CanActivate {
    constructor(private asignaturasService: Asignaturas) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return true
    }
}
