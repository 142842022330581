<ll-titulo *ngIf="evaluacionId && ready && instrumento">
    Evaluación #{{ evaluacionId }}/Instrumento #{{ instrumento.id }}
</ll-titulo>
<ll-titulo *ngIf="evaluacionId == undefined && ready && instrumento">Instrumento #{{ instrumento.id }}</ll-titulo>

<div class="row vertical-margin">
    <div class="col-md-8">
        <cui-section-headline>Busque aquí su Evaluación o Instrumento</cui-section-headline>
        <input type="text" class="form-control" [(ngModel)]="instrumento_o_evaluacion_id" />
    </div>
    <div class="col-md-4 bigger-top-margin">
        <button class="btn btn-default" type="button" (click)="show()">Buscar Instrumento</button>
        &nbsp;
        <button class="btn btn-default" type="button" (click)="show(true)">Buscar Evaluación</button>
    </div>
</div>
<datos-generales
    *ngIf="instrumento && ready"
    [instrumento]="instrumento"
    [groupedBy]="['curso', 'habilidad cognitiva', 'eje temático,eje temático']"
></datos-generales>
<div *ngIf="instrumento && ready" class="row top-margin">
    <div *ngIf="!instrumento.instrumento_material" class="col-md-4 inverted-col padding-bottom">
        <opciones-instrumento
            *ngIf="isValidToUse"
            [evaluacionId]="evaluacionId"
            [instrumento]="instrumento"
            [tipoId]="1"
            [compartirTodosCursos]="true"
            [enableAdminOptions]="true"
        ></opciones-instrumento>
    </div>

    <div *ngIf="instrumento.instrumento_material" class="col-md-4 inverted-col padding-bottom">
        <div class="tab-content">
            <ul class="nav nav-tabs">
                <li class="active">
                    <a href="#tab1" role="tab" data-toggle="tab">Pauta</a>
                </li>
                <li>
                    <a href="#tab2" role="tab" data-toggle="tab">Compartir</a>
                </li>
            </ul>

            <div id="tab1" class="active tab-pane clearfix" #tabContent>
                <br />
                <pauta-prueba-propia [instrumento]="instrumento"></pauta-prueba-propia>
            </div>

            <div id="tab2" class="tab-pane" #tabContent>
                <opciones-instrumento
                    *ngIf="isValidToUse"
                    [evaluacionId]="evaluacionId"
                    [instrumento]="instrumento"
                    [tipoId]="1"
                    [compartirTodosCursos]="true"
                    [enableAdminOptions]="true"
                ></opciones-instrumento>
            </div>
        </div>
    </div>

    <div class="col-md-8">
        <cui-section-headline>Vista previa</cui-section-headline>
        <instrumento [instrumento]="instrumento"></instrumento>
    </div>
</div>

<div *ngIf="!instrumento && ready">
    <p>
        La evaluación o instrumento que está buscando no existe. Por favor revise si el identificador que está usando es
        el correcto.
    </p>
</div>
