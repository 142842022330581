import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PlanPersonalContenido, PlanPersonalContenidos } from "@puntaje/puntaje/api-services"
import { PlanPersonalContenidoForm } from "@puntaje/puntaje/api-services"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"

@Component({
    selector: "plan-personal-contenido-new",
    templateUrl: "./plan-personal-contenido-new.component.html",
    styleUrls: ["./plan-personal-contenido-new.component.scss"]
})
export class PlanPersonalContenidoNewComponent implements OnInit {
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() planificacionId
    @Input() sessionId
    planPersonalContenido = new PlanPersonalContenido()
    params = PlanPersonalContenidoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar Contenido"
    display_buttons_options = "all"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(protected planPersonalCotenidosService: PlanPersonalContenidos, protected router: Router) {}

    ngOnInit() {
        this.planPersonalContenido.plan_personal_sesion_id = this.sessionId
        this.form = PlanPersonalContenidoForm.getForm(this.planPersonalContenido)
        this.loadingLayout.ready()
    }

    save() {
        PlanPersonalContenidoForm.markFormControlsAsTouched(this.form)

        if (this.form.valid) {
            this.planPersonalCotenidosService.save(this.planPersonalContenido).then(response => {
                this.router.navigate(["/planificaciones", this.planificacionId])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.planPersonalContenido = new PlanPersonalContenido()
            PlanPersonalContenidoForm.markFormControlsAsPristine(this.form)
            PlanPersonalContenidoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
