import { Component, Injector } from "@angular/core"
import { View } from "./view.component"

@Component({
    selector: "artezonaview",
    template: `
        <ng-template #viewLabel>{{ label }}:</ng-template>
        <ng-template #viewValue>
            <ng-container *ngIf="urls && urls.length > 0">
                <span class="multiple_thumbnails">
                    <img *ngFor="let url of urls" [attr.src]="url" />
                </span>
            </ng-container>
            <ng-container *ngIf="iframes && iframes.length > 0">
                <span class="multiple_thumbnails">
                    <span class="iframe-aspect">
                        <iframe
                            *ngFor="let url of iframes"
                            seamless="seamless"
                            [attr.width]="'300px'"
                            [attr.height]="'100px'"
                            [src]="url | trustedurl"
                        ></iframe>
                    </span>
                </span>
            </ng-container>
        </ng-template>
    `,
    styleUrls: ["model_simple_view.component.scss"]
})
export class MultipleArtezonaView extends View {
    urls: string[]
    iframes: string[]

    constructor(private injector: Injector) {
        super()
    }

    ngOnInit() {
        if (this.options !== undefined) {
            if (this.value[this.options.imageKey])
                this.urls = this.value[this.options.imageKey].map(im => im["image_url"])
            if (this.value[this.options.iframeKey])
                this.iframes = this.value[this.options.iframeKey].map(im => im["iframe_url"])
        }
    }
}
