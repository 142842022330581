import { TextView, LinkView, DateView, BooleanView } from "@puntaje/shared/core"
import { PlanPersonales } from "./plan_personales.service"

export class PlanPersonalView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: TextView, tableVisible: true, options: { class: PlanPersonales, key: "id" } },
            plan_personal: { label: "Nombre", type: TextView, tableVisible: true },
            plan_personal_predefinido: { label: "Activo", type: BooleanView, tableVisible: true },
            generador_instrumento: { label: "Generador Instrumento", type: TextView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
