import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { typeformWidgetsRouting } from "./admin-typeform-widgets.routing"

import { AdminLayoutsModule } from "../layouts"
import { TypeformWidgetsModule } from "@puntaje/puntaje/new-modules/typeform-widgets"
import { PuntajeTypeformWidgetsModule } from "@puntaje/puntaje/core"
import { ToolNavbarModule } from "@puntaje/shared/core"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AdminLayoutsModule,
        TypeformWidgetsModule,
        typeformWidgetsRouting,
        PuntajeTypeformWidgetsModule,
        ToolNavbarModule
    ]
})
export class AdminTypeformWidgetsModule {}
