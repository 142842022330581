import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import {
    Establecimiento,
    Establecimientos,
    EstablecimientoView,
    EstablecimientoUsuario,
    EstablecimientoUsuarios,
    Usuario,
    Usuarios,
    EstablecimientoMonitores,
    EstablecimientoMonitor
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento",
    templateUrl: "establecimiento.component.html",
    styleUrls: ["establecimiento.component.scss"]
})
export class EstablecimientoComponent implements OnInit {
    config: typeof config

    @Input() establecimientoId: number
    @Output() establecimientoChange = new EventEmitter<any>()

    establecimiento: Establecimiento
    params = EstablecimientoView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    usuario: Usuario

    filteredUsuarios: Usuario[]
    showAssociateUsuario: boolean = false
    showAssociateMonitor: boolean = false
    hasRol: any

    @Input() mostrarTablaDesempenoInforme: boolean = true

    constructor(
        protected establecimientosService: Establecimientos,
        protected router: Router,
        protected route: ActivatedRoute,
        protected usuariosService: Usuarios,
        protected establecimientoUsuariosService: EstablecimientoUsuarios,
        protected establecimientoMonitoresService: EstablecimientoMonitores,
        protected sessionService: SessionService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.sessionService.setEstablecimientos([this.establecimientoId.toString()])
        const roles = this.sessionService.getRoles()
        this.hasRol = roles.reduce((acc, rol) => {
            acc[rol] = true

            return acc
        }, {})
        this.establecimientosService
            .find(this.establecimientoId, {
                include: "[usuarios,monitores,lugar]",
                methods: "[numero_alumnos_año,numero_docentes_año,numero_personas]"
            })
            .then((establecimiento: Establecimiento) => {
                this.establecimientoChange.emit(establecimiento)
                this.establecimiento = establecimiento
                this.loadingLayout.ready()
            })
    }

    toggleUsuarios() {
        this.showAssociateUsuario = !this.showAssociateUsuario
    }

    getUsuarios(usuarioFilter: string) {
        this.usuariosService
            .where({
                per: 100,
                raw: 1,
                usuario: { nombre_completo: usuarioFilter },
                include: "[usuario_chile,usuario_colombia,usuario_mexico]"
            })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
                if (this.filteredUsuarios.length < 1) {
                    if (this.config.plataforma.pais == "chile") {
                        this.getUsuariosByRut(usuarioFilter)
                    } else if (this.config.plataforma.pais == "colombia") {
                        this.getUsuariosByIdentificador(usuarioFilter)
                    } else if (this.config.plataforma.pais == "mexico") {
                        this.getUsuariosByCurp(usuarioFilter)
                    }
                }
            })
    }

    getUsuariosByRut(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_chile: { rut: usuarioFilter }, include: "usuario_chile" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    getUsuariosByIdentificador(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_colombia: { ti_o_cc: usuarioFilter }, include: "usuario_colombia" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    getUsuariosByCurp(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_mexico: { numero_unico: usuarioFilter }, include: "usuario_mexico" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    deleteUsuario(usuario) {
        let params = {
            establecimiento_usuario: {
                establecimiento_id: this.establecimiento.id,
                usuario_id: usuario.id
            }
        }

        this.establecimientoUsuariosService.where(params).then((establecimientoUsuarios: EstablecimientoUsuario[]) => {
            let establecimiento = new Establecimiento()
            establecimientoUsuarios.forEach(ae => ((ae as any)._destroy = true))
            establecimiento.establecimiento_usuarios = establecimientoUsuarios

            this.establecimientosService.update(this.establecimiento.id, establecimiento).then(a => {
                establecimientoUsuarios.forEach(ae => {
                    let index = this.establecimiento.usuarios.findIndex(u => u.id == ae.usuario_id)
                    establecimiento.usuarios = this.establecimiento.usuarios.splice(index, 1)
                })
            })
        })
    }

    associateUsuario() {
        let establecimiento = new Establecimiento()
        let establecimientoUsuario = new EstablecimientoUsuario()
        establecimientoUsuario.establecimiento_id = this.establecimiento.id
        establecimientoUsuario.usuario_id = this.usuario.id

        establecimiento.establecimiento_usuarios = [establecimientoUsuario]

        this.establecimientosService.update(this.establecimiento.id, establecimiento).then(a => {
            this.establecimiento.usuarios.push(this.usuario)
            this.showAssociateUsuario = false
            this.filteredUsuarios = []
            this.usuario = null
        })
    }

    toggleMonitores() {
        this.showAssociateMonitor = !this.showAssociateMonitor
    }

    deleteMonitor(usuario) {
        let params = {
            establecimiento_monitor: {
                establecimiento_id: this.establecimiento.id,
                usuario_id: usuario.id
            }
        }

        this.establecimientoMonitoresService
            .where(params)
            .then((establecimientoMonitores: EstablecimientoMonitor[]) => {
                let establecimiento = new Establecimiento()
                establecimientoMonitores.forEach(ae => ((ae as any)._destroy = true))
                establecimiento.establecimiento_monitores = establecimientoMonitores

                this.establecimientosService.update(this.establecimiento.id, establecimiento).then(a => {
                    establecimientoMonitores.forEach(ae => {
                        let index = this.establecimiento.monitores.findIndex(u => u.id == ae.monitor_id)
                        establecimiento.monitores = this.establecimiento.monitores.splice(index, 1)
                    })
                })
            })
    }

    associateMonitor() {
        let establecimiento = new Establecimiento()
        let establecimientoMonitor = new EstablecimientoMonitor()
        establecimientoMonitor.establecimiento_id = this.establecimiento.id
        establecimientoMonitor.monitor_id = this.usuario.id

        establecimiento.establecimiento_monitores = [establecimientoMonitor]

        this.establecimientosService.update(this.establecimiento.id, establecimiento).then(a => {
            this.establecimiento.monitores.push(this.usuario)
            this.showAssociateMonitor = false
            this.filteredUsuarios = []
            this.usuario = null
        })
    }

    logInAsAlumno(usuarioId: number) {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(usuarioId, { application: "estudiante" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }

    logInAsProfesor(usuarioId: number) {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(usuarioId, { application: "docente" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }
}
