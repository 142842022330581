import { Component, Input, HostBinding } from "@angular/core"

@Component({
    selector: "cui-basic-layout,[cui-basic-layout]",
    template: `
        <div class="header"><ng-content select="[cui-layout-slot=header]"></ng-content></div>
        <div class="body"><ng-content select="[cui-layout-slot=body]"></ng-content></div>
        <div class="footer"><ng-content select="[cui-layout-slot=footer]"></ng-content></div>
    `,
    styleUrls: ["cui-basic-layout.component.scss"]
})
export class CUIBasicLayoutComponent {
    constructor() {}
}
