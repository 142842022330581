import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asociacion_contactos_new.component.html"
})
export class AsociacionContactosNewComponent implements OnInit {
    asociacionId: number
    protected sub: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asociaciones.asociacion_contactos.new")
        this.sub = this.route.params.subscribe(params => {
            this.asociacionId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
