import {
    AsociacionComponent,
    AsociacionesComponent,
    AsociacionesNewComponent,
    AsociacionesEditComponent,
    AsociacionContactoComponent,
    AsociacionContactosComponent,
    AsociacionContactosNewComponent,
    AsociacionContactosEditComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

//import { AsociacionesDeleteComponent } from './asociaciones.delete.component';

export const asociacionesRoutes: Routes = [
    //{ path: 'asociacion', component: AsociacionComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "asociaciones",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionesNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: AsociacionComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }
        ]
    },
    {
        path: "asociaciones/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionesEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/:id/asociacion_contactos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionContactosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/:id/asociacion_contactos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionContactosNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/:id/asociacion_contactos/:asociacionContactoId/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionContactosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asociaciones/:id/asociacion_contactos/:asociacionContactoId",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AsociacionContactoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
    //{ path: 'asociaciones/:id/delete', component: AsociacionesDeleteComponent, canActivate: [TokenGuard, AuthGuard] }
]

export const asociacionesRouting = RouterModule.forChild(asociacionesRoutes)
