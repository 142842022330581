<div class="buttonContainer">
    <ng-template ngFor let-key [ngForOf]="tableButtonsArray | keys">
        <button
            *ngIf="functionParameter && show[key]"
            (click)="tableButtonsArray[key].function(functionParameter)"
            class="btn btn-xs btn-default table-btn"
            [attr.data-toggle]="tableButtonsArray[key].callModal ? 'modal' : null"
            [attr.data-target]="tableButtonsArray[key].callModal ? '#simpleModal' : null"
        >
            <span
                *ngIf="tableButtonsArray[key].glyphicon"
                class="glyphicon small_btn"
                [ngClass]="tableButtonsArray[key].glyphicon"
                aria-hidden="true"
            ></span>
            {{ tableButtonsArray[key].text }}
        </button>
    </ng-template>
</div>
