import { PuntajeCuentasModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { AdminLayoutsModule } from "../layouts/index"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"

import { cuentasRouting } from "./admin_cuentas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        cuentasRouting,
        AdminLayoutsModule,
        CommonsLayoutsModule,
        GrupoUsuariosModule,
        UsuariosModule,
        PuntajeCuentasModule
    ],
    declarations: [],
    exports: []
})
export class AdminCuentasModule {
    static forRoot(): ModuleWithProviders<AdminCuentasModule> {
        return {
            ngModule: AdminCuentasModule,
            providers: []
        }
    }
}
