import { Subscription } from "rxjs"

import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

import { Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "instrumento_desde_lote.component.html",
    styleUrls: ["instrumento_desde_lote.component.scss"]
})
export class InstrumentoDesdeLoteComponent {
    instrumento: Instrumento

    constructor(
        protected titleService: TitleService,
        private router: Router,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos
    ) {}

    actualizarInstrumento(event) {
        if (event && !Array.isArray(event)) {
            this.instrumento = event
            this.router.navigate(["/instrumentos"])
        }
    }
}
