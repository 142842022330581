import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./plan-personal-contenido-new.component.html",
    styleUrls: ["./plan-personal-contenido-new.component.scss"]
})
export class PlanPersonalContenidoNewComponent implements OnInit {
    sessionId: number
    planificacionId: number
    protected sub: Subscription
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.plan_personal_contenidos.new")
        this.sub = this.route.params.subscribe(params => {
            this.sessionId = params["id"]
            this.planificacionId = params["id_planificacion"]
        })
    }
}
