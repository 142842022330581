import { TextView, LinkView, DateView, BooleanView } from "@puntaje/shared/core"
import { Instrumentos } from "./instrumentos.service"

export class InstrumentoView {
    public static get viewParams() {
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Instrumentos, key: "id" } },
            asignatura_id: { label: "Asignatura Id", type: TextView, tableVisible: true },
            numero_preguntas: { label: "Nº Preguntas", type: TextView, tableVisible: true },
            tiempo: { label: "Tiempo", type: TextView, tableVisible: true },
            oficial: { label: "Oficial", type: BooleanView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
