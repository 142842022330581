import { EnsayoNacionalConf } from "@puntaje/shared/core"
import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType,
    CarrerasConf,
    MensajesLoginType,
    PlanesEstudioConf
} from "@puntaje/shared/core"

export class UAConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoInforme: "assets/img/logos/logoSmall.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "default",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            sharedLayout: {
                backgroundImage: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/ua/landing/fondo.jpg"
            }
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica-4",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje-4",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia-4",
            clase: "historia",
            abreviacion: "Historia",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias-4",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Física",
            icono: "fisica-4",
            clase: "fisica",
            abreviacion: "Física",
            asignaturaPlataforma: "Ciencias Física",
            evaluacionTipo: ["ensayo", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: true,
                "prueba de transición": true
            }
        },
        {
            id: null,
            asignatura: "Ciencias Química",
            icono: "quimica-5",
            clase: "quimica",
            abreviacion: "Química",
            asignaturaPlataforma: "Ciencias Química",
            evaluacionTipo: ["ensayo", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: true,
                "prueba de transición": true
            }
        },
        {
            id: null,
            asignatura: "Ciencias Biología",
            icono: "biologia-4",
            clase: "biologia",
            abreviacion: "Biología",
            asignaturaPlataforma: "Ciencias Biología",
            evaluacionTipo: ["ensayo", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: true,
                "prueba de transición": true
            }
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "UA",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/puntajenacional.cl/",
                twitter: "https://twitter.com/puntajenacional",
                youtube: "https://www.youtube.com/user/puntajenacionalcl",
                instagram: "https://www.instagram.com/puntajenacional/"
            },
            soporte: {
                mail: "contactoua@ogr.cl",
                telefono: "(+56) 9 6323 5120<br />(+56) 9 3578 3784<br />(+56) 9 3586 7757<br />(+56) 9 3664 1076"
            },
            contacto: {
                telefono: "(+56) 9 3578 3784<br />(+56) 9 3586 7757<br />(+56) 9 3664 1076",
                mail: "ventas@ogr.cl"
            },
            companyName: "Universidad Autónoma de Chile",
            companySite: "www.uautonoma.cl"
        },
        evaluacionDefault: "paes",
        evaluacionTipoAdaptiveDefault: "diagnostico paes",
        resumenGeneral: {
            curriculum: "simce",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: ["paes"],
        evaluacionTipos: ["paes", "diagnostico paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: "paes",
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "prueba de transición",
        tiposNotificacion: [
            {
                tipo: "curricular",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "ensayo",
                label: "Ensayos"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "reforzamiento",
                label: "Reforzamiento"
            }
        ],
        canRecoverPasswordBySMS: true,
        canTextHighlight: true,
        planClasesRoute: "plan_clases"
    } as PlataformaType

    evaluaciones = {
        // ensayo, curricular, diagnostico ensayo
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        },
        "diagnostico paes": {
            clasificacionMaterial: {},
            planPersonalClasificacionMateriales: [],
            clasificacionTiposEstadisticas: [],
            cursos: [],
            cursosMaterial: [],
            estrellas: [],
            nivelTipoInstrumentoInformeFinal: [],
            rangoCalificacion: {
                min: 100,
                tickInterval: 50,
                max: 1000
            },
            clasificaciones: {
                estadisticas: [],
                groupBy: [],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                referencesTo: {},
                configuration: [],
                cambiarPregunta: [],
                methods: "clasificaciones"
            }
        },
        "prueba de transición": {
            alias: "prueba de transición",
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                estadisticasEvolutivas: "nivel 1 prueba de transición",
                desempenoEvaluacion: "nivel 1 prueba de transición",
                groupBy: ["nivel 1 prueba de transición,nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad cognitiva prueba de transición"
                ],
                methods: "clasificaciones",
                referencesTo: {
                    "nivel 2 prueba de transición": "nivel 1 prueba de transición"
                },
                configuration: [
                    {
                        clasificacionTipo: "nivel 1 prueba de transición",
                        name: "Nivel 1",
                        htmlId: "nivel1",
                        hasHijos: true
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Medio" },
                { clasificacion: "Octavo Básico", label: "8º Medio" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            baseUrl: "/pruebas"
        },
        "diagnostico prueba transicion": {
            cursos: [],
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: [],
                methods: "clasificaciones",
                estadisticas: [],
                referencesTo: {},
                configuration: [],
                cambiarPregunta: [],
                clasificacionTiposTablaEspecificaciones: [],
                desempenoEvaluacion: "nivel 1 prueba de transición,nivel 2 prueba de transición"
            },
            rangoCalificacion: {},
            clasificacionMaterial: {},
            clasificacionTiposEstadisticas: [],
            nivelTipoInstrumentoInformeFinal: [],
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT(sin puntos, con digito verificador)",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
        invalidLoginInactiveEstablishments:
            "<p>Su cuenta no se encuentra asociada a ningún establecimiento activo.</p><p>Para activar su cuenta le rogamos comunicarse con el administrador de su establecimiento o con su monitor de PuntajeNacional.cl al correo <b>contacto@puntajenacional.cl</b> o al teléfono <b>(+56) 9 3578 3784</b>, <b>(+56) 9 3586 7757</b>, o al <b>(+56) 9 3664 1076</b>.</p><p>Le dejamos abierta la invitación a acceder a la versión estudiante de la plataforma.</p>",
        invalidLoginInactiveEstablishmentsRedirectText: "Ir a la versión estudiante"
    } as MensajesLoginType

    carreras = {
        evaluacionTipo: ["ensayo"],
        ponderables: [
            {
                orden: 1,
                ponderable: "Matemática",
                asignaturaAsociada: "Matemática",
                tipo: "calificable"
            },
            {
                orden: 2,
                ponderable: "Lenguaje y Comunicación",
                asignaturaAsociada: "Lenguaje",
                tipo: "calificable"
            },
            {
                orden: 3,
                ponderable: "Historia y Ciencias Sociales",
                asignaturaAsociada: "Historia y Ciencias Sociales",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 4,
                ponderable: "Ciencias",
                asignaturaAsociada: "Ciencias PSU",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 5,
                ponderable: "NEM",
                alias: "Puntaje NEM",
                clase: "nem",
                tipo: "ingresable",
                field: "nem",
                minValue: 150,
                maxValue: 850
            },
            {
                orden: 6,
                ponderable: "Ranking",
                clase: "ranking",
                tipo: "ingresable",
                field: "ranking",
                minValue: 150,
                maxValue: 850
            }
        ]
    } as CarrerasConf

    ensayoNacional = {
        enabled: true,
        enableRedirectOnLogin: false,
        ruta: "ensayo_nacional_ubo",
        nombreUniversidad: "Universidad Bernardo O´Higgins",
        brandingImage: "https://ensayo-nacional.s3-us-west-1.amazonaws.com/ubo/Banner-Branding1800x1000.png",
        zonaConLink: {
            link: "http://www.ubo.cl/admision/simulador-de-puntaje/",
            imagen: "https://ensayo-nacional.s3-us-west-1.amazonaws.com/ubo/Boton-600x400.png"
        },
        evaluaciones: [
            {
                evaluacionTipo: "ensayo",
                asignatura: "Lenguaje",
                evaluacionId: 1415293
            },
            {
                evaluacionTipo: "ensayo",
                asignatura: "Matemáticas",
                evaluacionId: 1415295
            }
        ]
    } as EnsayoNacionalConf

    aditionalClass = false

    componentsInSubmodule: { subirPruebaPropia: { skipContestableTipos: true } }

    registro = {
        enableCarreras: true,
        universidades: ["Universidad Autónoma de Chile"],
        amount: 3,
        enableTelefono: true,
        enableNivel: true,
        nivelObligatorio: true,
        enableColegio: true,
        colegioObligatorio: true,
        enableGenero: true,
        generoObligatorio: true,
        enableInstagram: true,
        enableFechaNacimiento: true,
        fechaNacimientoObligatorio: true,
        mensajeRegistro: `Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
        minutos más, por favor revisa tu carpeta de spam.`
    }

    planesEstudio = {
        hidePlanesAdaptive: true
    } as PlanesEstudioConf
}
