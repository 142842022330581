<ll-titulo>
    <div class="print-hide">Establecimiento #{{ establecimientoId }}</div>
</ll-titulo>
<ll-toolnavbar>
    <establecimiento-toolnavbar
        [displayButtons]="'edit,all'"
        [establecimientoId]="establecimientoId"
    ></establecimiento-toolnavbar>
</ll-toolnavbar>

<establecimiento
    [establecimientoId]="establecimientoId"
    [mostrarTablaDesempenoInforme]="mostrarTablaDesempenoInforme"
></establecimiento>
