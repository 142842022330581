import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "establecimiento.component.html"
})
export class EstablecimientoComponent implements OnInit {
    establecimientoId: number
    protected sub: Subscription
    subQuery: Subscription

    mostrarTablaDesempenoInforme: boolean = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.establecimientos.establecimientos.show")
        this.sub = this.route.params.subscribe(params => {
            this.establecimientoId = +params["id"]
        })

        this.subQuery = this.route.data.subscribe(data => {
            this.mostrarTablaDesempenoInforme = data.mostrarTablaDesempenoInforme ?? this.mostrarTablaDesempenoInforme
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
