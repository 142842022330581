import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"

import { NominaAlumnosComponent } from "./nomina_alumnos.component"
import { NominaProfesoresComponent } from "./nomina_profesores.component"
import { nominasRouting } from "./nominas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        NebuModule,
        UtilModule,
        FormModule,
        CommonsLayoutsModule,
        ModelViewModule
    ],
    declarations: [NominaAlumnosComponent, NominaProfesoresComponent],
    exports: [NominaAlumnosComponent, NominaProfesoresComponent],
    providers: []
})
export class NominasModule {}
