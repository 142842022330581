import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "informes.component.html"
})
export class InformesComponent {
    openingTab: string = "parcial"
    currentTab: string

    constructor(protected titleService: TitleService, protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.estadisticas.informes")
        this.currentTab = this.openingTab

        this.loggedLayoutService.setOptions({ hideTitleOnPrint: true })
    }

    setCurrentTab(tab: string) {
        this.currentTab = tab
    }
}
