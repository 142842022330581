import { BaseForm } from "@puntaje/shared/core"
import { BannerZonas } from "./banner_zonas.service"

export class BannerZonaForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            zona: { label: "Zona", type: "text", visible: true },
            ancho: { label: "Ancho (px)", type: "number", visible: true },
            alto: { label: "Alto (px)", type: "number", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
