import { Component, ViewChild, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asociaciones.component.html"
})
export class AsociacionesComponent {
    mios: boolean
    sub: Subscription
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.asociaciones.asociaciones.list")
        this.sub = this.route.queryParams.subscribe(query => {
            this.mios = query["mios"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
