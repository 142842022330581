<div class="ensayos-breves">
    <p>Ensayos este mes:</p>
    <loading-layout #loadingLayout>
        <div class="ensayos-breves">
            <div class="row-ensayos">
                <div *ngFor="let a of lista_asignaturas" class="cell-ensayo">
                    <a
                        class="{{ a.clase }} asignatura"
                        *ngIf="evaluaciones[a.id]"
                        [routerLink]="['/ensayos', evaluaciones[a.id]?.id, 'realizar']"
                        (click)="trackClickEnsayo(a.asignatura)"
                    >
                        <ogr-icon name="{{ a.icono }}" [align]="'middle'" class="icono"></ogr-icon>
                        {{ a.abreviacion }}
                    </a>
                    <a class="{{ a.clase }} asignatura" *ngIf="!evaluaciones[a.id]">
                        <ogr-icon name="{{ a.icono }}" [align]="'middle'" class="icono"></ogr-icon>
                        {{ a.abreviacion }} - N/A
                    </a>
                </div>
            </div>
        </div>
    </loading-layout>
    <br />
    <br />
    <cui-section-headline>Últimos ensayos realizados</cui-section-headline>
    <loading-layout #loadingLayout2>
        <div class="scroll-content" *ngIf="ultimasEvaluaciones.length > 0">
            <!-- <div *ngFor="let ei of ultimasEvaluaciones" class="evaluacion-box {{clases_asignatura[ei.evaluacion.instrumento.asignatura_id]?.clase}}"> -->
            <div
                *ngFor="let ei of ultimasEvaluaciones"
                class="evaluacion-box {{ lista_asignaturas[ei.asignaturaIndex].clase }}"
            >
                <h4>{{ lista_asignaturas[ei.asignaturaIndex]?.abreviacion }}</h4>
                <div class="icono">
                    <ogr-icon name="{{ lista_asignaturas[ei.asignaturaIndex]?.icono }}"></ogr-icon>
                </div>
                <p>
                    <b>Fecha:</b>
                    &nbsp;{{ ei.instancia.created_at | date: "dd/mm/yyyy HH:mm" }}
                </p>
                <p>
                    <b>Puntaje:</b>
                    &nbsp;{{ ei.instancia.calificacion }}
                </p>
                <a [routerLink]="['/ensayo_instancias', ei?.instancia.id]"><span class="click-ei"></span></a>
            </div>
        </div>

        <div *ngIf="ultimasEvaluaciones.length == 0">
            <p class="empty">No se han encontrado ensayos realizados.</p>
        </div>
    </loading-layout>
</div>
