import { PuntajeEvaluacionesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"

import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { InstrumentoPredefinidosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { routing } from "./admin_evaluaciones.routing"
import { UtilModule } from "@puntaje/shared/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        AdminLayoutsModule,
        InstrumentosModule,
        InstrumentoPredefinidosModule,
        EnsayosModule,
        UtilModule,
        routing,
        PuntajeEvaluacionesModule
    ],
    exports: [],
    declarations: []
})
export class AdminEvaluacionesModule {}
