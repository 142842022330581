import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import {
    Banner,
    Banners,
    BannerForm,
    BannerTipo,
    BannerTipos,
    ImageBanners,
    ImageBannersForm,
    IframeBannersForm
} from "@puntaje/puntaje/api-services"
import { S3, S3Service } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "banners-edit",
    templateUrl: "banners_edit.form.component.html",
    styleUrls: ["banners.form.component.scss"]
})
export class BannersEditComponent implements OnInit {
    @Input() bannerId: number
    @Input() listRoute: string

    oBanner: Banner = new Banner()
    banner: Banner = new Banner()
    form: UntypedFormGroup
    // s3_model = new S3();
    // file: File;
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    params: any
    paramsImages: any
    paramsIframes: any
    loadingOpaque: boolean = false

    constructor(
        protected bannersService: Banners,
        protected s3Service: S3Service,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected imageBannersService: ImageBanners
    ) {}

    ngOnInit() {
        this.bannersService.find(this.bannerId).then((response: Banner) => {
            this.banner = response
            this.oBanner = response.clone()
            this.form = BannerForm.getForm(this.banner, BannerForm.getFormByTipo(this.banner.banner_tipo.nombre))
            this.params = BannerForm.getFormByTipo(this.banner.banner_tipo.nombre)
            this.paramsIframes = IframeBannersForm.formParams
            this.paramsImages = ImageBannersForm.formParams
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        BannerForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            if (this.banner.image_banners && this.banner.image_banners.length > 0) {
                this.loadingOpaque = true
                if (this.loadingLayout) this.loadingLayout.standby()
                let promises = this.banner.image_banners
                    .filter(im => im.file)
                    .map(im => {
                        return new Promise((resolve, reject) => {
                            this.s3Service.where(im.getS3Params()).then((policies: S3[]) => {
                                let policy = policies as any as S3
                                //this.banner.image_banners.find(x => x. == im.);
                                im.image_url = policy["key"]
                                this.s3Service.uploadToS3(policy, im.file, this.imageBannersService.tableName, resolve)
                            })
                        })
                    })
                Promise.all(promises).then(res => {
                    this.doSave()
                })
            } else {
                this.doSave()
            }
        }
    }

    doSave = () => {
        this.bannersService.update(this.banner.id, this.banner).then(response => {
            if (this.loadingLayout) this.loadingLayout.ready()
            this.loadingOpaque = false
            this.router.navigate(["banners"])
        })
    }

    clear() {
        setTimeout(() => {
            this.banner = this.oBanner.clone()
            BannerForm.markFormControlsAsPristine(this.form)
            BannerForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 0)
    }

    addImage() {
        this.banner.addImageBanner()
    }

    removeImage(index: number) {
        this.banner.image_banners.splice(index, 1)
    }
}
