import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { Noticia, Noticias, NoticiaView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"

@Component({
    selector: "noticias",
    templateUrl: "noticias.component.html"
})
export class NoticiasComponent implements OnInit {
    noticias: Noticia[]
    params = NoticiaView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected noticiasService: Noticias,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) { }

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
    }

    showAll(page: number = 1, count: number = 20) {
        const params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            rol: { rol: ["Administrador", "SuperAdministrador"] }
        }
        return this.noticiasService.where(params).then((response: Noticia[], total: number) => {
            this.noticias = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(noticia: Noticia) {
        this.router.navigate(["noticias/" + noticia.id])
    }

    edit(noticia: Noticia) {
        this.router.navigate(["noticias/" + noticia.id + "/edit"])
    }

    delete(id: number) {
        this.noticiasService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    showModalDelete(noticia: Noticia) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este noticia?")
        this.simpleModalService.setModalCallback(() => this.delete(noticia.id))
    }
}
