import { BaseForm, Validations } from "@puntaje/shared/core"
import { Banners } from "./banners.service"
import { BannerTipo } from "./banner_tipos.model"
import { BannerTipos } from "./banner_tipos.service"

export class ImageBannersForm extends BaseForm {
    public static get formParams(): any {
        return {
            file: { label: "Subir Imagen (se completará 'URL imagen' automáticamente)", type: "file", visible: true },
            image_url: { label: "URL Imagen (link de la imagen)", type: "text", visible: true },
            link_url: { label: "URL Link (link adonde lleva al hacer click)", type: "text", visible: true },
            size: { label: "Tamaño (para banners con múltiples imágenes por tamaño)", type: "number", visible: true }
        }
    }
}
