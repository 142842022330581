import { EventEmitter, Component, ViewChild, Input, Output, OnInit } from "@angular/core"
import { GenericModalComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Recordatorio, Recordatorios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "recordatorio-modal",
    templateUrl: "recordatorio_modal.component.html"
})
export class RecordatorioModalComponent implements OnInit {
    @Input() modalId: string

    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    recordatorio: Recordatorio = new Recordatorio()

    @Output() closeCrossCallback: EventEmitter<any> = new EventEmitter<any>()

    rec: Recordatorio
    texto: string
    fecha_envio: Date
    id: number

    constructor(
        protected recordatoriosService: Recordatorios,
        protected authService: AuthService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.rec = new Recordatorio()
    }

    guardar() {
        this.rec.texto = this.texto
        this.rec.fecha_envio = this.fecha_envio
        this.rec.usuario_id = this.authService.getUserData().id
        this.rec.enviado = false

        this.recordatoriosService.save(this.rec).then(response => {
            this.router.navigate(["establecimientos/"])
        })
    }

    closeCross() {
        this.closeCrossCallback.emit()
    }

    public open() {
        this.genericModal.buttonPressed()
    }

    public close() {
        this.genericModal.close()
    }
}
