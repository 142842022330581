import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { Noticia, Noticias, NoticiaView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "noticia",
    templateUrl: "noticia.component.html"
})
export class NoticiaComponent implements OnInit {
    @Input() noticiaId: number
    @Output() noticiaChange = new EventEmitter<any>()
    noticia: Noticia
    params = NoticiaView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected noticiasService: Noticias) {}

    ngOnInit() {
        this.noticiasService.find(this.noticiaId).then((noticia: Noticia) => {
            this.noticiaChange.emit(noticia)
            this.noticia = noticia
            this.loadingLayout.ready()
        })
    }
}
