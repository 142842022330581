import { Component, ViewEncapsulation } from "@angular/core"

@Component({
    selector: "cui-main-headline",
    template: `
        <h1 class="cui-main-headline"><ng-content></ng-content></h1>
    `,
    styleUrls: ["cui-main-headline.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CUIMainHeadlineComponent {
    constructor() {}
}
