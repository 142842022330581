import { PuntajePlanEstudiosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"

import { planEstudiosRouting } from "./admin_plan_estudios.routing"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { AdminLayoutsModule } from "../layouts/index"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdminLayoutsModule,
        UtilModule,
        PlanEstudiosModule,
        ModelViewModule,
        planEstudiosRouting,
        PuntajePlanEstudiosModule
    ],
    declarations: [],
    exports: []
})
export class AdminPlanEstudiosModule {}
