import { Component, Injector, ComponentFactoryResolver } from "@angular/core"
import { ModelViewComponent } from "./model_view.component"

/*#################################

Vista simple de celdas para tablas de componentes.
Requiere que esté definido un show_params en el modelo. Recibe show_params como params, el objeto como value, y el valor actual a evaluar
del objeto como key (por ejemplo, objeto asignatura, con key de fecha_creación, que dentro de show_params es type "date").
Dentro de show_params:
Type: puede ser text, date, link. Si es link, se requiere parámetros adicionales para saber hacia donde se debe linkear.
Label: el texto del label.

#################################*/

@Component({
    template: `
        <model-table-cell-layout #layoutComponent>
            <div #content></div>
        </model-table-cell-layout>
    `,
    styleUrls: ["model_table_cell.component.scss"],
    selector: "model-table-cell"
})
export class ModelTableCellComponent extends ModelViewComponent {
    constructor(injector: Injector, resolver: ComponentFactoryResolver) {
        super(injector, resolver)
    }
}
