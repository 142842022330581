import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Banner,
    Banners,
    BannerZona,
    BannerZonas,
    BannerPagina,
    BannerPaginas,
    BannerInstancia,
    BannerInstancias
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "banner_instancias",
    templateUrl: "banner_instancias.component.html",
    styleUrls: ["banner_instancias.component.scss"]
})
export class BannerInstanciasComponent implements OnInit {
    @Input() bannerId: number
    @Input() listRoute: string
    bannerZonas: BannerZona[] = []
    bannerPaginas: BannerPagina[] = []
    bannerInstancias: BannerInstancia[] = []
    orderedInstances = []
    form: UntypedFormGroup = new UntypedFormGroup({})
    ready: boolean = false
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutWait") loadingLayoutWait: LoadingLayoutComponent
    toggleWait: boolean = false

    constructor(
        private router: Router,
        private bannersService: Banners,
        private bannerZonasService: BannerZonas,
        private bannerPaginasService: BannerPaginas,
        private bannerInstanciasService: BannerInstancias
    ) {}

    ngOnInit() {
        this.bannerZonasService.where().then((response: BannerZona[]) => {
            this.bannerZonas = response
            this.bannerPaginasService.where().then((response: BannerPagina[]) => {
                this.bannerPaginas = response
                this.bannerInstanciasService
                    .where({ banner_instancia: { banner_id: this.bannerId }, all: true })
                    .then((response: BannerInstancia[]) => {
                        this.bannerInstancias = response
                        this.makeForm()
                        this.ready = true
                        this.loadingLayout.ready()
                    })
            })
        })
    }

    makeForm() {
        var formito = {}
        for (let pagina of this.bannerPaginas) {
            this.orderedInstances[pagina.id] = []
            for (let zona of this.bannerZonas) {
                this.orderedInstances[pagina.id][zona.id] = this.bannerInstancias
                    .filter(i => i.banner_pagina_id == pagina.id)
                    .filter(i => i.banner_zona_id == zona.id)
                    .sort((a, b) => (a.activo === b.activo ? 0 : a.activo ? -1 : 1))
                let nombrecito = "i_" + pagina.id + "_" + zona.id
                formito[nombrecito] = new UntypedFormControl("")
            }
        }
        this.form = new UntypedFormGroup(formito)
    }

    toggle(pagina_id, zona_id) {
        let previous = this.orderedInstances[pagina_id][zona_id]
        if (previous.length > 0) {
            //Desactivar la instancia
            this.orderedInstances[pagina_id][zona_id][0].activo = !this.orderedInstances[pagina_id][zona_id][0].activo
        } else {
            var newInstance = new BannerInstancia()
            newInstance.banner_pagina_id = pagina_id
            newInstance.banner_zona_id = zona_id
            newInstance.activo = true
            this.orderedInstances[pagina_id][zona_id] = [newInstance]
        }
    }

    save() {
        var newList = []
        for (let pagina of this.bannerPaginas) {
            for (let zona of this.bannerZonas) {
                let slot = this.orderedInstances[pagina.id][zona.id] as BannerInstancia[]
                if (slot.length > 0 && (slot[0].id || (!slot[0].id && slot[0].activo))) newList.push(slot[0])
            }
        }
        this.bannersService.find(this.bannerId).then((banner: Banner) => {
            this.bannersService.saveInstanceList(banner, newList).then(response => {
                this.router.navigate([this.listRoute])
            })
        })
    }
}
