import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Establecimiento } from "@puntaje/puntaje/api-services"
import { Establecimientos } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "establecimiento_contactos.component.html"
})
export class EstablecimientoContactosComponent implements OnInit {
    establecimientoId: number
    subtitle: string
    protected sub: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected establecimientosService: Establecimientos
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.establecimientos.establecimiento_contactos.list")
        this.sub = this.route.params.subscribe(params => {
            this.establecimientoId = +params["id"]
        })
        this.setSubtitle()
    }

    setSubtitle() {
        this.establecimientosService.find(this.establecimientoId).then((establecimiento: Establecimiento) => {
            this.subtitle = establecimiento.toStringWithIdPais()
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
