import { Component, ViewChild, OnInit, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezona_zonas.edit.component.html"
})
export class BannerZonasEditComponent implements OnInit {
    bannerZonaId: number
    loggedLayoutTitle = "Editar Zona"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.artezona_zona.edit")
        this.route.params.subscribe(params => {
            this.bannerZonaId = +params["id"]
        })
    }
}
