import { TextView, LinkView, DateView, BooleanView } from "@puntaje/shared/core"
import { Instrumentos } from "./instrumentos.service"

export class InstrumentoAsSugeridaView {
    public static get viewParams() {
        return {
            numero_preguntas: { label: "Nº Preguntas", type: TextView, tableVisible: true },
            tiempo: { label: "Tiempo", type: TextView, tableVisible: true }
        }
    }
}
