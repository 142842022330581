<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="establecimiento.id" name="id" class="form-control" />
        </div>

        <div class="form-group">
            <label>Establecimiento</label>
            <input
                type="text"
                [(ngModel)]="establecimiento.establecimiento"
                name="establecimiento"
                class="form-control"
            />
        </div>

        <div class="form-group">
            <label>{{ idEstablecimientoNombre }}</label>
            <input
                type="text"
                [(ngModel)]="establecimientoPais[idEstablecimientoKey]"
                name="rbd"
                class="form-control"
            />
        </div>

        <div *ngIf="monitores" class="form-group">
            <label>Monitor</label>
            <select class="form-control" [(ngModel)]="monitor_id" name="monitor-id" (input)="onChangeMonitor($event)">
                <option [value]="undefined">Todos</option>
                <option *ngFor="let monitor of monitores" [value]="monitor.id">
                    {{ monitor.nombre + " " + monitor.apellido_paterno }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" [value]="true" [(ngModel)]="establecimiento.activo" name="activo" />
                    Activo
                </label>
            </div>

            <div class="radio">
                <label>
                    <input type="radio" [value]="false" [(ngModel)]="establecimiento.activo" name="activo" />
                    No activos
                </label>
            </div>

            <div class="radio">
                <label>
                    <input type="radio" [(ngModel)]="establecimiento.activo" name="activo" />
                    Todos
                </label>
            </div>
        </div>

        <cui-button-link class="btn_style btn-search" (click)="search()">Buscar</cui-button-link>
        <cui-button-link class="btn_style pull-right" (click)="download()">Exportar</cui-button-link>
    </div>
</form>
