import { Component, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Asociacion, Asociaciones, AsociacionForm } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "asociaciones.new.component.html"
})
export class AsociacionesNewComponent {
    loggedLayoutTitle = "Agregar asociacion"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}
}
