import { AppConfig, BaseForm } from "@puntaje/shared/core"
import { Asignaturas } from "@puntaje/nebulosa/api-services"

declare const config: AppConfig
export class InstrumentoPredefinidoForm extends BaseForm {
    private static convertToObject(array: Array<String>) {
        var pairs = []
        if (array) {
            array.forEach((v, i) => {
                pairs.push({
                    id: v,
                    toString() {
                        return v
                    }
                })
            })
        }
        return pairs
    }

    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            instrumento_id: { label: "Instrumento ID", type: "number", visible: true },
            instrumento_multiple_id: { label: "Instrumento Multiple ID", type: "number", visible: true },
            nombre: { label: "Nombre", type: "text", visible: true },
            descripcion: { label: "Descripción", type: "textarea", tableVisible: true },
            tag: {
                label: "Tag",
                type: "select",
                visible: true,
                options: this.convertToObject(config.instrumentosPredefinidos.zonas)
            },
            asignatura_id: { label: "Asignatura", type: "select", visible: true, options: { class: Asignaturas } },
            created_at: { label: "Fecha de creación", type: "hidden", visible: false },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: false }
        }
    }
}
