import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        carreras: urls.carrerasUrl,
        achievements: urls.achievementsUrl
    },
    googleAnalyticsPropertyId: {},
    googleTagManagerPropertyId: {
        profesores: "GTM-T5JCLK9",
        alumnos: "GTM-T5JCLK9"
    },
    globalSiteTag: {
        alumnos: { propertyId: "G-WVPFSS5CYT", sendTo: "" }
    },
    facebookAnalyticsPropertyId: {},
    domain: {
        profesores: "docentes.preuniversitario.uautonoma.cl",
        alumnos: "preuniversitario.uautonoma.cl",
        admin: "admin.preuniversitario.uautonoma.cl"
    },
    facebook: {
        appId: "1104415359622082",
        apiVersion: "v2.11"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: "1104415359622082",
    facebookApiVersion: "v2.6",
    domain: {
        profesores: "docentes.preuniversitario.uautonoma.cl",
        alumnos: "preuniversitario.uautonoma.cl",
        admin: "admin.preuniversitario.uautonoma.cl"
    }
}
