import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"

import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"

import { BannerComponent } from "./artezona.component"
import { BannersNewComponent } from "./artezonas.new.component"
import { BannersEditComponent } from "./artezonas.edit.component"
import { BannersComponent } from "./artezonas.component"

import { BannerPaginaComponent } from "./artezona_paginas/artezona_pagina.component"
import { BannerPaginasNewComponent } from "./artezona_paginas/artezona_paginas.new.component"
import { BannerPaginasEditComponent } from "./artezona_paginas/artezona_paginas.edit.component"
import { BannerPaginasComponent } from "./artezona_paginas/artezona_paginas.component"

import { BannerZonaComponent } from "./artezona_zonas/artezona_zona.component"
import { BannerZonasNewComponent } from "./artezona_zonas/artezona_zonas.new.component"
import { BannerZonasEditComponent } from "./artezona_zonas/artezona_zonas.edit.component"
import { BannerZonasComponent } from "./artezona_zonas/artezona_zonas.component"

import { BannerInstanciasComponent } from "./artezona_instancias.component"
import { BannersActivosComponent } from "./artezonas-activas.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [CommonModule, FormsModule, UtilModule, BannersModule, ModelViewModule, CUILayoutsModule],
    declarations: [
        BannerComponent,
        BannersNewComponent,
        BannersEditComponent,
        BannersComponent,
        BannerInstanciasComponent,
        BannerPaginaComponent,
        BannerPaginasNewComponent,
        BannerPaginasEditComponent,
        BannerPaginasComponent,
        BannerZonaComponent,
        BannerZonasNewComponent,
        BannerZonasEditComponent,
        BannerZonasComponent,
        BannersActivosComponent
    ],
    exports: [
        BannerComponent,
        BannersNewComponent,
        BannersEditComponent,
        BannersComponent,
        BannerInstanciasComponent,
        BannerPaginaComponent,
        BannerPaginasNewComponent,
        BannerPaginasEditComponent,
        BannerPaginasComponent,
        BannerZonaComponent,
        BannerZonasNewComponent,
        BannerZonasEditComponent,
        BannerZonasComponent,
        BannersActivosComponent
    ]
})
export class PuntajeArtezonasModule {}
