import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { Banner, Banners, BannerView } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "banners",
    templateUrl: "banners.component.html"
})
export class BannersComponent implements OnInit {
    @Input() listRoute: string
    banners: Banner[]
    params = BannerView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent

    constructor(
        protected bannersService: Banners,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            instances: {
                function: this.instances.bind(this),
                text: "Instancias",
                callModal: false,
                glyphicon: "glyphicon-list"
            },
            users: {
                function: this.users.bind(this),
                text: "Usuarios",
                callModal: false,
                glyphicon: "glyphicon-list"
            },
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.showAll()
    }

    showAll(page: number = 1, count: number = 20) {
        return this.bannersService
            .where({
                page: page,
                per: count,
                sort_by: "created_at",
                order: "desc",
                include: "[banner_tipo,image_banners,iframe_banners]"
            })
            .then((response: Banner[], total: number) => {
                this.banners = response
                this.loadingLayout.ready()
                return total
            })
    }

    users(banner: Banner) {
        this.bannersService.usuariosClick(banner.id).then(banners => {})
    }

    instances(banner: Banner) {
        this.router.navigate([this.listRoute + "/instancias/" + banner.id])
    }

    view(banner: Banner) {
        this.router.navigate([this.listRoute + "/" + banner.id])
    }

    edit(banner: Banner) {
        this.router.navigate([this.listRoute + "/" + banner.id + "/edit"])
    }

    delete(id: number) {
        this.bannersService.remove(id).then(response => {
            this.router.navigate([this.listRoute])
            //this.paginator.changePage(this.paginator.currentPage);
        })
    }

    showModalDelete(banner: Banner) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este banner?")
        this.simpleModalService.setModalCallback(() => this.delete(banner.id))
    }
}
