<loading-layout #loadingLayout [opaque]="loadingOpaque">
    <div class="row" *ngIf="bannerTipos">
        <div class="col-md-8 col-sm-12">
            <div class="select-container">
                <label>Seleccione un tipo de banner:</label>
                <select [(ngModel)]="bannerTipoSelected" (ngModelChange)="getBannerForm($event)" class="form-control">
                    <option [ngValue]="undefined">Seleccione tipo de banner</option>
                    <option *ngFor="let bannerTipo of bannerTipos" [ngValue]="bannerTipo">
                        {{ bannerTipo.nombre }}
                    </option>
                </select>
            </div>
            <div *ngIf="form">
                <form novalidate [formGroup]="form">
                    <form-input
                        *ngFor="let key of params | keys"
                        [form]="form"
                        [params]="params[key]"
                        [(value)]="banner[key]"
                        [key]="key"
                    ></form-input>
                    <br />
                    <div *ngFor="let im of banner.image_banners; let i = index" class="submodel">
                        <button class="btn-remove" (click)="removeImage(i)" *ngIf="banner.image_banners.length > 1">
                            ×
                        </button>
                        <h4>
                            <fa name="picture-o"></fa>
                            Imagen {{ i + 1 }}
                        </h4>
                        <form-input
                            *ngFor="let key of paramsImages | keys"
                            [form]="form.controls['image_banners']['controls'][i]"
                            [params]="paramsImages[key]"
                            [(value)]="im[key]"
                            [key]="key"
                        ></form-input>
                    </div>

                    <div *ngFor="let im of banner.iframe_banners; let i = index" class="submodel">
                        <h4>Iframe {{ i + 1 }}</h4>
                        <form-input
                            *ngFor="let key of paramsIframes | keys"
                            [form]="form.controls['iframe_banners']['controls'][i]"
                            [params]="paramsIframes[key]"
                            [(value)]="im[key]"
                            [key]="key"
                        ></form-input>
                    </div>

                    <button (click)="save()" type="button" class="btn btn-default">Guardar</button>
                    <button (click)="clear()" type="button" class="btn btn-default">Borrar</button>
                    <button
                        (click)="addImage()"
                        type="button"
                        class="btn btn-default pull-right"
                        *ngIf="banner.image_banners"
                    >
                        Agregar imagen
                    </button>
                </form>
            </div>
        </div>
    </div>
</loading-layout>
