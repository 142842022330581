import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    GrupoUsuarios,
    PlanPersonales
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
import { ConfigModel } from "ngx-bootstrap/pagination/models"
declare const config: AppConfig

@Component({
    selector: "filtro-plan-clase-admin-usuarios",
    templateUrl: "./filtro-plan-clase-admin-usuarios.component.html",
    styleUrls: ["./filtro-plan-clase-admin-usuarios.component.scss"]
})
export class FiltroPlanClaseAdminUsuariosComponent {
    startDate: string
    endDate: string
    establecimiento: Establecimiento = null
    establecimientos: Establecimiento[]
    grupoUsuario: GrupoUsuario = null
    grupoUsuarios: GrupoUsuario[]
    usersHash: any
    deshabilitarRestriccionDeFecha = config.plataforma.deshabilitarRestriccionDeFecha || false
    @Input() planId: number

    @Output() conjuntoWithAvances = new EventEmitter<any>()

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        protected establecimientosService: Establecimientos,
        protected grupoUsuariosService: GrupoUsuarios,
        protected planPersonalesService: PlanPersonales
    ) {}

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({
                per: 100,
                id_pais_establecimiento: establecimientoFilter,
                pais: config.plataforma.pais,
                include: `[establecimiento_${config.plataforma.pais}]`
            })
            .then((establecimientos: Establecimiento[]) => {
                this.establecimientos = establecimientos
            })
    }

    getGrupoUsuarios(establecimiento: Establecimiento) {
        if (this.establecimiento.id !== establecimiento.id) console.log("Something really weird is happening :S")
        this.grupoUsuario = null
        this.grupoUsuarios = null
        this.grupoUsuariosService
            .where({ grupo_usuario: { establecimiento_id: this.establecimiento.id } })
            .then((grupoUsuarios: GrupoUsuario[]) => {
                this.grupoUsuarios = grupoUsuarios
                this.loadData()
            })
    }

    loadData() {
        console.log("loading data")
        this.conjuntoWithAvances.emit(null)
        if (this.planId && this.checkDates()) {
            const params = {
                start_time: this.startDate,
                end_time: this.endDate
            }
            if (this.establecimiento) params["establecimiento_id"] = this.establecimiento.id
            if (this.grupoUsuario) params["grupo_usuario_id"] = this.grupoUsuario.id
            this.planPersonalesService.avanceSesionesAlumnos(this.planId, params).then(data => {
                data["nombre"] = "Todos"
                if (this.establecimiento) data["nombre"] = this.establecimiento.establecimiento
                if (this.grupoUsuario) data["nombre"] = this.grupoUsuario.nombre
                this.conjuntoWithAvances.emit(data)
            })
        }
    }

    checkDates() {
        console.log("checking")
        const regexp = /[0-9]{4}-[0-9]{2}-[0-9]{2}/gi
        if (this.startDate === undefined || this.endDate === undefined) {
            return false
        }
        if (!this.startDate.match(regexp) || !this.endDate.match(regexp)) {
            return false
        }
        const sDate = new Date(this.startDate)
        const eDate = new Date(this.endDate)
        const difference = eDate.getTime() - sDate.getTime()
        const diff_days = difference / 86400000
        if (!this.deshabilitarRestriccionDeFecha && (!this.establecimiento || !this.grupoUsuario)) {
            return diff_days < 31 && diff_days > 0
        } else {
            console.log(diff_days)
            return diff_days < 366 && diff_days > 0
        }
    }
}
