<loading-layout #loadingLayout>
    <table-with-depending-actions
        [contentArray]="planEstudios"
        [showParams]="params"
        [tableButtonsArray]="tableButtons"
    ></table-with-depending-actions>
    <paginator [getList]="this.showAll.bind(this)"></paginator>

    <loading-layout #loadingLayout2>
        <div class="row" *ngIf="planEstudioAplicado">
            <div class="col-md-12"><h3>Datos del plan de estudio</h3></div>

            <div class="col-md-6">Nombre</div>
            <div class="col-md-6">
                <input type="text" class="form-control" [(ngModel)]="planEstudioAplicado.plan_estudio_aplicado" />
            </div>

            <div class="col-md-12"><h3>Lotes del plan de estudio</h3></div>
            <ng-container *ngFor="let planEstudioLote of planEstudioLotes">
                <div class="col-md-6">
                    {{ planEstudioLote.nombre }}
                </div>

                <div class="col-md-6">
                    <select class="form-control" [(ngModel)]="planEstudioLote.generador_instrumento_id">
                        <option *ngFor="let gi of generadorInstrumentos" [ngValue]="gi.id">
                            {{ gi.generador_instrumento }}
                        </option>
                    </select>
                </div>
            </ng-container>
            <div class="col-md-12">
                <button class="btn btn-default" (click)="activate()">
                    <span class="glyphicon glyphicon-pencil"></span>
                    &nbsp;Activar
                </button>
            </div>
        </div>
    </loading-layout>
</loading-layout>

<plan-estudio-aplicados *ngIf="!disablePlanEstudioAplicados" [listRoute]="listRoute"></plan-estudio-aplicados>
