import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Banner } from "@puntaje/puntaje/api-services"
import { ToolNavbar } from "@puntaje/shared/core"

@Component({
    templateUrl: "banners.toolnavbar.component.html",
    selector: "banner-toolnavbar"
})
export class BannersToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() bannerId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayToolNavbar: boolean = true
    displayBtnInstancias: boolean = false

    constructor(router: Router, route: ActivatedRoute) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/banners"
        this.urlNew = "/banners/new"
        if (this.bannerId != null) {
            this.urlEdit = "/banners/" + this.bannerId + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnInstancias = this.displayButtons.indexOf("instancias") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit && !this.displayBtnInstancias) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.bannerId != null) {
            this.urlEdit = "/banners/" + this.bannerId + "/edit"
        }
        super.edit()
    }

    instancias() {
        this.router.navigate(["banners/instancias/" + this.bannerId])
    }
}
