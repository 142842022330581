import { TypeformWidgetsComponent, TypeformWidgetComponent, TypeformWidgetsEditComponent, TypeformWidgetsNewComponent } from "@puntaje/puntaje/core"
import { TypeformWidgetsDeleteComponent } from "@puntaje/puntaje/new-modules/typeform-widgets"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

const routes: Routes = [
    {
        path: "typeform_widgets",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TypeformWidgetsComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "typeform_widgets/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TypeformWidgetsNewComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "typeform_widgets/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TypeformWidgetComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "typeform_widgets/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TypeformWidgetsEditComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    {
        path: "typeform_widgets/:id/delete",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: TypeformWidgetsDeleteComponent, canActivate: [AuthGuard, TokenGuard] }]
    }
]

export const typeformWidgetsRouting = RouterModule.forChild(routes)