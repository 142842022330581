import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core"
import { Subscription } from "rxjs"
import { TypeformWidgetsBuscadorService } from "./typeform-widgets-buscador.service"
import { AppConfig } from "@puntaje/shared/core"
import {
    Lugar,
    Lugares,
    TypeformWidget,
    TypeformWidgetTipoUsuario,
    TypeformWidgetTipoUsuarios
} from "@puntaje/puntaje/api-services"
declare const config: AppConfig

@Component({
    selector: "typeform-widgets-buscador",
    templateUrl: "./typeform-widgets-buscador.component.html"
})
export class TypeformWidgetsBuscadorComponent implements OnInit, OnDestroy {
    config: typeof config

    typeformWidget: TypeformWidget = new TypeformWidget()
    sub: Subscription
    open = false
    lugar: Lugar
    lugares: Lugar[]
    tipoUsuario: TypeformWidgetTipoUsuario
    tipoUsuarios: TypeformWidgetTipoUsuario[]
    establecimientoPais: any = {}
    idEstablecimientoNombre: string = config.plataforma.identificadorEstablecimiento[0]
    idEstablecimientoKey: string = config.plataforma.identificadorEstablecimiento[0].replace(" ", "_").toLowerCase()

    // sirve para poder poner campos por defecto que se llenen de forma asincrona
    @Output() onReady = new EventEmitter()

    constructor(
        protected typeformWidgetsBuscadorService: TypeformWidgetsBuscadorService,
        protected lugaresService: Lugares,
        protected typeformWidgetTipoUsuariosService: TypeformWidgetTipoUsuarios
    ) {
        this.config = config
    }

    async ngOnInit() {
        this.lugares = await this.lugaresService.where({ lugar_tipo: config.plataforma.lugarTipo })
        this.tipoUsuarios = await this.typeformWidgetTipoUsuariosService.where({})

        this.sub = this.typeformWidgetsBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        this.onReady.emit()
    }

    search() {
        this.typeformWidgetsBuscadorService.search(
            this.typeformWidget,
            this.establecimientoPais,
            this.idEstablecimientoKey
        )
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
