<loading-layout #loadingLayout>
    <div class="clase-wrap">
        <div *ngIf="!loadingLayout.loading" class="clase">
            <div class="titulo-wrap">
                <h3 class="label-titulo">Clase {{ index + 1 }}:</h3>
                <div class="titulo-container">
                    <p class="nombre-editable" *ngIf="!editingSesionFields.nom">{{ clase.plan_personal_sesion }}</p>
                </div>
                <div class="iconos_acciones">
                    <fa
                        name="pencil-square-o"
                        class="editar"
                        [routerLink]="['/planificaciones', planificacionId, 'sesiones', clase.id, 'edit']"
                    ></fa>
                    <fa name="trash-o" class="eliminar" (click)="emitClase()"></fa>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-6">
                    <p>
                        <label>Clasificaciones:</label>
                        <span class="clasificacion" *ngFor="let clasificacion of clase.nebu_clasificaciones">
                            {{ clasificacion.clasificacion }}
                        </span>
                    </p>
                </div>
                <div class="col-sm-6">
                    <div class="fechas-container">
                        <div class="fechas">
                            <p>
                                <ogr-icon class="clock clock-start" name="clock-start"></ogr-icon>
                                <label>Fecha de publicación:</label>
                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_inicial | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_inicial"
                                        (ngModelChange)="checkDateRange(clase)"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p>
                                <ogr-icon class="clock clock-stop" name="clock-stop"></ogr-icon>
                                <label>Fecha de cierre:</label>
                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_final | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_final"
                                        (ngModelChange)="checkDateRange(clase)"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p *ngIf="hasEvaluaciones">
                                <ogr-icon class="clock" name="clock-evaluacion"></ogr-icon>
                                <label>Fecha publicación pauta:</label>

                                <ng-container *ngIf="!editingSesionFields.fecha">
                                    {{ clase.fecha_pauta | date: "dd/MM/yyyy HH:mm" }}
                                </ng-container>

                                <ng-container *ngIf="editingSesionFields.fecha">
                                    <datepicker-form
                                        [(ngModel)]="clase.fecha_pauta"
                                        [asISOString]="true"
                                    ></datepicker-form>
                                </ng-container>
                            </p>
                            <p *ngIf="editingSesionFields.dateRangeError" class="error-warning">
                                <fa name="times" class="warning-icon"></fa>
                                La fecha de publicación debe ser menor o igual a la fecha de cierre.
                            </p>
                            <ng-container *ngIf="editingSesionFields.fecha">
                                <cui-button
                                    class="btn-primary pull-right btn_style btn-edit"
                                    (click)="saveEdit('fecha'); fechasChange.emit()"
                                >
                                    <fa name="pencil"></fa>
                                    &nbsp;Editar
                                </cui-button>
                                <cui-button
                                    buttonType="cancel"
                                    class="btn-primary pull-right btn_style_2 btn-edit"
                                    (click)="cancelEdit('fecha')"
                                >
                                    Cancelar
                                </cui-button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-6">
                    <a
                        class="addContenido"
                        [routerLink]="['/planificaciones', planificacionId, 'sesiones', clase.id, 'contenidos', 'new']"
                    >
                        Agregar Material/Evaluación
                    </a>
                    <table>
                        <tr *ngFor="let contenido of clase.plan_personal_contenidos; let indexContenido = index">
                            <td>
                                <div class="contenido" *ngIf="contenido.tipo == 'Material'">
                                    <ogr-icon name="guia" class="icono"></ogr-icon>
                                    <a (click)="openMaterialPreview(contenido.material)" class="nombre-contenido">
                                        {{ contenido.tipo }}: {{ contenido.material.material }}
                                    </a>
                                </div>
                                <div class="contenido" *ngIf="contenido.tipo == 'Evaluacion'">
                                    <ogr-icon name="prueba" class="icono"></ogr-icon>
                                    <div>
                                        <a
                                            class="nombre-contenido"
                                            (click)="openEvalPreview(evaluacionesId['contenido'][indexContenido])"
                                        >
                                            Evaluación{{
                                                contenido.evaluacion.evaluacion
                                                    ? ": " + contenido.evaluacion.evaluacion
                                                    : " #" + evaluacionesId["contenido"][indexContenido]
                                            }}
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <fa
                                    name="pencil-square-o"
                                    [routerLink]="[
                                        '/planificaciones',
                                        planificacionId,
                                        'sesiones',
                                        clase.id,
                                        'contenidos',
                                        contenido.id,
                                        'edit'
                                    ]"
                                    class="iconContenido"
                                ></fa>
                            </td>
                            <td>
                                <fa
                                    name="times"
                                    class="iconContenido"
                                    (click)="showModalDelete(contenido, indexContenido)"
                                ></fa>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <evaluacion-preview-modal
                *ngIf="modalEvaluacionId"
                [modalId]="'evaluacion'"
                [evaluacionId]="modalEvaluacionId"
                (closeCrossCallback)="closeEvalPreview()"
                [enableReporte]="enableReporte"
            ></evaluacion-preview-modal>
            <material-preview-modal
                *ngIf="materialOpen"
                [modalId]="'material'"
                (closeCrossCallback)="closeMaterialPreview()"
            ></material-preview-modal>
        </div>
    </div>
</loading-layout>
