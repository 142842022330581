import { BaseForm, Validations } from "@puntaje/shared/core"

export class EstablecimientosBrasilForm extends BaseForm {
    companyName: string = ""

    public static get formParams(): any {
        return {
            establecimiento_id: { type: "hidden", visible: true },
            inep_mec: { label: "INEP/MEC", type: "text", visible: true }
        }
    }
}
