<ll-titulo>Contactos</ll-titulo>
<ll-subtitulo>{{ subtitle }}</ll-subtitulo>
<ll-toolnavbar>
    <establecimiento-contacto-toolnavbar
        [establecimientoId]="establecimientoId"
        [displayButtons]="'new,search'"
    ></establecimiento-contacto-toolnavbar>
</ll-toolnavbar>

<establecimiento-contactos [establecimientoId]="establecimientoId"></establecimiento-contactos>
