import { AppConfig, BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { TypeformWidgetTipos } from "./typeform_widget_tipos.service"
import { TypeformWidgetTipoUsuarios } from "./typeform_widget_tipo_usuarios.service"
import { Lugares } from "./lugares.service"
import { Establecimientos } from "./establecimientos.service"
declare const config: AppConfig

export class TypeformWidgetForm extends BaseForm {
    public static get formParams() {
        const params: any = {
            id: { label: "Id", type: "hidden", visible: false },
            nombre_referencia: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            codigo: {
                label: "codigo",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            texto_boton: {
                label: "Texto botón",
                type: "text",
                visible: true
            },
            color_boton: {
                label: "Color botón",
                type: "color",
                visible: true
            },
            fecha_inicio: {
                label: "Fecha Inicio",
                type: "datepicker",
                visible: true,
                disableTimepicker: true,
                asISOString: true,
                validations: [Validators.required]
            },
            fecha_termino: {
                label: "Fecha Término",
                type: "datepicker",
                visible: true,
                disableTimepicker: true,
                asISOString: true,
                validations: [Validators.required]
            },
            typeform_widget_tipo_id: {
                label: "Tipo",
                type: "select",
                visible: true,
                options: { class: TypeformWidgetTipos },
                validations: [Validators.required]
            },
            typeform_widget_tipo_usuario_id: {
                label: "Tipo Usuario",
                type: "select",
                visible: true,
                options: { class: TypeformWidgetTipoUsuarios },
                validations: [Validators.required]
            },
            lugar: {
                label: "Lugar",
                type: "autocomplete",
                placeholder: "Lugares",
                options: { class: Lugares, params: { lugar_tipo: "Comuna", pais: config.plataforma.pais } },
                visible: true,
                validations: []
            },
            establecimiento_id: { label: "Id", type: "hidden", visible: false },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }

        return params
    }
}
