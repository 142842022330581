import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { Streaming, Streamings, StreamingView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent, GenericModalComponent } from "@puntaje/shared/core"

@Component({
    selector: "streaming-admin",
    templateUrl: "streaming_admin.component.html"
})
export class StreamingAdminComponent implements OnInit {
    canalesStreaming: Streaming[]
    params = StreamingView.viewParams
    tableButtonsArray: any
    selectedMaterialStream: Streaming
    materialPreviewId: number
    materialId: number

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected streamingsService: Streamings,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            toggleStreaming: {
                function: this.toggleStreaming.bind(this),
                text: "Habilitar/deshabilitar streaming",
                callModal: false,
                glyphicon: "glyphicon-facetime-video"
            },
            custom: {
                function: this.showModalMaterial.bind(this),
                text: "Asociar material descargable",
                callModal: false,
                glyphicon: "glyphicon-facetime-video"
            }
            // view:   {function: this.view.bind(this), text: 'Ver', callModal: false, glyphicon:"glyphicon-search"},
            // edit:   {function: this.edit.bind(this), text: 'Editar', glyphicon:"glyphicon-pencil"},
            // delete: {function: this.showModalDelete.bind(this), text: 'Borrar', callModal: true, glyphicon:"glyphicon-trash"}
        }
    }

    showAll(page: number = 1, count: number = 20) {
        let params = {
            page: page,
            per: count
        }
        return this.streamingsService.where(params).then((response: Streaming[], total: number) => {
            this.canalesStreaming = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(streaming: Streaming) {
        this.router.navigate(["streamings/" + streaming.id])
    }

    edit(streaming: Streaming) {
        this.router.navigate(["streamings/" + streaming.id + "/edit"])
    }

    delete(id: number) {
        this.streamingsService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    asociarMaterial() {
        this.selectedMaterialStream.material_id = this.materialId
        this.streamingsService.update(this.selectedMaterialStream.id, this.selectedMaterialStream).then(response => {
            this.openModal.emit()
        })
    }

    showModalMaterial(streaming: Streaming) {
        this.selectedMaterialStream = streaming
        this.openModal.emit()
    }

    showModalDelete(streaming: Streaming) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este canal?")
        this.simpleModalService.setModalCallback(() => this.delete(streaming.id))
    }

    toggleStreaming(streaming: Streaming) {
        streaming.streaming_enabled = !streaming.streaming_enabled
        if (!streaming.streaming_enabled) {
            streaming.transmitiendo = false
        }
        this.streamingsService.update(streaming.id, streaming).then(response => {
            console.log(response)
        })
    }
}
