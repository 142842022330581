<loading-layout #loadingLayout>
    <div class="options-bar">
        <button class="btn btn-default" (click)="hideOutOfDate = !hideOutOfDate">
            <fa class="icono" name="eye-slash" *ngIf="hideOutOfDate"></fa>
            <fa class="icono" name="eye" *ngIf="!hideOutOfDate"></fa>
            Fuera de fecha
        </button>
    </div>
    <div class="paginas">
        <artezonas-activas-pagina
            *ngFor="let bp of bannerPaginas"
            [bannerPagina]="bp"
            [bannerZonas]="bannerZonas"
            [hideOutOfDate]="hideOutOfDate"
        ></artezonas-activas-pagina>
    </div>
</loading-layout>
