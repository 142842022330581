import { BaseForm } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { TipoContacto } from "./tipo_contactos.model"
import { TipoContactos } from "./tipo_contactos.service"

export class AsociacionContactoForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "Id", type: "hidden", visible: false },
            fecha: { label: "Fecha", type: "datepicker", visible: true },
            comentarios: {
                label: "Comentarios",
                type: "textarea",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            tipo_contactos: {
                label: "Tipo Contactos",
                type: "checkbox",
                multiple: true,
                visible: true,
                options: { class: TipoContactos }
            },
            efectivo: { label: "Efectivo", type: "checkbox", visible: true },
            resuelto: { label: "Resuelto", type: "checkbox", visible: true }
        }
    }
}
