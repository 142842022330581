import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutService } from "../layout.service"

interface LoggedLayoutSidebarOptions {
    subtitle?: string
    freeContentStyle?: boolean
    hideTitleOnPrint?: boolean
    noTitleBar?: boolean
    noSpaceIfNoTitle?: boolean
    hideTitle?: boolean
    noTitle?: boolean
}

@Injectable({ providedIn: "root" })
export class LoggedLayoutSidebarService extends LayoutService<LoggedLayoutSidebarOptions> {
    defaultOptions: LoggedLayoutSidebarOptions = {
        hideTitleOnPrint: false
    }

    options$ = new BehaviorSubject<LoggedLayoutSidebarOptions>(this.defaultOptions)
}
