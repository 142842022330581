import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./planificaciones-new.component.html",
    styleUrls: ["./planificaciones-new.component.scss"]
})
export class PlanificacionesNewComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.planificaciones.new")
    }
}
