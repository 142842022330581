import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Subject } from "rxjs"

import { S3Service } from "@puntaje/shared/core"
import { Nomina, NominaForm, Nominas } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "nomina-profesores",
    templateUrl: "nomina_profesores.component.html"
})
export class NominaProfesoresComponent implements OnInit {
    nomina: Nomina = new Nomina()
    saving: boolean = false
    response: any
    filas: number
    grupos: any
    errores: Array<string>
    form: UntypedFormGroup
    xhr_upload: Subject<XMLHttpRequest> = new Subject<XMLHttpRequest>()
    params = NominaForm.formParams
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    uploadTimeout: number = 900000

    constructor(private nominasService: Nominas, public s3Service: S3Service, private router: Router) {}

    ngOnInit() {
        this.form = NominaForm.getForm(this.nomina)
        this.loadingLayout.ready()
    }

    save() {
        this.saving = true
        NominaForm.markFormControlsAsTouched(this.form)

        if (this.form.valid) {
            this.nominasService.cargarNomina("profesores").uploadFile(
                "file",
                this.nomina.file,
                this.xhr_upload,
                response => {
                    this.response = JSON.parse(response)
                    this.updateValues()
                    //this.router.navigate(['nominas/alumnos']);
                    this.saving = false
                },
                this.uploadTimeout
            )
        } else {
            this.saving = false
        }
    }

    updateValues() {
        if (this.response) {
            this.filas = this.response["success"]
            this.grupos = this.response["grupos"]
            this.errores = this.response["errores"]
        }
    }

    clear() {
        setTimeout(() => {
            this.nomina = new Nomina()
            NominaForm.markFormControlsAsPristine(this.form)
            NominaForm.markFormControlsAsUntouched(this.form)
        }, 0)
    }

    downloadSample() {
        this.nominasService.plantillaNomina("profesores").then((response: any) => {
            const plantillaExcelDecoded = atob(response.plantilla)
            let binaryData = new Uint8Array(plantillaExcelDecoded.length)
            binaryData = binaryData.map((d, i) => plantillaExcelDecoded.charCodeAt(i))

            const plantillaBlob = new Blob([binaryData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })

            const a = document.createElement("a")
            a.download = "plantilla_nomina_profesores.xlsx"
            a.href = window.URL.createObjectURL(plantillaBlob)
            a.click()
        })
    }
}
