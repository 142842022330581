import { Component, Input, OnInit } from "@angular/core"

/*#################################

Tabla con botones de acción en la columna derecha

#################################*/

@Component({
    templateUrl: "table_with_depending_actions.component.html",
    selector: "table-with-depending-actions",
    styleUrls: ["table_with_depending_actions.component.scss"]
})
export class TableWithDependingActionsComponent {
    @Input("contentArray") contentArray: any[]
    @Input("showParams") show_params: any

    @Input() tableButtonsArray: (any) => any

    showSmallTable: boolean

    onResize(event) {
        this.showSmallTable = event.target.innerWidth <= 650
    }

    id(index, content) {
        return content ? content.id : undefined
    }
}
