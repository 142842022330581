import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import {
    EstablecimientoContacto,
    EstablecimientoContactos,
    EstablecimientoContactoView
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { DomSanitizer } from "@angular/platform-browser"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "establecimiento-contacto",
    templateUrl: "establecimiento_contacto.component.html"
})
export class EstablecimientoContactoComponent implements OnInit {
    @Input() establecimientoContactoId: number
    @Output() establecimientoContactoChange = new EventEmitter<any>()

    establecimientoContacto: EstablecimientoContacto
    params = EstablecimientoContactoView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected establecimientoContactosService: EstablecimientoContactos,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.establecimientoContactosService
            .find(this.establecimientoContactoId)
            .then((establecimientoContacto: EstablecimientoContacto) => {
                this.establecimientoContactoChange.emit(establecimientoContacto)
                this.establecimientoContacto = establecimientoContacto

                this.loadingLayout.ready()
            })
    }
}
