import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"
import { TypeformWidgetsBuscadorService } from "./typeform-widgets-buscador/typeform-widgets-buscador.service"

@Component({
    selector: "typeform-widget-toolnavbar",
    templateUrl: "typeform-widgets.toolnavbar.component.html"
})
export class TypeformWidgetsToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() typeformWidgetId: number
    @Input() displayButtons: string

    displayBtnAll = false
    displayBtnNew = false
    displayBtnEdit = false
    displayBtnSearch = false
    displayToolNavbar = true

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected typeformWidgetsBuscadorService: TypeformWidgetsBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/typeform_widgets"
        this.urlNew = "/typeform_widgets/new"
        if (this.typeformWidgetId != null) {
            this.urlEdit = "/typeform_widgets/" + this.typeformWidgetId + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.typeformWidgetId != null) {
            this.urlEdit = "/typeform_widgets/" + this.typeformWidgetId + "/edit"
        }
        super.edit()
    }

    search() {
        this.typeformWidgetsBuscadorService.openSearch.next()
    }
}
