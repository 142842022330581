import { Component, Input } from "@angular/core"

@Component({
    selector: "demo",
    templateUrl: "demo.component.html",
    styleUrls: ["demo.component.scss"]
})
export class DemoComponent {
    constructor() {}
}
