import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { ToolNavbar } from "@puntaje/shared/core"
import { InstrumentoPredefinidosBuscadorService } from "./instrumento-predefinidos-buscador/instrumento-predefinidos-buscador.service"

@Component({
    templateUrl: "instrumento_predefinidos.toolnavbar.component.html",
    selector: "instrumento-predefinidos-toolnavbar"
})
export class InstrumentoPredefinidosToolNavbarComponent extends ToolNavbar implements OnInit {
    @Input() instrumentoPredefinidoId: number
    @Input() displayButtons: string

    displayBtnAll: boolean = false
    displayBtnNew: boolean = false
    displayBtnEdit: boolean = false
    displayBtnSearch: boolean = false
    displayToolNavbar: boolean = true

    urlAll: string
    urlNew: string
    urlEdit: string
    displayButtonsInput: string

    constructor(
        router: Router,
        route: ActivatedRoute,
        protected instrumentoPredefinidosBuscadorService: InstrumentoPredefinidosBuscadorService
    ) {
        super(router, route)
    }

    ngOnInit() {
        this.urlAll = "/instrumento_predefinidos"
        this.urlNew = "/instrumento_predefinidos/new"
        if (this.instrumentoPredefinidoId != null) {
            this.urlEdit = "/instrumento_predefinidos/" + this.instrumentoPredefinidoId + "/edit"
        }
        this.displayButtonsInput = this.displayButtons
        this.checkDisplayButtons()
    }

    checkDisplayButtons() {
        if (this.displayButtons != null) {
            this.displayBtnAll = this.displayButtons.indexOf("all") !== -1
            this.displayBtnNew = this.displayButtons.indexOf("new") !== -1
            this.displayBtnEdit = this.displayButtons.indexOf("edit") !== -1
            this.displayBtnSearch = this.displayButtons.indexOf("search") !== -1
        }
        if (!this.displayBtnAll && !this.displayBtnNew && !this.displayBtnEdit && !this.displayBtnSearch) {
            this.displayToolNavbar = false
        }
    }

    edit() {
        if (this.instrumentoPredefinidoId != null) {
            this.urlEdit = "/instrumento_predefinidos/" + this.instrumentoPredefinidoId + "/edit"
        }
        super.edit()
    }

    search() {
        this.instrumentoPredefinidosBuscadorService.openSearch.next()
    }
}
