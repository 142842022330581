import { Subscription } from "rxjs"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Instrumento, Instrumentos, Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "evaluacion-admin.component.html",
    styleUrls: ["evaluacion-admin.component.scss"]
})
export class EvaluacionAdminComponent implements OnInit {
    evaluacion: Evaluacion
    instrumento: Instrumento
    evaluacionId: number
    subParams: Subscription
    subQuery: Subscription

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.evaluaciones_admin")
        this.subParams = this.route.params.subscribe(params => {
            this.evaluacionesService.find(+params["id"], { profesor: 1 }).then((evaluacion: Evaluacion) => {
                this.evaluacion = evaluacion
                this.instrumentosService
                    .find(this.evaluacion.instrumento_id, { with_generador_instrumento: 1 })
                    .then((instrumento: Instrumento) => {
                        this.instrumento = instrumento
                    })
            })
        })
    }

    ngOnDestroy() {
        this.subParams.unsubscribe()
    }
}
