import { Subscription } from "rxjs"

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { Instrumento, Instrumentos, Evaluacion, Evaluaciones } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "buscador_instrumento.component.html",
    styleUrls: ["buscador_instrumento.component.scss"]
})
export class BuscadorInstrumentoComponent implements OnInit {
    instrumento_o_evaluacion_id: number
    instrumento: Instrumento
    evaluacionId: number
    ready: boolean = false
    subParams: Subscription
    subQuery: Subscription
    isValidToUse: boolean = false

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos,
        protected evaluacionesService: Evaluaciones
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.instrumentos.buscador_instrumento")
    }

    show(is_evaluacion: boolean = false) {
        this.instrumento = null
        this.evaluacionId = null
        this.isValidToUse = false
        if (this.instrumento_o_evaluacion_id) {
            this.ready = false
            if (is_evaluacion) {
                this.evaluacionesService.enableIgnoreCatch()
                this.evaluacionesService
                    .find(this.instrumento_o_evaluacion_id, { raw: 1 })
                    .then((evaluacion: Evaluacion) => {
                        this.instrumentosService
                            .find(evaluacion.instrumento_id, {
                                include: "[instrumento_material]",
                                with_generador_instrumento: 1
                            })
                            .then((instrumento: Instrumento) => {
                                this.evaluacionId = this.instrumento_o_evaluacion_id
                                this.instrumento = instrumento
                                if (
                                    this.instrumento &&
                                    this.instrumento.generador_instrumento &&
                                    this.instrumento.generador_instrumento.tipo_instrumento
                                ) {
                                    this.isValidToUse = true
                                }
                                this.ready = true
                            })
                            .catch(response => {
                                this.ready = true
                            })
                    })
                    .catch(response => {
                        this.ready = true
                    })
            } else {
                this.instrumentosService.enableIgnoreCatch()
                this.instrumentosService
                    .find(this.instrumento_o_evaluacion_id, {
                        include: "[instrumento_material]",
                        with_generador_instrumento: 1
                    })
                    .then((instrumento: Instrumento) => {
                        this.instrumento = instrumento
                        if (
                            this.instrumento &&
                            this.instrumento.generador_instrumento &&
                            this.instrumento.generador_instrumento.tipo_instrumento
                        ) {
                            this.isValidToUse = true
                        }
                        this.ready = true
                    })
                    .catch(response => {
                        this.ready = true
                    })
            }
        }
    }
}
