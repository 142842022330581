import { Component, ViewChild, OnInit, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezonas.edit.component.html"
})
export class BannersEditComponent implements OnInit {
    bannerId: number
    loggedLayoutTitle = "Editar banner"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.artezonas.edit")
        this.route.params.subscribe(params => {
            this.bannerId = +params["id"]
        })
    }
}
