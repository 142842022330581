<ll-titulo>Editar contacto #{{ asociacionContactoId }}</ll-titulo>
<ll-toolnavbar>
    <asociacion-contacto-toolnavbar
        [displayButtons]="'all'"
        [asociacionId]="asociacionId"
        [asociacionContactoId]="asociacionContactoId"
    ></asociacion-contacto-toolnavbar>
</ll-toolnavbar>

<asociacion-contactos-edit
    [asociacionId]="asociacionId"
    [asociacionContactoId]="asociacionContactoId"
></asociacion-contactos-edit>
