import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { AsociacionContacto, AsociacionContactos, AsociacionContactoForm } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "asociacion-contactos-edit",
    templateUrl: "asociacion_contactos.form.component.html"
})
export class AsociacionContactosEditComponent implements OnInit {
    @Input() asociacionContactoId: number
    @Input() asociacionId: number

    oAsociacionContacto: AsociacionContacto = new AsociacionContacto()
    asociacionContacto: AsociacionContacto = new AsociacionContacto()
    params = AsociacionContactoForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected asociacionContactosService: AsociacionContactos,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = AsociacionContactoForm.getForm(this.asociacionContacto)
        this.asociacionContactosService.find(this.asociacionContactoId).then((response: AsociacionContacto) => {
            this.asociacionContacto = response
            this.oAsociacionContacto = response.clone()
            this.loadingLayout.ready()
            this.cdr.detectChanges()
        })
    }

    save() {
        AsociacionContactoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            ;(this.asociacionContacto as any).tipo_contacto_ids = this.asociacionContacto.tipo_contactos.map(
                tc => tc.id
            )

            this.asociacionContactosService
                .update(this.asociacionContacto.id, this.asociacionContacto)
                .then(response => {
                    this.router.navigate(["asociaciones/" + this.asociacionId + "/asociacion_contactos"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.asociacionContacto = this.oAsociacionContacto.clone()
            AsociacionContactoForm.markFormControlsAsPristine(this.form)
            AsociacionContactoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
