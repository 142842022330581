import { AppConfig, BaseForm, Validations } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { RolUsuarioCuentasForm } from "./rol_usuarios_cuentas.form"
import { PremiumUsuarioForm } from "./premium_usuarios.form"
import { Roles } from "./roles.service"

import { UsuariosCompletarRegistroSocialCbChileForm } from "./usuarios_completar_registro_social_cb_chile.form"
import { UsuariosCompletarRegistroSocialColombiaForm } from "./usuarios_completar_registro_social_colombia.form"

declare const config: AppConfig

export class CuentasCbForm extends BaseForm {
    public static get formParams() {
        let classes = {
            chile: UsuariosCompletarRegistroSocialCbChileForm,
            colombia: UsuariosCompletarRegistroSocialColombiaForm
        }

        let params: any = {
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            ["usuario_" + config.plataforma.pais]: { type: "model", class: classes[config.plataforma.pais] },
            premium_usuarios: { label: "Test", type: "models", class: PremiumUsuarioForm, visible: true },
            roles: { label: "Roles", type: "checkbox", multiple: true, options: { class: Roles } }
        }

        return params
    }
}
