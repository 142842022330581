import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Usuario, UsuarioView, Usuarios } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "usuario-admin",
    templateUrl: "usuario_admin.component.html",
    styleUrls: ["usuario_admin.component.scss"]
})
export class UsuarioAdminComponent implements OnInit {
    usuario: Usuario
    params = UsuarioView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreIdPais: any = {}
    @Input() enableShowCountryData: boolean = false
    @Input() usuarioId: number
    isPrivate: boolean = true

    constructor(protected authService: AuthService, protected usuariosService: Usuarios) {
        //this.usuarioId = this.authService.getUserData().id;
    }

    ngOnInit() {
        this.isPrivate = false
        this.idPais.forEach(id => {
            this.nombreIdPais[id] = this.aliases?.[id] || id
        })
        this.usuariosService
            .find(this.usuarioId, { include: "[grupo_usuario_usuarios:grupo_usuario:establecimiento]" })
            .then((response: Usuario) => {
                this.usuario = response
                this.sortGrupos()
                this.loadingLayout.ready()
            })
    }

    sortGrupos() {
        if (this.usuario.grupo_usuario_usuarios && this.usuario.grupo_usuario_usuarios.length > 0) {
            this.usuario.grupo_usuario_usuarios.sort((a, b) => {
                if (a.grupo_usuario.nombre < b.grupo_usuario.nombre) return -1
                if (a.grupo_usuario.nombre > b.grupo_usuario.nombre) return 1
                return 0
            })
        }
    }

    logInAsAlumno() {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(this.usuario.id, { application: "estudiante" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }

    logInAsProfesor() {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(this.usuario.id, { application: "docente" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }
}
