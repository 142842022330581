<loading-layout #loadingLayout>
    <cui-section-headline>Paso 1: Ingrese las preguntas y seleccione generador</cui-section-headline>
    <div class="info-container">
        <div class="row">
            <div class="col-md-6">
                <div class="search-input">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Preguntas ID (Ej: 1, 21, 34)"
                            [(ngModel)]="preguntasIds"
                            (keyup.enter)="getPreguntas()"
                        />
                    </div>
                    <cui-button class="btn_style" (click)="getPreguntas()">Buscar Preguntas</cui-button>
                </div>
                <ng-container *ngIf="preguntas && preguntasIdsArray">
                    <p>
                        Se encontraron
                        <b>{{ preguntas.length }}</b>
                        preguntas (de {{ preguntasIdsArray.length }}).
                    </p>
                    <alert-preguntas-exclusivas
                        [preguntasNotPlatform]="preguntasNotPlatform"
                    ></alert-preguntas-exclusivas>
                    <div class="form-group">
                        <label>Tiempo (minutos)</label>
                        <input type="number" class="form-control" [(ngModel)]="tiempo" placeholder="Tiempo" />
                    </div>
                </ng-container>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <select
                        id="curriculum"
                        [(ngModel)]="generador_selected"
                        (ngModelChange)="updateValues($event)"
                        class="form-control"
                    >
                        <option [ngValue]="null" [disabled]="true" [selected]="true">
                            -- Seleccione Tipo de Generador --
                        </option>
                        <option *ngFor="let generador of generadores" [ngValue]="generador">
                            {{ generador.generador_instrumento }}
                        </option>
                    </select>
                </div>
                <div *ngIf="generador_selected" class="generador-info">
                    <p>
                        <label>Asignatura:</label>
                        {{ generador_selected?.asignatura.asignatura }}
                    </p>
                    <p>
                        <label>Tipo:</label>
                        {{ generador_selected?.tipo_instrumento.tipo_instrumento }}
                    </p>
                    <p>
                        <label>N° Preguntas:</label>
                        {{ generador_selected.numero_preguntas }}
                        <i>(Referencial)</i>
                    </p>
                    <p>
                        <label>Tiempo:</label>
                        {{ generador_selected.tiempo }} minutos
                    </p>
                </div>
                <p class="tutorial" *ngIf="!generador_selected">Seleccione un tipo de generador.</p>
            </div>
        </div>
    </div>
    <br />
    <cui-section-headline>Paso 2: Vista previa y generar</cui-section-headline>
    <div class="row">
        <div class="col-md-8">
            <p class="not-found" *ngIf="!preguntas || (preguntas && preguntas.length == 0)">
                Sin preguntas disponibles para la vista previa.
            </p>
            <p class="not-found" *ngIf="!generador_selected">Sin generador para la vista previa.</p>
            <loading-layout #loadingLayoutPreguntas></loading-layout>
            <div *ngIf="preguntas && generador_selected && instrumentoPreguntas && !errorPreguntasNotPlatform">
                <div *ngFor="let pregunta of preguntas; let i = index" [class.hide-preguntas]="!preguntasAreVisible">
                    <instrumento-pregunta
                        [pregunta]="pregunta"
                        [instrumentoPregunta]="instrumentoPreguntas[i]"
                        [index]="i"
                        [grupoPreguntaPrev]="preguntas[i - 1]?.grupo_pregunta"
                        [showCambiarPregunta]="false"
                        [enableReportBtn]="false"
                        [evaluacionTipo]="generador_selected.tipo_instrumento.tipo_instrumento"
                    ></instrumento-pregunta>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="info-container">
                <p class="tutorial">
                    Una vez conforme con la lista de preguntas y el generador, cree el instrumento haciendo click a
                    continuación:
                </p>
                <cui-button
                    [isBlock]="true"
                    class="btn_style"
                    [disabled]="
                        !preguntas ||
                        (preguntas && preguntas.length == 0) ||
                        !generador_selected ||
                        errorPreguntasNotPlatform
                    "
                    (click)="generar()"
                >
                    Generar Instrumento
                </cui-button>
            </div>
        </div>
    </div>
</loading-layout>
