import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "home",
    templateUrl: "home.component.html"
})
export class HomeComponent implements OnInit {
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;

    constructor(protected router: Router, protected route: ActivatedRoute) {}

    ngOnInit() {}
}
