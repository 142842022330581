import { BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class CuentasBrasilForm extends BaseForm {
    companyName: string = ""

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            cpf: {
                label: "CPF",
                type: "text",
                validations: [Validators.required, Validations.validateCPF],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueCPFNotSelf }]
            },
            telefono: {
                label: "Telefone",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateTelefonoMexico]
            },
            year_egreso: {
                label: "Em que ano você vai sair ou saiu da escola?",
                type: "number",
                visible: true
            }
        }
    }
}
