<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <model-simple-view
            *ngFor="let key of params | keys"
            [params]="params[key]"
            [value]="asociacion"
            [key]="key"
        ></model-simple-view>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <div class="info-container">
                <cui-section-headline>Establecimientos</cui-section-headline>
                <ul class="establecimientos-list">
                    <li *ngFor="let establecimiento of asociacion?.establecimientos">
                        <span class="nombre">{{ establecimiento.establecimiento }}</span>
                        <div class="opciones">
                            <cui-button-link
                                (click)="deleteEstablecimiento(establecimiento)"
                                class="btn_style btn_base_1 btn-xs"
                            >
                                <fa name="times" class="left-icon"></fa>
                                Desasociar
                            </cui-button-link>
                        </div>
                    </li>
                </ul>

                <cui-button-link (click)="toggleEstablecimientos()" class="btn_style btn_base_1">
                    <fa name="plus" class="left-icon"></fa>
                    Asociar Establecimiento
                </cui-button-link>
                <div *ngIf="showAssociateEstablecimiento" class="asociar-container">
                    <autocomplete
                        class="autocomplete"
                        [(ngModel)]="establecimiento"
                        [options]="filteredEstablecimientos"
                        (filterChange)="getEstablecimientos($event)"
                        showTextFun="toStringWithIdPais"
                    ></autocomplete>

                    <cui-button-link (click)="associateEstablecimiento()" class="btn_style btn_base_1">
                        <fa name="plus" class="left-icon"></fa>
                        Asociar
                    </cui-button-link>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <div class="info-container">
                <cui-section-headline>Administradores de la asociación</cui-section-headline>
                <ul class="administradores-list">
                    <li *ngFor="let usuario of asociacion?.usuarios">
                        <a [routerLink]="'/usuarios/' + usuario.id" class="nombre">
                            <fa name="user-o" class="left-icon"></fa>
                            {{ usuario.nombreCompleto() }}
                        </a>
                        <div class="opciones">
                            <cui-button-link (click)="deleteUsuario(usuario)" class="btn_style btn_base_1 btn-xs">
                                <fa name="times" class="left-icon"></fa>
                                Desasociar
                            </cui-button-link>
                            <button class="btn btn-default btn-xs" (click)="logInAsProfesor(usuario.id)">
                                <fa name="user-o" class="left-icon"></fa>
                                Impersonar Profesor
                            </button>
                        </div>
                    </li>
                </ul>

                <cui-button-link (click)="toggleUsuarios()" class="btn_style btn_base_1">
                    <fa name="user-plus" class="left-icon"></fa>
                    Asociar usuario
                </cui-button-link>
                <div *ngIf="showAssociateUsuario" class="asociar-container">
                    <autocomplete
                        class="autocomplete"
                        [(ngModel)]="usuario"
                        [options]="filteredUsuarios"
                        (filterChange)="getUsuarios($event)"
                        showTextFun="toStringDatos"
                    ></autocomplete>
                    <cui-button-link (click)="associateUsuario()" class="btn_style btn_base_1">
                        <fa name="plus" class="left-icon"></fa>
                        Asociar
                    </cui-button-link>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="info-container">
                <cui-section-headline>Monitores de la asociación</cui-section-headline>
                <ul class="monitores-list">
                    <li *ngFor="let usuario of asociacion?.monitores">
                        <a [routerLink]="'/usuarios/' + usuario.id" class="nombre">
                            <fa name="user-o" class="left-icon"></fa>
                            {{ usuario.nombreCompleto() }}
                        </a>
                        <div class="opciones">
                            <cui-button-link (click)="deleteMonitor(usuario)" class="btn_style btn_base_1 btn-xs">
                                <fa name="times" class="left-icon"></fa>
                                Desasociar
                            </cui-button-link>
                        </div>
                    </li>
                </ul>

                <cui-button-link (click)="toggleMonitores()" class="btn_style btn_base_1">
                    <fa name="user-plus" class="left-icon"></fa>
                    Asociar usuario
                </cui-button-link>
                <div *ngIf="showAssociateMonitor" class="asociar-container">
                    <autocomplete
                        class="autocomplete"
                        [(ngModel)]="usuario"
                        [options]="filteredUsuarios"
                        (filterChange)="getUsuarios($event)"
                        showTextFun="toStringDatos"
                    ></autocomplete>
                    <cui-button-link (click)="associateMonitor()" class="btn_style btn_base_1">
                        <fa name="plus" class="left-icon"></fa>
                        Asociar
                    </cui-button-link>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container">
        <cui-section-headline>Administrar grupos</cui-section-headline>
        <select [(ngModel)]="establecimientoAdmin">
            <option [ngValue]="undefined">-- Seleccione establecimiento --</option>
            <option *ngFor="let establecimiento of asociacion?.establecimientos" [ngValue]="establecimiento">
                {{ establecimiento.establecimiento }}
            </option>
        </select>
        <grupo-usuarios-asociados
            *ngIf="establecimientoAdmin"
            [establecimientoId]="establecimientoAdmin.id"
        ></grupo-usuarios-asociados>
    </div>
</loading-layout>
