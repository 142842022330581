<form *ngIf="open" class="buscador">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <input type="number" [(ngModel)]="usuario.id" name="id" class="form-control" placeholder="ID" min="1" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <input
                    type="text"
                    [(ngModel)]="usuario.like.nombre"
                    name="nombre"
                    class="form-control"
                    placeholder="Nombre"
                />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <input
                    type="text"
                    [(ngModel)]="usuario.like.apellido_paterno"
                    name="apellido_paterno"
                    class="form-control"
                    placeholder="Apellido paterno"
                />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <input
                    type="text"
                    [(ngModel)]="usuario.like.apellido_materno"
                    name="apellido_materno"
                    class="form-control"
                    placeholder="Apellido materno"
                />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <input type="text" [(ngModel)]="usuario.email" name="email" class="form-control" placeholder="Email" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <input
                    type="text"
                    [(ngModel)]="idPaisUsuario"
                    name="id_pais"
                    class="form-control"
                    placeholder="{{ nombreId }}"
                />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <select class="form-control" id="usuariopro" [(ngModel)]="usuario.is_pro" name="usuariopro">
                    <option [ngValue]="undefined">Tipo de usuario</option>
                    <option *ngFor="let user of tUsers" [value]="user.value">{{ user.UserType }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" *ngIf="enableCodigosLibros">
            <div class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        [(ngModel)]="buscarEstudiantesCodigoProfesor"
                        name="buscar-estudiantes-codigo-profesor"
                    />
                    Buscar usuarios con código de profesor que sean estudiantes.
                </label>
            </div>
        </div>
    </div>
    <div class="row last-row">
        <div class="col-md-4">
            <cui-button-link [isBlock]="true" class="btn_style btn-search" (click)="search()">
                <fa name="search"></fa>
                &nbsp;Buscar
            </cui-button-link>
        </div>
    </div>
</form>
