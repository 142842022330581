import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./sesiones-edit.component.html"
})
export class SesionesEditComponent implements OnInit {
    sesionId: number
    id: number
    protected sub: Subscription
    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.sesiones.edit")
        this.sub = this.route.params.subscribe(params => {
            this.sesionId = params["id_session"]
            this.id = params["id"]
        })
    }
}
