<loading-layout #loadingLayout>
    <estadisticas-usuario-portada
        [usuarios]="usuarios"
        [estadisticas]="estadisticas"
        [asignatura]="asignatura"
        [establecimiento]="establecimiento"
        [lugar]="lugar"
    ></estadisticas-usuario-portada>
    <div class="row">
        <div class="col-md-12">
            <div class="big-title-margin">
                <div class="big-title-container big-title-orange">
                    <div class="big-title-left">
                        <span class="big-title-icon"><fa [name]="'bar-chart'"></fa></span>
                    </div>
                    <h2 class="big-title-text">
                        Resultados para:
                        <span class="big-title-color" *ngIf="establecimiento">
                            {{ establecimiento.establecimiento }}
                        </span>
                        <span class="big-title-color" *ngIf="lugar">{{ lugar.nombre }}</span>
                        - {{ usuarios.length }} estudiantes
                    </h2>
                </div>
            </div>
            <div *ngIf="enableGraphs">
                <grafico-distribucion-tramo-puntaje
                    [tipoEvaluacion]="evaluacionTipo"
                    class="print-page-break-after"
                    [asignatura]="asignatura"
                    [data]="distribucion_ensayos_tramo_puntaje"
                    [tipoGrupo]="'cursos'"
                ></grafico-distribucion-tramo-puntaje>

                <grafico-desempeno-promedio
                    class="print-page-break-after"
                    [asignatura]="asignatura"
                    [dataOmitidas]="desempeno_promedio_omitidas"
                    [dataIncorrectas]="desempeno_promedio_incorrectas"
                    [dataCorrectas]="desempeno_promedio_correctas"
                ></grafico-desempeno-promedio>

                <ng-template ngFor let-cte [ngForOf]="clasificacionTiposEstadisticas">
                    <ng-template [ngIf]="cte.compuesto">
                        <grafico-desempeno-clasificacion-compuesto
                            class="print-page-break-after"
                            [evaluacionTipo]="evaluacionTipo"
                            [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                            [asignatura]="asignatura"
                            [clasificaciones]="this['desempeno_' + cte.nombre]"
                            [dataOmitidas]="this['desempeno_' + cte.nombre + '_omitidas']"
                            [dataIncorrectas]="this['desempeno_' + cte.nombre + '_incorrectas']"
                            [dataCorrectas]="this['desempeno_' + cte.nombre + '_correctas']"
                            [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                        ></grafico-desempeno-clasificacion-compuesto>
                    </ng-template>

                    <ng-template [ngIf]="!cte.compuesto">
                        <grafico-desempeno-clasificacion
                            class="print-page-break-after"
                            [tipoClasificacion]="cte.nombreMostrable || cte.nombre"
                            [asignatura]="asignatura"
                            [clasificaciones]="this['desempeno_' + cte.nombre]"
                            [data]="this['desempeno_' + cte.nombre + '_correctas']"
                            [dataTotal]="this['desempeno_' + cte.nombre + '_total']"
                        ></grafico-desempeno-clasificacion>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</loading-layout>
