<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <div *ngIf="response && filas" class="alert alert-success" role="alert">
                <p>Se han leido {{ filas }} filas correctamente</p>
                <br />
                <table *ngIf="grupos" class="table">
                    <thead>
                        <tr>
                            <th>Curso</th>
                            <th>Profesores Ya Cargados</th>
                            <th>Profesores Nuevos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let k of grupos | keys">
                            <td>{{ grupos[k]["nombre"] }}</td>
                            <td>{{ grupos[k]["profes_viejos"] }}</td>
                            <td>{{ grupos[k]["profes_nuevos"] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="response && errores && errores.length > 0" class="alert alert-danger" role="alert">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Errores</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let error of errores">
                            <td>{{ error }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <form novalidate [formGroup]="form">
                <form-input
                    [form]="form"
                    [params]="params['file']"
                    [(value)]="nomina['file']"
                    [key]="'file'"
                    [xhr_upload]="s3Service.xhr_upload"
                ></form-input>

                <button [disabled]="saving" (click)="save()" type="button" class="btn btn-default">
                    {{ save_button_text | easyplaceholder: "Guardar" }}
                </button>
                <button (click)="clear()" type="button" class="btn btn-default">
                    {{ clear_button_text | easyplaceholder: "Borrar" }}
                </button>
            </form>
            <a href="javascript:void(0)" (click)="downloadSample()">Descargar plantilla</a>
        </div>
    </div>
</loading-layout>
