import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { EstablecimientosBuscadorService } from "./establecimientos_buscador.service"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { IMyDpOptions } from "mydatepicker"
import { SessionService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"

@Component({
    selector: "establecimientos-buscador",
    templateUrl: "establecimientos_buscador.component.html"
})
export class EstablecimientosBuscadorComponent implements OnInit {
    establecimiento: any = { activo: true }
    establecimientoPais: any = {}
    establecimientoMonitor: any = {}

    idEstablecimientoNombre: string = config.plataforma.identificadorEstablecimiento[0]
    idEstablecimientoKey: string = config.plataforma.identificadorEstablecimiento[0].replace(" ", "_").toLowerCase()

    fechaInicialUso: any = {}
    fechaFinalUso: any = {}

    monitores: Usuario[]
    monitor_id: number

    // sirve para poder poner campos por defecto que se llenen de forma asincrona
    @Output() onReady = new EventEmitter()

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: "dd/mm/yyyy",
        editableDateField: false,
        openSelectorOnInputClick: true
    }

    sub: Subscription
    subQueryParams: Subscription

    open: boolean = false
    filteredUsuarios: Usuario[] = []

    constructor(
        protected establecimientosBuscadorService: EstablecimientosBuscadorService,
        protected usuariosService: Usuarios,
        protected sessionService: SessionService,
        protected route: ActivatedRoute,
        protected router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subQueryParams = this.route.queryParams.subscribe(params => {
            this.loadSearchParamsFromUrl(params)
        })

        this.sub = this.establecimientosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })

        //por defecto que aparezca abierto
        this.establecimientosBuscadorService.openSearch.next(true)

        //monitores para llenar el select
        this.usuariosService.where({ rol: { rol: "Monitor" } }).then(monitores => {
            this.monitores = monitores

            if (!this.monitor_id && this.sessionService.getRoles().includes("Monitor")) {
                this.monitor_id = this.sessionService.getUserData().id
                this.establecimientoMonitor.monitor_id = this.monitor_id
            }

            //Ycampos por defecto listos
            this.onReady.emit()
            this.search()
        })
    }

    /**
     * Carga los query params en los inputs/select del form del buscador
     * @param params query params
     */
    loadSearchParamsFromUrl(params) {
        this.establecimiento.id = params["id"]
        this.establecimiento.establecimiento = params["establecimiento"]
        this.establecimientoPais[this.idEstablecimientoKey] = params[this.idEstablecimientoKey]
        this.monitor_id = params["monitor_id"]
        this.establecimientoMonitor.monitor_id = this.monitor_id

        switch (params["activo"]) {
            case "true":
                this.establecimiento.activo = true
                break
            case "false":
                this.establecimiento.activo = false
                break
            case "todos":
                this.establecimiento.activo = undefined
        }

        this.cdr.detectChanges()
    }

    search() {
        this.addSearchParamsToUrl()

        this.establecimientosBuscadorService.search(
            this.establecimiento,
            this.establecimientoMonitor,
            this.establecimientoPais,
            this.idEstablecimientoNombre,
            this.idEstablecimientoKey,
            this.fechaInicialUso.jsdate,
            this.fechaFinalUso.jsdate
        )
    }

    download() {
        this.addSearchParamsToUrl()

        this.establecimientosBuscadorService.search(
            this.establecimiento,
            this.establecimientoMonitor,
            this.establecimientoPais,
            this.idEstablecimientoNombre,
            this.idEstablecimientoKey,
            this.fechaInicialUso.jsdate,
            this.fechaFinalUso.jsdate,
            "establecimientos.csv"
        )
    }

    /**
     * Agrega los paramatros de busqueda a los query params
     */
    addSearchParamsToUrl() {
        this.router.navigate([], {
            queryParams: {
                id: this.establecimiento.id,
                establecimiento: this.establecimiento.establecimiento,
                [this.idEstablecimientoKey]: this.establecimientoPais[this.idEstablecimientoKey],
                monitor_id: this.monitor_id,
                activo: this.establecimiento.activo == undefined ? "todos" : this.establecimiento.activo
            }
        })
    }

    onChangeMonitor(event) {
        this.establecimientoMonitor.monitor_id = event.target.value == "Todos" ? undefined : event.target.value
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQueryParams.unsubscribe()
    }
}
