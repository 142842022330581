import { Component, OnInit, Input } from "@angular/core"
import { AsociacionContactosBuscadorService } from "./asociacion_contactos_buscador.service"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asociacion-contactos-buscador",
    templateUrl: "asociacion_contactos_buscador.component.html"
})
export class AsociacionContactosBuscadorComponent implements OnInit {
    sub: Subscription
    open: boolean = false

    @Input() asociacionId: number
    asociacionContacto: any = { like: {}, gte: {}, lte: {} }

    tContactos: any[] = [
        { value: 0, ContactoType: "No Efectivo" },
        { value: 1, ContactoType: "Efectivo" }
    ]
    tTipos: any[] = [
        { value: 1, TipoType: "Llamada" },
        { value: 2, TipoType: "Email" },
        { value: 3, TipoType: "Zopim" }
    ]
    tResuelto: any[] = [
        { value: 0, ResueltoType: "No Resuelto" },
        { value: 1, ResueltoType: "Resuelto" }
    ]

    constructor(protected asociacionContactosBuscadorService: AsociacionContactosBuscadorService) {}

    ngOnInit() {
        this.sub = this.asociacionContactosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
    }

    search() {
        this.asociacionContactosBuscadorService.search(this.asociacionContacto, this.asociacionId)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
