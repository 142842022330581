import { Component, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "artezona_paginas.new.component.html"
})
export class BannerPaginasNewComponent {
    loggedLayoutTitle = "Agregar pagina"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}
}
