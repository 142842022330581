import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    PlanPersonal,
    PlanPersonales

} from "@puntaje/puntaje/api-services"
import { AsignaturaWithConfig, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { Subscription, combineLatest } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import {
    State,
    selectSelectedEvaluacionTipo,
    selectAsignaturasByEvaluacionTipo,
    selectSelectedAsignatura,
    SetSelectedAsignaturaEvaluacionTipo,
    SetSelectedEvaluacionTipo,
    SetSelectedAsignatura
} from "@puntaje/puntaje/store"
import { map, filter } from "rxjs/operators"

@Component({
    selector: "filtro-plan-clase-admin",
    templateUrl: "./filtro-plan-clase-admin.component.html",
    styleUrls: ["./filtro-plan-clase-admin.component.scss"]
})
export class FiltroPlanClaseAdminComponent implements OnInit, OnDestroy {
    
    sub: Subscription
    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))
    evaluacionTipo$ = this.store.pipe(select(selectSelectedEvaluacionTipo))
    asignaturasByEvaluacionTipo$ = this.store.pipe(select(selectAsignaturasByEvaluacionTipo))
    asignaturas$ = combineLatest([
        this.asignaturasByEvaluacionTipo$.pipe(filter(x => !!x)),
        this.evaluacionTipo$.pipe(filter(x => !!x))
    ]).pipe(map(([asignaturasByEvaluacionTipo, evaluacionTipo]) => asignaturasByEvaluacionTipo[evaluacionTipo] || []))
    evaluacionTipos = config.plataforma.evaluacionTipos.filter(et => et.indexOf("diagnostico") == -1)
    asignatura: AsignaturaWithConfig
    asignaturas: AsignaturaWithConfig[]
    evaluacionTipo: string
    planes: PlanPersonal[]
    planId: number = null

    @Output() filtro = new EventEmitter<any>()

    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = combineLatest([this.asignatura$, this.evaluacionTipo$, this.asignaturas$]).subscribe(
            ([asignatura, evaluacionTipo, asignaturas]) => {
                if (!!asignaturas && !!evaluacionTipo) {
                  this.evaluacionTipo = evaluacionTipo
                  this.asignaturas = asignaturas
                }
                if (!!asignatura && !!evaluacionTipo) {
                    const asignaturaEvaluacionTipo = asignatura.confByEvaluacionTipo[evaluacionTipo]
                    this.store.dispatch(new SetSelectedAsignaturaEvaluacionTipo({ asignaturaEvaluacionTipo }))
                    this.asignatura = asignatura
                    this.evaluacionTipo = evaluacionTipo
                    this.updatePlanes()
                }
            }
        )
    }

    updatePlanes() {
        this.planes = []
        this.planId = null
        if (this.asignatura) {
          this.generadorInstrumentosService.where({
            asignatura_plataforma: {
              asignatura_id: this.asignatura.id,
              plataforma_id: config.plataforma.id
            }
          }).then((generadores) => {
            const ids = []
            generadores.forEach((generador) => {
              ids.push(generador.id)
            })
            const params = {
                plan_personal_tipo: { tipo: "plan de clase alumno" },
                generador_instrumento_ids: ids
            }
            this.planPersonalesService.where(params).then((planes: PlanPersonal[]) => {
                this.planes = planes
            })
          })
        }
    }

    selectEvaluacionTipo(event) {
        const selectedEvaluacionTipo = event.target.value
        this.evaluacionTipo = selectedEvaluacionTipo            
        this.store.dispatch(new SetSelectedEvaluacionTipo({ selectedEvaluacionTipo }))
    }

    selectAsignatura(event) {
        const name = event.target.value
        const selectedAsignatura = this.asignaturas.filter( a => a.asignatura === name)[0]
        this.asignatura = selectedAsignatura
        this.store.dispatch(new SetSelectedAsignatura({ selectedAsignatura }))
    }

    onFiltro() {
        this.filtro.emit(this.planId)
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
