<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <div *ngIf="form">
                <form novalidate [formGroup]="form">
                    <ng-container *ngFor="let key of params | keys">
                        <form-input
                            *ngIf="key != 'evaluacion_multiple_id' || showEvaluacionMultiple"
                            [form]="form"
                            [params]="params[key]"
                            [(value)]="evaluacionSugerida[key]"
                            [key]="key"
                        ></form-input>
                    </ng-container>

                    <button (click)="save()" type="button" class="btn btn-default">Guardar</button>
                    <button (click)="clear()" type="button" class="btn btn-default">Borrar</button>
                </form>
            </div>
        </div>
    </div>
</loading-layout>
