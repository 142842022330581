import { Component } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "ensayos_mensuales.component.html",
    styleUrls: ["ensayos_mensuales.component.scss"]
})
export class EnsayosMensualesComponent {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    enableResponsive: boolean = false

    nombreEvaluacionTipoSingular: string = "ensayo"
    nombreEvaluacionTipoPlural: string = "ensayos"

    subData: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subData = this.route.data.subscribe(data => {
            this.nombreEvaluacionTipoSingular = data.nombreEvaluacionTipoSingular ?? this.nombreEvaluacionTipoSingular
            this.nombreEvaluacionTipoPlural = data.nombreEvaluacionTipoPlural ?? this.nombreEvaluacionTipoPlural
        })
    }
}
