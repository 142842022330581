import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import {
    Evaluacion,
    Evaluaciones,
    Establecimiento,
    Establecimientos,
    GrupoUsuario,
    Instrumento,
    Usuario,
    EvaluacionView
} from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "admin-evaluaciones-masivas",
    templateUrl: "admin_evaluaciones_masivas.component.html"
})
export class AdminEvaluacionesMasivasComponent implements OnInit {
    @Input() listRoute: string
    evaluaciones: Evaluacion[]
    params = EvaluacionView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected router: Router,
        protected authService: AuthService,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = (evaluacion: Evaluacion) => {
            return {
                view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" }
            }
        }
    }

    showAll(page: number = 1, count: number = 20) {
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            evaluacion_usuario: {
                receptor_type: "Usuario",
                receptor_id: "null"
            },
            include: "[evaluacion_usuarios]"
        }

        return this.evaluacionesService.where(params).then((response: Evaluacion[], total: number) => {
            this.evaluaciones = response
            this.loadingLayout.ready()
            return total
        })
    }

    view(evaluacion: Evaluacion) {
        this.router.navigate([this.listRoute + "/" + evaluacion.id])
    }
}
