import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import { AsociacionContacto, AsociacionContactos, AsociacionContactoView } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { AsociacionContactosBuscadorService } from "./asociacion_contactos_buscador.service"
import { Subscription } from "rxjs"

@Component({
    selector: "asociacion-contactos",
    templateUrl: "asociacion_contactos.component.html"
})
export class AsociacionContactosComponent implements OnInit {
    @Input() asociacionId: number
    asociacionContactos: AsociacionContacto[]
    params = AsociacionContactoView.viewParams
    tableButtonsArray: any

    sub: Subscription
    searchParams: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected asociacionContactosService: AsociacionContactos,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected asociacionContactosBuscadorService: AsociacionContactosBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.sub = this.asociacionContactosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll = (page: number = 1, count: number = 20) => {
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            asociacion_contacto: {
                asociacion_id: this.asociacionId,
                ...this.searchParams.asociacion_contacto
            }
        }
        params = Object.assign(params, this.searchParams)

        return this.asociacionContactosService.where(params).then((response: AsociacionContacto[], total: number) => {
            this.asociacionContactos = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(asociacionContacto: AsociacionContacto) {
        this.router.navigate(["asociaciones/" + this.asociacionId + "/asociacion_contactos/" + asociacionContacto.id])
    }

    edit(asociacionContacto: AsociacionContacto) {
        this.router.navigate([
            "asociaciones/" + this.asociacionId + "/asociacion_contactos/" + asociacionContacto.id + "/edit"
        ])
    }

    delete(id: number) {
        this.asociacionContactosService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showModalDelete(asociacionContacto: AsociacionContacto) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este contacto?")
        this.simpleModalService.setModalCallback(() => this.delete(asociacionContacto.id))
    }
}
