import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent } from "@puntaje/shared/core"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import {
    InstrumentoPredefinido,
    InstrumentoPredefinidos,
    InstrumentoPredefinidoView
} from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { InstrumentoPredefinidosBuscadorService } from "./instrumento-predefinidos-buscador/instrumento-predefinidos-buscador.service"

@Component({
    selector: "instrumento-predefinidos",
    templateUrl: "instrumento_predefinidos.component.html"
})
export class InstrumentoPredefinidosComponent implements OnInit {
    @Input() listRoute: string
    instrumentoPredefinidos: InstrumentoPredefinido[]
    asignaturas: { [id: number]: Asignatura } = {}
    generador_instrumentos: { [id: number]: GeneradorInstrumento } = {}
    params = InstrumentoPredefinidoView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any

    buscadorReady = false

    constructor(
        protected instrumentoPredefinidosService: InstrumentoPredefinidos,
        protected instrumentoPredefinidosBuscadorService: InstrumentoPredefinidosBuscadorService,
        protected asignaturasService: Asignaturas,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected router: Router,
        protected simpleModalService: SimpleModalService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.asignaturasService.where().then((response: Asignatura[]) => {
            response.forEach((a, i) => {
                this.asignaturas[a.id] = a
            })
        })

        this.sub = this.instrumentoPredefinidosBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    onBuscadorReady() {
        this.buscadorReady = true
        this.loadingLayout.ready()
    }

    showAll(page: number = 1, count: number = 10) {
        let params: any = { page, per: count }
        params = Object.assign({}, params, this.searchParams)
        if (params["instrumento_predefinido"]) {
            if (params["instrumento_predefinido"]["generador_instrumento_id"]) {
                params["instrumento"] = {
                    generador_instrumento_id: params["instrumento_predefinido"]["generador_instrumento_id"]
                }
            }
        }

        return this.instrumentoPredefinidosService
            .where(params)
            .then((response: InstrumentoPredefinido[], total: number) => {
                this.instrumentoPredefinidos = response
                this.instrumentoPredefinidos = this.instrumentoPredefinidos.map((i, index) => {
                    i.asignatura = this.asignaturas[i.asignatura_id]
                    return i
                })
                this.loadingLayout.ready()
                return total
            })
    }

    edit(instrumentoPredefinido: InstrumentoPredefinido) {
        this.router.navigate([this.listRoute + "/" + instrumentoPredefinido.id + "/edit"])
    }

    delete(id: number) {
        this.instrumentoPredefinidosService.remove(id).then(response => {
            //this.router.navigate([this.listRoute]);
            location.reload()
            //this.paginator.changePage(this.paginator.currentPage);
        })
    }

    showModalDelete(instrumentoPredefinido: InstrumentoPredefinido) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este instrumento predefinido?")
        this.simpleModalService.setModalCallback(() => this.delete(instrumentoPredefinido.id))
    }
}
