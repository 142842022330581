<ll-titulo>Editar contacto #{{ establecimientoContactoId }}</ll-titulo>
<ll-toolnavbar>
    <establecimiento-contacto-toolnavbar
        [displayButtons]="'all'"
        [establecimientoId]="establecimientoId"
        [establecimientoContactoId]="establecimientoContactoId"
    ></establecimiento-contacto-toolnavbar>
</ll-toolnavbar>

<establecimiento-contactos-edit
    [establecimientoId]="establecimientoId"
    [establecimientoContactoId]="establecimientoContactoId"
></establecimiento-contactos-edit>
