import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    EstablecimientoContacto,
    EstablecimientoContactos,
    EstablecimientoContactoForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { RecordatorioModalComponent } from "./modal/recordatorio_modal.component"

@Component({
    selector: "establecimiento-contactos-new",
    templateUrl: "establecimiento_contactos.form.component.html"
})
export class EstablecimientoContactosNewComponent implements OnInit {
    @Input() establecimientoId: number

    establecimientoContacto: EstablecimientoContacto = new EstablecimientoContacto()
    params = EstablecimientoContactoForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar establecimiento"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    @ViewChild(RecordatorioModalComponent, { static: true }) RecordatorioModalComponent: RecordatorioModalComponent

    constructor(
        protected establecimientoContactosService: EstablecimientoContactos,
        protected simpleModalService: SimpleModalService,
        protected router: Router
    ) {}

    ngOnInit() {
        this.establecimientoContacto.resuelto = false
        this.establecimientoContacto.establecimiento_id = this.establecimientoId
        this.form = EstablecimientoContactoForm.getForm(this.establecimientoContacto)
        this.loadingLayout.ready()
    }

    presave() {
        if (!this.establecimientoContacto.resuelto) {
            this.showModalRecordatorio()
        } else {
            this.save()
        }
    }

    save() {
        EstablecimientoContactoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            ;(this.establecimientoContacto as any).tipo_contacto_ids = this.establecimientoContacto.tipo_contactos.map(
                tc => tc.id
            )

            this.establecimientoContactosService.save(this.establecimientoContacto).then(response => {
                this.router.navigate(["establecimientos/" + this.establecimientoId + "/establecimiento_contactos"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimientoContacto = new EstablecimientoContacto()
            this.establecimientoContacto.establecimiento_id = this.establecimientoId
            EstablecimientoContactoForm.markFormControlsAsPristine(this.form)
            EstablecimientoContactoForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    showModalRecordatorio() {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Desea crear un recordatorio?")
        this.simpleModalService.setModalCallback(() => this.creaRecordatorio())
        this.simpleModalService.cancelModalCallback(() => this.save())
        this.simpleModalService.showSimpleModal()
    }

    creaRecordatorio() {
        this.RecordatorioModalComponent.open()
        this.simpleModalService.cleanModal()
    }
}
