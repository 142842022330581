import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { BannerZona, BannerZonas, BannerZonaForm } from "@puntaje/puntaje/api-services"
import { FormsModule } from "@angular/forms"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "bannerZonas-new",
    templateUrl: "banner_zonas.form.component.html"
})
export class BannerZonasNewComponent implements OnInit {
    @Input() listRoute: string
    bannerZona: BannerZona = new BannerZona()
    params = BannerZonaForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar banner_zona"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(private bannerZonasService: BannerZonas, private router: Router) {}

    ngOnInit() {
        this.form = BannerZonaForm.getForm(this.bannerZona)
        this.loadingLayout.ready()
    }

    save() {
        BannerZonaForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.bannerZonasService.save(this.bannerZona).then(response => {
                this.router.navigate([this.listRoute])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.bannerZona = new BannerZona()
            BannerZonaForm.markFormControlsAsPristine(this.form)
            BannerZonaForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }
}
