import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    EstablecimientoReporte,
    EstablecimientoReportes,
    EstablecimientoReporteForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "establecimiento-reportes-edit",
    templateUrl: "establecimiento_reportes.form.component.html"
})
export class EstablecimientoReportesEditComponent implements OnInit {
    @Input() establecimientoReporteId: number
    @Input() establecimientoId: number

    oEstablecimientoReporte: EstablecimientoReporte = new EstablecimientoReporte()
    establecimientoReporte: EstablecimientoReporte = new EstablecimientoReporte()
    params = EstablecimientoReporteForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected establecimientoReportesService: EstablecimientoReportes,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = EstablecimientoReporteForm.getForm(this.establecimientoReporte)
        this.establecimientoReportesService
            .find(this.establecimientoReporteId)
            .then((response: EstablecimientoReporte) => {
                this.establecimientoReporte = response
                this.oEstablecimientoReporte = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    save() {
        EstablecimientoReporteForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.establecimientoReportesService
                .update(this.establecimientoReporte.id, this.establecimientoReporte)
                .then(response => {
                    this.router.navigate(["establecimientos/" + this.establecimientoId + "/establecimiento_reportes"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimientoReporte = this.oEstablecimientoReporte.clone()
            EstablecimientoReporteForm.markFormControlsAsPristine(this.form)
            EstablecimientoReporteForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }
}
