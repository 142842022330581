<p class="titulo-pagina">{{ bannerPagina.pagina }}</p>
<loading-layout #loadingLayout>
    <ng-container *ngFor="let bz of bannerZonas">
        <div
            class="zone-wrap"
            *ngIf="instanciasPorZona && instanciasPorZona[bz.id] && instanciasPorZona[bz.id].length > 0"
        >
            <p>{{ bz.zona }}</p>
            <div class="image-gallery">
                <a
                    [routerLink]="['/banners', bi.banner_id]"
                    class="image-wrap"
                    [popover]="bipopover"
                    triggers="mouseenter:mouseleave"
                    *ngFor="let bi of instanciasPorZona[bz.id]"
                >
                    <ng-template #bipopover>
                        <p>
                            <b>{{ bi.banner.nombre }}</b>
                        </p>
                        Impresiones: {{ bi.impresiones | easyplaceholder: "-" }}
                        <br />
                        Clicks: {{ bi.clicks | easyplaceholder: "-" }}
                    </ng-template>
                    <ng-container
                        *ngIf="bi.banner['image_banners'] && bi.banner['image_banners'].length > 0; else noImage"
                    >
                        <img [src]="bi.banner['image_banners'][0].image_url" />
                    </ng-container>
                    <ng-template #noImage>
                        {{ bi.banner_id }}
                    </ng-template>
                </a>
            </div>
        </div>
    </ng-container>
</loading-layout>
