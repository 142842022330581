import { Component, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./editar-evaluacion-recomendada.component.html",
    styleUrls: ["./editar-evaluacion-recomendada.component.scss"]
})
export class EditarEvaluacionRecomendadaComponent implements OnInit {
    evaluacionSugeridaId: number
    protected sub: Subscription
    subData: Subscription

    showEvaluacionMultiple = false

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.evaluaciones.evaluaciones_recomendadas.edit")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionSugeridaId = +params["id"]
        })

        this.subData = this.route.data.subscribe(data => {
            this.showEvaluacionMultiple = data.showEvaluacionMultiple ?? this.showEvaluacionMultiple
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
