import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Establecimiento,
    Establecimientos,
    TypeformWidget,
    TypeformWidgetes,
    TypeformWidgetForm
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "typeform-widgets-new",
    templateUrl: "typeform-widgets.form.component.html"
})
export class TypeformWidgetsNewComponent implements OnInit {
    typeformWidget: TypeformWidget = new TypeformWidget()
    params = TypeformWidgetForm.formParams
    form: UntypedFormGroup
    logged_layout_title = "Agregar Typeform widget"
    display_buttons_options = "all"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"
    establecimiento: Establecimiento
    establecimientos: Establecimiento[] = []
    pais: string = config.plataforma.pais
    identificadorEstablecimiento: string = config.plataforma.identificadorEstablecimiento[0]

    constructor(
        protected typeformWidgetsService: TypeformWidgetes,
        protected establecimientosService: Establecimientos,
        protected router: Router
    ) {}

    ngOnInit() {
        this.form = TypeformWidgetForm.getForm(this.typeformWidget)
        this.loadingLayout.ready()
    }

    save() {
        TypeformWidgetForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.typeformWidget.lugar_id = this.form.controls["lugar"].value["id"]
            this.typeformWidgetsService.save(this.typeformWidget).then(response => {
                this.router.navigate(["typeform_widgets"])
            })
        }
    }

    clear() {
        setTimeout(() => {
            this.typeformWidget = new TypeformWidget()
            TypeformWidgetForm.markFormControlsAsPristine(this.form)
            TypeformWidgetForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    onEstablecimientoAutocompleteChange(establecimientoStr: string) {
        const params: any = {
            page: 1,
            per: 100,
            raw: 1,
            render_options: {
                include: {
                    [`establecimiento_${this.pais}`]: null
                }
            }
        }
        const paramsEstablecimientos = {
            ...params,
            establecimiento: {
                establecimiento: establecimientoStr
            }
        }

        const paramsEstablecimientosPais = {
            ...params,
            [`establecimiento_${this.pais}`]: {
                [this.identificadorEstablecimiento]: establecimientoStr
            }
        }

        const establecimientosPromise = this.establecimientosService.where(paramsEstablecimientos)
        const establecimientosByIdPaisPromise = this.establecimientosService.where(paramsEstablecimientosPais)

        Promise.all([establecimientosPromise, establecimientosByIdPaisPromise]).then(
            ([establecimientos, establecimientosByIdPais]) => {
                this.establecimientos = establecimientosByIdPais.concat(establecimientos)
            }
        )
    }

    async onEstablecimientoSelected(establecimiento: Establecimiento) {
        if (establecimiento) {
            this.form.controls["establecimiento_id"].setValue(establecimiento.id)
        }
    }
}
