import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    EstablecimientoContacto,
    EstablecimientoContactos,
    EstablecimientoContactoForm,
    Recordatorio
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { RecordatorioModalComponent } from "./modal/recordatorio_modal.component"

@Component({
    selector: "establecimiento-contactos-edit",
    templateUrl: "establecimiento_contactos.form.component.html"
})
export class EstablecimientoContactosEditComponent implements OnInit {
    @Input() establecimientoContactoId: number
    @Input() establecimientoId: number

    oEstablecimientoContacto: EstablecimientoContacto = new EstablecimientoContacto()
    establecimientoContacto: EstablecimientoContacto = new EstablecimientoContacto()
    recordatorio: Recordatorio = new Recordatorio()

    params = EstablecimientoContactoForm.formParams
    form: UntypedFormGroup
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(RecordatorioModalComponent, { static: true }) RecordatorioModalComponent: RecordatorioModalComponent

    display_buttons_options = "all new"
    save_button_text = "Guardar"
    clear_button_text = "Deshacer"

    constructor(
        protected establecimientoContactosService: EstablecimientoContactos,
        protected simpleModalService: SimpleModalService,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = EstablecimientoContactoForm.getForm(this.establecimientoContacto)
        this.establecimientoContactosService
            .find(this.establecimientoContactoId)
            .then((response: EstablecimientoContacto) => {
                this.establecimientoContacto = response
                this.oEstablecimientoContacto = response.clone()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
            })
    }

    presave() {
        if (!this.establecimientoContacto.resuelto) {
            this.showModalRecordatorio()
        } else {
            this.save()
        }
    }

    save() {
        EstablecimientoContactoForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            ;(this.establecimientoContacto as any).tipo_contacto_ids = this.establecimientoContacto.tipo_contactos.map(
                tc => tc.id
            )

            this.establecimientoContactosService
                .update(this.establecimientoContacto.id, this.establecimientoContacto)
                .then(response => {
                    this.router.navigate(["establecimientos/" + this.establecimientoId + "/establecimiento_contactos"])
                })
        }
    }

    clear() {
        setTimeout(() => {
            this.establecimientoContacto = this.oEstablecimientoContacto.clone()
            EstablecimientoContactoForm.markFormControlsAsPristine(this.form)
            EstablecimientoContactoForm.markFormControlsAsUntouched(this.form)
            this.cdr.detectChanges()
        }, 150)
    }

    showModalRecordatorio() {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Desea crear un recordatorio?")
        this.simpleModalService.setModalCallback(() => this.creaRecordatorio())
        this.simpleModalService.cancelModalCallback(() => this.save())
        this.simpleModalService.showSimpleModal()
    }

    creaRecordatorio() {
        this.RecordatorioModalComponent.open()
        this.simpleModalService.cleanModal()
    }
}
