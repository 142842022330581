import { Component, Directive, ViewContainerRef, ContentChild, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ViewLayout } from "./view_layout.component"

@Component({
    selector: "model-table-cell-layout",
    template: `
        <ng-template #viewValue></ng-template>
    `
})
export class ModelTableCellLayout extends ViewLayout {
    constructor(private chref: ChangeDetectorRef) {
        super(chref)
    }
}
