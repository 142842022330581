import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { BannerZona, BannerPagina, BannerInstancia, BannerInstancias } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "artezonas-activas-pagina",
    templateUrl: "banners-activos-pagina.component.html",
    styleUrls: ["banners-activos-pagina.component.scss"]
})
export class BannersActivosPaginaComponent implements OnInit {
    @Input() bannerPagina: BannerPagina
    @Input() bannerZonas: BannerZona[]
    bannerInstancias: BannerInstancia[]
    instanciasPorZona: any = {}
    _hideOutOfDate: boolean = true
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    @Input()
    set hideOutOfDate(hideOutOfDate: boolean) {
        this._hideOutOfDate = hideOutOfDate
        this.getData()
    }
    get hideOutOfDate() {
        return this._hideOutOfDate
    }

    constructor(private bannerInstanciasService: BannerInstancias) {}

    ngOnInit() {
        this.getData()
    }

    getData() {
        if (this.loadingLayout) this.loadingLayout.standby()
        let params: any = {
            banner_instancia: {
                banner_pagina_id: this.bannerPagina.id,
                activo: 1
            },
            include: "[banner:[image_banners,iframe_banners]]"
        }
        if (this._hideOutOfDate) {
            const today = new Date().toISOString()
            params.banner = {
                lte: {
                    fecha_inicial: [today, "null"]
                },
                gte: {
                    fecha_final: [today, "null"]
                }
            }
        }
        this.bannerInstanciasService.where(params).then((response: BannerInstancia[]) => {
            this.bannerInstancias = response
            this.bannerZonas.forEach(bz => {
                this.instanciasPorZona[bz.id] = this.bannerInstancias.filter(bi => bi.banner_zona_id == bz.id)
            })
            this.loadingLayout.ready()
        })
    }
}
