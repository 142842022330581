import { Component, ViewChild, OnInit, Input } from "@angular/core"
import {
    Asociacion,
    Asociaciones,
    AsociacionView,
    Establecimiento,
    Establecimientos,
    AsociacionEstablecimiento,
    AsociacionEstablecimientos,
    AsociacionUsuario,
    AsociacionUsuarios,
    Usuario,
    Usuarios,
    AsociacionMonitores,
    AsociacionMonitor
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "asociacion",
    templateUrl: "asociacion.component.html",
    styleUrls: ["asociacion.component.scss"]
})
export class AsociacionComponent implements OnInit {
    @Input() asociacionId: number
    config: typeof config = config
    asociacion: Asociacion
    params = AsociacionView.viewParams
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    filteredEstablecimientos: Establecimiento[]
    establecimiento: Establecimiento

    establecimientoAdmin: Establecimiento

    filteredUsuarios: Usuario[]
    usuario: Usuario

    showAssociateEstablecimiento: boolean = false
    showAssociateUsuario: boolean = false
    showAssociateMonitor: boolean = false

    constructor(
        protected asociacionesService: Asociaciones,
        protected establecimientosService: Establecimientos,
        protected asociacionEstablecimientosService: AsociacionEstablecimientos,
        protected asociacionUsuariosService: AsociacionUsuarios,
        protected asociacionMonitoresService: AsociacionMonitores,
        protected usuariosService: Usuarios
    ) {}

    ngOnInit() {
        this.asociacionesService
            .find(this.asociacionId, { include: "[usuarios,monitores,establecimientos]" })
            .then((response: Asociacion) => {
                this.asociacion = response
                this.loadingLayout.ready()
            })
    }

    toggleUsuarios() {
        this.showAssociateUsuario = !this.showAssociateUsuario
    }

    toggleMonitores() {
        this.showAssociateMonitor = !this.showAssociateMonitor
    }

    getUsuarios(usuarioFilter: string) {
        this.usuariosService
            .where({
                per: 100,
                raw: 1,
                usuario: { nombre_completo: usuarioFilter },
                include: "[usuario_chile,usuario_colombia]"
            })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
                if (this.filteredUsuarios.length < 1) {
                    if (this.config.plataforma.pais == "chile") {
                        this.getUsuariosByRut(usuarioFilter)
                    } else if (this.config.plataforma.pais == "colombia") {
                        this.getUsuariosByIdentificador(usuarioFilter)
                    }
                }
            })
    }

    getUsuariosByRut(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_chile: { rut: usuarioFilter }, include: "usuario_chile" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    getUsuariosByIdentificador(usuarioFilter: string) {
        this.usuariosService
            .where({ per: 100, raw: 1, usuario_colombia: { ti_o_cc: usuarioFilter }, include: "usuario_colombia" })
            .then((usuarios: Usuario[]) => {
                this.filteredUsuarios = usuarios
            })
    }

    deleteUsuario(usuario) {
        let params = {
            asociacion_usuario: {
                asociacion_id: this.asociacion.id,
                usuario_id: usuario.id
            }
        }

        this.asociacionUsuariosService.where(params).then((asociacionUsuarios: AsociacionUsuario[]) => {
            let asociacion = new Asociacion()
            asociacionUsuarios.forEach(ae => ((ae as any)._destroy = true))
            asociacion.asociacion_usuarios = asociacionUsuarios

            this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
                asociacionUsuarios.forEach(ae => {
                    let index = this.asociacion.usuarios.findIndex(u => u.id == ae.usuario_id)
                    asociacion.usuarios = this.asociacion.usuarios.splice(index, 1)
                })
            })
        })
    }

    associateUsuario() {
        let asociacion = new Asociacion()
        let asociacionUsuario = new AsociacionUsuario()
        asociacionUsuario.asociacion_id = this.asociacion.id
        asociacionUsuario.usuario_id = this.usuario.id

        asociacion.asociacion_usuarios = [asociacionUsuario]

        this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
            this.asociacion.usuarios.push(this.usuario)
            this.showAssociateUsuario = false
            this.filteredUsuarios = []
            this.usuario = null
        })
    }

    deleteMonitor(usuario) {
        let params = {
            asociacion_monitor: {
                asociacion_id: this.asociacion.id,
                monitor_id: usuario.id
            }
        }

        this.asociacionMonitoresService.where(params).then((asociacionMonitores: AsociacionMonitor[]) => {
            let asociacion = new Asociacion()
            asociacionMonitores.forEach(ae => ((ae as any)._destroy = true))
            asociacion.asociacion_monitores = asociacionMonitores

            this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
                asociacionMonitores.forEach(ae => {
                    let index = this.asociacion.monitores.findIndex(u => u.id == ae.monitor_id)
                    asociacion.monitores = this.asociacion.monitores.splice(index, 1)
                })
            })
        })
    }

    associateMonitor() {
        let asociacion = new Asociacion()
        let asociacionUsuario = new AsociacionMonitor()
        asociacionUsuario.asociacion_id = this.asociacion.id
        asociacionUsuario.monitor_id = this.usuario.id

        asociacion.asociacion_monitores = [asociacionUsuario]

        this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
            this.asociacion.monitores.push(this.usuario)
            this.showAssociateMonitor = false
            this.filteredUsuarios = []
            this.usuario = null
        })
    }

    toggleEstablecimientos() {
        this.showAssociateEstablecimiento = !this.showAssociateEstablecimiento
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({
                per: 100,
                // establecimiento: { establecimiento: establecimientoFilter },
                id_pais_establecimiento: establecimientoFilter,
                pais: config.plataforma.pais,
                include: `[establecimiento_${config.plataforma.pais}]`
            })
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    deleteEstablecimiento(establecimiento) {
        let params = {
            asociacion_establecimiento: {
                asociacion_id: this.asociacion.id,
                establecimiento_id: establecimiento.id
            }
        }

        this.asociacionEstablecimientosService
            .where(params)
            .then((asociacionEstablecimientos: AsociacionEstablecimiento[]) => {
                let asociacion = new Asociacion()
                asociacionEstablecimientos.forEach(ae => ((ae as any)._destroy = true))
                asociacion.asociacion_establecimientos = asociacionEstablecimientos

                this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
                    asociacionEstablecimientos.forEach(ae => {
                        let index = this.asociacion.establecimientos.findIndex(e => e.id == ae.establecimiento_id)
                        asociacion.establecimientos = this.asociacion.establecimientos.splice(index, 1)
                    })
                })
            })
    }

    associateEstablecimiento() {
        let asociacion = new Asociacion()
        let asociacionEstablecimiento = new AsociacionEstablecimiento()
        asociacionEstablecimiento.asociacion_id = this.asociacion.id
        asociacionEstablecimiento.establecimiento_id = this.establecimiento.id

        asociacion.asociacion_establecimientos = [asociacionEstablecimiento]

        this.asociacionesService.update(this.asociacion.id, asociacion).then(a => {
            this.asociacion.establecimientos.push(this.establecimiento)
            this.showAssociateEstablecimiento = false
            this.filteredEstablecimientos = []
            this.establecimiento = null
        })
    }

    logInAsProfesor(usuarioId: number) {
        this.usuariosService.enableIgnoreModel()
        this.usuariosService.getUrlWithToken(usuarioId, { application: "docente" }).then((obj: any) => {
            this.usuariosService.disableIgnoreModel()
            if (obj.url) {
                open(obj.url, "_blank")
            }
        })
    }
}
