import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./typeform-widget.component.html",
    styleUrls: ["./typeform-widget.component.scss"]
})
export class TypeformWidgetComponent implements OnInit {
    sub: Subscription
    typeformWidgetId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.typeform_widget.show")
        this.sub = this.route.params.subscribe(params => {
            this.typeformWidgetId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
