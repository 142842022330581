import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "cuentas.component.html"
})
export class CuentasComponent implements OnInit {
    usuario: Usuario
    usuarios: Usuario[]

    constructor(
        protected titleService: TitleService,
        protected usuariosService: Usuarios,
        protected route: ActivatedRoute,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.cuentas.list")
    }

    ngOnDestroy() {}
}
