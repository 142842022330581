import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Store } from "@ngrx/store"
import { Asignatura, ClasificacionTipo, ClasificacionTipos, Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"
import {
    Establecimiento,
    Estadisticas,
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionSugerida,
    EvaluacionSugeridas,
    EvaluacionTiempo,
    EvaluacionTiempos,
    EvaluacionTipo,
    GrupoUsuario,
    Usuario
} from "@puntaje/puntaje/api-services"
import { SetSelectedAsignatura, SetSelectedAsignaturaWithId, State } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "estadisticas-evaluacion-recomendada",
    templateUrl: "./estadisticas-evaluacion-recomendada.component.html",
    styleUrls: ["./estadisticas-evaluacion-recomendada.component.scss"]
})
export class EstadisticasEvaluacionRecomendadaComponent implements OnInit {
    @Input() evaluacionSugeridaId: number
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    pais = this.config.plataforma.pais

    evaluacionSugerida: EvaluacionSugerida
    evaluacion: Evaluacion
    evaluacionTipo: EvaluacionTipo
    asignatura: Asignatura

    estadisticas: any[]
    estadisticaUsuarios: any[]
    evaluacionInstancias: EvaluacionInstancia[]
    usuarios: Usuario[]
    evaluacionTiempos: EvaluacionTiempo[]

    distribucion_ensayos_tramo_puntaje: number[] = []
    desempeno_promedio_omitidas: number
    desempeno_promedio_correctas: number
    desempeno_promedio_incorrectas: number

    clasificacionTipos: ClasificacionTipo[]
    clasificacionTiposEstadisticas: any
    clasificacionReferenced: any
    clasificacionTipoByNombre: any

    establecimiento: Establecimiento
    grupoUsuario: GrupoUsuario

    dataRespuestas: any

    showGraphs: boolean = true

    constructor(
        protected evaluacionesService: Evaluaciones,
        protected evaluacionSugeridasService: EvaluacionSugeridas,
        protected estadisticasService: Estadisticas,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected preguntasService: Preguntas,
        protected clasificacionTiposService: ClasificacionTipos,
        protected config: AppConfig,
        protected store: Store<State>
    ) {}

    async ngOnInit() {
        this.loadingLayout.standby()

        this.evaluacionSugerida = await this.evaluacionSugeridasService.find(this.evaluacionSugeridaId)
        this.evaluacion = await this.evaluacionesService.find(this.evaluacionSugerida.evaluacion_id, {
            raw: 1,
            include:
                "[evaluacion_usuarios:[grupo_usuario:establecimiento],evaluacion_tipo,instrumento:[generador_instrumento,instrumento_preguntas]]"
        })
        this.evaluacionTipo = this.evaluacion.evaluacion_tipo
        this.asignatura = this.evaluacion.instrumento.generador_instrumento.asignatura_plataforma.asignatura
        this.store.dispatch(new SetSelectedAsignaturaWithId({ asignaturaId: this.asignatura.id })) // Esto porque por alguna razón, dentro de uno de los componentes de adentro lo necesita

        await this.setupClasificacionTipos()

        await this.setData()
    }

    async setupClasificacionTipos() {
        this.clasificacionReferenced = {
            ...this.config.evaluaciones[this.evaluacionTipo.evaluacion_tipo].clasificaciones.referencesTo
        }
        if (Object.values(this.clasificacionReferenced).length > 0) {
            const clasificacionTiposReferenced = await this.clasificacionTiposService.where({
                clasificacion_tipo: { clasificacion_tipo: Object.values(this.clasificacionReferenced) }
            })

            Object.keys(this.clasificacionReferenced).forEach(key => {
                this.clasificacionReferenced[key] = clasificacionTiposReferenced.find(
                    ctr => ctr.clasificacion_tipo == this.clasificacionReferenced[key]
                )
            })
        }

        this.clasificacionTiposEstadisticas = this.config.evaluaciones[
            this.evaluacionTipo.evaluacion_tipo
        ].clasificacionTiposEstadisticas.filter(ct => ct.profesores)

        let cTipos = this.clasificacionTiposEstadisticas.map(ct => ct.nombre)

        this.clasificacionTipos = <ClasificacionTipo[]>(
            await this.clasificacionTiposService.where({ clasificacion_tipo: { clasificacion_tipo: cTipos } })
        )
        this.clasificacionTipos = cTipos.map(ct => this.clasificacionTipos.find(cto => cto.clasificacion_tipo == ct))
        this.clasificacionTipoByNombre = this.clasificacionTipos.groupBy(ct => ct.clasificacion_tipo)
    }

    async setData() {
        this.loadingLayout.standby()

        this.showGraphs = !this.grupoUsuario && !this.establecimiento

        this.estadisticas = await this.estadisticasService.where({
            collection: "EstadisticaEvaluacion",
            estadistica: {
                evaluacion_id: this.evaluacion.id,
                oficial: 1
            }
        })

        const evaluacionInstanciaParams: any = {
            evaluacion_instancia: {
                evaluacion_id: this.evaluacion.id,
                oficial: 1
            },
            render_options: {
                include: {
                    evaluacion_instancia_asignaturas: null
                }
            }
        }

        if (this.grupoUsuario) {
            evaluacionInstanciaParams.evaluacion_instancia.usuario_id = this.grupoUsuario.usuarios.map(u => u.id)
        } else if (this.establecimiento) {
            evaluacionInstanciaParams.establecimiento = { id: this.establecimiento.id }
            evaluacionInstanciaParams.no_compartida_curso = 1
        }
        evaluacionInstanciaParams.render_options.include = {
            ...evaluacionInstanciaParams.render_options.include,
            usuario: {
                include: {
                    grupo_usuarios: {
                        include: { establecimiento: null }
                    }
                }
            }
        }
        evaluacionInstanciaParams.render_options.include.usuario.include[`usuario_${this.pais}`] = null

        this.evaluacionInstancias = await this.evaluacionInstanciasService.where(evaluacionInstanciaParams)
        this.evaluacionTiempos = await this.evaluacionTiemposService.where({
            evaluacion_tiempo: { evaluacion_id: this.evaluacion.id }
        })

        const usuarios = this.evaluacionInstancias.map(ei => {
            ei.usuario.grupo_usuarios = ei.usuario.grupo_usuarios.sort((g1, g2) => g2.id - g1.id)

            return ei.usuario
        })

        if (this.establecimiento && !this.grupoUsuario) {
            this.usuarios = usuarios.filter(u =>
                u.grupo_usuarios.find(gu => gu.establecimiento.id == this.establecimiento.id)
            )
        } else if (this.establecimiento && this.grupoUsuario) {
            this.usuarios = usuarios.filter(u => u.grupo_usuarios.find(gu => gu.id == this.grupoUsuario.id))
        } else {
            this.usuarios = usuarios
        }

        // this.usuarios =
        //     this.establecimiento && !this.grupoUsuario
        //         ? this.establecimiento.grupo_usuarios.map(gu => gu.alumnos).flat()
        //         : this.establecimiento && this.grupoUsuario
        //         ? this.grupoUsuario.alumnos
        //         : this.evaluacionInstancias.map(ei => {
        //               ei.usuario.grupo_usuarios = ei.usuario.grupo_usuarios.sort((g1, g2) => g2.id - g1.id)

        //               return ei.usuario
        //           })

        const estadisticaInstanciaParams: any = {
            collection: "EstadisticaInstanciaClasificacion",
            estadistica: { evaluacion_instancia_id: this.evaluacionInstancias.map(ei => ei.id) },
            clasificacion_methods: this.config.evaluaciones[this.evaluacionTipo.evaluacion_tipo].clasificaciones.methods
        }

        if (this.estadisticas[0]) {
            estadisticaInstanciaParams.estadistica.generador_instrumento_id =
                this.estadisticas[0].generador_instrumento_id
        }

        const estadisticaInstanciaClasificaciones = <any[]>(
            await this.estadisticasService.wherePost(estadisticaInstanciaParams)
        )
        this.estadisticaUsuarios = estadisticaInstanciaClasificaciones.map(eic => {
            const data: any = {}
            const evaluacionInstancia = this.evaluacionInstancias.find(ei => ei.id == eic.evaluacion_instancia_id)

            data.usuario_id = evaluacionInstancia.usuario_id
            data.estadistica_clasificaciones = eic.estadistica_clasificaciones

            return data
        })

        this.setValues()

        this.loadingLayout.ready()
    }

    setValues() {
        this.distribucion_ensayos_tramo_puntaje = this.estadisticas.reduce((acc, e) => {
            Object.keys(e.distribucion_calificaciones).forEach(key => {
                const calificacion = +key.replace(",", ".")

                acc[calificacion] = acc[calificacion] || 0
                acc[calificacion] += e.distribucion_calificaciones[key]
            })

            return acc
        }, {})

        let estadistica_clasificaciones = {}
        this.estadisticas.forEach(e => {
            e.estadistica_clasificaciones &&
                e.estadistica_clasificaciones.forEach(ec => {
                    if (!estadistica_clasificaciones[ec.clasificacion_id]) {
                        estadistica_clasificaciones[ec.clasificacion_id] = ec
                    } else {
                        estadistica_clasificaciones[ec.clasificacion_id].correctas += ec.correctas
                        estadistica_clasificaciones[ec.clasificacion_id].incorrectas += ec.incorrectas
                        estadistica_clasificaciones[ec.clasificacion_id].omitidas += ec.omitidas
                    }
                })
        })

        estadistica_clasificaciones = (Object as any).values(estadistica_clasificaciones)

        this.clasificacionTipos.forEach(ct => {
            let ecs = (estadistica_clasificaciones as any[]).filter(
                ec => ec.clasificacion.clasificacion_tipo_id == ct.id
            )

            let nombre = (ct as any).clasificacion_tipo

            this["desempeno_" + nombre] = ecs.map(
                ec =>
                    ec.clasificacion.clasificacion +
                    (ec.clasificacion.curso ? " (" + ec.clasificacion.curso.clasificacion + ")" : "")
            )
            this["desempeno_" + nombre + "_omitidas"] = ecs.map(ec => ec.omitidas)
            this["desempeno_" + nombre + "_incorrectas"] = ecs.map(ec => ec.incorrectas)
            this["desempeno_" + nombre + "_correctas"] = ecs.map(ec => ec.correctas)
            this["desempeno_" + nombre + "_total"] = ecs.map(ec => ec.omitidas + ec.incorrectas + ec.correctas)
        })

        let numero_evaluaciones = this.estadisticas.reduce((x, e) => x + e.numero_evaluaciones, 0)
        this.desempeno_promedio_omitidas = ~~(
            this.estadisticas.reduce((x, e) => x + e.omitidas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_incorrectas = ~~(
            this.estadisticas.reduce((x, e) => x + e.incorrectas, 0) / numero_evaluaciones
        )
        this.desempeno_promedio_correctas = ~~(
            this.estadisticas.reduce((x, e) => x + e.correctas, 0) / numero_evaluaciones
        )
    }

    async onFilter({
        grupoUsuario,
        establecimiento
    }: {
        grupoUsuario?: GrupoUsuario
        establecimiento?: Establecimiento
    }) {
        this.grupoUsuario = grupoUsuario
        this.establecimiento = establecimiento

        await this.setData()
    }

    onResultadosPorPreguntaReady(data) {
        this.dataRespuestas = data
    }

    print() {
        window.print()
    }
}
