import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { StateService, PaginatorComponent, AuthService } from "@puntaje/shared/core"
import { Usuario, Usuarios, Asociacion, Asociaciones, AsociacionView } from "@puntaje/puntaje/api-services"
import { SimpleModalService, LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AsociacionesBuscadorService } from "./asociaciones_buscador.service"

@Component({
    selector: "asociaciones",
    templateUrl: "asociaciones.component.html"
})
export class AsociacionesComponent implements OnInit {
    @Input() mios: boolean

    asociaciones: Asociacion[]
    params = AsociacionView.viewParams
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("paginator", { static: true }) paginator: PaginatorComponent

    sub: Subscription
    searchParams: any

    constructor(
        protected asociacionesService: Asociaciones,
        protected router: Router,
        protected authService: AuthService,
        protected asociacionesBuscadorService: AsociacionesBuscadorService,
        protected simpleModalService: SimpleModalService
    ) { }

    ngOnInit() {
        this.tableButtonsArray = {
            contactos: { function: this.contactos.bind(this), text: "Contactos", glyphicon: "glyphicon-list" },
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }

        this.sub = this.asociacionesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = { ...params }
            if (this.paginator.getList) this.paginator.reload()
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes["mios"] &&
            !changes["mios"].firstChange &&
            changes["mios"].currentValue != changes["mios"].previousValue
        ) {
            this.paginator.changePage(1)
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showAll(page: number = 1, count: number = 20) {
        this.loadingLayout.standby()
        let params: any = { page, per: count }
        params = {
            ...params,
            ...this.searchParams
        }

        if (this.mios) {
            params.asociacion_monitor = { monitor_id: this.authService.getUserData().id }
        }

        return this.asociacionesService.where(params).then((response: Asociacion[], total: number) => {
            this.asociaciones = response
            this.loadingLayout.ready()

            return total
        })
    }

    view(asociacion: Asociacion) {
        this.router.navigate(["asociaciones/" + asociacion.id])
    }

    edit(asociacion: Asociacion) {
        this.router.navigate(["asociaciones/" + asociacion.id + "/edit"])
    }

    delete(id: number) {
        this.asociacionesService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    contactos(asociacion: Asociacion) {
        this.router.navigate(["asociaciones/" + asociacion.id + "/asociacion_contactos"])
    }

    showModalDelete(asociacion: Asociacion) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta asociación?")
        this.simpleModalService.setModalCallback(() => this.delete(asociacion.id))
    }
}
