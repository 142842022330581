import { PuntajeNominasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { AdminLayoutsModule } from "../layouts/admin_layouts.module"
import { NominasModule } from "@puntaje/puntaje/new-modules/carga-nominas"

import { nominasRouting } from "./admin_nominas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AdminLayoutsModule,
        NominasModule,
        nominasRouting,
        PuntajeNominasModule
    ],
    declarations: [],
    exports: []
})
export class AdminNominasModule {}
