<form class="row" *ngIf="open">
    <div class="col-md-6">
        <div class="form-group">
            <label>ID</label>
            <input type="text" [(ngModel)]="asociacion.id" (ngModelChange)="toArray(asociacion, 'id')" name="id"
                class="form-control" />
        </div>

        <div class="form-group">
            <label>Nombre Asociación</label>
            <input type="text" [(ngModel)]="asociacion.like.asociacion"
                (ngModelChange)="deleteIfUndefined(asociacion.like, 'asociacion')" name="asociacion"
                class="form-control" />
        </div>

        <div class="form-group">
            <label>Monitor</label>
            <autocomplete ngModel [options]="filteredUsuarios" (filterChange)="getUsuarios($event)"
                (ngModelChange)="onSelectUsuario($event)" showTextFun="toStringDatos" name="monitor_id"></autocomplete>
        </div>

        <cui-button-link class="btn_style" (click)="search()">Buscar</cui-button-link>
    </div>
</form>