import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-lista-planificaciones",
    templateUrl: "./lista-planificaciones.component.html",
    styleUrls: ["./lista-planificaciones.component.scss"]
})
export class ListaPlanificacionesComponent implements OnInit {
    planPersonalTipo: string | string[]
    subData: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.lista_planificaciones")
        this.subData = this.route.data.subscribe(data => {
            this.planPersonalTipo = data.planPersonalTipo ?? this.planPersonalTipo
        })
    }

    ngOnDestroy() {
        this.subData.unsubscribe()
    }
}
