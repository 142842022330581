import { Component, OnInit, ViewChild, Input } from "@angular/core"
import {
    PlanPersonales,
    PlanPersonal,
    PlanPersonalView,
    PlanPersonalSesiones,
    PlanPersonalSesion,
    PlanClaseService
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"

@Component({
    selector: "planificacion-admin",
    templateUrl: "./planificacion-admin.component.html",
    styleUrls: ["./planificacion-admin.component.scss"]
})
export class PlanificacionAdminComponent implements OnInit {
    @Input() planificacionId: number
    @Input() enableReporte: boolean = true
    planificacion: PlanPersonal
    params = PlanPersonalView.viewParams
    planPersonalSesiones = []
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected planPersonalSesionesService: PlanPersonalSesiones,
        protected simpleModalService: SimpleModalService,
        protected planClaseService: PlanClaseService
    ) {}

    async ngOnInit() {
        this.loadingLayout.standby()
        await this.planClaseService.getData(this.planificacionId, null)
        this.planificacion = this.planClaseService.planPersonal
        this.planPersonalSesiones = this.planificacion.plan_personal_ciclos[0]?.plan_personal_sesiones.sort(
            (a, b) => a.orden - b.orden
        )
        this.loadingLayout.ready()
    }

    showModalDelete(opt: { clase: PlanPersonalSesion; index: number }) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar esta sesión?")
        this.simpleModalService.setModalCallback(() => this.deleteSesion(opt))
        this.simpleModalService.showSimpleModal()
    }

    deleteSesion(opt) {
        this.planPersonalSesionesService.remove(opt.clase.id).then(response => {
            this.planificacion.plan_personal_ciclos[0].plan_personal_sesiones.splice(opt.index, 1)
        })
    }
}
