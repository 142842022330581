import { UsuariosBuscadorService } from "./usuarios_buscador.service"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"

declare const config: AppConfig

@Component({
    selector: "usuarios-buscador",
    templateUrl: "usuarios_buscador.component.html",
    styleUrls: ["usuarios_buscador.component.scss"]
})
export class UsuariosBuscadorComponent implements OnInit, OnDestroy {
    usuario: any = { like: {} }
    idPaisUsuario: string
    pais: string = config.plataforma.pais
    idPais: any = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join("/")
    freemium: string

    enableCodigosLibros: boolean = config.plataforma.enableCodigosLibros
    buscarEstudiantesCodigoProfesor = false

    sub: Subscription
    subQueryParams: Subscription

    open = false

    tUsers: any[] = [
        { value: false, UserType: "Usuario normal" },
        { value: true, UserType: "Usuario pro" }
    ]

    constructor(
        public usuariosBuscadorService: UsuariosBuscadorService,
        public route: ActivatedRoute,
        public router: Router
    ) {}

    ngOnInit() {
        this.subQueryParams = this.route.queryParams.subscribe(params => {
            this.loadSearchParamsFromUrl(params)
        })
        this.sub = this.usuariosBuscadorService.openSearch.subscribe(() => {
            this.open = !this.open
        })
        this.search()
    }

    /**
     * Carga los query params en los inputs/select del form del buscador
     * @param params query params
     */
    loadSearchParamsFromUrl(params) {
        this.usuario.id = params["id"]
        this.usuario.like.nombre = params["nombre"]
        this.usuario.like.apellido_paterno = params["apellido_paterno"]
        this.usuario.like.apellido_materno = params["apellido_materno"]
        this.usuario.email = params["email"]
        this.idPaisUsuario = params["id_pais"]
        this.usuario.is_pro = params["is_pro"]
    }

    /**
     * Agrega los parámetros de busqueda a los query params
     */
    addSearchParamsToUrl() {
        this.router.navigate([], {
            queryParams: {
                id: this.usuario.id,
                nombre: this.usuario.like.nombre,
                apellido_paterno: this.usuario.like.apellido_paterno,
                apellido_materno: this.usuario.like.apellido_materno,
                email: this.usuario.email,
                id_pais: this.idPaisUsuario,
                is_pro: this.usuario.is_pro
            }
        })
    }

    search() {
        this.addSearchParamsToUrl()
        this.usuariosBuscadorService.search(this.usuario, this.idPaisUsuario, this.buscarEstudiantesCodigoProfesor)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQueryParams.unsubscribe()
    }
}
