import { SaveType, TextView, LinkView, DateView, BooleanView, HtmlView } from "@puntaje/shared/core"
import { Noticias } from "./noticias.service"

export class NoticiaView {
    public static get viewParams() {
        let self = this
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: Noticias, key: "id" } },
            titulo: { label: "Titulo", type: TextView, tableVisible: true },
            noticia: { label: "Noticia", type: HtmlView, tableVisible: true },
            destacado: { label: "Destacado", type: BooleanView, tableVisible: true },
            noticia_tipo: { label: "Tipo de Noticia", type: TextView, tableVisible: true },
            noticias_perfil: { label: "Noticia Perfil", type: TextView, tableVisible: true },
            tipo: { label: "Mostrar Popup", type: TextView, tableVisible: true },
            fecha_inicio: { label: "Fecha Inicio Noticia", type: DateView, tableVisible: true },
            fecha_termino: { label: "Fecha Termino Noticia", type: DateView, tableVisible: true },
            created_at: { label: "Fecha de creación", type: DateView, tableVisible: true },
            updated_at: { label: "Fecha de actualización", type: DateView, tableVisible: true }
        }
    }
}
