<ll-titulo>{{ usuario?.nombreCompleto() }}</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs" #tabList>
        <li role="presentation" [ngClass]="setTabClasses('perfil')">
            <a data-toggle="tab" href="#profile">Perfil</a>
        </li>
    </ul>
</ll-tabs>

<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="container-fluid">
            <div class="tab-content">
                <div id="profile" class="tab-pane fade" [ngClass]="setTabClasses('perfil')">
                    <cui-button-link
                        [routerLinkValue]="['/usuarios/editar']"
                        class="pull-right btn_style edit-btn"
                        *ngIf="!isPrivate"
                    >
                        <fa name="edit"></fa>
                        <span>&nbsp;Editar cuenta</span>
                    </cui-button-link>
                    <usuario-admin [usuarioId]="usuario.id" [enableShowCountryData]="true"></usuario-admin>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
