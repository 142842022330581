import { SaveType, TextView, LinkView, DateView, BooleanView, ArrayView, MultipleLinkView } from "@puntaje/shared/core"
import { AsociacionContactos } from "./asociacion_contactos.service"
import { Lugares } from "./lugares.service"
import { Asociaciones } from "./asociaciones.service"
import { Asociacion } from "./asociaciones.model"

export class AsociacionContactoView {
    public static get viewParams() {
        let self = this
        return {
            id: { label: "Id", type: LinkView, tableVisible: true, options: { class: AsociacionContactos, key: "id" } },
            fecha: { label: "Fecha", type: DateView, tableVisible: true },
            comentarios: { label: "Comentarios", type: TextView, tableVisible: true, pre_line_white_space: true },
            tipo_contactos: { label: "Tipo Contacto", type: ArrayView, tableVisible: true },
            efectivo: { label: "Efectivo", type: BooleanView, tableVisible: true },
            usuario: { label: "Reportado por", type: TextView, tableVisible: true },
            resuelto: { label: "Resuelto", type: BooleanView, tableVisible: true }
        }
    }
}
