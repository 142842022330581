import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router } from "@angular/router"
import {
    EstablecimientoReporte,
    EstablecimientoReportes,
    EstablecimientoReporteView
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent } from "@puntaje/shared/core"
import { EstablecimientoReportesBuscadorService } from "./establecimiento_reportes_buscador.service"
import { Subscription } from "rxjs"

@Component({
    selector: "establecimiento-reportes",
    templateUrl: "establecimiento_reportes.component.html"
})
export class EstablecimientoReportesComponent implements OnInit {
    @Input() establecimientoId: number
    establecimientoReportes: EstablecimientoReporte[]
    params = EstablecimientoReporteView.viewParams
    tableButtonsArray: any

    sub: Subscription
    searchParams: any = {}

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent

    constructor(
        protected establecimientoReportesService: EstablecimientoReportes,
        protected router: Router,
        protected simpleModalService: SimpleModalService,
        protected establecimientoReportesBuscadorService: EstablecimientoReportesBuscadorService
    ) {}

    ngOnInit() {
        this.tableButtonsArray = {
            view: { function: this.view.bind(this), text: "Ver", callModal: false, glyphicon: "glyphicon-search" },
            edit: { function: this.edit.bind(this), text: "Editar", glyphicon: "glyphicon-pencil" },
            delete: {
                function: this.showModalDelete.bind(this),
                text: "Borrar",
                callModal: true,
                glyphicon: "glyphicon-trash"
            }
        }
        this.sub = this.establecimientoReportesBuscadorService.searchParams.subscribe(params => {
            this.searchParams = params
            this.paginator.changePage(1)
        })
    }

    showAll(page: number = 1, count: number = 20) {
        let params = {
            page: page,
            per: count,
            sort_by: "created_at",
            order: "desc",
            establecimiento_reporte: {
                establecimiento_id: this.establecimientoId,
                ...this.searchParams.establecimiento_reporte
            }
        }
        params = Object.assign(params, this.searchParams)
        return this.establecimientoReportesService
            .where(params)
            .then((response: EstablecimientoReporte[], total: number) => {
                this.establecimientoReportes = response
                this.loadingLayout.ready()

                return total
            })
    }

    view(establecimientoReporte: EstablecimientoReporte) {
        this.router.navigate([
            "establecimientos/" + this.establecimientoId + "/establecimiento_reportes/" + establecimientoReporte.id
        ])
    }

    edit(establecimientoReporte: EstablecimientoReporte) {
        this.router.navigate([
            "establecimientos/" +
                this.establecimientoId +
                "/establecimiento_reportes/" +
                establecimientoReporte.id +
                "/edit"
        ])
    }

    delete(id: number) {
        this.establecimientoReportesService.remove(id).then(response => {
            this.paginator.changePage(this.paginator.currentPage)
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    showModalDelete(establecimientoReporte: EstablecimientoReporte) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent("¿Está seguro que desea eliminar este establecimiento?")
        this.simpleModalService.setModalCallback(() => this.delete(establecimientoReporte.id))
    }
}
